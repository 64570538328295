import { LayoutMenuButton } from '@/shared/ui/layout-navigation/styles'
import { MenuList, MenuListItem, MenuListItemText } from './styles'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useAppSelector } from '@/shared/hooks/types'
import { layoutHeaderSelector } from '@/app/store/selectors/layout.selectors'
import { DashboardButton } from '@/shared/ui/dashboard-button'
import { Box } from '@mui/material'

type Props = {
  handleDrawerClose: () => void
}

export const LayoutMenuList = ({ handleDrawerClose }: Props) => {
  const { t } = useTranslation()
  const header = useAppSelector(layoutHeaderSelector)
  const router = useRouter()

  const handleLoginClick = async () => {
    await router.push('/login')
    handleDrawerClose()
  }

  const handleLinkClicked = (link: string) => {
    router.push(link)
    handleDrawerClose()
  }

  return (
    <MenuList>
      {header &&
        header.map(text => (
          <MenuListItem key={text.title} disablePadding onClick={() => handleLinkClicked(text.url)}>
            <MenuListItemText>{t(text.title)}</MenuListItemText>
          </MenuListItem>
        ))}
      {router.pathname === '/submit-ticket' ? (
        <Box sx={{ mt: '24px' }} onClick={() => router.push('/watcher-dashboard')}>
          <DashboardButton onClick={handleDrawerClose} title={'Dashboard'} />
        </Box>
      ) : (
        <LayoutMenuButton sx={{ mt: '24px' }} onClick={handleLoginClick} variant='contained' disableRipple>
          {t('Login')}
        </LayoutMenuButton>
      )}
    </MenuList>
  )
}
