export const CheckIcon = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.1581 7.34171C24.6137 7.79732 24.6137 8.53601 24.1581 8.99162L14.1414 19.0084C12.7745 20.3752 10.5585 20.3752 9.19163 19.0084L5.00821 14.825C4.5526 14.3693 4.5526 13.6307 5.00821 13.175C5.46382 12.7194 6.20252 12.7194 6.65813 13.175L10.8415 17.3585C11.2972 17.8141 12.0358 17.8141 12.4915 17.3585L22.5082 7.34171C22.9638 6.8861 23.7025 6.8861 24.1581 7.34171Z'
        fill='#39BA71'
      />
    </svg>
  )
}
