import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'

export const MerchantsInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px 16px',
  gap: '10px',
  width: '100%',
  background: 'rgba(24, 106, 223, 0.1)',
  borderRadius: '16px',
  marginBottom: '24px'
}))

export const MerchantsCardImage = styled(Box)(() => ({
  background: '#D9D9D9',
  borderRadius: '16px',
  width: '100%',
  height: '312px'
}))

export const MerchantsIconImage = styled(Box)(() => ({
  background: '#186ADF',
  height: '86px',
  width: '86px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  marginBottom: '16px'
}))

export const MerchantsIconText = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '20px',
  lineHeight: '160%',
  letterSpacing: '0.25px',
  color: '#186ADF'
}))

export const MerchantsCardHeading = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '34px',
  lineHeight: '123.5%',
  letterSpacing: '0.25px',
  marginBottom: '12px',
  marginTop: '24px',
  color: theme.palette.common.white
}))

export const MerchantsCardText = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '0.25px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const MerchantsButton = styled(Button)(({ theme }) => ({
  width: '151px',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  textTransform: 'uppercase',
  letterSpacing: '0.25px',
  color: 'rgba(255, 255, 255, 0.75)',
  marginTop: '24px'
}))
