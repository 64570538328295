import Box from '@mui/material/Box'
import { SingleTicket, TicketsPagination } from '@/shared/ui/tickets'
import { useCallback, useDeferredValue } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/shared'
import { ticketsStateSelector } from '@/app/store/selectors'

type Props = {
  handleChange(e: any, p: number): void
}

export const TicketsList = ({ handleChange }: Props) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { list: tickets, meta } = useAppSelector(ticketsStateSelector)
  const count = Math.ceil(Number(meta?.total) / Number(meta?.perPage))

  const onTicketClick = useCallback(
    (id: string) => {
      router.push(`/support-tickets/${id}`)
    },
    [router]
  )

  const ticketsList = useDeferredValue(
    (tickets ?? []).map(ticket => (
      <Box key={ticket.id}>
        <SingleTicket ticket={ticket} handleClick={() => onTicketClick(String(ticket.id))} />
      </Box>
    ))
  )

  return (
    <Box>
      {ticketsList.length ? (
        <>
          <Box>{ticketsList}</Box>
          <TicketsPagination
            count={count}
            size='large'
            page={Number(meta?.currentPage)}
            shape='circular'
            onChange={handleChange}
          />
        </>
      ) : (
        <Box sx={{ textAlign: 'center', fontSize: '20px' }}>{t('No tickets yet...')}</Box>
      )}
    </Box>
  )
}
