import Head from 'next/head'

type MetaProps = {
  title?: string
  description?: string
  url?: string
  image?: string
  twitterImage?: string
  facebookImage?: string
  preview?: any
}

export const Meta = ({
  title = 'Crypto processing - Dashboard',
  description = `Crypto processing dashboard`,
  url = process.env.APP_REFERRAL_HOST
}: MetaProps) => {
  return (
    <>
      <Head>
        <title>{title}</title>

        {/*  meta */}
        <meta name='title' content={title} />
        <meta name='description' content={description} />

        {/*facebook */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content={url} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />

        {/*twitter */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={url} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={description} />
      </Head>
    </>
  )
}
