import { HeaderNavigation, HeaderNavigationButton, HeaderNavigationItem, HeaderNavigationWrapper } from './styles'
import { useTranslation } from 'react-i18next'
import { useCallback, useDeferredValue } from 'react'
import { useRouter } from 'next/router'
import { DashboardButton } from '@/shared/ui/dashboard-button'
import { useAppSelector } from '@/shared'
import { layoutHeaderSelector } from '@/app/store/selectors'

type Props = {
  handleDrawerClose?: () => void
}

export const LayoutNavigation = ({ handleDrawerClose }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const header = useAppSelector(layoutHeaderSelector)

  const handleLinkClicked = useCallback(
    (link: string) => {
      router.push(link)
      handleDrawerClose && handleDrawerClose()
    },
    [handleDrawerClose, router]
  )

  const navigation = useDeferredValue(
    header &&
      header.map(({ title, url }) => (
        <HeaderNavigationItem key={title} onClick={() => handleLinkClicked(url)}>
          {t(title)}
        </HeaderNavigationItem>
      ))
  )

  return (
    <HeaderNavigationWrapper>
      <HeaderNavigation>{navigation}</HeaderNavigation>
      {router.pathname === '/submit-ticket' ? (
        <DashboardButton title={'Dashboard'} onClick={() => router.push('/watcher-dashboard')} />
      ) : (
        <HeaderNavigationButton disableRipple variant='contained' onClick={() => router.push('/login')}>
          {t('Login')}
        </HeaderNavigationButton>
      )}
    </HeaderNavigationWrapper>
  )
}
