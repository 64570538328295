/**
 *  props for styled widgets
 */
export const paperProps = {
  style: {
    borderRadius: '10px',
    padding: 0,
    width: '230px',
    background: '#424242',
    marginTop: '15px',
    boxShadow:
      '0px 6px 6px -3px rgba(20, 21, 33, 0.2), 0px 10px 14px 1px rgba(20, 21, 33, 0.14), 0px 4px 18px 3px rgba(20, 21, 33, 0.12)'
  }
}
