import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const SetNewPasswordForm = styled('form')(() => ({
  maxWidth: 300,
  width: '100%',
  height: '100%'
}))

export const SetNewPasswordInner = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  flex: 1,
  flexDirection: 'column'
}))
