import { ImageWithState } from '@/shared/ui/image-with-state'
import { layoutHeaderLogoSelector } from '@/app/store/selectors'
import { useAppSelector } from '@/shared'

type Props = {
  height?: number
  width?: number
  color?: string
}

export const LogoCryptoWhite = ({ height = 36, width = 196, color }: Props) => {
  const logo = useAppSelector(layoutHeaderLogoSelector)

  return <ImageWithState src={logo ?? ''} height={height} width={width} alt='logo-crypto-white' color={color} />
}
