import { WithdrawalTicketNavigationContainer } from './styles'
import { WithdrawalTicketNavigationInfo, WithdrawalTicketOptionsMenu } from '@/shared/ui/withdrawal-one-ticket'
import { WithdrawalTicket } from '@/shared/types/withdrawal-tickets'
import { useAppSelector } from '@/shared/hooks/types'
import { useTranslation } from 'react-i18next'
import { TicketStatus } from '@/shared'
import { Box } from '@mui/material'
import { withdrawalTicketsStateSelector } from '@/app/store/selectors'

export const WithdrawalOneTicketNavigation = () => {
  const withdrawalTickets = useAppSelector(withdrawalTicketsStateSelector)
  const { t } = useTranslation()
  const ticket = withdrawalTickets?.withdrawal

  return (
    <WithdrawalTicketNavigationContainer>
      <WithdrawalTicketNavigationInfo id={Number(ticket?.id)} />
      <Box sx={{ display: 'flex' }}>
        <TicketStatus variant={'contained'} disableTouchRipple>
          {t(String(ticket?.status))}
        </TicketStatus>
        <WithdrawalTicketOptionsMenu ticket={ticket as WithdrawalTicket} />
      </Box>
    </WithdrawalTicketNavigationContainer>
  )
}
