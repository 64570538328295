import { Box, IconButton } from '@mui/material'
import { LayoutAppBar, LayoutCloseButton, LayoutDrawer, LayoutIconButton } from '@/shared/ui/layout-burger-menu/styles'
import { AppToolbar, HeaderWrapper, LogoWrapper } from '@/shared/ui/blank-layout-header/styles'
import { ElevationScroll, LayoutBurgerIcon, LogoCryptoBlack } from '@/shared/ui'
import { AppBarMenu, LanguageDropdown } from '@/shared/layouts'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import Image from 'next/image'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { LayoutMenuList } from '@/shared/ui/layout-burger-menu/layout-menu-list'
import { useScrollHidden } from '@/shared/hooks/useScrollHidden'

type Props = {
  isDashboard?: boolean
}

export const LayoutBurgerMenu = ({ isDashboard = false }: Props) => {
  const [open, setOpen] = useState(false)
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const router = useRouter()
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  useScrollHidden(open)

  const HeaderMenu = isDashboard ? AppBarMenu : LayoutMenuList

  const handleDrawerClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton edge='start' onClick={handleDrawerOpen} sx={{ ...(open && { display: 'none' }), padding: 0 }}>
        <LayoutIconButton>
          <LayoutBurgerIcon />
        </LayoutIconButton>
      </IconButton>
      <LayoutDrawer variant='persistent' anchor='top' open={open}>
        <ElevationScroll>
          <LayoutAppBar>
            <AppToolbar
              sx={{ borderBottom: '1px solid #F7F7F7', background: '#FFF', padding: '4px 16px', minHeight: 80 }}
            >
              <LogoWrapper
                onClick={() => {
                  router.push('/watcher-dashboard')
                  handleDrawerClose()
                }}
              >
                <LogoCryptoBlack height={isTablet ? 36 : 58} width={isTablet ? 160 : 238} />
              </LogoWrapper>
              <HeaderWrapper>
                <LanguageDropdown isAuthPages />
                <IconButton edge='start' onClick={handleDrawerClose} sx={{ padding: 0 }}>
                  <LayoutCloseButton>
                    <Image src='/images/icons/close.svg' height={24} width={24} loading='lazy' alt='close-icon' />
                  </LayoutCloseButton>
                </IconButton>
              </HeaderWrapper>
            </AppToolbar>
            <HeaderMenu handleDrawerClose={handleDrawerClose} />
          </LayoutAppBar>
        </ElevationScroll>
      </LayoutDrawer>
    </Box>
  )
}
