import { BackIcon, InfoTicketHash, InfoTicketName, InfoWrapper } from './styles'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

type Props = {
  id: number
}

export const WithdrawalTicketNavigationInfo = ({ id }: Props) => {
  const router = useRouter()
  const { t } = useTranslation()

  const handleBack = () => {
    router.push('/withdrawals-tickets')
  }

  return (
    <InfoWrapper>
      <BackIcon onClick={handleBack} />
      <InfoTicketName>{t('Ticket ID')}</InfoTicketName>
      <InfoTicketHash>#{id}</InfoTicketHash>
    </InfoWrapper>
  )
}
