import { Box, Checkbox, FormControl, ListItemIcon } from '@mui/material'
import { memo, useEffect, useLayoutEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import {
  FormSelectLabel,
  PaymentsMenuItem,
  PaymentsMenuItemText,
  PaymentsMenuSelectAll,
  PaymentsSelect,
  SelectedRenderValue,
  SelectResetButton
} from './styles'
import { useTranslation } from 'react-i18next'
import { useAppSelector, UsersHistoryFilters } from '@/shared'
import { historyStateSelector } from '@/app/store/selectors/history.selectors'
import { paperProps } from './utils'

type Props = {
  label: string
  onFilterCollect(filters: Partial<UsersHistoryFilters>): void
}

export const PaymentsFiltersBlockchainDropdown = memo(({ label, onFilterCollect }: Props) => {
  const {
    history: { currencies, filters }
  } = useAppSelector(historyStateSelector)
  const options = (currencies ?? [])?.map(currency => currency.name)
  const [selected, setSelected] = useState<string[]>([])
  const isSingleOption = options.length === 1
  const noSelections = !selected.length
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const isAllSelected = options.length > 1 && selected.length === options.length
  const handleReset = () => {
    setSelected(['none'])
  }

  useEffect(() => {
    if (filters?.blockchain_name) {
      setSelected(filters?.blockchain_name as string[])
    }
  }, [filters?.blockchain_name])

  const handleClose = () => {
    onFilterCollect({ blockchain_name: selected })
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  useLayoutEffect(() => {
    noSelections && handleReset()
  }, [noSelections])

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[]
    if (value.at(-1) === 'all') {
      setSelected(selected.length === options.length ? ['none'] : options)

      return
    }
    setSelected(value.filter(option => option !== 'none'))
  }

  return (
    <FormControl variant='filled'>
      <FormSelectLabel shrink>{t(label)}</FormSelectLabel>
      <PaymentsSelect
        multiple
        open={open}
        autoFocus={false}
        displayEmpty
        onClose={handleClose}
        onOpen={handleOpen}
        disableUnderline
        value={selected}
        onChange={handleChange}
        MenuProps={{
          PaperProps: paperProps
        }}
        renderValue={(selected: unknown) => (
          <SelectedRenderValue>
            {t('Selected')}:
            {` ${
              isAllSelected ? t('All currencies') : noSelections ? t('none') : (selected as string[]).map(t).join(', ')
            }`}
          </SelectedRenderValue>
        )}
      >
        {!isSingleOption && (
          <PaymentsMenuItem value='all'>
            <PaymentsMenuSelectAll primary={t('All currencies')} />
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={selected.length > 0 && selected.length < options.length}
              />
            </ListItemIcon>
          </PaymentsMenuItem>
        )}
        {options.map(option => (
          <PaymentsMenuItem key={option} value={option} disableRipple disableTouchRipple>
            <PaymentsMenuItemText primary={option} />
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
          </PaymentsMenuItem>
        ))}
        <Box sx={{ display: 'flex' }}>
          <SelectResetButton variant='contained' onClick={handleReset}>
            {t('Reset')}
          </SelectResetButton>
        </Box>
      </PaymentsSelect>
    </FormControl>
  )
})
