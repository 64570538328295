import List from '@mui/material/List'
import { Fragment, ReactNode } from 'react'
import { VerticalListItem } from './vertical-list-item'
import { useVerticalLinks } from '@/shared/hooks/useVerticalLinks'
import { VerticalNavItemsType } from '@/shared/layouts/types'
import { Settings } from '@/app/context'
import { Box } from '@mui/material'

interface Props {
  hidden: boolean
  navWidth: number
  navHover: boolean
  settings: Settings
  children: ReactNode
  navVisible: boolean
  collapsedNavWidth: number
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  setNavHover: (values: boolean) => void
  setNavVisible: (value: boolean) => void
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  verticalNavMenuContent?: (props?: any) => ReactNode
  afterVerticalNavMenuContent?: (props?: any) => ReactNode
  beforeVerticalNavMenuContent?: (props?: any) => ReactNode
}

export const VerticalListItems = (props: Props) => {
  const { verticalLinks } = useVerticalLinks()
  const { navHover, navVisible, settings, collapsedNavWidth, toggleNavVisibility, navigationBorderWidth } = props
  const isCollapsed = settings.navCollapsed && !navHover

  return (
    <>
      {verticalLinks.map((link, index) => (
        <List
          component='nav'
          subheader={<Box sx={{ textAlign: isCollapsed ? 'center' : 'left' }}>{link.subheader}</Box>}
          key={index}
        >
          {link.children.map(item => (
            <Fragment key={item.text}>
              <VerticalListItem
                item={item}
                navHover={navHover}
                navVisible={navVisible}
                collapsedNavWidth={collapsedNavWidth}
                toggleNavVisibility={toggleNavVisibility}
                navigationBorderWidth={navigationBorderWidth}
              />
            </Fragment>
          ))}
        </List>
      ))}
    </>
  )
}
