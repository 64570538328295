import { Accordion, Box, styled } from '@mui/material'

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '52px 16 48px',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '1200px',
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '138px'
  }
}))

export const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  [theme.breakpoints.down('xl')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }
}))

export const FooterSocial = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  marginRight: '24px',
  padding: '11px 9px',
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    marginBottom: '36px'
  },
  '&:last-child': {
    marginRight: 0
  }
}))

export const FooterLinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

export const FooterLinksContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
}))

export const FooterContentInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

export const FooterLinkBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '28px',
  color: 'rgba(252, 252, 252, 0.6)',
  cursor: 'pointer',
  marginBottom: '16px',
  padding: '4px 0'
}))

export const FooterHeading = styled(Box)(({ theme }) => ({
  marginBottom: '40px',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['700'],
  color: '#FCFCFC'
}))

export const FooterAccordion = styled(Accordion)(() => ({
  width: '100%',
  boxShadow: 'none',
  background: 'transparent',
  marginBottom: '24px'
}))

export const FooterSocialsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export const FooterSocialsInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50%',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:first-of-type': {
    order: 1,
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      order: 2
    }
  },
  '&:last-of-type': {
    order: 2,
    justifyContent: 'flex-end',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      order: 1,
      justifyContent: 'flex-start'
    }
  }
}))

export const FooterPrivacyPolicy = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '50px',
  [theme.breakpoints.down('md')]: {
    marginTop: '36px',
    marginRight: 0
  }
}))

export const FooterPrivacyPolicyTitle = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '28px',
  color: 'rgba(252, 252, 252, 0.6)',
  marginBottom: '6px'
}))

export const FooterPrivacyPolicyText = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(252, 252, 252, 0.3)',
  marginBottom: '6px'
}))
