import { Button } from '@mui/material'
import { BoxProps, styled } from '@mui/system'

export const DashboardOutlined = styled(Button)<BoxProps>(({ theme }) => ({
  width: 161,
  height: 48,
  background: '#FFFFFF',
  textTransform: 'uppercase',
  border: '2.5px solid #186ADF',
  borderRadius: '12px',
  fontSize: '14px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '16px',
  fontWeight: theme.palette.fontWeight['700'],
  color: '#186ADF',
  marginRight: '24px',
  '&:hover': {
    background: '#FFFFFF'
  }
}))
