import { MutableRefObject } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Box, FormHelperText } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { FormWrapper } from '../react-hook-form'
import { Captcha } from './styles'

type Props = {
  control: Control<any>
  captchaRef: MutableRefObject<ReCAPTCHA | null>
  onChange: (token: string | null) => void
  name: string
}

export const FormCaptcha = ({ captchaRef, onChange, name = '', control }: Props) => {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => {
          if ('g-recaptcha-response' in errors) {
            captchaRef.current?.reset()
          }

          return (
            <FormWrapper sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Captcha {...field} sitekey={String(process.env.APP_SITE_KEY)} ref={captchaRef} onChange={onChange} />
              <Box>
                {errors.hasOwnProperty(name) && errors[name] && (
                  <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                    {errors[name]?.message as string}
                  </FormHelperText>
                )}
              </Box>
            </FormWrapper>
          )
        }}
      />
    </Box>
  )
}
