import { authConfig, axiosInstance, CreateStoreData, StoreCurrencyType, UpdateStoreData } from '@/shared'
import { AxiosResponse } from 'axios'

const StoresService = () => {
  const getStores = async (currencyId?: string) => {
    const res = await axiosInstance.get(authConfig.storesEndpoint, { params: { currency_id: currencyId } })

    return res.data
  }
  const createStore = async (data: CreateStoreData) => {
    const res = await axiosInstance.post(authConfig.storesEndpoint, data)

    return res.data
  }
  const updateStore = async (data: Partial<UpdateStoreData>, id: string) => {
    const res = await axiosInstance.patch(`${authConfig.storesEndpoint}/${id}`, data)

    return res.data
  }
  const deleteStore = async (id: string) => {
    const res = await axiosInstance.delete(`${authConfig.storesEndpoint}/${id}`)

    return res.data
  }
  const refreshApiKey = async (id: string) => {
    const res = await axiosInstance.post(`${authConfig.storesEndpoint}/${id}/refresh-api-key`)

    return res.data
  }
  const getStoreCurrencies = async (): Promise<AxiosResponse<{ currencies: StoreCurrencyType[] }>> => {
    const res = await axiosInstance.get(authConfig.currenciesEndpoint)

    return res.data
  }
  const updateStoreName = async (id: string, name: string) => {
    const res = await axiosInstance.post(`${authConfig.storesEndpoint}/${id}/update-name`, { name })

    return res.data
  }

  return {
    getStores,
    createStore,
    updateStore,
    deleteStore,
    refreshApiKey,
    getStoreCurrencies,
    updateStoreName
  }
}

export const storesService = StoresService()
