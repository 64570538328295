import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { MouseEvent, useCallback, useState } from 'react'
import { historyStateSelector } from '@/app/store/selectors/history.selectors'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import { useAppSelector } from '@/shared'
import { ChipMenuOption } from './styles'
import Badge from '@mui/material/Badge'
import Zoom from '@mui/material/Zoom'
import Box from '@mui/system/Box'

type MenuOptionsProps = {
  chipsData: string[]
  handleDelete: (chipToDelete: string) => void
}

const ITEM_HEIGHT = 48

export const PaymentsFiltersMenu = ({ chipsData, handleDelete }: MenuOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {
    history: { filters }
  } = useAppSelector(historyStateSelector)
  const badgeContent = Object.values(filters ?? {}).flat().length
  const keys = Object.keys(filters ?? {})
  const open = Boolean(anchorEl)
  const hasFilters = keys.length > 0
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionFx = useCallback(
    (chipToDelete: string) => {
      handleDelete(chipToDelete)
      handleClose()
    },
    [handleDelete]
  )

  return (
    <Badge badgeContent={badgeContent} color='primary' sx={{ mr: '10px' }}>
      <Zoom in={hasFilters}>
        <Box>
          <IconButton
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <FilterListIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch'
              }
            }}
          >
            {chipsData.map(option => {
              const label = option.substring(0, 10)

              return (
                <ChipMenuOption key={option} onClick={() => handleOptionFx(option)}>
                  <Box sx={{ display: 'flex' }}>{label}</Box>
                  <CloseIcon />
                </ChipMenuOption>
              )
            })}
          </Menu>
        </Box>
      </Zoom>
    </Badge>
  )
}
