import { styled } from '@mui/system'
import { Box, Menu, MenuItem, Paper, TableCell } from '@mui/material'

export const CurrencyPlatform = styled(Box)(({ theme }) => ({
  family: '`Iter`, sans-serif',
  marginRight: '12px',
  fontSize: '20px',
  fontWeight: theme.palette.fontWeight['500'],
  lineHeight: '160%',
  color: '#186ADF'
}))

export const CurrencyMenuItem = styled(MenuItem)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  lineHeight: '143%',
  padding: '14px 20px',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  borderBottom: '1px solid rgba(234, 234, 255, 0.12)',
  '&:last-of-type': {
    borderBottom: 'none'
  }
}))

export const CurrencyOptionMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    width: 'fit-content',
    height: 'fit-content',
    minWidth: '120px',
    background: '#424242',
    boxShadow:
      '0px 6px 6px -3px rgba(20, 21, 33, 0.2), 0px 10px 14px 1px rgba(20, 21, 33, 0.14), 0px 4px 18px 3px rgba(20, 21, 33, 0.12)',
    borderRadius: '8px'
  }
}))

export const CurrencySelectText = styled(Box)(({ theme }) => ({
  family: '`Iter`, sans-serif',
  fontSize: '24px',
  lineHeight: '133%',
  fontWeight: theme.palette.fontWeight['400'],
  color: '#FCFCFC',
  marginRight: '16px'
}))

export const CurrencyBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '24px',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '12px'
  }
}))

export const CurrencyInfoBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '5px',
  alignItems: 'flex-start',
  '&:last-child': {
    marginBottom: '0'
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '12px'
  }
}))

export const GridCryptoItem = styled(Paper)(({ theme }) => ({
  padding: '24px 16px 1px 16px',
  gap: '24px',
  backgroundColor: '#292929',
  minWidth: '650px',
  borderRadius: '16px',
  width: '100%',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    minWidth: 'auto'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px 0 16px 16px'
  }
}))

export const GridCryptoQuestion = styled(Paper)(({ theme }) => ({
  padding: '24px 16px 24px 16px',
  gap: '24px',
  backgroundColor: 'rgba(24, 106, 223, 0.1)',
  minHeight: '229px',
  borderRadius: '16px',
  width: '100%',
  minWidth: '458px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto'
  }
}))

export const CurrencyCreditBalance = styled(Box)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  lineHeight: '157%',
  fontWeight: theme.palette.fontWeight['500'],
  color: theme.palette.common.white,
  marginRight: '12px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '250px'
  }
}))

export const CurrencyCreditBalanceValue = styled(Box)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  lineHeight: '157%',
  fontWeight: theme.palette.fontWeight['500'],
  color: '#186ADF'
}))

export const CurrencyCreditQrLink = styled(Box)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  lineHeight: '143%',
  fontWeight: theme.palette.fontWeight['400'],
  color: '#FFB400'
}))

export const CurrencyGrid = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexWrap: 'wrap'
}))

export const CurrencyCryptoQuestionText = styled(Box)(({ theme }) => ({
  family: '`Iter`, sans-serif',
  fontSize: '20px',
  lineHeight: '160%',
  maxWidth: '426px',
  fontWeight: theme.palette.fontWeight['500'],
  color: '#186ADF'
}))

export const CurrencyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '24px',
  fontFamily: `'Ubuntu', sans-serif`,
  textTransform: 'none',
  fontWeight: theme.palette.fontWeight['500'],
  letterSpacing: '0.15px',
  color: theme.palette.common.white
}))

export const CurrencyTableTitle = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '160%',
  fontFamily: `'Iter', sans-serif`,
  fontWeight: theme.palette.fontWeight['500'],
  letterSpacing: '0.15px',
  color: '#FCFCFC'
}))

export const CurrencyTableDate = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '143%',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: '0.15px',
  color: theme.palette.common.white
}))

export const CurrencyTableName = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '143%',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: '0.15px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const CurrencyImageBlock = styled(Box)(() => ({
  marginRight: '20px'
}))

export const CurrencyCreditBalanceBlock = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: '24px'
  }
}))

export const CurrencyGridContent = styled(Box)(({ theme }) => ({
  marginRight: '24px',
  marginBottom: '24px',
  order: 1,
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    order: 2
  }
}))

export const CurrencyCryptoQuestion = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  order: 2,
  [theme.breakpoints.down('md')]: {
    order: 1,
    marginTop: '36px'
  }
}))

export const CommissionsAndDepositsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const MobileTableWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  width: '100%',
  padding: '24px 16px',
  background: '#292929',
  borderRadius: '12px'
}))

export const HistoryContent = styled(Box)(() => ({
  padding: 0,
  '&:nth-of-type(2n + 1)': {
    borderBottom: '1px solid rgba(247, 247, 247, 0.1)'
  }
}))

export const HistoryBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const HistoryCell = styled(CurrencyCell)(() => ({
  padding: '12px 0',
  border: 'none'
}))

export const CurrencyTableHeading = styled(Box)(() => ({
  marginBottom: '2px'
}))
