import { OptionsWrapper } from './styles'
import { TicketStatus } from '@/shared/ui/tickets'
import { TicketOptionsMenu } from './ticket-options-menu'
import { Box } from '@mui/material'
import { ITicket } from '@/shared'
import { useTranslation } from 'react-i18next'

type Props = {
  ticket: ITicket
}

export const TicketNavigationOptions = ({ ticket }: Props) => {
  const { t } = useTranslation()
  const options = [t('Close ticket')]

  return (
    <OptionsWrapper>
      <TicketStatus variant={'contained'} disableTouchRipple>
        {t(ticket?.status)}
      </TicketStatus>
      <Box sx={{ ml: '12px' }}>
        <TicketOptionsMenu options={options} ticket={ticket} />
      </Box>
    </OptionsWrapper>
  )
}
