import { AccordionDetails, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import {
  AccordionContent,
  AccordionSummaryContent,
  AccordionTypography,
  EcommerceAccordion,
  EcommerceAddNewStoreButton,
  PlatformOptionsMenu
} from '@/shared/ui'
import AddIcon from '@mui/icons-material/Add'
import { PlatformsAddForm } from './platforms-add-form'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { storesLoadingSelector, storesStateSelector } from '@/app/store/selectors'
import { PlatformPreparedStore } from '@/shared/ui/platforms/ui/platform-prepared-store'
import { useAppSelector } from '@/shared/hooks/types'
import { useModulesLoad } from '@/shared'

export const PlatformsAddNewStore = () => {
  const stores = useAppSelector(storesStateSelector)
  const loading = useAppSelector(storesLoadingSelector)
  const accordionStores = stores.map(store => ({ ...store, panel: `panel${store?.id}` }))
  const [addNewStore, setAddNewStore] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(true)
  const { t } = useTranslation()

  useModulesLoad()

  const preparedStores = accordionStores.map(store => <PlatformPreparedStore key={store.id} store={store} />)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  const onCancelCreation = () => setAddNewStore(false)

  const toggleAddStoreButtonVisibility = () => {
    setAddNewStore(!addNewStore)
  }

  return (
    <Box>
      <EcommerceAddNewStoreButton onClick={toggleAddStoreButtonVisibility} variant='contained'>
        <AddIcon sx={{ mr: '8px' }} />
        {t('Add a new store')}
      </EcommerceAddNewStoreButton>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {loading ? (
          <CircularProgress color='inherit' size={30} />
        ) : (
          <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{preparedStores}</Box>
            {addNewStore ? (
              <EcommerceAccordion disableGutters expanded={expanded} onChange={handleChange}>
                <AccordionSummaryContent expandIcon={<ExpandMoreIcon fontSize={'large'} />}>
                  <AccordionContent sx={{ mt: '3px' }}>
                    <AccordionTypography>{t('Create store')}</AccordionTypography>
                  </AccordionContent>
                  <PlatformOptionsMenu onDeleteStore={onCancelCreation} />
                </AccordionSummaryContent>
                <AccordionDetails sx={{ padding: 0 }}>
                  <PlatformsAddForm hideCreateAccordion={onCancelCreation} />
                </AccordionDetails>
              </EcommerceAccordion>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  )
}
