import { createAsyncThunk } from '@reduxjs/toolkit'
import { pageService } from '@/shared/services/page.service'
import { GetPageModuleArgs } from '@/shared'

export const getPagesTooltip = createAsyncThunk('pages-module/getPagesTooltip', async () => {
  const response = await pageService.getPagesTooltip()

  return response.data
})

export const getPageModules = createAsyncThunk<GetPageModuleArgs, { slug: string; signal: AbortSignal }>(
  'pages-module/getPageModules',
  async ({ signal, slug }) => {
    const response = await pageService.getPageModules(slug, signal)

    return response.data
  }
)
