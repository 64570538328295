import { Box } from '@mui/material'
import { styled } from '@mui/system'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const HistoryTicketNavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export const HistoryTicketWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}))

export const HistoryTicketSupportChatWrapper = styled(Box)(({ theme }) => ({
  marginTop: '24px',
  display: 'flex',
  padding: '48px 36px',
  flexDirection: 'column',
  gap: '36px',
  width: '100%',
  overflow: 'auto',
  maxHeight: '692px',
  height: '100%',
  background: 'transparent',
  border: '1px solid #292929',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    padding: '36px 16px'
  }
}))

export const HistoryInfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '8px'
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export const HistoryTicketOptionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start'
  }
}))

export const HistoryInfoTicketId = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Iter', sans-serif`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const HistoryInfoTicketName = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Iter', sans-serif`,
  wordBreak: 'break-all',
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  margin: '0 12px',
  [theme.breakpoints.down('md')]: {
    margin: 0,
    order: 2
  }
}))

export const HistorySupportMessageWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '36px'
}))

export const HistorySupportMessageSender = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const HistorySupportMessageName = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '150%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white
}))

export const HistorySupportMessageBody = styled(Box)<{ is_admin: boolean }>(({ is_admin }) => ({
  display: 'flex',
  padding: '16px 12px',
  gap: '10px',
  flexDirection: 'column',
  width: '100%',
  minHeight: '56px',
  color: '#fff',
  wordBreak: 'break-all',
  background: !is_admin ? '#424242' : is_admin ? 'transparent' : '#fff',
  border: is_admin ? '1px solid #424242' : 'none',
  borderRadius: '8px',
  alignSelf: 'stretch',
  marginTop: '12px',
  marginBottom: '8px'
}))

export const BackIcon = styled(ArrowBackIcon)({ fontSize: '24px', cursor: 'pointer', mb: '4px' })

export const HistoryTicketOptionTitle = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '175%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  marginRight: '12px',
  [theme.breakpoints.down('md')]: {
    marginTop: '12px'
  }
}))

export const HistorySupportMessageTime = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '143%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const HistoryTicketMessagesLoader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
}))
