import { Box } from '@mui/material'
import { styled } from '@mui/system'

type ImageProps = {
  width?: string | number | undefined
}

export const ImageWithStateContainer = styled(Box)<ImageProps>(({ width }) => ({
  display: 'flex',
  maxWidth: width
}))

export const ImageCircularProgress = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
