import {
  CrmTransactionsTableWrapper,
  CryptocurrenciesTableNavigation,
  CryptocurrenciesTableTitle,
  CurrencyTransactionType,
  NavigationButton,
  TableCurrenciesCell,
  TableNoTransactions,
  TableRowCell,
  TableRowsPerPage,
  useAppSelector
} from '@/shared'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { ChangeEvent, useCallback, useState } from 'react'
import { walletLoadingSelector, walletSliceSelector } from '@/app/store/selectors'
import TablePagination from '@mui/material/TablePagination'
import TableFooter from '@mui/material/TableFooter'

type FilterButtonType = { name: string; filter: string }
export const CrmYesterdayTransactions = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const wallet = useAppSelector(walletSliceSelector)
  const [transactionFilter, setTransactionFilter] = useState<string>('all')
  const loading = useAppSelector(walletLoadingSelector)
  const yesterdayTransactions: Record<string, CurrencyTransactionType[]> = wallet?.dashboard?.transactions?.today || {}
  const tableRows = yesterdayTransactions[transactionFilter] as CurrencyTransactionType[]
  const rowsAvailable = tableRows?.length > 0
  const amountOnAdmission = ((tableRows as CurrencyTransactionType[]) ?? [])?.reduce(
    (acc, item) => acc + Number(item.amount_on_admission),
    0
  )
  const valueForToday = ((tableRows as CurrencyTransactionType[]) || [])?.reduce(
    (acc, item) => acc + Number(item.value_for_today),
    0
  )
  const buttons: FilterButtonType[] = [
    { name: 'All', filter: 'all' },
    { name: 'BTC', filter: '1' },
    { name: 'BNB', filter: '3' }
  ]

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleTransactionChange = useCallback((name: string) => {
    setTransactionFilter(name)
  }, [])
  const renderTableFilters = () =>
    buttons.map(button => (
      <NavigationButton
        key={button.name}
        variant={transactionFilter === button.filter ? 'contained' : 'outlined'}
        onClick={() => handleTransactionChange(button.filter)}
      >
        {t(button.name)}
      </NavigationButton>
    ))

  return (
    <CrmTransactionsTableWrapper>
      <TableContainer component={Paper}>
        {loading ? (
          <Skeleton variant='rectangular' width='100%' height={426} />
        ) : (
          <Box>
            <CryptocurrenciesTableTitle>{t('Transactions for last 24h')}</CryptocurrenciesTableTitle>
            <CryptocurrenciesTableNavigation>{renderTableFilters()}</CryptocurrenciesTableNavigation>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    '& > .MuiTableCell-head:first-of-type': {
                      paddingLeft: 0
                    }
                  }}
                >
                  <TableCurrenciesCell>{t('Currency')}</TableCurrenciesCell>
                  <TableCurrenciesCell>{t('Amount on admission')}</TableCurrenciesCell>
                  <TableCurrenciesCell sx={{ minWidth: 160 }} align='right'>
                    {t('Value for Today')}
                  </TableCurrenciesCell>
                </TableRow>
              </TableHead>
              {rowsAvailable ? (
                <TableBody>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& > .MuiTableCell-root:first-of-type:nth-child(1)': {
                        paddingLeft: 0
                      }
                    }}
                  >
                    <TableCurrenciesCell component='th' scope='row'>
                      {`${t('transactions')}: ${tableRows?.length}`}
                    </TableCurrenciesCell>
                    <TableCurrenciesCell>{`${amountOnAdmission.toString()} ${tableRows[0]?.fiat_name?.toLocaleUpperCase()}`}</TableCurrenciesCell>
                    <TableCurrenciesCell align='right'>{`${valueForToday.toString()} ${tableRows[0]?.fiat_name?.toLocaleUpperCase()}`}</TableCurrenciesCell>
                  </TableRow>
                  {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                    const sign = row.is_positive ? '+' : '-'
                    const amount = `${sign} ${row?.sum} ${row?.currency_name?.toLocaleUpperCase()} ≈ ${
                      row?.amount_on_admission
                    } ${row?.fiat_name?.toLocaleUpperCase()}`

                    return (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& > .MuiTableCell-root:first-of-type': {
                            padding: 0
                          }
                        }}
                      >
                        <TableRowCell>{row?.currency_name?.toLocaleUpperCase()}</TableRowCell>
                        <TableRowCell sx={{ color: row?.is_positive ? '#64C623' : '#E04440' }}>{amount}</TableRowCell>
                        <TableRowCell align='right'>{`${
                          row?.value_for_today
                        } ${row?.fiat_name?.toLocaleUpperCase()}`}</TableRowCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              ) : null}
              {!rowsAvailable && (
                <TableFooter>
                  <TableNoTransactions>{t('There is no transactions yet')}</TableNoTransactions>
                </TableFooter>
              )}
              {rowsAvailable ? (
                <TableFooter
                  sx={{
                    '& > td': {
                      border: 0
                    }
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={<TableRowsPerPage>{t('Rows per page')}:</TableRowsPerPage>}
                    count={tableRows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableFooter>
              ) : null}
            </Table>
          </Box>
        )}
      </TableContainer>
    </CrmTransactionsTableWrapper>
  )
}
