import { DisableForm, EnableInstructionContent, Heading, InstructionStepText, InstructionWrapper } from '@/shared'
import { useTranslation } from 'react-i18next'

export const DisableInstruction = () => {
  const { t } = useTranslation()

  return (
    <InstructionWrapper>
      <Heading text={'2-Step Verification'} />
      <EnableInstructionContent>
        <InstructionStepText>
          {t(`Enter your code from Google Authenticator application to disable 2FA.`)}
        </InstructionStepText>
        <DisableForm />
      </EnableInstructionContent>
    </InstructionWrapper>
  )
}
