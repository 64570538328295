import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AllowedWalletsResponseType,
  GetAuthUserDashboardDataArgs,
  GetDashboardSpentData,
  GetWalletsByUsersStoreArgs
} from '@/shared'
import { userService } from '@/shared/services/user.service'

export const getWalletAndAllowedCurrencies = createAsyncThunk<AllowedWalletsResponseType, string>(
  'user/getWalletAndAllowedCurrencies',
  async currencyId => {
    const { data } = await userService.getWalletAndAllowedCurrencies(currencyId)

    return data
  }
)

export const getWalletsListByUsersStores = createAsyncThunk<GetWalletsByUsersStoreArgs, void>(
  'user/getWalletsListByUsersStores',
  async () => {
    const { data } = await userService.getWalletsListByUsersStores()

    return data
  }
)

export const getAuthUserDashboardData = createAsyncThunk<GetAuthUserDashboardDataArgs, void>(
  'user/getAuthUserDashboardData',
  async () => {
    const { data } = await userService.getAuthUserDashboardData()

    return data
  }
)
export const getAuthUserDashboardSpentData = createAsyncThunk<GetDashboardSpentData, void>(
  'user/getAuthUserDashboardSpentData',
  async () => {
    const { data } = await userService.getAuthUserDashboardSpentData()

    return data
  }
)
