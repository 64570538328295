import Link from 'next/link'
import Image from 'next/image'
import { LogoCryptoCollapsed, useAppSelector } from '@/shared'
import { StyledLink } from '../vertical/navigation/styles'
import { layoutHeaderLogoSelector } from '@/app/store/selectors'

type Props = {
  collapsed?: boolean
}

export const DashboardMenuLogo = ({ collapsed }: Props) => {
  const logoUri = useAppSelector(layoutHeaderLogoSelector)

  return (
    <Link href='/watcher-dashboard' passHref>
      <StyledLink
        sx={{
          ml: !collapsed ? '-25px' : '0'
        }}
      >
        {!collapsed ? (
          <Image src={logoUri ?? ''} alt='dashboard menu picture' height='36px' width='194px' loading='lazy' />
        ) : (
          <LogoCryptoCollapsed height={24} width={36} />
        )}
      </StyledLink>
    </Link>
  )
}
