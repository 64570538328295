import * as yup from 'yup'
import i18n from 'i18next'

export const setNewPasswordFormSchema = () =>
  yup.object({
    password: yup
      .string()
      .required(i18n.t('Password is required field.'))
      .min(8, i18n.t('Password should be minimum 8 characters.'))
      .max(16, i18n.t('Password should be up to 16 characters.'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,16}$/gm,
        i18n.t('At least 8 character long, must include at least one-ticket uppercase character and digit')
      ),
    password_confirmation: yup
      .string()
      .required(i18n.t('Repeat Password is required field'))
      .min(8, i18n.t('Repeat Password should be minimum 8 characters.'))
      .max(16, i18n.t('Repeat Password should be up to 16 characters.'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,16}$/gm,
        i18n.t('At least 8 character long, must include at least one-ticket uppercase character and digit')
      )
      .oneOf([yup.ref('password')], i18n.t('Please make sure that the passwords match.'))
  })
