// ** Styled Components
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { ListItemText, ListSubheader } from '@mui/material'
import { ListSubheaderProps } from '@mui/material/ListSubheader/ListSubheader'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'
import { ElementType } from 'react'
import themeConfig from '@/shared/configs/themeConfig'
import Link from 'next/link'
import { LinkProps } from 'next/dist/client/link'

export const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.spacing(4),
  justifyContent: 'space-between',
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

export const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['700'],
  fontFamily: `'Proxima Nova'`,
  marginLeft: '5px',
  fontSize: '16px',
  letterSpacing: '0.740567px',
  fontFeatureSettings: `'liga' off`,
  color: '#F7F7F7',
  lineHeight: '103%',
  textTransform: 'uppercase',
  transition: 'opacity .25s ease-in-out, margin .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    color: '#F7F7F7'
  }
}))

export const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

export const NavListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  paddingRight: 0,
  lineHeight: '150%',
  letterSpacing: '0.15px',
  '& > span': {
    color: theme.palette.common.white
  }
}))

export const NavListItemHeader = styled(ListSubheader)<ListSubheaderProps>(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  textTransform: 'capitalize',
  padding: '7px 16px',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  background: 'rgba(255, 255, 255, 0.12)',
  marginTop: '20px',
  lineHeight: '143%',
  letterSpacing: '0.15px',
  color: '#FCFCFC !important'
}))

export const NavListItemMetaWrapper = styled(Box)<BoxProps>({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
})

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}))

export const NavListItemLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; target?: '_blank' | undefined }
>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    '&, &:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
      color: `${theme.palette.common.white} !important`
    },
    '& .MuiListItemIcon-root': {
      color: `${theme.palette.common.white} !important`
    }
  }
}))

export const VerticalLink = styled(Link)<LinkProps>(() => ({
  width: '100%',
  display: 'flex',
  textDecoration: 'none',
  outline: 'none',
  border: 0,
  color: 'antiquewhite'
}))
