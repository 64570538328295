import { createAsyncThunk } from '@reduxjs/toolkit'
import { HeaderLinkType, LayoutFooterColumnsType } from '@/shared'
import { wpAxiosInstance } from '@/shared/configs/axios.instance'

export const getLayout = createAsyncThunk<
  { header: HeaderLinkType[]; footer: LayoutFooterColumnsType; logo: string },
  { locale?: string }
>('layout/getLayout', async ({ locale }) => {
  const res = await wpAxiosInstance.get(`/menu`, { params: { lang: locale } })

  return res.data
})
