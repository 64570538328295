import {
  EnableForm,
  EnableInstructionContent,
  EnableTwoFactorQrCode,
  Heading,
  InstructionChip,
  InstructionStepText,
  InstructionWrapper,
  useAppDispatch,
  useAppSelector
} from '@/shared'
import { ImageWithState } from '@/shared/ui/image-with-state'
import { userLoadingSelector, userUserQrSelector } from '@/app/store/selectors'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { getUserTwoFactorQrCode } from '@/app/store/thunks'
import Skeleton from '@mui/material/Skeleton'

export const EnableInstruction = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userQrInfo = useAppSelector(userUserQrSelector)
  const loading = useAppSelector(userLoadingSelector)

  useEffect(() => {
    dispatch(getUserTwoFactorQrCode())
  }, [dispatch])

  return (
    <InstructionWrapper>
      <Heading text={t('2-Step Verification')} />
      <EnableInstructionContent>
        <InstructionStepText>
          {t('1. Scan this QR code with your Google Authenticator App. Alternatively, you can use this code:')}
          {loading ? (
            <Skeleton sx={{ width: '183px', height: '31px', ml: '5px' }} />
          ) : (
            <InstructionChip color='primary' label={userQrInfo?.qrcode_text ?? ''} clickable />
          )}
        </InstructionStepText>
        <EnableTwoFactorQrCode>
          <ImageWithState src={userQrInfo ? userQrInfo?.qrcode_src : ''} alt='qr-code' width={200} height={200} />
        </EnableTwoFactorQrCode>
        <InstructionStepText>{t('2. Enter the pin from Google Authenticator App.')}</InstructionStepText>
        <EnableForm />
      </EnableInstructionContent>
    </InstructionWrapper>
  )
}
