/**
 * MUI imports
 */
import Box from '@mui/material/Box'

/**
 * React widgets imports
 */
import { PropsWithChildren } from 'react'

/**
 * Styled widgets imports
 */
import { BlankLayoutHeader } from '@/shared/ui'

/**
 * Styles imports
 */
import { BlankLayoutWrapper } from './styles'

/**
 * @type {Props} - props interface for BlankLayout
 * @property {boolean} visibleHeader - variable that toggle header visibility
 */
type Props = {
  visibleHeader?: boolean
}

const BlankLayout = ({ children, visibleHeader = false }: PropsWithChildren<Props>) => {
  return (
    <BlankLayoutWrapper className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh' }}>
        {visibleHeader && <BlankLayoutHeader />}
        <Box>{children}</Box>
      </Box>
    </BlankLayoutWrapper>
  )
}

export default BlankLayout
