import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const ForgotPasswordForm = styled('form')(() => ({
  maxWidth: 300,
  width: '100%'
}))

export const FormDescription = styled(Typography)(({ theme }) => ({
  maxWidth: '468px',
  margin: '0 auto',
  marginTop: '23px',
  textAlign: 'center',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '28px',
  color: theme.palette.description.color,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '310px',
    margin: '12px auto'
  }
}))

export const ArrowIcon = styled(ArrowBackIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '30px',
  cursor: 'pointer',
  outline: 'none'
}))

export const BackToHome = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 10,
  [theme.breakpoints.down('sm')]: {
    top: 3
  }
}))
