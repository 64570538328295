import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  authConfig,
  authService,
  ResentEmailArgs,
  SendEmailResponse,
  tokenService,
  UserQrResponseData,
  UsersAlerts,
  UserType
} from '@/shared'
import { userService } from '@/shared/services/user.service'
import { SendEmailArgs } from '@/shared/types/send-email'
import toast from 'react-hot-toast'
import i18n from 'i18next'
import { setEmailResentError, setEmailStatusError, setRequestError } from '@/app/store/apps/user'
import { IPaymentResponse } from '@/shared/types/payment'
import { BaseParams } from '@/app/store/types'

export const getCurrentUser = createAsyncThunk<UserType, void>('user/getCurrentUser', async () => {
  const { data } = await userService.getUser()
  if (data) {
    const userData = data?.user as UserType
    tokenService.setCookieValue(authConfig.storageUserEmailName, userData?.email)
    tokenService.setUser(userData)
  }

  return data?.user
})

export const sendEmailVerification = createAsyncThunk<SendEmailResponse, SendEmailArgs>(
  'user/sendEmailVerification',
  async ({ id, token, router, lang }, { dispatch, rejectWithValue }) => {
    try {
      const response = await authService.sendEmailVerification(String(id), String(token), lang)
      if (response.data) {
        await router.push('/register-confirmation')
      }

      return response.data
    } catch (err: any) {
      const message = err?.response?.data?.error?.message
      const status = err?.response?.data?.status
      dispatch(setEmailStatusError(status))

      const verifiedErrorMessages = ['You already verified your email!', 'Ви вже веріфікували вашу email адресу!']
      const verificationErrorMessages = [
        'Error was occurred during verification. Please request another verification email to try again.',
        'В ході верифікації виникла помилка. Будь ласка, запросіть відправку листа веріфікації знову'
      ]

      if (status === 'Not found') {
        await router.replace('/login')
      }
      if (verifiedErrorMessages.includes(message)) {
        await router.replace('/email-already-verified')
      }
      if (verificationErrorMessages.includes(message)) {
        await router.replace('/email-not-verified')
      }
      dispatch(setRequestError(err.response.data))

      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  }
)

export const resentEmail = createAsyncThunk(
  'user/resentEmail',
  async ({ email, lang }: ResentEmailArgs, { dispatch, rejectWithValue }) => {
    try {
      const response = await authService.verificationNotification(email, lang)
      if (response.data) {
        toast.success(i18n.t('Verification code has been successfully sent!'), { position: 'bottom-right' })
      }

      return response.data
    } catch (err: any) {
      const status = err?.response?.status
      if (status === 404) {
        toast.error(i18n.t('Not found'), { position: 'bottom-right' })
      }
      dispatch(setEmailResentError(err.response.data))

      return rejectWithValue(err.response.data)
    }
  }
)

export const getUserTwoFactorQrCode = createAsyncThunk<UserQrResponseData, void>(
  'user/getUserTwoFactorQrCode',
  async () => {
    const response = await userService.getUserTwoFactorQrCode()

    return response.data
  }
)

export const enablePaymentNotifications = createAsyncThunk('user/enablePaymentNotifications', async () => {
  const response = await userService.enablePaymentNotifications()

  return response.data
})

export const disablePaymentNotifications = createAsyncThunk('user/disablePaymentNotifications', async () => {
  const response = await userService.disablePaymentNotifications()

  return response.data
})

export const getUserReferrals = createAsyncThunk<IPaymentResponse, BaseParams>(
  'user/getUserReferrals',
  async params => {
    const response = await userService.getUserReferrals(params)

    return response.data
  }
)

export const getUserReferralsAccruals = createAsyncThunk<IPaymentResponse, BaseParams>(
  'user/getUserReferralsAccruals',
  async params => {
    const response = await userService.getUserReferralsAccruals(params)

    return response.data
  }
)

export const getAlert = createAsyncThunk<UsersAlerts, { slug: string; signal: AbortSignal }>(
  'user/getAlert',
  async ({ slug, signal }) => {
    const response = await userService.getAlert(slug, signal)

    return response.data
  }
)

export const updateAlertShownStatus = createAsyncThunk(
  'user/updateAlertShownStatus',
  async ({ id }: { id: number }) => {
    const response = await userService.updateAlertShownStatus(id)

    return response.data
  }
)
