// ** MUI Import
import { CircularProgress } from '@mui/material'
import { SpinnerBackdrop } from './styles'

const FallbackSpinner = () => {
  return (
    <SpinnerBackdrop open>
      <CircularProgress color='inherit' size={50} />
    </SpinnerBackdrop>
  )
}

export default FallbackSpinner
