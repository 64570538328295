import { authConfig, axiosInstance, CreateTicketData, TickerDataType } from '@/shared'
import { BaseParams } from '@/app/store/types'

const TicketService = () => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const createTicket = async (data: CreateTicketData | FormData) => {
    await axiosInstance.post(authConfig.createTicketEndpoint, data, config)
  }

  const getTicketsNotifyData = async () => {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/notify`)

    return response.data
  }

  const replyInTicket = async (ticketId: string, data: TickerDataType | FormData) => {
    return await axiosInstance.post(`${authConfig.ticketEntryEndpoint}/${ticketId}/reply`, data, config)
  }
  const closeTicket = async (ticketId: string) => {
    return await axiosInstance.post(`${authConfig.ticketEntryEndpoint}/${ticketId}/close`)
  }

  const reopenTicket = async (ticketId: string) => {
    return await axiosInstance.post(`${authConfig.ticketEntryEndpoint}/${ticketId}/reopen`)
  }
  const getAllTickets = async (params: BaseParams, status?: string) => {
    const query = status === 'all' ? '' : `?filter[status]=${status}`
    const response = await axiosInstance.get(`${authConfig.ticketEntryEndpoint}${query}`, {
      params
    })

    return response.data
  }
  const getAllClosedTickets = async (params: BaseParams) => {
    const response = await axiosInstance.get(`${authConfig.ticketEntryEndpoint}/history`, { params })

    return response.data
  }

  const getWithdrawalsHistory = async (params: BaseParams) => {
    const response = await axiosInstance.get(`${authConfig.withdrawalEntryEndpoint}`, { params })

    return response.data
  }

  const getAllTicketsMessages = async (ticketId: string) => {
    const response = await axiosInstance.get(`${authConfig.ticketEntryEndpoint}/${ticketId}/show`)

    return response.data
  }

  return {
    createTicket,
    replyInTicket,
    closeTicket,
    reopenTicket,
    getAllTickets,
    getAllClosedTickets,
    getTicketsNotifyData,
    getAllTicketsMessages,
    getWithdrawalsHistory
  }
}

export const ticketApi = TicketService()
