import { authConfig } from '@/shared/configs/auth'

class LocaleStorageService {
  getCurrentLang() {
    return typeof window !== 'undefined' && (localStorage.getItem(authConfig.storageI18nKeyName) as string)
  }
  setCurrentLang(lang: string) {
    localStorage.setItem(authConfig.storageI18nKeyName, lang)
  }
}

export const localeStorageService = new LocaleStorageService()
