/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { FormControl, FormHelperText, TextFieldProps } from '@mui/material'

/**
 * styled items from styling
 */
import { FormInputAdornment, FormLabel, FormTextField, FormWrapper, PlatformTextField } from './styles'
import { UseControllerProps } from 'react-hook-form/dist/types/controller'
import { CheckIcon } from '../check'

/**
 * DashboardFormInput type for props
 */
type FormInputProps = {
  control?: Control<any> | undefined
  showIcon?: boolean
  fullWidth?: boolean
  isPlatform?: boolean
  disableMargin?: boolean
  rowInput?: boolean
  defaultValue?: string
  rules?: UseControllerProps['rules']
  name: string
  className?: string
  sx?: any
  wrapperSx?: any
  labelSx?: any
} & TextFieldProps

/**
 * Custom form input component
 * @params {FormInputProps} - props for input component
 * @default type - text
 */
export const FormInput = ({
  control,
  showIcon = false,
  isPlatform = false,
  rowInput = false,
  disableMargin = false,
  fullWidth = false,
  label = '',
  rules = {
    required: true
  },
  name,
  sx,
  labelSx,
  wrapperSx,
  variant = 'outlined',
  placeholder,
  ...otherProps
}: FormInputProps) => {
  const VariantInput = isPlatform ? PlatformTextField : FormTextField

  return (
    <FormControl sx={{ mb: !disableMargin ? '24px' : 0 }} fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, formState: { errors } }) => {
          const defaultOnChange = (e: { target: { value: string } }) => {
            const pattern = /[^\x00-\x7F]+/gi
            const value = e.target.value.replace(pattern, '')
            field.onChange(value)
          }

          return (
            <FormWrapper rowInput={rowInput} sx={wrapperSx}>
              {label && (
                <FormLabel sx={{ color: errors && errors[name] && 'error.main', ...labelSx }}>{label}</FormLabel>
              )}
              <VariantInput
                {...field}
                {...otherProps}
                fullWidth
                autoComplete='off'
                variant={variant}
                sx={sx}
                onChange={defaultOnChange}
                error={Boolean(errors[name])}
                placeholder={placeholder}
                InputProps={{
                  endAdornment: showIcon && !errors?.[name] && field.value.length > 0 && (
                    <FormInputAdornment position='end'>
                      <CheckIcon />
                    </FormInputAdornment>
                  )
                }}
                InputLabelProps={{ shrink: false }}
              />
              {errors.hasOwnProperty(name) && errors[name] && (
                <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                  {errors[name]?.message as string}
                </FormHelperText>
              )}
            </FormWrapper>
          )
        }}
      />
    </FormControl>
  )
}
