import { useEffect } from 'react'

export const useScrollHidden = (open: boolean) => {
  const documentBody = document.body

  useEffect(() => {
    if (open) {
      documentBody.style.overflow = 'hidden'
    }

    return () => {
      documentBody.style.overflow = 'unset'
    }
  }, [documentBody.style, open])
}
