// ** MUI Imports
import { Theme } from '@mui/material/styles'

const input = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          lineHeight: '1.5rem',
          '&:before': {
            border: 0
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 0
          },
          '&.Mui-disabled:before': {
            borderBottom: 0
          }
        }
      }
    }
  }
}

export default input
