import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import {
  CryptocurrenciesTableTitle,
  FiatBlockchainIso,
  FiatBlockchainValue,
  FiatCurrencyPriceValue,
  FiatCurrencyValue,
  TableCurrenciesCell,
  TableRowCurrencyCell,
  useAppDispatch,
  useAppSelector
} from '@/shared'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { getAuthUserDashboardData } from '@/app/store/thunks/wallet'
import { useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { walletLoadingSelector, walletSliceSelector } from '@/app/store/selectors'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export const CryptocurrenciesTable = () => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector(walletSliceSelector)
  const loading = useAppSelector(walletLoadingSelector)
  const fiatCurrencies = wallet?.dashboard?.currencies_to_fiats ?? []
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getAuthUserDashboardData())
  }, [dispatch])

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Skeleton variant='rectangular' width='100%' height={231} />
      ) : (
        <Box>
          <CryptocurrenciesTableTitle>{t('Cryptocurrencies')}</CryptocurrenciesTableTitle>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '& > .MuiTableCell-head:first-of-type': {
                    paddingLeft: 0
                  }
                }}
              >
                <TableCurrenciesCell>{t('Currency')}</TableCurrenciesCell>
                <TableCurrenciesCell>{t('Price')}</TableCurrenciesCell>
                <TableCurrenciesCell align='right'>{t('24h %')}</TableCurrenciesCell>
                <TableCurrenciesCell align='right'>{t('7d %')}</TableCurrenciesCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fiatCurrencies.map((row, key) => {
                const yesterdayPercentPositive = row?.to_yesterday_percent > 0
                const lastWeekPercentPositive = row?.to_last_week_percent > 0
                const currency = row?.iso_name === 'btc' ? '/images/icons/bitcoin.svg' : '/images/icons/bnb.svg'
                const FiatYesterdayIcon = yesterdayPercentPositive ? ArrowDropUpIcon : ArrowDropDownIcon
                const FiatWeekIcon = lastWeekPercentPositive ? ArrowDropUpIcon : ArrowDropDownIcon

                return (
                  <TableRow
                    key={key}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& > .MuiTableCell-root:first-of-type': {
                        padding: 0
                      }
                    }}
                  >
                    <TableRowCurrencyCell>
                      <FiatBlockchainValue>
                        <img width={24} height={24} loading='lazy' alt={currency} src={currency} />
                        <Box sx={{ ml: '6px' }}>{row?.exchange_name}</Box>
                        <FiatBlockchainIso>{row?.iso_name?.toLocaleUpperCase()}</FiatBlockchainIso>
                      </FiatBlockchainValue>
                    </TableRowCurrencyCell>
                    <FiatCurrencyPriceValue>{`${row?.to_fiat_price} ${row?.user_fiat}`}</FiatCurrencyPriceValue>
                    <TableRowCurrencyCell align='right'>
                      <FiatCurrencyValue>
                        <FiatYesterdayIcon
                          fontSize='large'
                          sx={{ color: yesterdayPercentPositive ? '#39BA71' : '#EA4335' }}
                        />
                        <Typography
                          variant='subtitle2'
                          sx={{ color: yesterdayPercentPositive ? '#39BA71' : '#EA4335' }}
                        >
                          {row?.to_yesterday_percent}%
                        </Typography>
                      </FiatCurrencyValue>
                    </TableRowCurrencyCell>
                    <TableRowCurrencyCell align='right'>
                      <FiatCurrencyValue>
                        <FiatWeekIcon
                          fontSize='large'
                          sx={{ color: lastWeekPercentPositive ? '#39BA71' : '#EA4335' }}
                        />
                        <Typography variant='subtitle2' sx={{ color: lastWeekPercentPositive ? '#39BA71' : '#EA4335' }}>
                          {row?.to_last_week_percent}%
                        </Typography>
                      </FiatCurrencyValue>
                    </TableRowCurrencyCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      )}
    </TableContainer>
  )
}
