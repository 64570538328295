import { Box, DialogTitle, ListItem } from '@mui/material'
import { styled } from '@mui/system'

export const MessageDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between'
})

export const UploadListItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 8px',
  gap: 10,
  width: 'fit-content',
  background: '#292929',
  border: '2.5px solid #292929',
  borderRadius: '12px',
  marginBottom: '12px',
  '&:last-type-of': {
    marginBottom: 0
  }
})

export const FileDetails = styled(Box)({ display: 'flex', alignItems: 'center' })

export const FilePreview = styled(Box)({ display: 'flex', marginRight: '8px' })

export const FileName = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '16px',
  color: '#FCFCFC',
  letterSpacing: '0.15px'
}))

export const FileSize = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '5px',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '12px',
  lineHeight: '16px',
  color: 'rgba(252, 252, 252, 0.6)',
  letterSpacing: '0.15px'
}))
