import {
  HistoryTicketCreatedTime,
  IClosedTicket,
  SupportImage,
  TicketLastMessage,
  TicketMessageContent,
  tokenService
} from '@/shared'
import { memo } from 'react'
import { Box } from '@mui/material'
import {
  HistoryTicketClosedTime,
  HistoryTicketLastMessage,
  HistoryTicketName,
  HistoryTicketStatus,
  HistoryTicketUniqueIdentifier,
  SingleHistoryTicketContainer,
  SingleHistoryTicketContent,
  SingleHistoryTicketInfo,
  SingleHistoryTicketOptions
} from './styles'
import { useDateString } from '@/shared/hooks/useDateString'
import { HistoryTicketOptionsMenu } from '@/shared/ui/history-one-ticket'
import { truncateString } from '@/shared/utils/truncate'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'

type Props = {
  handleClick: () => void
  closedTicket: IClosedTicket
}

export const SingleHistoryTicket = memo(({ closedTicket, handleClick }: Props) => {
  /**
   * variables
   */
  const { t } = useTranslation()
  const user = tokenService.getUser()
  const userName = user?.name
  const lastMessage = closedTicket.last_message.message
  const options = [t('Reopen ticket')]
  const reportTime = useDateString(closedTicket?.report_time)
  const closeTime = useDateString(closedTicket?.close_time)
  const isSupportManager = closedTicket.last_message.author === 'Support Manager'
  const isUserSender = closedTicket.last_message.author === userName
  const sender = `${isSupportManager ? t('Support') : isUserSender ? t('You') : closedTicket.last_message.author}:`

  const messageIcon = (
    <TicketMessageContent>
      {isSupportManager && (
        <SupportImage>
          <Image width={24} height={24} alt={'support-icon'} src={'/images/icons/support-icon.svg'} color={'#424242'} />
        </SupportImage>
      )}
      {closedTicket.last_message.author ? (
        <TicketLastMessage>{truncateString(`${sender} ${lastMessage}`, 50)}</TicketLastMessage>
      ) : null}
    </TicketMessageContent>
  )

  return (
    <SingleHistoryTicketContainer onClick={handleClick}>
      <SingleHistoryTicketContent>
        <SingleHistoryTicketInfo>
          <HistoryTicketCreatedTime>{`${t('Created')} ${t('on')} ${reportTime}`}</HistoryTicketCreatedTime>
          <HistoryTicketUniqueIdentifier>#{closedTicket?.id}</HistoryTicketUniqueIdentifier>
          <HistoryTicketName>{truncateString(closedTicket?.subject, 20)}</HistoryTicketName>
          {closedTicket?.last_message && <HistoryTicketLastMessage>{messageIcon}</HistoryTicketLastMessage>}
        </SingleHistoryTicketInfo>
        <SingleHistoryTicketOptions>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HistoryTicketStatus variant={'contained'} disableTouchRipple>
              {t(closedTicket?.status)}
            </HistoryTicketStatus>
            <Box sx={{ ml: '12px' }} onClick={e => e.stopPropagation()}>
              <HistoryTicketOptionsMenu options={options} closedTicket={closedTicket as IClosedTicket} />
            </Box>
          </Box>
          {closedTicket?.close_by && (
            <HistoryTicketClosedTime>
              {`${t('Closed')} ${t('on')} ${t(closeTime)} ${t('by')} ${closedTicket?.close_by}`}
            </HistoryTicketClosedTime>
          )}
        </SingleHistoryTicketOptions>
      </SingleHistoryTicketContent>
    </SingleHistoryTicketContainer>
  )
})
