import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { CurrencyTable, MobileCurrencyTable } from '@/shared/ui'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppSelector } from '@/shared'
import { walletStateSelector } from '@/app/store/selectors/wallet.selectors'
import { intlFormat } from '@/shared/utils/intl-format'
import { ICurrencyTableRow } from '@/shared/ui/commissions-and-deposits/ui/types'

type Props = {
  currency?: string
}

export const CommissionsTable = ({ currency }: Props) => {
  const { t } = useTranslation()
  const wallet = useAppSelector(walletStateSelector)
  const isLessThanTablet = useMediaQuery('(max-width: 760px)')
  const rows = wallet?.replenishments?.map(replenishment => ({
    date: intlFormat(replenishment?.created_at),
    txDetail: replenishment?.transaction_detail,
    amount: Number(replenishment?.sum),
    balance: replenishment?.balance
  }))

  return (
    <Box>
      {isLessThanTablet ? (
        <MobileCurrencyTable currency={currency} rows={rows as ICurrencyTableRow[]} />
      ) : (
        <CurrencyTable currency={currency} title={t('Payment history')} rows={rows as ICurrencyTableRow[]} />
      )}
    </Box>
  )
}
