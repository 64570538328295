// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as _ from 'lodash'
import { BaseState } from '@/app/store/types'
import { SettingsApiKeyType, SettingsFiatCurrencyType, SettingsQrType } from '@/shared'
import { getDefaultFiatCurrencies, getSettingsApiKey, getTwoFactorQr, updateDefaultFiatCurrencies } from '../../thunks'

interface SettingsState extends BaseState<null> {
  fiatCurrencies: SettingsFiatCurrencyType[]
  token: string
  qrcode_src: string
}

const initialState: SettingsState = {
  data: null,
  error: '',
  status: 'idle',
  fiatCurrencies: [],
  token: '',
  qrcode_src: ''
}

export const settingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setApiKey: (state: SettingsState, action: PayloadAction<SettingsApiKeyType>) => {
      state.token = _.merge(state.token, action.payload.token)
    }
  },
  extraReducers: builder => {
    builder.addCase(getTwoFactorQr.pending, (state: SettingsState) => {
      state.status = 'pending'
    })
    builder.addCase(getDefaultFiatCurrencies.pending, (state: SettingsState) => {
      state.status = 'pending'
    })
    builder.addCase(updateDefaultFiatCurrencies.pending, (state: SettingsState) => {
      state.status = 'pending'
    })
    builder.addCase(getSettingsApiKey.pending, (state: SettingsState) => {
      state.status = 'pending'
    })
    builder.addCase(getTwoFactorQr.fulfilled, (state: SettingsState, action: PayloadAction<SettingsQrType>) => {
      state.qrcode_src = action.payload.qrcode_src
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(updateDefaultFiatCurrencies.fulfilled, (state: SettingsState) => {
      state.status = 'fulfilled'
    })
    builder.addCase(
      getDefaultFiatCurrencies.fulfilled,
      (state: SettingsState, action: PayloadAction<SettingsFiatCurrencyType[]>) => {
        state.fiatCurrencies = action.payload
        state.status = 'fulfilled'
        state.error = ''
      }
    )
    builder.addCase(getSettingsApiKey.fulfilled, (state: SettingsState, action: PayloadAction<SettingsApiKeyType>) => {
      state.token = action.payload.token
      state.status = 'fulfilled'
      state.error = ''
    })
  }
})

const { reducer } = settingsSlice

export default reducer
