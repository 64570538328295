import {
  UserFooterAccordion,
  UserFooterHeading,
  UserFooterLinkBlock,
  UserFooterLinksContent,
  UserFooterLinkWrapper
} from './styles'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { LayoutHeaderType } from '@/shared'
import { useRouter } from 'next/router'

type Props = {
  heading: string
  url: string
  links: LayoutHeaderType
}

export const UserFooterLinksBlock = ({ heading, links, url }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const hiddenMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const columnLinks = links.map(({ title, url }) => (
    <UserFooterLinkBlock key={title} onClick={() => footerLinkHandler(url)}>
      {t(title)}
    </UserFooterLinkBlock>
  ))

  const [expanded, setExpanded] = useState<string | false>(false)

  const footerLinkHandler = (url: string) => router.push(url)

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <UserFooterLinkWrapper>
      {hiddenMd ? (
        <UserFooterAccordion expanded={Boolean(expanded)} onChange={handleChange('accordion')} disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#FCFCFC' }} />} sx={{ padding: 0 }}>
            <UserFooterHeading sx={{ mb: 0 }} onClick={() => footerLinkHandler(url)}>
              {t(heading).toLocaleUpperCase()}
            </UserFooterHeading>
          </AccordionSummary>
          <AccordionDetails>{columnLinks}</AccordionDetails>
        </UserFooterAccordion>
      ) : (
        <>
          <UserFooterHeading onClick={() => footerLinkHandler(url)}>{t(heading).toLocaleUpperCase()}</UserFooterHeading>
          <UserFooterLinksContent>{columnLinks}</UserFooterLinksContent>
        </>
      )}
    </UserFooterLinkWrapper>
  )
}
