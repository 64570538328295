import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from '@/app/store'

export const withdrawalTicketsRootState = (state: RootState) => state.withdrawalTickets

export const withdrawalTicketsStateSelector = createDraftSafeSelector(withdrawalTicketsRootState, state => state.data)
export const withdrawalTicketsLoadingSelector = createDraftSafeSelector(
  withdrawalTicketsRootState,
  state => state.status === 'pending'
)
