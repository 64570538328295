import { Box, Button, InputLabel, Select, styled } from '@mui/material'
import Avatar from '@/shared/ui/mui/avatar'

export const SettingsHeading = styled(Box)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '34px',
  lineHeight: '123.5%',
  letterSpacing: '0.25px',
  color: '#FCFCFC',
  marginBottom: '44px'
}))

export const ApiGenerator = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '542px',
  marginBottom: '20px'
}))

export const SettingsButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.25px',
  color: '#FFF',
  textTransform: 'uppercase'
}))

export const SettingsSelectLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  justifyContent: 'space-between',
  color: 'rgba(255, 255, 255, 0.4)',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '24px'
}))

export const SettingsSelect = styled(Select)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  justifyContent: 'space-between',
  borderBottom: `1px solid rgba(234, 234, 255, 0.22)`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '24px'
}))

export const AuthenticationLabel = styled(Box)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%'
}))

export const TwoFactorHeading = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '20px',
  lineHeight: '160%'
}))

export const TwoFactorDescription = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  color: 'rgba(255, 255, 255, 0.75)',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '143%',
  margin: '16px 0',
  maxWidth: '360px'
}))

export const LockAvatarWrapper = styled(Avatar)(() => ({
  marginRight: '16px',
  width: 48,
  height: 48,
  background: '#292929'
}))

export const AccountEmailTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: `'Iter', sans-serif`,
  color: 'rgba(255, 255, 255, 0.75)',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '12px',
  lineHeight: '12px',
  marginBottom: 31
}))

export const AccountCurrentEmail = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '3px'
}))

export const DeleteAccountButton = styled(Button)(({ theme }) => ({
  width: '100%',
  maxWidth: '200px',
  border: '1px solid #186ADF',
  borderRadius: '8px',
  fontFamily: `'Ubuntu', sans-serif`,
  color: '#186ADF',
  minHeight: '38px',
  textTransform: 'uppercase',
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  marginTop: '56px'
}))

export const SuccessOperationText = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter', sans-serif`,
  color: 'rgba(255, 255, 255, 0.75)',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '133.3%'
}))

export const DialogBody = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}))

export const DialogEmail = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}))

export const DialogInnerTitle = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter', sans-serif`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133.3%',
  marginBottom: '56px'
}))

export const DialogEmailText = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  fontFamily: `'Ubuntu', sans-serif`,
  color: 'rgba(252, 252, 252, 0.6)',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '143%'
}))

export const DialogLinkedEmail = styled(Box)(() => ({
  color: '#186ADF',
  display: 'inline-block'
}))

export const DialogOkButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  width: '65px',
  height: '38px',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(76, 78, 100, 0.42)',
  borderRadius: '8px'
}))
