import { Box, styled } from '@mui/material'

export const MobileMenuItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu', sans-serif`,
  cursor: 'pointer',
  color: '#FCFCFC',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 0',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '16px',
  lineHeight: '24px',
  gap: '8px',
  borderBottom: '1px solid #DEDEDE'
}))
