// ** Styled Components
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

export const BoxNotFoundWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

export const NotFoundText = styled(Box)(({ theme }) => ({
  family: '`Iter`, sans-serif',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: theme.palette.fontWeight['600'],
  lineHeight: '24px',
  color: '#FCFCFC'
}))

export const NotFoundWrapper = styled(Box)(() => ({
  p: 5,
  display: 'flex',
  flexDirection: 'column',
  height: '87vh',
  alignItems: 'center',
  justifyContent: 'center'
}))
