import { NoTicketFound, NoTicketFoundText, OneTicketMessagesLoader, SupportChatWrapper } from './styles'
import { SupportChatYourMessage } from './support-chat-your-message'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SendSupportMessage } from './send-support-message'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/types'
import { ticketMessagesSelector, ticketsLoadingStateSelector } from '@/app/store/selectors'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { getAllTicketsMessages } from '@/app/store/thunks/tickets'
import CircularProgress from '@mui/material/CircularProgress'
import ForumIcon from '@mui/icons-material/Forum'
import { useRouter } from 'next/router'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const OneTicketSupportChat = () => {
  const loading = useAppSelector(ticketsLoadingStateSelector)
  const dispatch = useAppDispatch()
  const { query } = useRouter()
  const { id } = query

  const scrollRef = useRef<HTMLElement | null>(null)

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }
  }
  const { t } = useTranslation()
  const messages = useAppSelector(ticketMessagesSelector)
  const isMessagesAvailable = (messages ?? []).length > 0

  useLayoutEffect(scrollToBottom, [messages, scrollToBottom])

  useEffect(() => {
    dispatch(getAllTicketsMessages({ ticketId: String(id) }))
  }, [dispatch, id])

  const supportMessages = isMessagesAvailable ? (
    messages.map(supportMessage => (
      <Box key={supportMessage.id}>
        <SupportChatYourMessage supportMessage={supportMessage} />
      </Box>
    ))
  ) : (
    <NoTicketFound>
      <ForumIcon sx={{ fontSize: '70px' }} />
      <NoTicketFoundText>{t('No messages found...')}</NoTicketFoundText>
    </NoTicketFound>
  )

  return (
    <SupportChatWrapper>
      <PerfectScrollbar
        containerRef={el => {
          scrollRef.current = el
        }}
      >
        {loading ? (
          <OneTicketMessagesLoader>
            <CircularProgress color='inherit' size={40} />
          </OneTicketMessagesLoader>
        ) : (
          <Box>{supportMessages}</Box>
        )}
      </PerfectScrollbar>
      {isMessagesAvailable && <SendSupportMessage />}
    </SupportChatWrapper>
  )
}
