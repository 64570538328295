import Accordion from '@mui/material/Accordion'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactNode } from 'react'
import { AccordionTypography } from './styles'

type Props = Readonly<{
  title: string
  content: ReactNode
}>

export const TableAccordion = ({ title = '', content }: Props) => {
  return (
    <Accordion sx={{ padding: '11px 0', borderRadius: '12px', '&:before': { position: 'static' } }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionTypography sx={{ color: '#FCFCFC', fontFamily: `'Inter'` }}>{title}</AccordionTypography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>{content}</AccordionDetails>
    </Accordion>
  )
}
