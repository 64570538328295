/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { Box, FormControl, FormHelperText, TextFieldProps } from '@mui/material'
import { FundsWithdrawalAmountControlHead, FundsWithdrawalFormLabel, FundsWithdrawalTextField } from '@/shared'
import { ChangeEvent } from 'react'

/**
 * styled items from styling
 */

type FormInputProps = TextFieldProps & {
  control?: Control<any> | undefined
  defaultValue?: string
  name: string
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
  amount?: JSX.Element
}

export const FundsWithdrawalAmountInput = ({
  control,
  label = '',
  name,
  variant = 'outlined',
  startAdornment,
  endAdornment,
  placeholder,
  amount,
  ...otherProps
}: FormInputProps) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => {
          const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const amount = e.target.value
            const matcher = /^\d+(\.\d{0,6})?$/
            const multipleZerosEntered = amount.startsWith('0') && amount.charAt(1) === '0'

            if (multipleZerosEntered) return
            if (amount === '' || amount.match(matcher)) {
              field.onChange(amount)
            }
          }

          return (
            <Box>
              <FundsWithdrawalAmountControlHead>
                {label && (
                  <FundsWithdrawalFormLabel sx={{ color: errors && errors[name] && 'error.main' }}>
                    {label}
                  </FundsWithdrawalFormLabel>
                )}
                {amount}
              </FundsWithdrawalAmountControlHead>
              <FundsWithdrawalTextField
                {...field}
                {...otherProps}
                fullWidth
                autoComplete='off'
                variant={variant}
                onChange={handleChange}
                error={Boolean(errors[name])}
                placeholder={placeholder}
                InputProps={{
                  startAdornment,
                  endAdornment
                }}
                InputLabelProps={{ shrink: false }}
              />
              {errors.hasOwnProperty(name) && errors[name] && (
                <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                  {errors[name]?.message as string}
                </FormHelperText>
              )}
            </Box>
          )
        }}
      />
    </FormControl>
  )
}
