import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { EnableFormButtons, EnableFormWrapper, EnableTwoFactorButton, useAppDispatch } from '@/shared'
import toast from 'react-hot-toast'
import { FormInput } from '@/shared/ui/react-hook-form'
import { enableFormSchema } from '@/shared/ui/two-factor/enable-form.schema'
import { userService } from '@/shared/services/user.service'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { getCurrentUser } from '@/app/store/thunks'
import { Theme } from '@mui/material/styles'

type EnableFormData = {
  code: string
}

const defaultValues = {
  code: ''
}

export const EnableForm = () => {
  /**
   * hook form
   */
  const router = useRouter()
  const { i18n } = useTranslation()
  const schema = useMemo(() => enableFormSchema(), [i18n.language])

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<EnableFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  /**
   * translation hooks
   */
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  /**
   * submit handler
   */
  const onSubmit = async (data: EnableFormData) => {
    const response = await userService.enableTwoFactor(data.code).catch(e => {
      const { message } = e?.response?.data?.error
      if (message) {
        setError('code', { message })
      }
    })

    if (response !== undefined) {
      dispatch(getCurrentUser())
      toast.success(t(`2-Step Verification enabled.`), {
        position: 'bottom-right'
      })
      await router.push('/settings')
    }
  }

  const goBackHandler = () => router.push('/settings')

  return (
    <EnableFormWrapper noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        sx={{ boxShadow: (theme: Theme) => theme.shadows[3] }}
        name='code'
        control={control}
        label={''}
        placeholder={t('Authenticator code')}
        inputProps={{ minLength: 0, maxLength: 6 }}
      />
      <EnableFormButtons>
        <EnableTwoFactorButton
          fullWidth
          sx={{ m: 0 }}
          size='large'
          disabled={!isDirty || !isValid || isSubmitting}
          type='submit'
          variant='contained'
        >
          {isSubmitting ? <CircularProgress color='inherit' size={30} /> : t('Enable 2FA')}
        </EnableTwoFactorButton>
        <Button variant='text' sx={{ height: '48px' }} onClick={goBackHandler}>
          {t('Back')}
        </Button>
      </EnableFormButtons>
    </EnableFormWrapper>
  )
}
