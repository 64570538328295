import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import { useTheme } from '@mui/material/styles'
import { useRouter } from 'next/router'
import {
  BootstrapTooltip,
  NavListItemLink,
  NavListItemMetaWrapper
} from '@/shared/layouts/core/vertical/navigation/styles'
import { handleURLQueries } from '@/shared/layouts'
import { useSettings } from '@/shared/hooks/useSettings'
import themeConfig from '@/shared/configs/themeConfig'
import Translations from '@/shared/layouts/core/additional/translations'
import Zoom from '@mui/material/Zoom'
import { Box } from '@mui/material'
import Link from 'next/link'

type MenuLinkItemType = { path: string; icon: JSX.Element; text: string; openInNewTab: boolean; tooltip: string | null }

type Props = {
  item: MenuLinkItemType
  navVisible: boolean
  navHover: boolean
  collapsedNavWidth: number
  navigationBorderWidth: number
  toggleNavVisibility: () => void
}

export const VerticalListItem = ({
  item,
  navHover,
  navVisible,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}: Props) => {
  const { settings } = useSettings()
  const theme = useTheme()
  const router = useRouter()

  const isCollapsed = settings.navCollapsed && !navHover
  const isNavLinkActive = (item: MenuLinkItemType) => {
    return router.pathname === item.path || handleURLQueries(router, item.path)
  }

  return (
    <BootstrapTooltip title={item?.tooltip ?? ''} placement='right' TransitionComponent={Zoom}>
      <ListItem
        key={item.text}
        disablePadding
        className='nav-link'
        sx={{
          transition: 'padding .25s ease-in-out',
          px: parent ? '0 !important' : `${theme.spacing(isCollapsed ? 2 : 3)} !important`
        }}
      >
        <Link passHref href={item.path === undefined ? '/' : `${item.path}`}>
          <NavListItemLink
            component={'a'}
            className={isNavLinkActive(item) ? 'active' : ''}
            {...(item.openInNewTab ? { target: '_blank' } : null)}
            onClick={e => {
              if (isNavLinkActive(item) || item.path === undefined) {
                e.preventDefault()
                e.stopPropagation()
              }
              if (navVisible) {
                toggleNavVisibility()
              }
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              py: 2.25,
              pr: isCollapsed ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 3,
              pl: isCollapsed ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 4
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <ListItemIcon
                sx={{
                  transition: 'margin .25s ease-in-out',
                  marginRight: isCollapsed ? 0 : '10px',
                  ...(parent ? { ml: 0 } : {})
                }}
              >
                {item.icon}
              </ListItemIcon>
              <NavListItemMetaWrapper
                sx={{
                  ...(isCollapsed ? { display: 'none' } : { display: 'flex' }),
                  paddingRight: 0
                }}
              >
                <Typography
                  {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && isCollapsed)) && {
                    noWrap: true
                  })}
                >
                  <Translations text={item.text} />
                </Typography>
              </NavListItemMetaWrapper>
            </Box>
          </NavListItemLink>
        </Link>
      </ListItem>
    </BootstrapTooltip>
  )
}
