import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { UseFormClearErrors } from 'react-hook-form/dist/types/form'

export const useVerified = (initialState = false, clearErrors?: UseFormClearErrors<any>) => {
  const captchaRef = useRef<ReCAPTCHA | null>(null)
  const token = captchaRef?.current?.getValue()

  const [verifiedCaptcha, setVerifiedCaptcha] = useState<boolean>(initialState)
  const onCaptchaChange = (token: string | null): void => {
    if (!token) {
      return
    }
    if (token && verifiedCaptcha) {
      clearErrors && clearErrors('g-recaptcha-response')
    }
    setVerifiedCaptcha(true)
  }

  return {
    captchaRef,
    token,
    verifiedCaptcha,
    onCaptchaChange
  }
}
