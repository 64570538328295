import { CrmCryptocurrenciesCard } from '@/shared/ui/dashboard'
import CardContent from '@mui/material/CardContent'
import { CryptocurrenciesTable } from './cryptocurrencies-table'

export const CrmCryptocurrencies = () => {
  return (
    <CrmCryptocurrenciesCard>
      <CardContent>
        <CryptocurrenciesTable />
      </CardContent>
    </CrmCryptocurrenciesCard>
  )
}
