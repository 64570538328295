import { AppBar, Box } from '@mui/material'
import { ElevationScroll, LayoutNavigation, LogoCryptoWhite } from '@/shared/ui'
import { AppToolbar, HeaderWrapper, LogoWrapper } from './styles'
import { LanguageDropdown } from '@/shared/layouts'
import { useRouter } from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { LayoutBurgerMenu } from '@/shared/ui/layout-burger-menu'

export const BlankLayoutHeader = () => {
  const router = useRouter()
  const isTablet = useMediaQuery('(max-width: 1150px)')
  const hiddenMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ElevationScroll>
        <AppBar component='nav' sx={{ background: '#101010', borderBottom: '1px solid #292929', padding: '2px 16px' }}>
          <AppToolbar disableGutters>
            <LogoWrapper onClick={() => router.push('/watcher-dashboard')}>
              <LogoCryptoWhite height={hiddenMd ? 36 : 58} width={hiddenMd ? 160 : 238} />
            </LogoWrapper>
            {isTablet ? (
              <HeaderWrapper>
                <LanguageDropdown />
                <LayoutBurgerMenu />
              </HeaderWrapper>
            ) : (
              <HeaderWrapper>
                <LayoutNavigation />
                <LanguageDropdown />
              </HeaderWrapper>
            )}
          </AppToolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  )
}
