import { MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormTicketsSelect } from '@/shared/ui/react-hook-form/form-tickets-select'

type StatusDisplayOptions = 'all' | 'open' | 'answered'

type TicketSelectSort = { status: StatusDisplayOptions }

const defaultValues: TicketSelectSort = {
  status: 'all'
}

export const useTicketsStatus = () => {
  const { t } = useTranslation()
  const { control, watch } = useForm<TicketSelectSort>({
    defaultValues,
    mode: 'onChange'
  })

  const status = watch('status')

  const ticketStatuses: { name: string; value: StatusDisplayOptions }[] = [
    { name: 'All', value: 'all' },
    { name: 'Opened', value: 'open' },
    { name: 'Answered', value: 'answered' }
  ]

  const renderStatusDropdown = () => (
    <FormTicketsSelect
      sx={{ height: 40 }}
      name='status'
      fullWidth
      control={control}
      label=''
      placeholder={t('Select store')}
    >
      {ticketStatuses.map(status => (
        <MenuItem key={status.name} value={status.value}>
          {t(status.name)}
        </MenuItem>
      ))}
    </FormTicketsSelect>
  )

  return {
    status,
    renderStatusDropdown
  }
}
