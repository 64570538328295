import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'

export const HistoryTicketsBlock = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}))

export const HistoryTicketsContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: '32px'
}))

export const HistoryTicketsInner = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const HistoryTicketsHeading = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  marginRight: '8px'
}))

export const HistoryTicketsCount = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  marginRight: '8px'
}))

export const NewHistoryTicketButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 125,
  textTransform: 'uppercase',
  padding: '7px 22px',
  fontSize: '14px',
  fontFamily: `'Inter'`,
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['500'],
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  '&:hover': {
    background: '#186ADF'
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '12px'
  }
}))

export const SingleHistoryTicketContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '24px 36px',
  gap: '10px',
  width: '100%',
  background: '#101010',
  cursor: 'pointer',
  borderRadius: '12px',
  marginBottom: '24px',
  transition: '.2s ease-in-out',
  border: '1px solid #292929',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px 14px'
  }
}))

export const SingleHistoryTicketContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse'
  }
}))

export const SingleHistoryTicketInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

export const HistoryTicketClosedTime = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu'`,
  marginTop: '10px',
  lineHeight: '175%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  marginBottom: '10px',
  textAlign: 'right',
  [theme.breakpoints.down('md')]: {
    textAlign: 'left'
  }
}))

export const HistoryTicketCreatedTime = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu'`,
  marginTop: '10px',
  lineHeight: '175%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  marginBottom: '10px'
}))

export const HistoryTicketUniqueIdentifier = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '157%',
  fontWeight: theme.palette.fontWeight['500'],
  color: 'rgba(255, 255, 255, 0.75)',
  marginRight: '8px'
}))

export const HistoryTicketName = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  fontFamily: `'Iter', sans-serif`,
  lineHeight: '133%',
  fontWeight: theme.palette.fontWeight['400'],
  color: '#186ADF',
  marginTop: '2px',
  marginBottom: '10px'
}))

export const HistoryTicketStatus = styled(Button)(({ theme }) => ({
  padding: '8px 18px',
  gap: '8px',
  fontSize: '16px',
  fontFamily: `'Iter', sans-serif`,
  lineHeight: '26px',
  fontWeight: theme.palette.fontWeight['500'],
  color: 'rgba(255, 255, 255, 0.75)',
  border: '1px solid rgba(255, 255, 255, 0.75)',
  borderRadius: '8px',
  background: 'transparent',
  '&:hover': {
    background: 'transparent'
  }
}))
export const HistoryTicketLastMessage = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '150%',
  width: '100%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))

export const SingleHistoryTicketOptions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start'
  }
}))
