import { Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconedMenuText, MobileMenuItem } from '@/shared/layouts'

type Props = {
  title: string
  onClick: () => void
  icon?: any
}

export const HeadMenuItem = ({ title, onClick, icon }: Props) => {
  return (
    <MobileMenuItem onClick={onClick}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <IconedMenuText>{title}</IconedMenuText>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ArrowForwardIosIcon sx={{ fontSize: '24px', cursor: 'pointer', color: '#101010' }} />
        </Box>
      </Box>
    </MobileMenuItem>
  )
}
