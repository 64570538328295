import Box from '@mui/system/Box'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

export const ConfirmDeleteWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const ConfirmDeleteTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: `'Inter'`,
  color: '#FCFCFC',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '20px',
  lineHeight: '29px',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center'
  }
}))

export const ConfirmDeleteButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '400px',
  marginTop: '20px',
  [theme.breakpoints.down('lg')]: {
    margin: '20px auto'
  }
}))

export const ConfirmButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Inter'`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  width: '130px',
  marginRight: '20px',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(76, 78, 100, 0.42)',
  borderRadius: '8px'
}))
