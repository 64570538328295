import { authConfig, axiosInstance } from '@/shared'

class PageService {
  async getPagesTooltip() {
    const response = await axiosInstance.get(`${authConfig.pageEndpoint}`)

    return response.data
  }

  async getPageModules(slug: string, signal: AbortSignal) {
    const response = await axiosInstance.get(`${authConfig.pageEndpoint}/modules`, {
      signal,
      params: { slug }
    })

    return response.data
  }
}

export const pageService = new PageService()
