import Grid from '@mui/material/Grid'
import {
  CrmCryptocurrencies,
  CrmLastMonthTransactions,
  CrmYesterdayTransactions,
  CryptocurrenciesAnalytics
} from '@/shared'

export const AllTransactions = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={8}>
        <CrmCryptocurrencies />
      </Grid>
      <Grid item xs={12} md={4}>
        <CryptocurrenciesAnalytics />
      </Grid>
      <Grid item xs={12} md={6}>
        <CrmYesterdayTransactions />
      </Grid>
      <Grid item xs={12} md={6}>
        <CrmLastMonthTransactions />
      </Grid>
    </Grid>
  )
}
