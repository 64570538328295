import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { notVerifiedEmailFormSchema } from './not-verified-email-form.schema'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@/shared/ui/react-hook-form'
import { Countdown, NotVerifiedForm, useAppDispatch, useAppSelector } from '@/shared'
import { resentEmail } from '@/app/store/thunks'
import { EmailNotVerifiedButton } from '@/shared/ui/email-not-verified-button'
import { userErrorSelector } from '@/app/store/selectors'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'

export type NotVerifiedEmailFormData = {
  email: string
}

const defaultValues = {
  email: ''
}

type Props = { countdown: Countdown }

export const NotVerifiedEmailForm = ({ countdown }: Props) => {
  const { i18n } = useTranslation()

  /**
   * hook form
   */
  const schema = useMemo(() => notVerifiedEmailFormSchema(), [i18n.language])
  const dispatch = useAppDispatch()
  const message = useAppSelector(userErrorSelector)

  const {
    control,
    setError,
    reset,
    watch,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<NotVerifiedEmailFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })
  const { t } = useTranslation()
  const email = watch('email')

  /**
   * submit handler
   */

  const onSubmit = async () => {
    const response = await dispatch(resentEmail({ email: String(email), lang: i18n.language }))
    if (response?.payload !== undefined) {
      if (Boolean(message)) {
        toast.error(t(message), { position: 'bottom-right' })
        setError('email', { message })
      }
      reset()
    }
  }

  return (
    <NotVerifiedForm noValidate autoComplete='off'>
      <FormInput
        name='email'
        control={control}
        disableMargin
        disabled={countdown.isActive}
        label={''}
        placeholder={t('Enter your email')}
        inputProps={{ minLength: 8, maxLength: 100 }}
      />
      <EmailNotVerifiedButton
        countdown={countdown}
        fullWidth
        size='large'
        disabled={!isDirty || !isValid || isSubmitting}
        onClick={onSubmit}
        variant='contained'
      />
    </NotVerifiedForm>
  )
}
