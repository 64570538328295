import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const TranslationsLink = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '150%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white
}))
