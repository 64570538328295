import { BackIcon, InfoTicketHash, InfoTicketName, InfoWrapper } from './styles'
import { useRouter } from 'next/router'

type Props = {
  id: number
  subject: string
}

export const TicketNavigationInfo = ({ id, subject }: Props) => {
  const router = useRouter()

  const handleBack = () => {
    router.push('/support-tickets')
  }

  return (
    <InfoWrapper>
      <BackIcon onClick={handleBack} />
      <InfoTicketName>{subject}</InfoTicketName>
      <InfoTicketHash>#{id}</InfoTicketHash>
    </InfoWrapper>
  )
}
