import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { Button } from '@mui/material'
import Chip from '@mui/material/Chip'

export const EnableInstructionContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const InstructionWrapper = styled(Box)(() => ({
  height: '87vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const InstructionStepText = styled(Box)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  fontSize: '14px',
  lineHeight: '143%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    textAlign: 'center'
  }
}))

export const EnableFormWrapper = styled('form')(() => ({
  maxWidth: 300,
  marginTop: 15,
  width: '100%'
}))

export const InstructionChip = styled(Chip)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  display: 'flex',
  alignItems: 'center',
  fontSize: '15px',
  lineHeight: '143%',
  marginLeft: '5px',
  fontWeight: theme.palette.fontWeight['700'],
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginTop: '10px'
  }
}))

export const EnableTwoFactorButton = styled(Button)(({ theme }) => ({
  background: '#186ADF',
  width: 150,
  height: 48,
  padding: '20px 5px',
  fontSize: '14px',
  fontFamily: `'Inter'`,
  lineHeight: '16px',
  fontWeight: theme.palette.fontWeight['700'],
  color: '#FCFCFC',
  borderRadius: '12px',
  '&:hover': {
    background: '#1B76F7',
    boxShadow: '0px 6px 8px rgba(24, 106, 223, 0.35)'
  },
  '&&&': {
    '&.Mui-disabled': {
      '&:disabled': {
        background: '#186ADF',
        opacity: 0.5,
        color: theme.palette.common.white,
        cursor: 'not-allowed',
        pointerEvents: 'none !important'
      }
    }
  }
}))

export const EnableTwoFactorQrCode = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1.5rem',
  width: 'fit-content',
  background: '#292929',
  margin: '24px 0'
}))

export const EnableFormButtons = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))
