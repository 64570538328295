import DialogContent from '@mui/material/DialogContent'
import { useEffect, useState } from 'react'

import { PaymentsHistoryTableToolbar } from '@/shared/ui'

import {
  ApplyAllButton,
  FiltersHeading,
  ModalActionsButtons,
  PaymentsHistoryDialog,
  PaymentsHistoryDialogTitle,
  PaymentsHistoryPaper,
  ResetAllButton,
  SelectFilterItem,
  SelectFiltersContainer
} from './styles'
import { useTranslation } from 'react-i18next'
import { PaymentsFiltersTimeDropdown } from './payments-filters-time-dropdown'
import { HistoryInputFilters, useAppDispatch, useAppSelector, UsersHistoryFilters } from '@/shared'
import { PaymentsFiltersPresetDropdown } from './payments-filters-preset-dropdown'
import { PaymentsFiltersTagDropdown } from './payments-filters-tag-dropdown'
import { PaymentsFiltersToolbar } from './payments-filters-toolbar'
import { PaymentsFiltersBlockchainDropdown } from './payments-filters-blockchain-dropdown'
import { setDefaultFilters, setRequestFilters, setRequestParams } from '@/app/store/apps/history'
import { historyStateSelector } from '@/app/store/selectors/history.selectors'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Box } from '@mui/material'

const defaultValues = {
  date_start: '',
  date_end: ''
}

export const PaymentsFiltersModal = () => {
  const methods = useForm<HistoryInputFilters>({
    defaultValues,
    mode: 'onChange'
  })
  const {
    history: { filters }
  } = useAppSelector(historyStateSelector)

  const [collectedFilters, setCollectedFilters] = useState<Record<string, string | string[]>>(filters ?? {})
  const dispatch = useAppDispatch()

  useEffect(() => {
    setCollectedFilters(collectedFilters)
  }, [collectedFilters])

  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const tableFiltersReset = () => {
    methods.reset()
    setCollectedFilters({})
    dispatch(setDefaultFilters())
    dispatch(setRequestFilters({}))
  }

  const onFiltersReset = () => {
    toast.success(t(`Filters reset`), {
      position: 'bottom-right'
    })
    tableFiltersReset()
    handleClose()
  }

  const onFilterCollect = (grabbedFilters: Partial<UsersHistoryFilters>) => {
    setCollectedFilters({ ...collectedFilters, ...grabbedFilters })
  }

  const onSubmit: SubmitHandler<HistoryInputFilters> = data => {
    for (const key in data) {
      if (data[key as keyof typeof data] === '') {
        delete data[key as keyof typeof data]
      }
    }
    const filters = { ...collectedFilters, ...data }
    dispatch(setRequestParams({ page: 0 }))
    dispatch(setRequestFilters(filters))
    handleClose()
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PaymentsFiltersToolbar />
        <PaymentsHistoryTableToolbar tableFiltersReset={tableFiltersReset} onClick={handleClickOpen} />
      </Box>
      <PaymentsHistoryDialog onClose={handleClose} open={open} PaperComponent={PaymentsHistoryPaper}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <PaymentsHistoryDialogTitle onClose={handleClose} />
            <DialogContent>
              <FiltersHeading>{t('Filters')}</FiltersHeading>
              <SelectFiltersContainer>
                <SelectFilterItem>
                  <PaymentsFiltersPresetDropdown label='Store' onFilterCollect={onFilterCollect} />
                </SelectFilterItem>
                <SelectFilterItem>
                  <PaymentsFiltersTagDropdown label='Tag' onFilterCollect={onFilterCollect} />
                </SelectFilterItem>
                <SelectFilterItem>
                  <PaymentsFiltersTimeDropdown />
                </SelectFilterItem>
                <SelectFilterItem>
                  <PaymentsFiltersBlockchainDropdown label='Currency' onFilterCollect={onFilterCollect} />
                </SelectFilterItem>
              </SelectFiltersContainer>
            </DialogContent>
            <ModalActionsButtons>
              <ResetAllButton variant='contained' onClick={onFiltersReset}>
                {t('Reset all')}
              </ResetAllButton>
              <ApplyAllButton variant='contained' type='submit'>
                {t('Apply')}
              </ApplyAllButton>
            </ModalActionsButtons>
          </form>
        </FormProvider>
      </PaymentsHistoryDialog>
    </Box>
  )
}
