import toast from 'react-hot-toast'
import { FormControl, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { ReferralCopy, ReferralText } from '@/shared/ui'
import { useTranslation } from 'react-i18next'
import { tokenService } from '@/shared'

export const CopyToClipboard = () => {
  const user = tokenService.getUser()
  const copyText = `${process.env.APP_SITE_HOST}register?ref=${user?.referral_token}`

  const { t } = useTranslation()

  const copyTextToClipboard = async (text: string) => {
    return 'clipboard' in navigator && (await navigator.clipboard.writeText(text))
  }

  const handleCopy = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        toast.success(t('Referral link copied to clipboard'), { position: 'bottom-right' })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <FormControl fullWidth variant='outlined'>
      <ReferralCopy>
        {copyText}
        <IconButton type='button' sx={{ p: '10px' }} onClick={handleCopy}>
          <ContentCopyIcon />
        </IconButton>
      </ReferralCopy>
      <ReferralText>{t('To earn affiliate commission you can use the below referral link.')}</ReferralText>
    </FormControl>
  )
}
