import { Box } from '@mui/material'
import { styled } from '@mui/system'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const WithdrawalOneTicketWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}))

export const WithdrawalTicketNavigationContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
}))

export const InfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export const InfoTicketName = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Iter', sans-serif`,
  lineHeight: '123%',
  wordBreak: 'break-all',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  margin: '0 12px',
  [theme.breakpoints.down('md')]: {
    margin: 0,
    order: 2
  }
}))

export const InfoTicketHash = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Iter', unset`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  [theme.breakpoints.down('sm')]: {
    order: 1
  }
}))

export const SupportChatWrapper = styled(Box)<{ $nomessages?: boolean }>(({ theme, $nomessages }) => ({
  marginTop: '24px',
  display: 'flex',
  padding: '48px 36px 0px',
  flexDirection: 'column',
  gap: '36px',
  width: '100%',
  transition: 'height 1s ease',
  maxHeight: $nomessages ? '244px' : '520px',
  height: '100%',
  background: '#292929',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    padding: '36px 16px'
  }
}))

export const NoTicketFound = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const SupportMessageWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '36px'
}))

export const SupportMessageSender = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const SupportMessageName = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '150%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white
}))

export const SupportMessageBody = styled(Box)<{ is_admin: boolean }>(({ is_admin }) => ({
  display: 'flex',
  padding: '16px 12px',
  flexDirection: 'column',
  gap: '10px',
  width: '100%',
  minHeight: '56px',
  wordBreak: 'break-all',
  background: '#424242',
  color: '#fff',
  border: is_admin ? '1px solid #424242' : 'none',
  borderRadius: '8px',
  alignSelf: 'stretch',
  marginTop: '12px',
  marginBottom: '8px'
}))

export const SupportMessageTime = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu', sans-serif`,
  lineHeight: '143%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const BackIcon = styled(ArrowBackIcon)({ fontSize: '24px', cursor: 'pointer', mb: '4px' })

export const OneTicketMessagesLoader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
}))

export const ImagePreviewBody = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px'
}))

export const ImagePreview = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '10px',
  cursor: 'pointer'
}))

export const TicketChatInfoHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginTop: '24px',
  cursor: 'pointer'
}))

export const ImagePreviewText = styled(Box)(({ theme }) => ({
  family: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '150%',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const WithdrawalChatInfo = styled(Box)(() => ({
  display: 'flex',
  marginRight: '16px',
  marginBottom: '12px',
  '&:last-child': {
    marginBottom: 0
  }
}))

export const WithdrawalChatName = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '150%',
  marginRight: '4px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const WithdrawalChatValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '150%',
  marginRight: '4px',
  color: '#FFF'
}))

export const StartConversationText = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Iter', sans-serif`,
  fontSize: '24px',
  lineHeight: '133.4%',
  color: 'rgba(252, 252, 252, 0.3)',
  fontWeight: theme.palette.fontWeight['400']
}))
