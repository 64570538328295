import * as yup from 'yup'
import i18n from 'i18next'

export const fundsWithdrawalFormSchema = () =>
  yup.object({
    currency_id: yup.number().required(i18n.t('Currency is required field')),
    address: yup
      .string()
      .min(1, i18n.t('Minimum length 1 character'))
      .max(100, i18n.t('Max 100 symbols allowed'))
      .required(i18n.t('Address is required field')),
    sum: yup.string().required(i18n.t('Sum is required field')),
    message: yup.string().optional(),
    two_auth_code: yup.string().required(i18n.t('Code is required field'))
  })
