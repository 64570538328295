// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseState } from '@/app/store/types'
import { getTestPaymentTransactionLogs, testPaymentTransactionFx } from '@/app/store/thunks/payment'
import { TestPaymentTransactionLogs } from '@/shared/types/payment'

type PaymentState = BaseState<TestPaymentTransactionLogs>

const initialState: PaymentState = {
  data: {
    items: []
  },
  error: '',
  status: 'idle'
}

export const paymentSlice = createSlice({
  name: 'userPayment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(testPaymentTransactionFx.pending, (state: PaymentState) => {
      state.status = 'pending'
    })
    builder.addCase(testPaymentTransactionFx.rejected, (state: PaymentState) => {
      state.status = 'rejected'
    })
    builder.addCase(getTestPaymentTransactionLogs.pending, (state: PaymentState) => {
      state.status = 'pending'
    })
    builder.addCase(getTestPaymentTransactionLogs.rejected, (state: PaymentState) => {
      state.status = 'rejected'
    })
    builder.addCase(
      getTestPaymentTransactionLogs.fulfilled,
      (state: PaymentState, action: PayloadAction<TestPaymentTransactionLogs>) => {
        state.status = 'fulfilled'
        state.data.items = action.payload.items
      }
    )
  }
})
const { reducer } = paymentSlice

export default reducer
