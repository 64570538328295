import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {
  CheckCircled,
  FundsWithdrawalContentText,
  FundsWithdrawalDialog,
  FundsWithdrawalModalButton,
  FundsWithdrawalTextLink
} from '@/shared'
import { FundsWithdrawalDialogTitle } from './fundswithdrawals-dialog-title'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleRequestModal: () => void
}

export const WithdrawalRequestModal = ({ open, handleRequestModal }: Props) => {
  const { t } = useTranslation()

  return (
    <FundsWithdrawalDialog open={open} onClose={handleRequestModal}>
      <FundsWithdrawalDialogTitle onClose={handleRequestModal}>
        {t('Withdrawal request accepted')}
      </FundsWithdrawalDialogTitle>
      <DialogContent sx={{ textAlign: 'center', '& > a': { color: '#186ADF' } }}>
        <CheckCircled />
        <FundsWithdrawalContentText>
          {t('You will receive a response via a support ticket.')}
        </FundsWithdrawalContentText>
        <FundsWithdrawalTextLink href={`/withdrawals-tickets`} passHref>
          <a target='_blank' rel='noopener noreferrer'>
            {t('Ticket link.')}
          </a>
        </FundsWithdrawalTextLink>
      </DialogContent>
      <DialogActions>
        <FundsWithdrawalModalButton type='button' variant='contained' onClick={handleRequestModal}>
          {t('OK')}
        </FundsWithdrawalModalButton>
      </DialogActions>
    </FundsWithdrawalDialog>
  )
}
