import { styled } from '@mui/system'
import { Box, Button } from '@mui/material'

export const DeletedAccountText = styled(Box)(({ theme }) => ({
  fontFamily: `'Iter', sans-serif`,
  fontWeight: theme.palette.fontWeight['900'],
  fontSize: '42px',
  lineHeight: '56px',
  letterSpacing: '0.25px',
  color: '#FCFCFC',
  textAlign: 'center',
  marginBottom: '2rem'
}))

export const DeletedAccountWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60vh'
}))

export const DeletedAccountButton = styled(Button)(() => ({
  minWidth: 300
}))
