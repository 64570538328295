/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { FormHelperText, TextFieldProps } from '@mui/material'

/**
 * styled items from styling
 */
import { ChangeEvent, memo } from 'react'
import { InputBaseProps } from '@mui/material/InputBase'
import { OutlinedFormTextInput, OutlinedTextInputControl } from './styles'

/**
 * FormTextInput type for props
 */
type FormInputProps = {
  control?: Control<any> | undefined
  name: string
  inputProps?: InputBaseProps['inputProps']
  pattern?: RegExp
  className?: string
  label: string
  sx?: any
} & TextFieldProps

/**
 * Custom form input component
 * @params {FormInputProps} - props for input component
 * @default type - text
 */
export const FormTextInput = memo(
  ({ control, label = '', name, variant = 'outlined', placeholder, type, pattern, ...otherProps }: FormInputProps) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => {
          const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const amount = e.target.value
            const matcher = pattern ? pattern : /^\d+(\.\d{0,6})?$/
            const multipleZerosEntered = amount.startsWith('0') && amount.charAt(1) === '0'

            if (multipleZerosEntered) return
            if (amount === '' || amount.match(matcher)) {
              field.onChange(amount)
            }
          }

          return (
            <OutlinedTextInputControl fullWidth>
              <OutlinedFormTextInput
                {...field}
                {...otherProps}
                value={field.value ?? ''}
                variant={variant}
                label={label}
                onChange={type === 'number' ? handleChange : field.onChange}
                invalid={!!(errors && errors[name])}
                error={Boolean(errors[name])}
                placeholder={placeholder}
                InputLabelProps={{ shrink: true }}
              />
              {errors.hasOwnProperty(name) && errors[name] ? (
                <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                  {errors[name]?.message as string}
                </FormHelperText>
              ) : null}
            </OutlinedTextInputControl>
          )
        }}
      />
    )
  }
)
