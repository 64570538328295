import { Box, styled } from '@mui/system'
import Card from '@mui/material/Card'
import { TableCell, TableCellProps } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'

export const TabContextContainer = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .MuiButtonBase-root': {
    fontFamily: `'Inter'`,
    fontWeight: theme.palette.fontWeight['500'],
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.4px',
    textTransform: 'uppercase'
  },
  '& .MuiTabs-root': {
    marginBottom: '24px'
  },
  '& .Mui-selected': {
    color: '#186ADF'
  },
  '& .MuiTabs-indicator': {
    background: '#186ADF'
  }
}))

export const CrmCryptocurrenciesCard = styled(Card)(() => ({
  minHeight: 260,
  background: '#292929',
  boxShadow: '0px 2px 10px rgba(20, 21, 33, 0.18)',
  borderRadius: '10px'
}))

export const CrmWeekAmountTransactionsCard = styled(Card)(() => ({}))
export const CryptocurrenciesTableTitle = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '20px',
  textTransform: 'none',
  lineHeight: '160%',
  color: '#FFF'
}))

export const TableCurrenciesCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  textTransform: 'none',
  lineHeight: '24px',
  color: '#FFF'
}))

export const TableRowCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  textTransform: 'none',
  lineHeight: '160%',
  color: 'rgba(255, 255, 255, 0.75)'
}))
export const TableBalanceRow = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  textTransform: 'none',
  lineHeight: '143%',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const TableRowCurrencyCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  textTransform: 'none',
  lineHeight: '24px',
  color: '#FFF'
}))
export const TableNoTransactions = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '20px',
  textTransform: 'none',
  marginTop: '12px',
  lineHeight: '133%',
  color: 'rgba(252, 252, 252, 0.6)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  }
}))

export const FiatCurrencyValue = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}))

export const FiatBlockchainValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.palette.fontWeight['700'],
  fontFamily: `'Ubuntu'`,
  fontSize: '16px',
  color: '#FFF',
  '& > img': {
    marginRight: '8px'
  }
}))
export const FiatCurrencyPriceValue = styled(TableCell)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  textTransform: 'none',
  lineHeight: '143%',
  minWidth: '90px',
  color: '#FFF'
}))

export const FiatBlockchainIso = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu'`,
  width: '100%',
  fontSize: '16px',
  marginLeft: '6px',
  lineHeight: '150%',
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const FiatBlockchainSum = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu'`,
  fontSize: '14px',
  lineHeight: '143%',
  color: '#FFF'
}))

export const CardContentAnalytics = styled(CardContent)(({ theme }) => ({
  padding: '4px !important',
  '& .apexcharts-canvas .apexcharts-yaxis-label': { fontSize: '0.875rem', fontWeight: 600 },
  '& .apexcharts-canvas .apexcharts-xaxis-label': {
    fontFamily: '`Ubuntu`',
    fontWeight: theme.palette.fontWeight['400'],
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    fill: 'rgba(255, 255, 255, 0.4)',
    textTransform: 'none'
  },
  '& .apexcharts-data-labels .apexcharts-datalabel': {
    fontFamily: '`Ubuntu`',
    fontWeight: theme.palette.fontWeight['700'],
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    fill: theme.palette.common.white
  }
}))

export const CardContentTitle = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter', sans-serif`,
  maxWidth: 338,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '20px',
  textTransform: 'none',
  lineHeight: '160%',
  color: '#FFF'
}))

export const CrmTransactionsTableWrapper = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
  gap: '12px',
  background: '#292929',
  borderRadius: '12px',
  '&:nth-type-of(odd)': {
    paddingLeft: '1.5rem'
  }
}))

export const CryptocurrenciesTableNavigation = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '12px 0'
}))
export const NavigationButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '4px 13px',
  boxShadow: '0px 4px 8px -4px rgba(76, 78, 100, 0.42)',
  borderRadius: '8px',
  width: 'fit-content',
  marginRight: '12px',
  fontFamily: '`Iter`, sans-serif',
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '13px',
  lineHeight: '22px',
  letterSpacing: '0.15px',
  textTransform: 'uppercase'
}))
