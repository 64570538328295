import { Menu } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { MouseEvent, useState } from 'react'
import { paperProps } from '@/shared/ui/one-ticket/ui/utils'
import { TicketOptionsIconButton, TicketOptionsNavigationItem } from '@/shared/ui/one-ticket'
import { IClosedTicket, useAppSelector } from '@/shared'
import { getAllClosedTickets, reopenTicket } from '@/app/store/thunks/tickets'
import { useAppDispatch } from '@/shared/hooks/types'
import { useRouter } from 'next/router'
import { closedTicketsSelector } from '@/app/store/selectors'

type Props = {
  options: string[]
  closedTicket: IClosedTicket
}

export const HistoryTicketOptionsMenu = ({ options, closedTicket }: Props) => {
  /**
   * States
   */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  /**
   * hooks
   */
  const dispatch = useAppDispatch()
  const { params } = useAppSelector(closedTicketsSelector)

  const router = useRouter()

  /**
   * Variables
   */
  const open = Boolean(anchorEl)

  /**
   * Click handlers
   *
   */
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (event.target === event.currentTarget) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onTicketClose = async () => {
    await dispatch(reopenTicket({ ticketId: String(!closedTicket ? router.query.id : closedTicket?.id) })).then(
      async () => {
        closedTicket && (await router.push('/history-tickets'))
        await dispatch(getAllClosedTickets({ page: Number(params?.page) }))
      }
    )
    setAnchorEl(null)
  }

  return (
    <div>
      <TicketOptionsIconButton
        open={open}
        aria-controls={open ? 'long-menu' : undefined}
        disableRipple
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </TicketOptionsIconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
      >
        {options.map(option => (
          <TicketOptionsNavigationItem key={option} onClick={onTicketClose}>
            {option}
          </TicketOptionsNavigationItem>
        ))}
      </Menu>
    </div>
  )
}
