import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const SingleTicketContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '24px 36px',
  gap: '10px',
  width: '100%',
  background: '#292929',
  cursor: 'pointer',
  borderRadius: '12px',
  marginBottom: '24px',
  transition: '.2s ease-in-out',
  '&:hover': {
    background: 'rgba(24, 106, 223, 0.1)'
  },
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px 14px'
  }
}))

export const SingleTicketContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse'
  }
}))

export const SingleTicketInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '100%',
  width: '100%',
  wordBreak: 'break-all'
}))

export const SingleTicketInfoBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start'
  }
}))
