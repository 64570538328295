import { AppBar, Drawer, List, ListItem } from '@mui/material'
import { Box, styled } from '@mui/system'

export const LayoutIconButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',
  height: '36px',
  background: '#fff',
  borderRadius: '12px'
})

export const LayoutCloseButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',
  height: '36px',
  background: '#101010',
  borderRadius: '12px'
})

export const LayoutDrawer = styled(Drawer)({
  height: '100%',
  width: '100%',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '100%',
    height: '100%'
  }
})

export const MenuList = styled(List)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  overflow: 'auto',
  padding: '16px',
  paddingBottom: '100px',
  background: '#F7F7F7'
}))

export const MenuListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 0',
  background: '#F7F7F7',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '16px',
  lineHeight: '24px',
  color: '#101010',
  cursor: 'pointer',
  borderBottom: '1px solid #DEDEDE'
}))

export const LayoutAppBar = styled(AppBar)(() => ({
  background: '#F7F7F7',
  height: '100vh'
}))

export const MenuListItemText = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '16px',
  lineHeight: '24px',
  color: '#101010',
  cursor: 'pointer'
}))
