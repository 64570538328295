import { IconedMenuItemContent, IconedMenuText, MobileMenuItem } from '@/shared/layouts'

type Props = {
  text: string
  icon: any
  onClick?: () => void
}

export const IconedMenuItem = ({ text, icon, onClick }: Props) => {
  return (
    <MobileMenuItem onClick={onClick}>
      <IconedMenuItemContent>
        {icon}
        <IconedMenuText>{text}</IconedMenuText>
      </IconedMenuItemContent>
    </MobileMenuItem>
  )
}
