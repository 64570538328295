import {
  AccessTime,
  addServerErrors,
  AvailableBalanceInWallet,
  FundsWithdrawalAmount,
  FundsWithdrawalButton,
  FundsWithdrawalCalculations,
  FundsWithdrawalCurrency,
  FundsWithdrawalCurrencyIcon,
  FundsWithdrawalCurrencyIconText,
  FundsWithdrawalElement,
  FundsWithdrawalLock,
  FundsWithdrawalNetwork,
  FundsWithdrawalNetworkName,
  FundsWithdrawalTo,
  FundsWithdrawalTotalAmountText,
  FundsWithdrawalTotalAmountValue,
  FundsWithdrawalTwoFactorKey,
  FundsWithdrawalTwoFactorStatus,
  InputAdornmentCurrency,
  InputEndAdornment,
  useAppDispatch,
  useAppSelector
} from '@/shared'
import { FundsWithdrawalSelectCoin } from './funds-withdrawal-select-coin'
import { fundsWithdrawalFormSchema } from './funds-withdrawal-form.schema'
import { FundsWithdrawalAmountInput } from './funds-withdrawal-amount'
import Divider from '@mui/material/Divider'
import {
  storesCurrenciesSelector,
  walletLoadingSelector,
  walletStateSelector,
  withdrawalTicketsLoadingSelector
} from '@/app/store/selectors'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { getStoreCurrencies } from '@/app/store/thunks'
import { memo, useEffect, useMemo, useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import { FundsWithdrawalAdminMessage } from '@/shared/ui/funds-withdrawal/ui/funds-withdrawal-admin-message'
import { AuthenticationLabel } from '@/widgets/pages/settings'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { FundsWithdrawalAddressInput } from '@/shared/ui/funds-withdrawal/ui/funds-withdrawal-address'
import { getWalletAndAllowedCurrencies } from '@/app/store/thunks/wallet'
import { CircularProgress } from '@mui/material'
import { withdrawalsToAddress } from '@/app/store/thunks/withdrawal-tickets'
import { PayloadAction } from '@reduxjs/toolkit'
import { ImageWithState } from '@/shared/ui/image-with-state'

export type FundsWithdrawalValues = {
  currency_id: number
  address: string
  sum: string
  message: string
  two_factor_code: string
}

type Props = {
  handleRequestModal: () => void
}

export const FundsWithdrawalForm = memo(({ handleRequestModal }: Props) => {
  const dispatch = useAppDispatch()
  const isWithdrawalLoading = useAppSelector(withdrawalTicketsLoadingSelector)
  const { t, i18n } = useTranslation()
  const schema = useMemo(() => fundsWithdrawalFormSchema(), [i18n.language])

  const defaultValues = {
    currency_id: 1,
    address: '',
    sum: '',
    message: '',
    two_factor_code: ''
  }

  const {
    control,
    watch,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<FundsWithdrawalValues>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const currencies = useAppSelector(storesCurrenciesSelector)
  const currenciesIcons: Record<string, string> = {
    BTC: '/images/icons/bitcoin.svg',
    BNB: '/images/icons/bnb.svg'
  }

  const hasErrors = Object.keys(errors || {}).length > 0

  useEffect(() => {
    dispatch(getStoreCurrencies())
  }, [dispatch])

  const [showCode, setShowCode] = useState(false)
  const currency_id = watch('currency_id')
  const amountValue = watch('sum')
  const twoFactorCode = watch('two_factor_code')
  const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 6 })
  const formValues = watch()
  const currency = currencies.find(curr => curr.id === currency_id)
  const twoFactorEntered = twoFactorCode.toString().trim().length === 6
  const wallet = useAppSelector(walletStateSelector)
  const loading = useAppSelector(walletLoadingSelector)

  useEffect(() => {
    dispatch(getWalletAndAllowedCurrencies(String(currency_id)))
  }, [dispatch, currency_id])

  useEffect(() => {
    if (formValues.sum) {
      clearErrors('sum')
    }
  }, [clearErrors, formValues.sum])

  const amount = loading ? (
    <Skeleton width='100%' />
  ) : (
    <AvailableBalanceInWallet>
      {t('Available in wallet:')} {wallet?.balance} {wallet?.currency?.name}
    </AvailableBalanceInWallet>
  )

  const fillAmountWithMaxBalanceFx = () => {
    setValue('sum', String(wallet?.balance))
  }

  const handleShowCode = () => {
    setShowCode(prev => !prev)
  }
  const onSubmit = async () => {
    const response = (await dispatch(withdrawalsToAddress({ data: formValues }))) as PayloadAction<any>
    const errors = 'error' in response?.payload ? response?.payload?.error : response?.payload?.errors
    const hasErrors = Object.keys(errors ?? {})?.length > 0

    if (hasErrors) {
      addServerErrors<FundsWithdrawalValues>(errors ?? {}, setError)

      return
    }

    if (!hasErrors) {
      handleRequestModal()
      reset()
    }
  }

  const bitcoinSelected = currency_id === 1

  return (
    <form>
      <FundsWithdrawalElement>
        <FundsWithdrawalSelectCoin
          name='currency_id'
          fullWidth
          control={control}
          label={t('Select coin*')}
          defaultValue={currencies[0]?.id}
        >
          {currencies?.map(currency => (
            <FundsWithdrawalCurrency key={currency.id} value={currency.id}>
              <FundsWithdrawalCurrencyIcon>
                <ImageWithState width={24} height={24} alt={currency.name} src={currenciesIcons[currency.name]} />
              </FundsWithdrawalCurrencyIcon>
              <FundsWithdrawalCurrencyIconText>{currency.name}</FundsWithdrawalCurrencyIconText>
              {currency.name}
            </FundsWithdrawalCurrency>
          ))}
        </FundsWithdrawalSelectCoin>
      </FundsWithdrawalElement>
      <FundsWithdrawalTo>{t('Withdrawal to')}</FundsWithdrawalTo>
      <FundsWithdrawalElement sx={{ mb: '36px' }}>
        <FundsWithdrawalAddressInput
          name='address'
          fullWidth
          control={control}
          label={`${t('Address')}*`}
          inputProps={{ minLength: 1, maxLength: 100 }}
          placeholder={t('Enter address here')}
        />
      </FundsWithdrawalElement>
      {!bitcoinSelected ? (
        <FundsWithdrawalElement>
          <FundsWithdrawalNetwork>{t('Network')}</FundsWithdrawalNetwork>
          <FundsWithdrawalNetworkName>BEP-20</FundsWithdrawalNetworkName>
        </FundsWithdrawalElement>
      ) : null}
      <FundsWithdrawalAmount>{t('Withdrawal amount')}</FundsWithdrawalAmount>
      <FundsWithdrawalElement sx={{ mb: '36px' }}>
        <FundsWithdrawalAmountInput
          name='sum'
          fullWidth
          control={control}
          label={t('Amount*')}
          inputProps={{ minLength: 1, maxLength: 100 }}
          placeholder={t('Enter amount here')}
          startAdornment={
            <InputAdornment position='start'>
              <InputAdornmentCurrency>{currency?.name || 'BNB'}</InputAdornmentCurrency>
              <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end' onClick={fillAmountWithMaxBalanceFx} sx={{ cursor: 'pointer' }}>
              <InputEndAdornment>{t('MAX')}</InputEndAdornment>
            </InputAdornment>
          }
          amount={amount}
        />
      </FundsWithdrawalElement>
      <FundsWithdrawalElement sx={{ mb: '36px' }}>
        <FundsWithdrawalAdminMessage
          name='message'
          fullWidth
          control={control}
          label={t('Message')}
          placeholder={t('Enter message here')}
        />
      </FundsWithdrawalElement>
      <FundsWithdrawalTwoFactorStatus>
        <FundsWithdrawalTwoFactorKey />
        <AuthenticationLabel>{t('Two - factor authentication')}</AuthenticationLabel>
      </FundsWithdrawalTwoFactorStatus>
      {!showCode ? (
        <FundsWithdrawalLock>
          <FundsWithdrawalButton variant='contained' onClick={handleShowCode}>
            {t('Enter the code')}
          </FundsWithdrawalButton>
        </FundsWithdrawalLock>
      ) : (
        <FundsWithdrawalElement>
          <FundsWithdrawalAmountInput
            name='two_factor_code'
            fullWidth
            control={control}
            inputProps={{ maxLength: 6 }}
            label={t('Two factor code')}
            placeholder={t('Enter code here')}
          />
        </FundsWithdrawalElement>
      )}
      <FundsWithdrawalElement>
        <FundsWithdrawalAmount sx={{ mb: '12px' }}>{t('Total amount')}</FundsWithdrawalAmount>
        <FundsWithdrawalCalculations>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FundsWithdrawalTotalAmountValue>
              {twoFactorEntered ? formatter.format(Number(amountValue)) : <AccessTime />} {wallet?.currency?.name}
            </FundsWithdrawalTotalAmountValue>
            <FundsWithdrawalTotalAmountText>{t('fee does not included')}</FundsWithdrawalTotalAmountText>
          </Box>
          <FundsWithdrawalButton
            variant='contained'
            type='button'
            onClick={onSubmit}
            disabled={!twoFactorEntered || hasErrors}
          >
            {isWithdrawalLoading ? <CircularProgress color='inherit' size={20} /> : t('Withdraw')}
          </FundsWithdrawalButton>
        </FundsWithdrawalCalculations>
      </FundsWithdrawalElement>
    </form>
  )
})
