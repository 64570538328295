import { ConfirmationArrowBack, ConfirmationInner, ConfirmationText, ConfirmationWrapper, EmailBox } from './styles'
import { Box } from '@mui/material'
import { CheckCircled, EmailIcon, Heading } from '@/shared/ui'
import { SubmitButton as LoginLinkButton } from '../login/styles'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useCallbackOne } from '@/shared/hooks/useMemoOne'
import { ArrowBack } from '@/shared/ui/arrow-back'

type Props = {
  title: string
  text: string
  confirmationIcon?: boolean
}

export const ConfirmationBody = ({ title, text = '', confirmationIcon = false }: Props) => {
  /**
   * hooks
   */
  const router = useRouter()
  const { t } = useTranslation()

  /**
   * redirect login func
   */
  const redirectToLogin = useCallbackOne(async () => {
    await router.push('/login')
  }, [])

  return (
    <Box className='content-center'>
      <ConfirmationWrapper>
        {!confirmationIcon && (
          <ConfirmationArrowBack onClick={redirectToLogin}>
            <ArrowBack />
          </ConfirmationArrowBack>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ConfirmationInner>
            <Heading text={title} />
            <EmailBox>
              {confirmationIcon ? <CheckCircled /> : <EmailIcon style={{ fontSize: '128px' }} />}
              <ConfirmationText>{text}</ConfirmationText>
            </EmailBox>
          </ConfirmationInner>
        </Box>
        <LoginLinkButton fullWidth size='large' type='button' variant='contained' onClick={redirectToLogin}>
          {t('Login')}
        </LoginLinkButton>
      </ConfirmationWrapper>
    </Box>
  )
}
