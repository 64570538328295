import {
  CurrencyCell,
  CurrencyTableDate,
  CurrencyTableName,
  CurrencyTableTitle
} from '@/shared/ui/commissions-and-deposits'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ICurrencyTableRow } from './types'
import { useAppSelector } from '@/shared'
import { metaStateSelector, walletLoadingSelector } from '@/app/store/selectors'
import { ChangeEvent, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { EmptyTableTitle } from '@/shared/ui/history/styles'

type Props = Readonly<{ title: string; rows: ICurrencyTableRow[]; currency?: string }>

export const CurrencyTable = ({ title, rows, currency }: Props) => {
  const { t } = useTranslation()
  const walletMeta = useAppSelector(metaStateSelector)
  const loading = useAppSelector(walletLoadingSelector)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(walletMeta?.perPage || 5)
  const count = Number(walletMeta?.total)
  const historyAvailable = (rows ?? []).length > 0

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box>
      {loading ? (
        <Skeleton variant='rectangular' width={'100%'} height={120} />
      ) : (
        <TableContainer component={Paper}>
          <Box sx={{ padding: '24px' }}>
            <CurrencyTableTitle>{title}</CurrencyTableTitle>
            {historyAvailable ? (
              <Table title={title}>
                <TableHead>
                  <TableRow>
                    <CurrencyCell>{t('Date')}</CurrencyCell>
                    <CurrencyCell>{t('Tx Detail')}</CurrencyCell>
                    <CurrencyCell>{t(`Amount`) + ` (${currency})`}</CurrencyCell>
                    <CurrencyCell align='right'>{t(`Balance`) + ` (${currency})`}</CurrencyCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component='th' scope='row'>
                        <CurrencyTableDate>{t(row.date)}</CurrencyTableDate>
                      </TableCell>
                      <TableCell>
                        <CurrencyTableName>{row.txDetail}</CurrencyTableName>
                      </TableCell>
                      <TableCell>
                        <CurrencyTableName>{row.amount}</CurrencyTableName>
                      </TableCell>
                      <TableCell align='right'>
                        <CurrencyTableName>{row.balance}</CurrencyTableName>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyTableTitle sx={{ mt: 2 }}>{t('There is not payments yet...')}</EmptyTableTitle>
            )}
          </Box>
        </TableContainer>
      )}
      {loading ? (
        <Skeleton variant='rectangular' width={'100%'} height={50} />
      ) : (
        walletMeta?.total !== 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )
      )}
    </Box>
  )
}
