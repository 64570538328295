import { styled } from '@mui/material'
import { FcGoogle } from 'react-icons/fc'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useCallback } from '@/shared/hooks/useMemoOne'

export const GoogleButton = styled('button')(({ theme }) => ({
  background: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  color: theme.palette.colors.button,
  minWidth: 276,
  display: 'flex',
  width: '100%',
  border: 0,
  outline: 0,
  boxShadow: theme.palette.textShadow.button,
  borderRadius: '2px',
  marginTop: 8,
  paddingTop: '14px',
  paddingBottom: '14px',
  paddingLeft: '8px',
  '&:hover': {
    cursor: 'pointer'
  }
}))

const GoogleIcon = styled(FcGoogle)(() => ({
  marginLeft: 8,
  marginRight: 24
}))

export const GoogleSignInButton = () => {
  /**
   * hooks
   */
  const { t } = useTranslation()

  /**
   * routing
   */
  const router = useRouter()

  /**
   * handlers
   */
  const onGoogleHandler = useCallback(async () => {
    const route = '/watcher-dashboard'
    await router.push(`${process.env.API_URL}auth/social/google?state=${route}`)
  }, [router])

  return (
    <GoogleButton onClick={onGoogleHandler}>
      <GoogleIcon />
      {t('Sign in with Google')}
    </GoogleButton>
  )
}
