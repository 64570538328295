import { useAppDispatch } from '@/shared'
import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getTicketsNotifyData } from '@/app/store/thunks/tickets'
import { dashboardPages } from '@/shared/configs/pages'

export const useTicketsWatchCount = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const route = router.asPath
  const onTicketsPageReload = useCallback(() => {
    dashboardPages.includes(route) && dispatch(getTicketsNotifyData())
  }, [dispatch, route])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isPageReload = window.sessionStorage.getItem('isReloaded')

      if (isPageReload) {
        onTicketsPageReload()
      } else {
        window.sessionStorage.setItem('isReloaded', 'true')
      }

      router.events.on('routeChangeComplete', onTicketsPageReload)

      return () => {
        router.events.off('routeChangeComplete', onTicketsPageReload)
        window.sessionStorage.removeItem('isReloaded')
      }
    }
  }, [onTicketsPageReload, router.events])
}
