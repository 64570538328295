import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const AppBarContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  padding: '0 20px',
  minHeight: 80,
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px'
  }
}))
