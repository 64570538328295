import { TestPaymentTraction, TestPaymentTransactionLogs } from '@/shared/types/payment'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { platformService } from '@/shared'

export const testPaymentTransactionFx = createAsyncThunk(
  'user/testPaymentTransactionFx',
  async (data: TestPaymentTraction, { rejectWithValue }) => {
    try {
      const response = await platformService.testPaymentTransaction(data)

      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  }
)
export const getTestPaymentTransactionLogs = createAsyncThunk<
  TestPaymentTransactionLogs,
  Partial<{ signal: AbortSignal; cancelPreviousRequest: () => void }>
>('user/getTestPaymentTransactionLogs', async ({ signal, cancelPreviousRequest }, { rejectWithValue }) => {
  try {
    const response = await platformService.getTestPaymentTransactionLogs(signal)

    return response.data
  } catch (err: any) {
    if (!err.response) {
      cancelPreviousRequest?.()
      throw err
    }

    return rejectWithValue(err.response.data)
  }
})
