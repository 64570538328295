import { Box, CircularProgress } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { AddStoreForm, FormHeading, PlatformAccordionLine, SaveChangesButton } from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { PlatformSelect } from '@/shared/ui/platforms/ui/platform-select'
import {
  CreateStoreData,
  PlatformCallbackField,
  PlatformCommonFields,
  PlatformDynamicFields,
  TDynamicFormField,
  useAppSelector
} from '@/shared'
import { useCallback, useMemo, useState } from 'react'
import { createStore, getStores } from '@/app/store/thunks'
import { useAppDispatch } from '@/shared/hooks/types'
import { toast } from 'react-hot-toast'
import { storesCurrenciesSelector } from '@/app/store/selectors'
import { ClearButton } from '@/shared/ui/history/styles'
import { formSchema } from '@/shared/ui/platforms/ui/update-store.schema'

type Props = {
  hideCreateAccordion: () => void
}

export const PlatformsAddForm = ({ hideCreateAccordion }: Props) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const currencies = useAppSelector(storesCurrenciesSelector)
  const [currencyName, setCurrencyName] = useState<string>(currencies[0]?.iso_name ?? 'btc')
  const schema = useMemo(() => formSchema(), [i18n.language])
  const names = currencies.map(currency => currency.name?.toLowerCase())
  const defaultCallback = names.reduce((acc, name) => ({ ...acc, [name]: '' }), {})

  const methods = useForm<CreateStoreData>({
    defaultValues: {
      name: '',
      xpub: '',
      binance_address: '',
      sample_receiving_address: '',
      tag: '',
      callback_url: defaultCallback
    },
    mode: 'onChange',
    resolver: yupResolver(schema)
  })
  const {
    handleSubmit,
    watch,
    setError,
    resetField,
    formState: { isSubmitting, isDirty, isValid }
  } = methods

  const formFields: TDynamicFormField[] = [
    {
      id: 'btc',
      label: 'Bitcoin',
      fields: [
        {
          id: 'xpub',
          label: 'Xpub',
          name: 'xpub',
          placeholder: 'Enter your bitcoin wallet xpub',
          additionalField: {
            name: 'sample_receiving_address',
            label: 'Sample Receiving Address',
            placeholder: 'bc1qqztaf8qddw8v5t9aulsezlqksrhnaad47v5nqg',
            linkLabel: 'Click "receive" in your wallet, copy the address and paste it here.'
          }
        }
      ]
    },
    {
      id: 'bnb',
      label: 'Binance',
      fields: [
        {
          id: 'binance_address',
          label: 'Binance Address',
          name: 'binance_address',
          placeholder: 'Enter your binance address'
        }
      ]
    }
  ]
  const formElements = formFields.find(field => field.id === currencyName)?.fields
  const values = watch()
  const isAnyFieldFilled: boolean = Object.values(values).some((value: string | Record<string, string>) => {
    if (typeof value === 'string') {
      return value.trim() !== ''
    }
    if (typeof value === 'object') {
      return Object.values(value).some(nestedValue => nestedValue.trim() !== '')
    }

    return false
  })
  const handleClearButtonClick = () => {
    resetField('name')
    resetField('tag')
    resetField(`callback_url.${currencyName}`)
    formFields.forEach(field => {
      if (field.id === currencyName) {
        const fieldNames = field.fields.map(f => f.name) as (keyof CreateStoreData)[]
        const additionalFieldNames = field.fields.map(f => f.additionalField?.name) as (keyof CreateStoreData)[]
        const names = additionalFieldNames.length ? fieldNames.concat(additionalFieldNames) : fieldNames
        names.forEach(name => resetField(name))
      }
    })
  }

  const onSubmit: SubmitHandler<CreateStoreData> = async fields => {
    for (const key in fields) {
      if (!Boolean(fields[key as keyof CreateStoreData])) {
        delete fields[key as keyof CreateStoreData]
      }
    }

    const response = await dispatch(createStore({ setError, ...fields }))

    if (response?.payload !== undefined) {
      hideCreateAccordion()
      toast.success(t('Store added!'), { position: 'bottom-right' })
      dispatch(getStores({}))
    }
  }
  const grabSelectOption = useCallback((name: string) => setCurrencyName(name), [])

  return (
    <Box>
      <PlatformAccordionLine />
      <FormProvider {...methods}>
        <AddStoreForm onSubmit={handleSubmit(onSubmit)}>
          <PlatformCommonFields />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormHeading>{t('Destination wallet')}</FormHeading>
            <PlatformSelect grabSelectOption={grabSelectOption} />
          </Box>
          <PlatformDynamicFields formElements={formElements} />
          <PlatformCallbackField currencyName={currencyName} />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <SaveChangesButton
              variant='contained'
              disableTouchRipple
              type='submit'
              disabled={!isDirty || !isAnyFieldFilled || !isValid || isSubmitting}
            >
              {isSubmitting ? <CircularProgress color='inherit' size={20} /> : t('Save changes')}
            </SaveChangesButton>
            {isAnyFieldFilled ? (
              <Box sx={{ textAlign: 'end' }}>
                <ClearButton variant='text' sx={{ m: 0 }} onClick={handleClearButtonClick}>
                  {t('Clear')}
                </ClearButton>
              </Box>
            ) : null}
          </Box>
        </AddStoreForm>
      </FormProvider>
    </Box>
  )
}
