import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { HeadingText } from './styles'

type HeadingProps = {
  text: string
  className?: string
  sx?: SxProps<Theme> | undefined
}

export const Heading = ({ text, className = '', sx, ...rest }: HeadingProps) => {
  const { t } = useTranslation()

  return (
    <HeadingText className={className} {...rest} sx={sx}>
      {t(text)}
    </HeadingText>
  )
}
