/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { FormControl, FormHelperText, Select, SelectProps, SxProps } from '@mui/material'

/**
 * styled items from styling
 */
import { PropsWithChildren } from 'react'
import { FieldLabel, FieldPlaceholder, FormWrapper } from './styles'
import { Theme } from '@mui/material/styles'

/**
 * DashboardFormInput type for props
 */
type FormSelectProps = {
  control?: Control<any> | undefined
  fullWidth?: boolean
  name: string
  className?: string
  label: string
  sx?: SxProps<Theme> | undefined
} & SelectProps

/**
 * Custom form select component
 * @params {FormSelectProps} - props for select component
 */
export const FormTicketsSelect = ({
  control,
  children,
  placeholder,
  sx = {},
  label = '',
  name,
  ...otherProps
}: PropsWithChildren<FormSelectProps>) => {
  const labelId = `${name}-label`

  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => (
          <FormWrapper>
            <FieldLabel sx={{ color: errors && errors[name] && 'error.main' }}>{label}</FieldLabel>
            <Select
              {...field}
              {...otherProps}
              displayEmpty
              error={Boolean(errors[name])}
              autoComplete='off'
              defaultValue={''}
              sx={sx}
              renderValue={field.value !== '' ? undefined : () => <FieldPlaceholder>{placeholder}</FieldPlaceholder>}
              labelId={labelId}
            >
              {children}
            </Select>
            {errors.hasOwnProperty(name) && errors[name] && (
              <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                {errors[name]?.message as string}
              </FormHelperText>
            )}
          </FormWrapper>
        )}
      />
    </FormControl>
  )
}
