/**
 * Style imports
 */
import { UserFooter, UserFooterContent, UserFooterContentInner } from './styles'

/**
 * React hooks & additional
 */
import { UserFooterLinksBlock } from './user-footer-links-block'
import { UserFooterSocials } from './user-footer-socials'
import { layoutFooterSelector } from '@/app/store/selectors/layout.selectors'
import { useAppSelector } from '@/shared/hooks/types'

export const UserLayoutFooter = () => {
  const footer = useAppSelector(layoutFooterSelector)

  const footerLinks = footer?.columns?.map(({ title, url, children }) => (
    <UserFooterLinksBlock key={title} heading={title} url={url} links={children} />
  ))

  return (
    <UserFooter>
      <UserFooterContent>
        <UserFooterContentInner>{footerLinks}</UserFooterContentInner>
        <UserFooterSocials />
      </UserFooterContent>
    </UserFooter>
  )
}
