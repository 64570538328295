/**
 * styled imports
 */
import { AccountCurrentEmail, AccountEmailTitle, AuthenticationLabel } from '@/widgets/pages/settings/ui/styles'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

/**
 * mui imports
 */
import { Box } from '@mui/material'
import { useAppSelector } from '@/shared/hooks/types'
import { userStateSelector } from '@/app/store/selectors'
import { useTranslation } from 'react-i18next'

/**
 * auth & theme hooks
 */

export const AccountInfo = () => {
  /**
   * Hooks
   */
  const currentUser = useAppSelector(userStateSelector)
  const { t } = useTranslation()

  return (
    <Box>
      <Box sx={{ mt: 6, mb: 4.5, display: 'flex', alignItems: 'center' }}>
        <ManageAccountsIcon sx={{ fontSize: '1.75rem', mr: 2, color: '#FFFFFF' }} />
        <AuthenticationLabel>{t('Account')}</AuthenticationLabel>
      </Box>
      <AccountEmailTitle>
        {t('Current Email')}
        <AccountCurrentEmail>{currentUser?.email || 'email@test.com'}</AccountCurrentEmail>
      </AccountEmailTitle>
    </Box>
  )
}
