import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { Button } from '@mui/material'

export const NewEmailWrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}))

export const EmailFormButtons = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center'
}))

export const FormEmailWrapper = styled(Box)<BoxProps>(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-start',
  flexWrap: 'wrap'
}))

export const FormCancelButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '104px',
  height: '38px',
  border: '1px solid #186ADF',
  borderRadius: '8px',
  fontFamily: `'Inter', sans-serif`,
  fontWeight: theme.palette.fontWeight[500],
  fontSize: '14px',
  marginRight: '24px',
  lineHeight: '24px',
  color: '#186ADF'
}))

export const SetNewEmailSubmitButton = styled(Button)(({ theme }) => ({
  background: '#186ADF',
  maxWidth: '185px',
  width: '100%',
  height: 38,
  borderRadius: '12px',
  '&&&': {
    '&.Mui-disabled': {
      '&:disabled': {
        background: '#186ADF',
        opacity: 0.5,
        color: theme.palette.common.white,
        cursor: 'not-allowed',
        pointerEvents: 'none !important'
      }
    }
  },
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  margin: 0
}))

export const SetNewEmailInner = styled('form')(() => ({
  width: '100%'
}))

export const SetNewEmailBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))
