import { CurrencyTableTitle, MobileTableWrapper } from './styles'
import { useTranslation } from 'react-i18next'
import { ICurrencyTableRow } from './types'
import { HistoryRow } from './history-row'
import { Box } from '@mui/material'

type Props = Readonly<{ rows: ICurrencyTableRow[]; currency?: string }>

export const MobileCurrencyTable = ({ rows, currency }: Props) => {
  const { t } = useTranslation()

  const history = (rows ?? [])?.map(row => <HistoryRow currency={currency} key={row?.date} row={row} />)

  return (
    <MobileTableWrapper>
      <CurrencyTableTitle>{t('Payment history')}</CurrencyTableTitle>
      <Box>{history}</Box>
    </MobileTableWrapper>
  )
}
