import * as yup from 'yup'
import i18n from 'i18next'

export const sentSupportMessageFormSchema = () =>
  yup.object().shape({
    message: yup
      .string()
      .optional()
      .min(1, i18n.t('Message should have minimum 1 char.'))
      .max(1000, i18n.t('Message should be up to 1000 characters.'))
  })
