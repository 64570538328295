import { HistoryTicketYourMessage } from './history-ticket-your-message'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { HistoryTicketMessagesLoader, HistoryTicketSupportChatWrapper } from './styles'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/types'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { getAllTicketsMessages } from '@/app/store/thunks/tickets'
import { ticketMessagesSelector, ticketsLoadingStateSelector } from '@/app/store/selectors/tickets.selectors'
import CircularProgress from '@mui/material/CircularProgress'
import ForumIcon from '@mui/icons-material/Forum'
import { Box } from '@mui/material'
import { NoTicketFound, NoTicketFoundText } from '@/shared/ui/one-ticket'
import { useTranslation } from 'react-i18next'

export const HistoryTicketSupportChat = () => {
  const dispatch = useAppDispatch()
  const {
    query: { id }
  } = useRouter()
  const loading = useAppSelector(ticketsLoadingStateSelector)

  const messages = useAppSelector(ticketMessagesSelector)
  const isMessagesAvailable = (messages ?? []).length > 0
  const scrollRef = useRef<HTMLElement | null>(null)
  const { t } = useTranslation()

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }
  }

  useLayoutEffect(scrollToBottom, [messages, scrollToBottom])

  useEffect(() => {
    dispatch(getAllTicketsMessages({ ticketId: String(id) }))
  }, [dispatch, id])

  const supportMessages = isMessagesAvailable ? (
    messages.map(supportMessage => (
      <Box key={supportMessage.id}>
        <HistoryTicketYourMessage supportMessage={supportMessage} />
      </Box>
    ))
  ) : (
    <NoTicketFound>
      <ForumIcon sx={{ fontSize: '70px' }} />
      <NoTicketFoundText>{t('No messages found...')}</NoTicketFoundText>
    </NoTicketFound>
  )

  return (
    <HistoryTicketSupportChatWrapper>
      <PerfectScrollbar
        containerRef={el => {
          scrollRef.current = el
        }}
      >
        {loading ? (
          <HistoryTicketMessagesLoader>
            <CircularProgress color='inherit' size={40} />
          </HistoryTicketMessagesLoader>
        ) : (
          <Box>{supportMessages}</Box>
        )}
      </PerfectScrollbar>
    </HistoryTicketSupportChatWrapper>
  )
}
