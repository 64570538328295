import Image from 'next/image'

type Props = {
  height?: number
  width?: number
  color?: string
}

export const LayoutBurgerIcon = ({ height = 24, width = 24, color }: Props) => {
  return (
    <Image
      src='/images/icons/burger-right.svg'
      height={height}
      width={width}
      loading='lazy'
      alt='burger'
      color={color}
    />
  )
}
