import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as _ from 'lodash'
import { BaseState } from '@/app/store/types'
import {
  IStore,
  SliceStoreData,
  StoreCurrencyType,
  SuccessfulStoreCreation,
  UpdateStoreArgs,
  UpdateStoreTagArgs
} from '@/shared'
import {
  createStore,
  deleteStore,
  getStoreCurrencies,
  getStores,
  refreshApiKey,
  updateStore,
  updateStoreName
} from '@/app/store/thunks'

type StoresState = BaseState<SliceStoreData>

const initialState: StoresState = {
  data: {
    stores: [],
    currencies: [],
    meta: null
  },
  error: '',
  status: 'idle'
}

export const storesSlice = createSlice({
  name: 'storesData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStores.pending, (state: StoresState) => {
      state.status = 'pending'
    })
    builder.addCase(getStores.rejected, (state: StoresState) => {
      state.status = 'rejected'
    })
    builder.addCase(refreshApiKey.rejected, (state: StoresState) => {
      state.status = 'rejected'
    })
    builder.addCase(updateStoreName.rejected, (state: StoresState) => {
      state.status = 'rejected'
    })
    builder.addCase(createStore.rejected, (state: StoresState) => {
      state.status = 'rejected'
    })
    builder.addCase(deleteStore.rejected, (state: StoresState) => {
      state.status = 'rejected'
    })
    builder.addCase(updateStore.rejected, (state: StoresState) => {
      state.status = 'rejected'
    })
    builder.addCase(getStores.fulfilled, (state: StoresState, action: PayloadAction<SliceStoreData>) => {
      const { stores, meta } = action.payload
      state.data.stores = stores
      state.data.meta = meta
      state.status = 'fulfilled'
    })
    builder.addCase(createStore.fulfilled, (state: StoresState, action: PayloadAction<SuccessfulStoreCreation>) => {
      const { data } = action.payload ?? {}
      if (data !== undefined) {
        state.data.stores = state.data.stores.concat(data)
      }
      state.status = 'fulfilled'
    })
    builder.addCase(refreshApiKey.fulfilled, (state: StoresState, action: PayloadAction<IStore>) => {
      const { id, api_key } = action.payload
      const currencyStoreToUpdate = state.data.stores.find(store => store.id === id)
      _.update(currencyStoreToUpdate as IStore, 'api_key', () => api_key)
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(updateStoreName.fulfilled, (state: StoresState, action: PayloadAction<UpdateStoreTagArgs>) => {
      const { id, name } = action.payload
      const currencyStoreToUpdate = state.data.stores.find(store => String(store.id) === id)
      _.update(currencyStoreToUpdate as IStore, 'tag', () => name)
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(updateStore.fulfilled, (state: StoresState, action: PayloadAction<UpdateStoreArgs>) => {
      const { id, data } = action.payload
      state.data.stores = state.data.stores.map(store => (String(store.id) === id ? _.merge(store, data) : store))
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(deleteStore.fulfilled, (state: StoresState, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      state.data.stores = _.remove(state.data.stores, store => String(store.id) === id)
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(getStoreCurrencies.fulfilled, (state: StoresState, action: PayloadAction<StoreCurrencyType[]>) => {
      state.data.currencies = action.payload
      state.status = 'fulfilled'
      state.error = ''
    })
  }
})
const { reducer } = storesSlice
export default reducer
