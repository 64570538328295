import { styled } from '@mui/system'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'

export const EcommerceDashboardWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
})

export const ApiKeyWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

export const ApiKeyHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center'
})

export const AddStoreForm = styled('form')({
  display: 'flex',
  flexDirection: 'column'
})

export const ApiKeyHeading = styled(Box)(({ theme }) => ({
  font: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  fontWeight: theme.palette.fontWeight['500'],
  lineHeight: '157%',
  color: '#FFF'
}))

export const EcommerceDashboardCopyInput = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  maxWidth: '553px',
  border: '1px solid rgba(234, 242, 255, 0.22)',
  family: '`Iter`, sans-serif',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.75)',
  wordBreak: 'break-all'
}))

export const EcommerceAddNewStoreButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  width: 'fit-content',
  fontWeight: theme.palette.fontWeight['500'],
  color: '#FFF',
  fontSize: '14px',
  lineHeight: '24px',
  marginBottom: '48px',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px'
}))

export const EcommerceAccordion = styled(Accordion)(({ theme }) => ({
  position: 'relative',
  height: 'fit-content',
  padding: '15px 16px',
  gap: '24px',
  marginBottom: '24px',
  minHeight: '88px',
  minWidth: '553px',
  flex: '0 1 553px',
  background: '#292929',
  borderRadius: '16px !important',
  '&:nth-of-type(odd)': {
    marginRight: '24px'
  },
  '&.MuiAccordion-root:before': { display: 'none' },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    '&:nth-of-type(odd)': {
      marginRight: 0
    }
  }
}))

export const AccordionTypography = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  family: '`Iter`, sans-serif',
  fontSize: '24px',
  lineHeight: '133%',
  color: '#fff'
}))

export const FormHeading = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['500'],
  family: '`Iter`, sans-serif',
  fontSize: '20px',
  lineHeight: '160%',
  color: '#fff',
  margin: '24px 12px 24px 0'
}))

export const SaveChangesButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  background: '#186ADF',
  color: theme.palette.common.white,
  boxShadow: 'none',
  borderRadius: '8px',
  family: '`Ubuntu`, sans-serif',
  fontWeight: theme.palette.fontWeight['500'],
  padding: '4px 23px',
  minWidth: '153px',
  textTransform: 'uppercase',
  fontSize: '13px',
  lineHeight: '22px'
}))

export const PlatformAccordionLine = styled('hr')(() => ({ border: '1px solid rgba(234, 242, 255, 0.22)' }))

export const PlatformLink = styled(Box)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  padding: '0 14px',
  fontSize: '12px',
  lineHeight: '20px',
  cursor: 'pointer',
  textDecoration: 'underline',
  fontWeight: theme.palette.fontWeight['400'],
  color: '#186ADF',
  marginTop: '3px'
}))

export const AccordionContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}))

export const PlatformInputGroup = styled(Box)(() => ({
  marginBottom: '24px'
}))

export const AccordionEditForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginTop: '9px',
  width: '100%'
}))

export const AccordionEditFormButtons = styled(Box)(() => ({
  display: 'flex'
}))

export const AccordionSummaryContent = styled(AccordionSummary)(() => ({
  padding: 0,
  width: '100%'
}))

export const PlatformAccordionEditInput = styled(TextField)(({ theme }) => ({
  family: `'Iter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  background: 'none',
  color: theme.palette.common.white
}))

export const PlatformOptionsNavigationItem = styled(MenuItem)(({ theme }) => ({
  family: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '143%',
  borderRadius: '10px',
  letterSpacing: '0.15px',
  padding: '6px 20px 14px 20px',
  color: theme.palette.common.white,
  '&:first-of-type': {
    paddingTop: '12px',
    borderRadius: '10px 10px 0 0',
    borderBottom: '1px solid rgba(234, 234, 255, 0.12)'
  },
  '&:nth-of-type(2)': {
    paddingTop: '12px',
    borderRadius: '0 0 10px 10px'
  }
}))

export const PlatformOptionsIconButton = styled(IconButton)<{ open: boolean }>(({ open, theme }) => ({
  borderRadius: open ? '4px' : 0,
  width: '24px',
  height: '24px',
  background: open ? '#424242' : 'none',
  color: theme.palette.common.white
}))

export const SelectedPlatform = styled(Box)(({ theme }) => ({
  family: '`Iter`, sans-serif',
  marginRight: '12px',
  fontSize: '20px',
  fontWeight: theme.palette.fontWeight['500'],
  lineHeight: '160%',
  color: '#186ADF'
}))

export const PlatformMenuItem = styled(MenuItem)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  lineHeight: '143%',
  padding: '14px 20px',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  borderBottom: '1px solid rgba(234, 234, 255, 0.12)',
  '&:last-of-type': {
    borderBottom: 'none'
  }
}))

export const PlatformOptionMenuIconButton = styled(IconButton)<{ open: boolean }>(({ open }) => ({
  height: '32px',
  background: open ? '#424242' : 'none',
  borderRadius: '8px'
}))

export const AccordionChip = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  minWidth: '113px',
  height: '32px',
  border: '1px solid #186ADF',
  borderRadius: '8px'
}))

export const AccordionChipText = styled(Box)(({ theme }) => ({
  family: '`Iter`, sans-serif',
  fontSize: '13px',
  lineHeight: '18px',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white
}))

export const AccordionChipLabel = styled(LabelOutlinedIcon)(() => ({
  color: 'rgba(255, 255, 255, 0.54)',
  marginRight: '8px'
}))

export const PlatformOptionMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    width: 'fit-content',
    height: 'fit-content',
    minWidth: '120px',
    background: '#424242',
    boxShadow:
      '0px 6px 6px -3px rgba(20, 21, 33, 0.2), 0px 10px 14px 1px rgba(20, 21, 33, 0.14), 0px 4px 18px 3px rgba(20, 21, 33, 0.12)',
    borderRadius: '8px'
  }
}))

export const IconCheckButton = styled(IconButton)(() => ({
  cursor: 'pointer',
  '&:disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'all'
  }
}))

export const PlatformCheck = styled(CheckIcon)(() => ({
  color: '#39BA71'
}))

export const PlatformEditMode = styled(Box)(() => ({ display: 'flex', alignItems: 'center', width: '100%' }))
