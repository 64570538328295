import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const ContactSupportButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const ContactSupportContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  marginTop: '56px',
  height: '80vh',
  maxWidth: '800px',
  margin: '0 auto',
  justifyContent: 'center'
}))

export const ContactSupportText = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  color: '#FCFCFC',
  textAlign: 'center',
  marginBottom: '15px',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px'
  }
}))
