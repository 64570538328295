export const authConfig = {
  loginEndpoint: '/api/v1/auth/login',
  pageEndpoint: '/api/v1/page',
  registerEndpoint: '/api/v1/auth/register',
  secondTwoFactorStepEndpoint: '/api/v1/auth/login/2step',
  logoutEndpoint: '/api/v1/auth/logout',
  forgotPasswordEndpoint: '/api/v1/auth/forgot-password',
  refreshEndpoint: '/api/v1/auth/refresh',
  userEndpoint: '/api/v1/user',
  userAlertsEndpoint: '/api/v1/alerts',
  updateUserEmailEndpoint: '/api/v1/user/update-email',
  updateUserPasswordEndpoint: '/api/v1/user/update-password',
  sendAccountDeleteConfirmationEmailEndpoint: '/api/v1/user/send-delete',
  deleteUserAccountEndpoint: '/api/v1/user/delete',
  resetPasswordEndpoint: '/api/v1/auth/reset-password',
  settingsApiKeyEndpoint: '/api/v1/settings/api-key',
  settingsFiatEndpoint: '/api/v1/settings/default_fiat_currency',
  settingsEndpoint: '/api/v1/settings',
  createTicketEndpoint: '/api/v1/ticket/submit',
  ticketEntryEndpoint: '/api/v1/ticket',
  withdrawalEntryEndpoint: '/api/v1/user/withdrawal/history',
  testTransactionEndpoint: '/api/v1/test',
  emailVerificationEndpoint: '/api/v1/verification/email',
  storesEndpoint: '/api/v1/stores',
  currenciesEndpoint: '/api/v1/currencies',
  storageUserKeyName: 'user-watcher-dashboard',
  storageUserEmailName: 'user-email',
  storageI18nKeyName: 'i18nextLng',
  storageSettingsName: 'i18nextLng',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageReferralTokenKeyName: 'referral_token',
  userVerifiedStatus: 'is-user-verified',
  storageTwoFactorKey: '2factor',
  storageTwoFactorPassedKey: '2fa_confirm'
}
