import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from '@/app/store'

export const ticketsRootState = (state: RootState) => state.tickets

export const ticketsStateSelector = createDraftSafeSelector(ticketsRootState, state => state.data.tickets)
export const ticketsLoadingStateSelector = createDraftSafeSelector(
  ticketsRootState,
  state => state.status === 'pending'
)
export const closedTicketsSelector = createDraftSafeSelector(ticketsRootState, state => state.data.closedTickets)
export const ticketMessagesSelector = createDraftSafeSelector(ticketsRootState, state => state.data.messages)
