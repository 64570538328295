import { Box, Paper, styled, TableCell, Typography } from '@mui/material'

export const WalletWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  weight: '100%'
}))

export const TotalWalletBalance = styled(Typography)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  lineHeight: '123.5%',
  letterSpacing: '0.25px',
  color: theme.palette.customColors.delight
}))

export const WalletCost = styled('span')(() => ({
  color: '#186ADF'
}))

export const TableWrapper = styled(Box)(() => ({
  marginTop: '24px',
  marginBottom: '48px'
}))

export const TablePaper = styled(Paper)(() => ({
  background: 'none'
}))

export const WalletTableHeading = styled(TableCell)(({ theme }) => ({
  width: '300px',
  fontSize: '14px',
  lineHeight: '24px',
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['500'],
  letterSpacing: '0.17px',
  textTransform: 'none',
  color: theme.palette.common.white
}))

export const AccordionHeading = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '24px',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  letterSpacing: '0.17px',
  textTransform: 'capitalize',
  color: theme.palette.common.white
}))

export const TableTitle = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '160%',
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  letterSpacing: '0.15px',
  textTransform: 'capitalize',
  color: theme.palette.customColors.delight
}))

export const CryptoValue = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '143%',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: '0.15px',
  textTransform: 'uppercase',
  color: 'rgba(255, 255, 255, 0.3)'
}))

export const CryptoName = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '143%',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['700'],
  letterSpacing: '0.15px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const CryptoCellText = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '143%',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: '0.15px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const WalletBreadcrumb = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: '0.15px',
  color: 'rgba(252, 252, 252, 0.6)'
}))

export const WalletHeading = styled(Box)(({ theme }) => ({
  fontSize: '28px',
  lineHeight: '40px',
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['900'],
  letterSpacing: '0.15px',
  color: theme.palette.common.white
}))

export const AccordionTypography = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '160%',
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  letterSpacing: '0.15px',
  color: theme.palette.customColors.delight
}))

export const AccordionText = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 16px',
  marginBottom: '24px'
}))

export const AccordionInner = styled(TableCell)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '12px 0 0 0'
}))
