import { Box, styled } from '@mui/material'

export const AppBurger = styled('button')<{ open: boolean }>(({ open }) => ({
  position: 'absolute',
  top: '5%',
  right: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  background: 'transparent',
  alignItems: 'flex-end',
  width: '2.5rem',
  height: '2rem',
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  zIndex: 10,

  '& > span': {
    width: '2rem',
    height: '0.25rem',
    background: open ? 'dark' : '#fff',
    borderRadius: '10px',
    transition: 'all 0.3s linear',
    position: 'relative',
    transformOrigin: '1px',

    '&:nth-of-type(1)': {
      background: '#FCFCFC',
      transform: open ? 'rotate(45deg)' : 'rotate(0)'
    },

    '&:nth-of-type(2)': {
      opacity: open ? 0 : 1,
      width: '1.5rem',
      background: '#186adf',
      transform: open ? 'translateX(20px)' : 'translateX(0)'
    },

    '&:nth-of-type(3)': {
      background: '#FCFCFC',
      transform: open ? 'rotate(-45deg)' : 'rotate(0)'
    }
  }
}))

export const IconedMenuItemContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: '`Ubuntu`, sans-serif',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  color: '#101010'
}))

export const IconedMenuText = styled(Box)(({ theme }) => ({
  fontFamily: '`Ubuntu`, sans-serif',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
  color: '#101010'
}))
