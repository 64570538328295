// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  disablePaymentNotifications,
  enablePaymentNotifications,
  getAlert,
  getCurrentUser,
  getUserReferrals,
  getUserReferralsAccruals,
  getUserTwoFactorQrCode,
  resentEmail,
  sendEmailVerification,
  updateAlertShownStatus
} from '@/app/store/thunks'
import { BaseParams, BaseState } from '@/app/store/types'
import { UserQrResponseData, UsersAlerts, UserType } from '@/shared'
import {IPaymentReferralAccrualsResponse, IPaymentResponse} from '@/shared/types/payment'

type UserState = BaseState<UserType | null> & UserQrResponseData & IPaymentResponse & BaseParams & UsersAlerts & IPaymentReferralAccrualsResponse

const initialState: UserState = {
  data: null,
  error: '',
  status: 'idle',
  qrcode_src: '',
  qrcode_text: '',
  responseStatus: '',
  users: [],
  referralsAccrual: [],
  referralsAccrualPage: 1,
  referralsAccrualPerPage: 5,
  referralsAccrualMeta: null,
  referralsAccrualState: 'pending',
  alerts: [],
  page: 1,
  per_page: 5,
  meta: null
}

export const userSlice = createSlice({
  name: 'appUsers',
  initialState,
  reducers: {
    setRequestError(state, action) {
      state.error = action.payload.message
    },
    setEmailResentError(state, action) {
      state.error = action.payload.message
    },
    setEmailStatusError(state, action) {
      state.error = action.payload
    },
    setRequestParams(state, action: PayloadAction<BaseParams>) {
      state.page = action.payload.page
      state.per_page = action.payload.per_page
    }
  },
  extraReducers: builder => {
    builder.addCase(getCurrentUser.pending, (state: UserState) => {
      state.status = 'pending'
    })
    builder.addCase(getUserReferrals.pending, (state: UserState) => {
      state.status = 'pending'
    })
    builder.addCase(getUserReferralsAccruals.pending, (state: UserState) => {
      state.referralsAccrualState = 'pending'
    })
    builder.addCase(sendEmailVerification.pending, (state: UserState) => {
      state.status = 'pending'
    })

    builder.addCase(getUserTwoFactorQrCode.pending, (state: UserState) => {
      state.status = 'pending'
    })
    builder.addCase(enablePaymentNotifications.pending, (state: UserState) => {
      state.status = 'pending'
    })

    builder.addCase(disablePaymentNotifications.pending, (state: UserState) => {
      state.status = 'pending'
    })
    builder.addCase(getCurrentUser.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(sendEmailVerification.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(getUserReferrals.rejected, (state: UserState) => {
      state.status = 'pending'
    })
    builder.addCase(getUserReferralsAccruals.rejected, (state: UserState) => {
      state.referralsAccrualState = 'pending'
    })
    builder.addCase(getUserTwoFactorQrCode.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(enablePaymentNotifications.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(getAlert.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(disablePaymentNotifications.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(sendEmailVerification.fulfilled, (state: UserState) => {
      state.status = 'fulfilled'
    })
    builder.addCase(resentEmail.pending, (state: UserState) => {
      state.status = 'pending'
    })
    builder.addCase(resentEmail.rejected, (state: UserState) => {
      state.status = 'rejected'
    })
    builder.addCase(getUserTwoFactorQrCode.fulfilled, (state: UserState, action: PayloadAction<UserQrResponseData>) => {
      state.qrcode_src = action.payload.qrcode_src
      state.qrcode_text = action.payload.qrcode_text
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(getCurrentUser.fulfilled, (state: UserState, action: PayloadAction<UserType>) => {
      state.data = action.payload
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(getAlert.fulfilled, (state: UserState, action: PayloadAction<UsersAlerts>) => {
      state.alerts = action.payload.alerts
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(updateAlertShownStatus.fulfilled, (state: UserState, action: PayloadAction<{ id: number }>) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload.id)
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(getUserReferrals.fulfilled, (state: UserState, action: PayloadAction<IPaymentResponse>) => {
      state.users = action.payload.users
      state.meta = action.payload.meta
      state.status = 'fulfilled'
      state.error = ''
    })
    builder.addCase(getUserReferralsAccruals.fulfilled, (state: UserState, action: any) => {
      state.referralsAccrual = action.payload.referralsAccrual
      state.referralsAccrualMeta = action.payload.meta
      state.referralsAccrualState = 'fulfilled'
      state.error = ''
    })
  }
})

export const { setRequestError, setEmailResentError, setEmailStatusError, setRequestParams } = userSlice.actions

const { reducer } = userSlice
export default reducer
