import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button } from '@mui/material'

export const Captcha = styled(ReCAPTCHA)(() => ({
  '& > .rc-anchor-normal': {
    width: '100px !important'
  }
}))

export const FormWrapper = styled(Box)<BoxProps & { disableMargin?: boolean }>(({ theme, disableMargin = false }) => ({
  width: '100%',
  display: 'flex',
  position: 'relative',
  marginTop: disableMargin ? '0px' : '80px',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 1200,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '80px'
  }
}))

export const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  marginTop: '28px',
  height: '100%',
  maxHeight: '600px',
  width: '100%',
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0
  }
}))

export const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

export const SignUpText = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.delight,
  fontSize: '18px',
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: '0.25px'
}))

export const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.delight,
  fontSize: '14px',
  fontWeight: theme.palette.fontWeight['400'],
  letterSpacing: theme.palette.letterSpacing.default
}))

export const SignUpWrapper = styled(Box)<{ hidden: boolean }>(({ hidden }) => ({
  display: 'flex',
  paddingTop: hidden ? '0' : '135px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))

export const LoginForm = styled('form')(() => ({
  maxWidth: 300,
  width: '100%',
  paddingTop: '14px'
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
  background: '#186ADF',
  fontSize: '14px',
  fontFamily: `'Inter'`,
  lineHeight: '16px',
  fontWeight: theme.palette.fontWeight['700'],
  color: '#FCFCFC',
  minHeight: '48px',
  width: 300,
  borderRadius: '12px',
  marginTop: '24px',
  '&:hover': {
    background: '#1B76F7',
    boxShadow: '0px 6px 8px rgba(24, 106, 223, 0.35)'
  },
  '&&&': {
    '&.Mui-disabled': {
      '&:disabled': {
        background: '#186ADF',
        opacity: 0.5,
        color: theme.palette.common.white,
        cursor: 'not-allowed',
        pointerEvents: 'none !important'
      }
    }
  }
}))
