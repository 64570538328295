import { styled } from '@mui/material'
import Link from 'next/link'

type NavLinkProps = {
  href: string
  linkText: string
  className?: string
}

const LinkText = styled('a')(() => ({
  color: '#186ADF !important',
  marginLeft: 5,
  textDecoration: 'none'
}))

export const NavLink = ({ href, linkText, className = '', ...rest }: NavLinkProps) => {
  return (
    <Link href={href} className={className} {...rest}>
      <LinkText href={href} >{linkText || ''}</LinkText>
    </Link>
  )
}
