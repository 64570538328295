// ** React Imports
import { PropsWithChildren, ReactElement, useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from '@/shared/hooks/useAuth'

interface GuestGuardProps {
  fallback: ReactElement | null
}

const GuestGuard = (props: PropsWithChildren<GuestGuardProps>) => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  useEffect(() => {
    const authIgnoreRoutes = ['/your-account-deleted', '/login-second-step', '/401', '/500', '/404']
    if (!router.isReady) {
      return
    }
    if (authIgnoreRoutes.includes(router.pathname)) {
      return
    }
  }, [router])

  if (auth.loading) {
    return fallback
  }

  return <>{children}</>
}

export default GuestGuard
