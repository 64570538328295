/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'
import { FormControl, FormHelperText, OutlinedInputProps } from '@mui/material'

/**
 * mdi-material-ui imports
 */
/**
 * styled items for form
 */
import { FormInputAdornment, FormLabel, FormOutlinedInput, FormWrapper } from './styles'
import { CheckIcon } from '../check'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

/**
 * react hooks
 */

/**
 * Initial props for input element
 */
type FormPasswordProps = {
  control?: Control<any> | undefined
  name: string
  className?: string
  label: string
  showIcon?: boolean
  wrapperStyle?: SxProps<Theme>
  sx?: SxProps<Theme>
} & OutlinedInputProps

/**
 * Custom form input component
 * @params {FormPasswordProps} - props for input component
 * @default type - password
 */

export const FormPassword = ({
  control,
  label = '',
  name,
  placeholder,
  wrapperStyle,
  sx,
  showIcon,
  ...otherProps
}: FormPasswordProps) => {
  return (
    <FormControl sx={{ ...wrapperStyle, mb: '24px' }}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => (
          <FormWrapper>
            <FormLabel sx={{ color: errors && errors[name] && 'error.main' }}>{label}</FormLabel>
            <FormOutlinedInput
              autoComplete='off'
              type='password'
              sx={sx}
              {...field}
              {...otherProps}
              onChange={e => {
                const pattern = /[^\x00-\x7F]+/gi
                const value = e.target.value.replace(pattern, '')
                field.onChange(value)
              }}
              error={Boolean(errors[name])}
              endAdornment={
                showIcon &&
                !errors[name] &&
                field.value.length >= 8 && (
                  <FormInputAdornment position='end'>
                    <CheckIcon />
                  </FormInputAdornment>
                )
              }
              placeholder={placeholder}
            />
            {errors.hasOwnProperty(name) && errors[name]?.message !== ' ' ? (
              <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                {errors[name]?.message as string}
              </FormHelperText>
            ) : null}
          </FormWrapper>
        )}
      />
    </FormControl>
  )
}
