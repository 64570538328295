import { Box, Menu, MenuItem, styled } from '@mui/material'

export const SelectedLang = styled(Box)(({ theme }) => ({
  marginRight: '8px',
  family: '`Ubuntu`, sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['700']
}))

export const DropdownMenuItem = styled(MenuItem)(({ theme }) => ({
  family: '`Ubuntu`, sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '12px 24px',
  fontWeight: theme.palette.fontWeight['700'],
  color: '#FCFCFC'
}))

export const LanguageDropdownMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    background: '#101010',
    color: '#FCFCFC',
    width: 'fit-content',
    
    // minHeight: '112px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
    borderRadius: '16px',
    border: `1px solid none`
  }
}))
