import * as yup from 'yup'
import i18n from 'i18next'

export const submitTicketFormSchema = () =>
  yup.object({
    subject: yup
      .string()
      .required(i18n.t('Subject is required field.'))
      .min(1, i18n.t('Subject should have minimum 1 char.'))
      .max(100, i18n.t('Subject should be up to 100 characters.')),
    description: yup
      .string()
      .required(i18n.t('Description is required field.'))
      .min(1, i18n.t('Description should have minimum 1 char.'))
      .max(1000, i18n.t('Description should be up to 1000 characters.'))
  })
