import { PageModule } from '@/shared'
import CardMedia from '@mui/material/CardMedia'
import {
  InfoModuleCard,
  InfoModuleCardButton,
  InfoModuleCardDescription,
  InfoModuleCardImage,
  InfoModuleCardText,
  InfoModuleCardTitle
} from './styles'
import { VerticalLink } from '@/shared/layouts/core/vertical/navigation/styles'
import { useTranslation } from 'react-i18next'

export const InfoModule = ({ description, image, title, btn }: PageModule) => {
  const href = btn?.link
  const { i18n } = useTranslation()
  const localization = i18n.language === 'ukr' ? 'uk' : 'en'
  const infoTitle = title?.[localization]
  const infoDescription = description?.[localization]
  const infoButtonName = btn?.name?.[localization]

  return (
    <InfoModuleCard>
      {image ? (
        <InfoModuleCardImage>
          <CardMedia
            component='img'
            sx={{ maxHeight: '272px' }}
            src={image || 'https://via.placeholder.com/150'}
            alt={infoDescription}
          />
        </InfoModuleCardImage>
      ) : null}
      <InfoModuleCardText>
        {infoTitle && <InfoModuleCardTitle dangerouslySetInnerHTML={{ __html: infoTitle }} />}
        {infoDescription && <InfoModuleCardDescription dangerouslySetInnerHTML={{ __html: infoDescription }} />}
        {infoButtonName ? (
          href !== null ? (
            <VerticalLink href={String(btn?.link)} passHref>
              <InfoModuleCardButton target='_blank' rel='noopener noreferrer'>
                {infoButtonName}
              </InfoModuleCardButton>
            </VerticalLink>
          ) : (
            <InfoModuleCardButton>{infoButtonName}</InfoModuleCardButton>
          )
        ) : null}
      </InfoModuleCardText>
    </InfoModuleCard>
  )
}
