import { BackIcon, HistoryInfoTicketId, HistoryInfoTicketName, HistoryInfoWrapper } from './styles'
import { useRouter } from 'next/router'

type Props = {
  id: number
  subject: string
}

export const HistoryTicketNavigationInfo = ({ id, subject }: Props) => {
  const router = useRouter()

  const handleBack = () => {
    router.push('/history-tickets')
  }

  return (
    <HistoryInfoWrapper>
      <BackIcon onClick={handleBack} />
      <HistoryInfoTicketName>{subject}</HistoryInfoTicketName>
      <HistoryInfoTicketId>#{id}</HistoryInfoTicketId>
    </HistoryInfoWrapper>
  )
}
