import { Box, FormControl, FormControlLabel, MenuItem, Switch, TextField } from '@mui/material'
import { Key } from 'mdi-material-ui'
import {
  ApiGenerator,
  AuthenticationLabel,
  DeleteAccountButton,
  LockAvatarWrapper,
  SettingsButton,
  SettingsHeading,
  SettingsSelect,
  SettingsSelectLabel,
  TwoFactorDescription,
  TwoFactorHeading
} from './styles'
import { ChangeEvent, memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import NotificationsIcon from '@mui/icons-material/Notifications'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { AccountInfo } from './account-info'
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import {
  disablePaymentNotifications,
  enablePaymentNotifications,
  getCurrentUser,
  getDefaultFiatCurrencies,
  getSettingsApiKey,
  getTwoFactorQr,
  updateDefaultFiatCurrencies
} from '@/app/store/thunks'
import {
  settingsApiKeySelector,
  settingsFiatSelector,
  settingsLoadingSelector,
  userStateSelector
} from '@/app/store/selectors'
import { useAppDispatch, useAppSelector } from '@/shared/hooks/types'
import { userService } from '@/shared/services/user.service'
import { CheckEmailModal } from '@/widgets/pages/settings'
import Skeleton from '@mui/material/Skeleton'
import { settingsService, UserType } from '@/shared'
import { SettingsApiKeyModal } from '@/widgets/pages/settings/ui/settings-api-key-modal'

export const SettingsBody = memo(() => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const user = useAppSelector(userStateSelector) as UserType
  const fiatCurrencyValues = useAppSelector(settingsFiatSelector)
  const settingsToken = useAppSelector(settingsApiKeySelector)
  const loading = useAppSelector(settingsLoadingSelector)
  const [apiKey, setApiKey] = useState<string>(settingsToken)
  const [fiatCurrency, setFiatCurrency] = useState<string>(user?.default_fiat_currency?.iso_name)
  const [receiveNotifications, setReceiveNotifications] = useState<boolean>(Boolean(user?.is_pay_email_enabled))
  const [isEnabledTwoFactor, setIsEnabledTwoFactor] = useState<boolean>(Boolean(user?.is_two_auth_enabled))
  const [open, setOpen] = useState<boolean>(false)
  const [apiModal, setApiModal] = useState<boolean>(false)

  const handleApiOpen = () => {
    toast.success(t(`The email with password was successfully sent!`), {
      position: 'bottom-right',
      duration: 4000
    })
    settingsService.generateTempPasswordAndSendEmail()
    setApiModal(true)
  }

  const handleApiClose = () => {
    setApiModal(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const router = useRouter()

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setFiatCurrency(event.target.value as string)
  }

  const TwoFactorLock = isEnabledTwoFactor ? LockOutlinedIcon : LockOpenOutlinedIcon

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250
      }
    }
  }

  const onAccountDelete = useCallback(async () => {
    handleClickOpen()
    await userService.sendAccountDeleteConfirmationEmail(i18n.language)
  }, [i18n.language])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value)
  }

  const changeTwoFactorStatus = useCallback(() => {
    const status = isEnabledTwoFactor ? 'disable' : 'enable'
    const twoFactorRoute = `/two-factor/${status}`
    router.replace(twoFactorRoute)
  }, [isEnabledTwoFactor, router])

  const updateFiatCurrency = useCallback(
    (id: number) => {
      dispatch(updateDefaultFiatCurrencies(id))
      toast.success(t('Default fiat currency was successfully updated'), { position: 'bottom-right' })
    },
    [t, dispatch]
  )

  const renderFiatCurrency = () =>
    (fiatCurrencyValues ?? []).map(({ id, iso_name }) => (
      <MenuItem key={id} value={iso_name} onClick={() => updateFiatCurrency(id)}>
        {iso_name}
      </MenuItem>
    ))

  const handleSwitchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setReceiveNotifications(event.target.checked)
    await dispatch(receiveNotifications ? disablePaymentNotifications() : enablePaymentNotifications()).then(
      async response => {
        const notification = response.payload?.message
        toast.success(t(notification), { position: 'bottom-right' })
        await dispatch(getCurrentUser())
      }
    )
  }

  useEffect(() => {
    const settingsPromises = [
      dispatch(getDefaultFiatCurrencies()),
      dispatch(getTwoFactorQr()),
      dispatch(getCurrentUser()),
      dispatch(getSettingsApiKey(i18n.language))
    ]
    Promise.all(settingsPromises)
  }, [dispatch, i18n.language])

  useLayoutEffect(() => {
    if (user?.default_fiat_currency) {
      setFiatCurrency(user?.default_fiat_currency?.iso_name)
    }
    if (settingsToken) {
      setApiKey(settingsToken)
    }
    if (user?.is_pay_email_enabled) {
      setReceiveNotifications(user?.is_pay_email_enabled)
    }
    if (isEnabledTwoFactor || user?.is_two_auth_enabled) {
      setIsEnabledTwoFactor(Boolean(user?.is_two_auth_enabled))
    }
  }, [
    isEnabledTwoFactor,
    settingsToken,
    user?.default_fiat_currency,
    user?.is_pay_email_enabled,
    user?.is_two_auth_enabled
  ])

  return (
    <Box>
      {loading ? (
        <Skeleton variant='rounded' width={'15%'} height={42} sx={{ mb: '44px' }} />
      ) : (
        <Box>
          <SettingsHeading>{t('Settings')}</SettingsHeading>
        </Box>
      )}
      {loading ? <Skeleton variant='rounded' width={'25%'} height={89} sx={{ mb: '31px' }} /> : <AccountInfo />}
      {loading ? (
        <Skeleton variant='rounded' width={542} height={110} sx={{ mb: '20px' }} />
      ) : (
        <ApiGenerator>
          <TextField
            sx={{ mb: '16px' }}
            label={t('API Key')}
            value={apiKey}
            InputProps={{
              readOnly: true
            }}
            onChange={handleChange}
          />
          <Box>
            <SettingsButton variant='contained' onClick={handleApiOpen}>
              {t('Generate New API Key')}
            </SettingsButton>
          </Box>
        </ApiGenerator>
      )}

      {loading ? (
        <Skeleton variant='rounded' width={'25%'} height={58} sx={{ mb: '48px' }} />
      ) : (
        <Box sx={{ mb: '48px' }}>
          <FormControl variant='standard' sx={{ m: 1, minWidth: 220 }}>
            <SettingsSelectLabel>{t('Currency for equivalent')}</SettingsSelectLabel>
            <SettingsSelect
              value={fiatCurrency}
              onChange={handleSelectChange}
              label={t('Currency for equivalent')}
              defaultValue={fiatCurrency}
              MenuProps={MenuProps}
            >
              {renderFiatCurrency()}
            </SettingsSelect>
          </FormControl>
        </Box>
      )}

      {loading ? (
        <Skeleton variant='rounded' width={'45%'} height={88} sx={{ mb: '48px' }} />
      ) : (
        <Box sx={{ mb: '48px' }}>
          <Box sx={{ mt: 6, mb: 4.5, display: 'flex', alignItems: 'center' }}>
            <NotificationsIcon sx={{ fontSize: '1.75rem', mr: 2, color: '#FFFFFF' }} />
            <AuthenticationLabel>{t('Notifications')}</AuthenticationLabel>
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={<Switch checked={receiveNotifications} onChange={handleSwitchChange} />}
              label={t('Receive Notifications about transaction on email')}
            />
          </Box>
        </Box>
      )}
      {loading ? (
        <Skeleton variant='rounded' width={'45%'} height={390} sx={{ mb: '36px' }} />
      ) : (
        <Box>
          <Box sx={{ mb: '36px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Key sx={{ mr: 4, color: '#FFFFFF' }} />
            <AuthenticationLabel>{t('Two - factor authentication')}</AuthenticationLabel>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <LockAvatarWrapper skin='light' variant='rounded' sx={{ mb: '16px' }}>
                <TwoFactorLock sx={{ color: '#186ADF' }} />
              </LockAvatarWrapper>
              <TwoFactorHeading>
                {t(
                  isEnabledTwoFactor
                    ? 'Two factor authentication is enabled.'
                    : 'Two factor authentication is not enabled yet.'
                )}
              </TwoFactorHeading>
              <TwoFactorDescription>
                {t(
                  'Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in. Learn more.'
                )}
              </TwoFactorDescription>
            </Box>
          </Box>
          <Box>
            <SettingsButton variant={'contained'} onClick={changeTwoFactorStatus}>
              {t(isEnabledTwoFactor ? 'Disable authentication' : 'Enable authentication')}
            </SettingsButton>
          </Box>
          <DeleteAccountButton variant='outlined' onClick={onAccountDelete}>
            {t('Delete Account')}
          </DeleteAccountButton>
        </Box>
      )}
      <SettingsApiKeyModal open={apiModal} handleClose={handleApiClose} />
      <CheckEmailModal open={open} handleClose={handleClose} />
    </Box>
  )
})
