import * as yup from 'yup'
import i18n from 'i18next'

export const apiValuesFormSchema = () =>
  yup.object().shape({
    '2fa_code': yup.string().required(i18n.t('Code is required field.')),
    admin_password: yup
      .string()
      .required(i18n.t('Password is required field.'))
      .matches(/[0-9a-zA-Z]{8,}/gm, i18n.t('The password must consist of latin letters and numbers!'))
  })

export const passwordFormSchema = () =>
  yup.object().shape({
    admin_password: yup
      .string()
      .required(i18n.t('Password is required field.'))
      .matches(/[0-9a-zA-Z]{8,}/gm, i18n.t('The password must consist of latin letters and numbers!'))
  })
