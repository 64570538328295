// ** React Imports
import { Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import Close from 'mdi-material-ui/Close'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { BsPlusLg } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { UploadFilesButton } from '@/shared/ui/submit-ticket'
import { FileProp } from '@/pages/submit-ticket'
import { acceptMimeOptions, bytesToKb, bytesToMb } from '@/shared'
import { ImageWithState } from '@/shared/ui/image-with-state'
import {
  FileDetails,
  FileName,
  FilePreview,
  FileSize,
  UploadListItem
} from '@/widgets/forms/form-elements/file-uploader/styles'
import { toast } from 'react-hot-toast'

type Props = Partial<{
  files: File[]
  onDrop(acceptedFiles: File[]): void
  handleRemoveFile(file: FileProp): void
}>

const FileUploaderMultiple = ({ files, onDrop, handleRemoveFile }: Props) => {
  // ** State
  const MAX_LENGTH = 3
  const MAX_LENGTH_EACH_FILE = 10e6
  const { t } = useTranslation()

  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_LENGTH,
    maxSize: MAX_LENGTH * MAX_LENGTH_EACH_FILE,
    onDropRejected: () => {
      toast.error(
        t(
          `You can only upload 3 files, maximum size of 10 MB. The File must be a file of type: jpg, jpeg, png, gif, heic, ico, webp, mp4, mov, wav, mp3, m4a, pdf, doc, docx, txt, rtf, xls, xlsx, ppt, pptx, csv, bmp.`
        ),
        {
          duration: 2000,
          position: 'bottom-right'
        }
      )
    },
    accept: acceptMimeOptions,
    onDrop
  })

  const fileList = (files ?? []).map((file: FileProp) => (
    <UploadListItem key={file.name}>
      <FileDetails>
        <FilePreview>
          <ImageWithState width={38} height={38} alt={file.name} src={'/images/icons/file.svg'} />
        </FilePreview>
        <div>
          <FileName>{file.name}</FileName>
          <FileSize>{Math.round(file.size / 100) / 10 > 1000 ? bytesToMb(file.size) : bytesToKb(file.size)}</FileSize>
        </div>
      </FileDetails>
      <IconButton
        sx={{ p: 0 }}
        onClick={() => handleRemoveFile && handleRemoveFile(file)}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Close fontSize='medium' sx={{ color: '#FCFCFC' }} />
      </IconButton>
    </UploadListItem>
  ))

  return (
    <Fragment>
      {(files ?? []).length ? (
        <Fragment>
          <List>{fileList}</List>
        </Fragment>
      ) : null}
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} multiple />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
            <UploadFilesButton variant='contained' endIcon={<BsPlusLg size={12} />}>
              {t('Attach files')}
            </UploadFilesButton>
          </Box>
        </Box>
      </div>
    </Fragment>
  )
}

export default FileUploaderMultiple
