import { combineReducers } from '@reduxjs/toolkit'

// ** Reducers
import layout from '../apps/layout'
import payment from '../apps/payment'
import settings from '../apps/settings'
import tickets from '../apps/tickets'
import history from '../apps/history'
import wallet from '../apps/wallet'
import stores from '../apps/stores'
import user from '../apps/user'
import chat from '../apps/chat'
import pagesModule from '../apps/pages-module'
import withdrawalTickets from '../apps/withdrawal-tickets'

export const rootReducer = combineReducers({
  user,
  chat,
  history,
  payment,
  layout,
  wallet,
  settings,
  tickets,
  withdrawalTickets,
  pagesModule,
  stores
})
