import { HistoryTicketOptionsWrapper, HistoryTicketOptionTitle } from './styles'
import { TicketStatus } from '@/shared/ui/tickets'
import { HistoryTicketOptionsMenu, IClosedTicket } from '@/shared'
import { Box } from '@mui/material'
import { useDateString } from '@/shared/hooks/useDateString'
import { useTranslation } from 'react-i18next'

type Props = {
  closedTicket: IClosedTicket
}

export const HistoryTicketNavigationOptions = ({ closedTicket }: Props) => {
  const { t } = useTranslation()
  const closeTime = useDateString(closedTicket?.close_time)
  const options = [t('Reopen ticket')]

  return (
    <HistoryTicketOptionsWrapper>
      <HistoryTicketOptionTitle>{`${t('Closed')} ${t('on')} ${t(closeTime)} ${t('by')} ${
        closedTicket?.close_by
      }`}</HistoryTicketOptionTitle>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TicketStatus variant={'contained'} disableTouchRipple>
          {t(closedTicket?.status)}
        </TicketStatus>
        <Box sx={{ ml: '12px' }}>
          <HistoryTicketOptionsMenu options={options} closedTicket={closedTicket} />
        </Box>
      </Box>
    </HistoryTicketOptionsWrapper>
  )
}
