import { SvgIcon, SvgIconProps } from '@mui/material'

type SvgColor = Readonly<{
  fillColor?: string
}>

export const UserSettingsIcon = (props: SvgIconProps & SvgColor) => {
  return (
    <SvgIcon width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='40' height='40' rx='20' fill={props?.fillColor || '#186ADF'} />
      <circle cx='20' cy='15' r='5' fill={'#FCFCFC'} fillOpacity='0.6' />
      <path
        d='M11 27V28C11 29.1046 11.8954 30 13 30H27C28.1046 30 29 29.1046 29 28V27C29 24.2386 26.7614 22 24 22H16C13.2386 22 11 24.2386 11 27Z'
        fill='#FCFCFC'
      />
    </SvgIcon>
  )
}
