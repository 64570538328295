import { styled } from '@mui/system'
import Box from '@mui/system/Box'
import Link from 'next/link'
import { FormLabel } from '@/shared/ui/react-hook-form'
import Select from '@mui/material/Select'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { SelectProps, TextField } from '@mui/material'
import { Key } from 'mdi-material-ui'

import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

export const FundsWithdrawalWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const FundsWithdrawalHeading = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  color: '#FCFCFC',
  marginBottom: '12px'
}))

export const FundsWithdrawalElement = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 557
}))

export const FundsWithdrawalTwoFactor = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  marginTop: '12px',
  fontSize: '24px',
  lineHeight: '133%',
  color: '#FCFCFC',
  '& > a': {
    textDecoration: 'none',
    padding: '0 10px',
    color: '#186ADF'
  }
}))

export const FundsWithdrawalLink = styled(Link)(() => ({}))

export const FundsWithdrawalSelect = styled(Select)<SelectProps>(({ theme }) => ({
  width: '100%',
  minWidth: '557px',
  maxHeight: '54px',
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    display: 'flex !important'
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto'
  }
}))

export const FundsWithdrawalSelectLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['500'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  fontSize: '14px',
  lineHeight: '157%',
  letterSpacing: '0.1px',
  color: '#FFFFFF'
}))

export const FundsWithdrawalPlaceholder = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const FundsWithdrawalCurrency = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  fontSize: '16px',
  lineHeight: '150%',
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const FundsWithdrawalCurrencyIconText = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['700'],
  fontFamily: `'Ubuntu', sans-serif`,
  fontSize: '16px',
  color: '#FFF',
  marginRight: '8px'
}))

export const FundsWithdrawalCurrencyIcon = styled(Box)(() => ({
  marginRight: '8px'
}))

export const FundsWithdrawalTo = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['700'],
  fontFamily: `'Ubuntu', sans-serif`,
  fontSize: '16px',
  color: 'rgba(255, 255, 255, 0.75)',
  marginTop: '36px',
  marginBottom: '24px'
}))

export const FundsWithdrawalNetwork = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['500'],
  fontFamily: `'Ubuntu', sans-serif`,
  fontSize: '14px',
  color: '#FFFFFF',
  marginBottom: '4px'
}))

export const FundsWithdrawalNetworkName = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Iter', sans-serif`,
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(255, 255, 255, 0.75)',
  marginBottom: '4px'
}))

export const FundsWithdrawalAmount = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['700'],
  fontFamily: `'Ubuntu', sans-serif`,
  fontSize: '16px',
  color: 'rgba(255, 255, 255, 0.75)',
  marginTop: '36px',
  marginBottom: '24px'
}))

export const InputEndAdornment = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['600'],
  fontFamily: `'Iter', sans-serif`,
  fontSize: '16px',
  color: '#186ADF'
}))

export const InputAdornmentCurrency = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['600'],
  fontFamily: `'Iter', sans-serif`,
  fontSize: '16px',
  color: 'rgba(255, 255, 255, 0.4)',
  marginRight: '8px'
}))

export const FundsWithdrawalTextField = styled(TextField)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Iter', sans-serif`,
  width: '100%',
  maxWidth: '557px',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const FundsWithdrawalFormLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['500'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  fontSize: '14px',
  lineHeight: '157%',
  color: '#FFF'
}))

export const FundsWithdrawalChars = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  paddingLeft: '14px',
  fontSize: '12px',
  lineHeight: '166%',
  color: '#FFF'
}))

export const FundsWithdrawalTotalAmountValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Iter', sans-serif`,
  width: '100%',
  fontSize: '24px',
  maxWidth: 557,
  wordBreak: 'break-word',
  lineHeight: '133.4%',
  color: '#FFF'
}))

export const AccessTime = styled(AccessTimeIcon)(() => ({
  marginRight: '10px'
}))

export const FundsWithdrawalTotalAmountText = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  fontSize: '16px',
  lineHeight: '175%',
  color: 'rgba(255, 255, 255, 0.4)',
  marginTop: '2px'
}))

export const AvailableBalanceInWallet = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['500'],
  fontFamily: `'Ubuntu', sans-serif`,
  textAlign: 'end',
  width: '100%',
  fontSize: '14px',
  lineHeight: '175%',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const FundsWithdrawalButton = styled(Button)(({ theme }) => ({
  background: '#186ADF',
  padding: '7px 22px',
  gap: 8,
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: 8,
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  maxWidth: '170px',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#FFF',
  '&:hover': {
    background: '#186ADF'
  }
}))

export const FundsWithdrawalTwoFactorStatus = styled(Box)(() => ({
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}))

export const FundsWithdrawalLock = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const FundsWithdrawalCalculations = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '600px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {}
}))

export const FundsWithdrawalAmountControlHead = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const FundsWithdrawalTwoFactorKey = styled(Key)(() => ({
  marginRight: '16px',
  color: '#FFFFFF'
}))

export const FundsWithdrawalContentText = styled(Box)(({ theme }) => ({
  family: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '143%',
  letterSpacing: '0.15px',
  color: 'rgba(252, 252, 252, 0.6)'
}))

export const FundsWithdrawalModalButton = styled(Button)(() => ({
  display: 'flex',
  minWidth: '200px',
  padding: '7px 22px',
  gap: 8,
  width: '100%',
  color: '#FFF',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(76, 78, 100, 0.42)',
  borderRadius: 8,
  '&:hover': {
    background: '#186ADF'
  }
}))

export const FundsWithdrawalDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '36px 56px',
    gap: 10,
    width: 446,
    height: 382,
    boxShadow: '0px 2px 40px rgba(37, 38, 55, 0.4)',
    background: '#292929',
    borderRadius: 10,
    outline: 0,
    border: 0,
    [theme.breakpoints.down('md')]: {
      padding: '25px',
      maxWidth: '500px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px',
      maxWidth: '95%'
    }
  },
  '& .MuiDialogContent-root': {
    padding: 0
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export const FundsWithdrawalTextLink = styled(Link)(() => ({
  textAlign: 'center'
}))
