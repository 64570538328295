import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

export const RegisterFailed = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '56px',
  height: '70vh',
  maxWidth: '800px',
  margin: '0 auto',
  justifyContent: 'center'
}))

export const RegisterFailedText = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '18px',
  lineHeight: '24px',
  marginLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  }
}))

export const RegisterFailedContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 15px',
  width: '100%',
  alignItems: 'center',
  margin: '20px 0'
}))

export const RegisterFailedButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const RegisterFailedLoginButton = styled(Button)(() => ({
  minWidth: 150,
  marginRight: '15px'
}))

export const RegisterFailedResentEmailButton = styled(Button)(({ theme }) => ({
  minWidth: 150,
  marginLeft: '10px',
  [theme.breakpoints.down('md')]: {
    marginTop: '10px',
    marginLeft: 0
  }
}))
