import { memo } from 'react'
import { SingleTicketContainer, SingleTicketContent, SingleTicketInfo } from './styles'
import {
  SingleTicketInfoBody,
  SingleTicketOptions,
  SupportImage,
  TicketCreatedAt,
  TicketLastMessage,
  TicketMessageContent,
  TicketStatus,
  TicketWithdrawalContent,
  TicketWithdrawalId,
  TicketWithdrawalInfo,
  TicketWithdrawalName,
  TicketWithdrawalValue,
  tokenService,
  truncateString,
  useDateString
} from '@/shared'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import { WithdrawalTicketOptionsMenu } from '@/shared/ui/withdrawal-one-ticket'
import { WithdrawalTicket } from '@/shared/types/withdrawal-tickets'

type Props = {
  handleClick: () => void
  ticket: WithdrawalTicket
}

export const WithdrawalSingleTicket = memo(({ ticket, handleClick }: Props) => {
  const { t } = useTranslation()
  const reportTime = useDateString(ticket?.report_time)
  const user = tokenService.getUser()
  const userName = user?.name
  const isSupportManager = ticket?.last_message?.author === 'Support Manager'
  const isUserSender = ticket?.last_message?.author === userName
  const ticketAuthor = ticket?.last_message?.author
  const lastMessage = ticket?.last_message?.message
  const bitcoinSelected = ticket?.currency?.name === 'BTC'
  const sender = `${isSupportManager ? t('Support') : isUserSender ? t('You') : ticket?.last_message?.author}:`
  const messageIcon = (
    <TicketMessageContent>
      {isSupportManager && (
        <SupportImage>
          <Image width={24} height={24} alt={'support-icon'} src={'/images/icons/support-icon.svg'} color={'#424242'} />
        </SupportImage>
      )}
      {ticketAuthor && lastMessage ? (
        <TicketLastMessage $lastMessage={Boolean(ticketAuthor && lastMessage)}>
          {truncateString(`${sender} ${lastMessage}`, 50)}
        </TicketLastMessage>
      ) : null}
    </TicketMessageContent>
  )

  return (
    <SingleTicketContainer onClick={handleClick}>
      <SingleTicketContent>
        <SingleTicketInfo>
          <SingleTicketInfoBody>
            <TicketCreatedAt sx={{ m: 0 }}>{`${t('Created')} ${t('on')} ${reportTime}`}</TicketCreatedAt>
            <SingleTicketOptions>
              <TicketStatus variant={'contained'} disableTouchRipple>
                {t(ticket?.status)}
              </TicketStatus>
              <WithdrawalTicketOptionsMenu ticket={ticket} />
            </SingleTicketOptions>
          </SingleTicketInfoBody>
          <TicketWithdrawalContent>
            <TicketWithdrawalInfo>
              <TicketWithdrawalName>{t('Coin')}:</TicketWithdrawalName>
              <TicketWithdrawalValue>{ticket?.currency?.name}</TicketWithdrawalValue>
            </TicketWithdrawalInfo>
            {!bitcoinSelected ? (
              <TicketWithdrawalInfo>
                <TicketWithdrawalName>{t('Network')}:</TicketWithdrawalName>
                <TicketWithdrawalValue>BEP-20</TicketWithdrawalValue>
              </TicketWithdrawalInfo>
            ) : null}
            <TicketWithdrawalInfo>
              <TicketWithdrawalName>{t('Amount')}:</TicketWithdrawalName>
              <TicketWithdrawalValue>
                {Number(ticket?.sum)} {ticket?.currency?.name}
              </TicketWithdrawalValue>
            </TicketWithdrawalInfo>
          </TicketWithdrawalContent>
          <TicketWithdrawalInfo sx={{ mb: '10px' }}>
            <TicketWithdrawalName>{t('Address')}:</TicketWithdrawalName>
            <TicketWithdrawalValue>{ticket?.address}</TicketWithdrawalValue>
          </TicketWithdrawalInfo>
          {ticket?.id && (
            <TicketWithdrawalId>
              {t('Ticket ID')}: #{ticket?.id}
            </TicketWithdrawalId>
          )}
          <TicketLastMessage>{messageIcon}</TicketLastMessage>
        </SingleTicketInfo>
      </SingleTicketContent>
    </SingleTicketContainer>
  )
})
