import { cloneElement, ReactElement } from 'react'
import { useScrollTrigger } from '@mui/material'

/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
interface Props {
  window?: () => Window
  children: ReactElement
}

export const ElevationScroll = ({ children, window }: Props) => {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0
  })
}
