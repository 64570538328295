import { authConfig, axiosDownloadInstance, axiosInstance, FundsWithdrawalValues, TickerDataType } from '@/shared'
import { BaseParams } from '@/app/store/types'

class TicketsService {
  async downloadChatAttachments(url: string, filename: string) {
    return await axiosDownloadInstance
      .get(url, {
        responseType: 'blob' // important
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click() //programmatically click the link so the user doesn't have to
        document.body.removeChild(link)
        URL.revokeObjectURL(url) //important for optimization and preventing memory leak even though link element has already been removed.
      })
  }

  async getWithdrawalTickets(params: BaseParams) {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/withdrawal`, { params })

    return response.data
  }

  async closeWithdrawal(id: number) {
    const response = await axiosInstance.post(`${authConfig.userEndpoint}/withdrawal/${id}/close`)

    return response.data
  }

  async reopenUserWithdrawal(id: number) {
    const response = await axiosInstance.post(`${authConfig.userEndpoint}/withdrawal/reopen/${id}`)

    return response.data
  }

  async getWithdrawal(id: string) {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/withdrawal/${id}`)

    return response.data
  }

  async withdrawalsToAddress(data: FundsWithdrawalValues) {
    const response = await axiosInstance.post(`${authConfig.userEndpoint}/withdrawal`, data)

    return response.data
  }

  async replyInWithdrawal(id: number, data: TickerDataType | FormData) {
    const response = await axiosInstance.post(`${authConfig.userEndpoint}/withdrawal/${id}/reply`, data)

    return response.data
  }
}

export const ticketsApi = new TicketsService()
