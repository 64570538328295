import { authConfig, axiosInstance, SettingsFiatCurrencyType } from '@/shared'
import { ApiKeyFormValues } from '@/widgets/pages/settings/ui/settings-api-key-modal'
import { SettingsApiKeyType } from '../types'
import { AxiosResponse } from 'axios'

class SettingsService {
  getSettingsApiKey = async (lang: string): Promise<AxiosResponse<SettingsApiKeyType>> => {
    const response = await axiosInstance.get(authConfig.settingsApiKeyEndpoint, { params: { lang } })

    return response.data
  }
  updateSettingsApiKey = async (data: ApiKeyFormValues): Promise<AxiosResponse<SettingsApiKeyType>> => {
    const response = await axiosInstance.put(authConfig.settingsApiKeyEndpoint, data)

    return response.data
  }
  getDefaultFiatCurrencies = async (): Promise<AxiosResponse<{ currencies: SettingsFiatCurrencyType[] }>> => {
    const response = await axiosInstance.get(`${authConfig.currenciesEndpoint}/fiats`)

    return response.data
  }
  generateTempPasswordAndSendEmail = async () => {
    const response = await axiosInstance.get(`${authConfig.settingsEndpoint}/generate-password`)

    return response.data
  }

  updateDefaultFiatCurrencies = async (currencyId: number): Promise<AxiosResponse<SettingsFiatCurrencyType>> => {
    const response = await axiosInstance.put(`${authConfig.settingsFiatEndpoint}`, { currency_id: currencyId })

    return response.data
  }
}

export const settingsService = new SettingsService()
