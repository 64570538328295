import { Box, Menu } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { MouseEvent, useState } from 'react'
import { TicketOptionsIconButton, TicketOptionsNavigationItem } from '@/shared/ui/one-ticket'
import { paperProps } from './utils'
import { useAppDispatch } from '@/shared/hooks/types'
import { useRouter } from 'next/router'
import { ticketsApi } from '@/shared'
import { closeWithdrawal, getWithdrawalsHistory, getWithdrawalTickets } from '@/app/store/thunks/withdrawal-tickets'
import { WithdrawalTicket } from '@/shared/types/withdrawal-tickets'
import { useTranslation } from 'react-i18next'
import { getTicketsNotifyData } from '@/app/store/thunks/tickets'

type Props = {
  ticket: WithdrawalTicket
}

export const WithdrawalTicketOptionsMenu = ({ ticket }: Props) => {
  /**
   * States
   */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const isTicketClosed = ticket?.status === 'declined'

  /**
   * Variables
   */
  const open = Boolean(anchorEl)

  /**
   * Click handlers
   *
   */
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (event.target === event.currentTarget) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onTicketReopen = async () => {
    await ticketsApi.reopenUserWithdrawal(Number(ticket?.id))
    dispatch(getWithdrawalsHistory({ page: 0 }))
    await router.push('/withdrawal-tickets-history')
    setAnchorEl(null)
  }

  const onTicketClose = async () => {
    const response = await dispatch(closeWithdrawal({ id: ticket?.id }))

    if (response.payload !== undefined) {
      await router.push('/withdrawals-tickets')
      await dispatch(getWithdrawalTickets({ page: 0 }))
      await dispatch(getTicketsNotifyData())
    }
    setAnchorEl(null)
  }

  return (
    <Box sx={{ ml: '16px' }} onClick={e => e.stopPropagation()}>
      <TicketOptionsIconButton
        open={open}
        aria-controls={open ? 'long-menu' : undefined}
        disableRipple
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </TicketOptionsIconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
      >
        {isTicketClosed ? (
          <TicketOptionsNavigationItem onClick={onTicketReopen}>{t('Reopen')}</TicketOptionsNavigationItem>
        ) : null}
        {!isTicketClosed ? (
          <TicketOptionsNavigationItem onClick={onTicketClose}>{t('Decline')}</TicketOptionsNavigationItem>
        ) : null}
      </Menu>
    </Box>
  )
}
