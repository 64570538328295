import Grid from '@mui/material/Grid'
import {
  CrmWalletBalance,
  WalletBalanceAnalytics,
  WalletBalanceLastMonthTransactions,
  WalletBalanceYesterdayTransactions
} from '@/shared'

export const AvailableInWallet = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={8}>
        <CrmWalletBalance />
      </Grid>
      <Grid item xs={12} md={4}>
        <WalletBalanceAnalytics />
      </Grid>
      <Grid item xs={12} md={6}>
        <WalletBalanceYesterdayTransactions />
      </Grid>
      <Grid item xs={12} md={6}>
        <WalletBalanceLastMonthTransactions />
      </Grid>
    </Grid>
  )
}
