import { Box, Button, Pagination } from '@mui/material'
import { styled } from '@mui/system'

export const TicketsBlock = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}))

export const TicketsContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: '32px'
}))

export const TicketsInner = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const TicketsHeading = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  marginRight: '8px'
}))

export const TicketsCount = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  marginRight: '8px'
}))

export const NewTicketButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 125,
  textTransform: 'uppercase',
  padding: '7px 22px',
  fontSize: '14px',
  fontFamily: `'Inter'`,
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['500'],
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  '&:hover': {
    background: '#186ADF'
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '12px'
  }
}))

export const SingleTicketContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '24px 36px',
  gap: '10px',
  width: '100%',
  background: '#292929',
  cursor: 'pointer',
  borderRadius: '12px',
  marginBottom: '24px',
  transition: '.2s ease-in-out',
  '&:hover': {
    background: 'rgba(24, 106, 223, 0.1)'
  },
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px 14px'
  }
}))

export const SingleTicketContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse'
  }
}))

export const SingleTicketInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '100%',
  width: '100%',
  wordBreak: 'break-all'
}))

export const SingleTicketInfoBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start'
  }
}))

export const TicketCreatedAt = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '175%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  marginBottom: '10px'
}))

export const TicketUniqueIdentifier = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '157%',
  fontWeight: theme.palette.fontWeight['500'],
  color: 'rgba(255, 255, 255, 0.75)',
  marginRight: '8px',
  [theme.breakpoints.down('md')]: {
    order: 1
  }
}))

export const TicketName = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  fontFamily: `'Inter'`,
  lineHeight: '133%',
  fontWeight: theme.palette.fontWeight['400'],
  color: '#186ADF',
  marginTop: '2px',
  marginBottom: '10px'
}))

export const TicketLastMessage = styled(Box)<{ $lastMessage?: boolean }>(({ theme, $lastMessage }) => ({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  fontFamily: `'Ubuntu'`,
  lineHeight: '150%',
  width: '100%',
  marginTop: $lastMessage ? '10px' : 0,
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))

export const TicketMessageContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end'
}))

export const SupportImage = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '8px'
}))

export const TicketStatus = styled(Button)(({ theme }) => ({
  padding: '8px 18px',
  gap: '8px',
  fontSize: '15px',
  fontFamily: `'Inter'`,
  lineHeight: '26px',
  fontWeight: theme.palette.fontWeight['500'],
  color: 'rgba(255, 255, 255, 0.75)',
  border: '1px solid rgba(255, 255, 255, 0.75)',
  borderRadius: '8px',
  background: 'transparent',
  '&:hover': {
    background: 'transparent'
  }
}))

export const TicketsPagination = styled(Pagination)(() => ({
  '& > ul': {
    justifyContent: 'center'
  }
}))

export const SingleTicketOptions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    marginBottom: '12px'
  }
}))

export const TicketWithdrawalInfo = styled(Box)(() => ({
  display: 'flex',
  marginRight: '16px'
}))

export const TicketWithdrawalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingBottom: 0
  }
}))

export const TicketWithdrawalName = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '157%',
  letterSpacing: '0.1px',
  marginRight: '4px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const TicketWithdrawalValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '157%',
  letterSpacing: '0.1px',
  color: '#FFFFFF'
}))

export const TicketWithdrawalId = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  color: '#186ADF'
}))
