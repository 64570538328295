import { createAsyncThunk } from '@reduxjs/toolkit'
import { SettingsApiKeyType, SettingsFiatCurrencyType, SettingsQrType, settingsService } from '@/shared'
import { userService } from '@/shared/services/user.service'

export const getTwoFactorQr = createAsyncThunk<SettingsQrType, void>('appSettings/getTwoFactorQr', async () => {
  const response = await userService.getTwoFactorQr()

  return response.data
})

export const getDefaultFiatCurrencies = createAsyncThunk('appSettings/getDefaultFiatCurrencies', async () => {
  const response = await settingsService.getDefaultFiatCurrencies()

  return response.data.currencies
})

export const updateDefaultFiatCurrencies = createAsyncThunk<SettingsFiatCurrencyType, number>(
  'appSettings/updateReferralSettings',
  async currencyId => {
    const response = await settingsService.updateDefaultFiatCurrencies(currencyId)

    return response.data
  }
)

export const getSettingsApiKey = createAsyncThunk<SettingsApiKeyType, string>(
  'appSettings/getSettingsApiKey',
  async lang => {
    const response = await settingsService.getSettingsApiKey(lang)

    return response.data
  }
)
