import { Box, CircularProgress } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import {
  AddStoreForm,
  ApiKeyHeader,
  ApiKeyHeading,
  ApiKeyWrapper,
  FormHeading,
  PlatformAccordionLine,
  PlatformInputGroup,
  PlatformLink,
  SaveChangesButton
} from './styles'
import { FormInput, FormTextInput } from '../../react-hook-form'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { formSchema } from './update-store.schema'
import { useTranslation } from 'react-i18next'
import {
  addServerErrors,
  IStore,
  PlatformCallbackField,
  PlatformDynamicFields,
  settingsService,
  TDynamicFormField,
  UpdateStoreData,
  useAppSelector,
  useModal,
  UserType
} from '@/shared'
import { PlatformsDashboardCopyKey } from '@/shared/ui/platforms/ui/platforms-dashboard-copy-key'
import { useAppDispatch } from '@/shared/hooks/types'
import { getStores, updateStore } from '@/app/store/thunks'
import { storesCurrenciesSelector, userStateSelector } from '@/app/store/selectors'
import toast from 'react-hot-toast'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import { PlatformSelect } from '@/shared/ui/platforms/ui/platform-select'
import { SettingsPlatformsFormModal } from '@/widgets/pages/settings/ui/settings-platforms-form-modal'
import { ClearButton, SentCode } from '@/shared/ui/history/styles'
import AlertTitle from '@mui/material/AlertTitle'

type Props = {
  incomingFormValues: IStore
  openAccordion: () => void
  closeAccordion: () => void
}

export const PlatformsEditForm = ({ incomingFormValues, openAccordion, closeAccordion }: Props) => {
  const { open, handleClose, handleOpen } = useModal()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const currencies = useAppSelector(storesCurrenciesSelector)
  const user = useAppSelector(userStateSelector) as UserType
  const twoFactorEnabled = Boolean(user?.is_two_auth_enabled)
  const [currencyName, setCurrencyName] = useState<string>(currencies[0]?.iso_name ?? 'btc')
  const schema = useMemo(() => formSchema(), [i18n.language])
  const methods = useForm<UpdateStoreData>({
    mode: 'onChange',
    defaultValues: {
      ...incomingFormValues,
      xpub: incomingFormValues?.xpub ?? '',
      binance_address: incomingFormValues?.binance_address ?? '',
      callback_url: incomingFormValues?.callback_url ?? {},
      api_key: incomingFormValues?.api_key ?? {}
    },
    resolver: yupResolver(schema)
  })

  const {
    handleSubmit,
    control,
    setError,
    watch,
    setValue,
    formState: { isSubmitting }
  } = methods
  const values = watch()
  const { sample_receiving_address, binance_address, xpub, api_key } = values
  const storesToken = String(api_key?.[currencyName] || '')

  const valuesToTouch = [
    incomingFormValues?.xpub === xpub,
    incomingFormValues?.sample_receiving_address === sample_receiving_address,
    incomingFormValues?.binance_address === binance_address
  ]
  const isValuesModified = valuesToTouch.some(val => !val)
  const formFields: TDynamicFormField[] = [
    {
      id: 'btc',
      label: 'Bitcoin',
      fields: [
        {
          id: 'xpub',
          label: 'Xpub',
          name: 'xpub',
          placeholder: 'Enter your bitcoin wallet xpub',
          additionalField: {
            name: 'sample_receiving_address',
            label: 'Sample Receiving Address',
            placeholder: 'bc1qqztaf8qddw8v5t9aulsezlqksrhnaad47v5nqg',
            linkLabel: 'Click "receive" in your wallet, copy the address and paste it here.'
          }
        }
      ]
    },
    {
      id: 'bnb',
      label: 'Binance',
      fields: [
        {
          id: 'binance_address',
          label: 'Binance Address',
          name: 'binance_address',
          placeholder: 'Enter your binance address'
        }
      ]
    }
  ]

  const formElements = formFields?.find(field => field.id === currencyName)?.fields
  const grabSelectOption = useCallback((name: string) => setCurrencyName(name), [])
  const generateNewApiKey = () => {
    toast.success(t(`The email with password was successfully sent!`), {
      position: 'bottom-right',
      duration: 4000
    })
    settingsService.generateTempPasswordAndSendEmail()
    handleOpen()
  }
  const onEditStore = async (id: string, data: UpdateStoreData) => {
    const response = await dispatch(updateStore({ data, id, setError }))

    if (response?.payload !== undefined) {
      toast.success(t('Store edited!'), { position: 'bottom-right' })
      closeAccordion()
      dispatch(getStores({}))
    }
  }
  const handleClearButtonClick = () => {
    setValue(`api_key.${currencyName}`, '')
    setValue(`callback_url.${currencyName}`, '')
    formFields.forEach(field => {
      if (field.id === currencyName) {
        const fieldNames = field.fields.map(f => f.name) as (keyof UpdateStoreData)[]
        const additionalFieldNames = field.fields.map(f => f.additionalField?.name) as (keyof UpdateStoreData)[]
        const names = additionalFieldNames.length ? fieldNames.concat(additionalFieldNames) : fieldNames
        names.forEach(name => setValue(name, ''))
      }
    })
  }

  const onSubmit: SubmitHandler<UpdateStoreData> = async fields => {
    try {
      await onEditStore(String(incomingFormValues?.id), fields)
    } catch (e: any) {
      const errors = e?.response?.data?.errors
      addServerErrors<UpdateStoreData>(errors, setError)
    }
  }
  const onSentCode = async () => {
    await settingsService.generateTempPasswordAndSendEmail()
    toast.success(t('The email with password was successfully sent!'), { position: 'bottom-right' })
  }

  useLayoutEffect(() => {
    if (incomingFormValues) {
      for (const [key, value] of Object.entries(incomingFormValues ?? {})) {
        setValue(key as keyof Omit<IStore, 'id' | 'name'>, value)
      }
    }
  }, [incomingFormValues, setValue])

  return (
    <Box>
      <PlatformAccordionLine />
      <FormProvider {...methods}>
        <AddStoreForm onSubmit={handleSubmit(onSubmit)}>
          <PlatformInputGroup>
            <FormInput
              name='tag'
              fullWidth
              isPlatform
              disableMargin
              control={control}
              label={t('Tag')}
              inputProps={{ maxLength: 20 }}
              placeholder={t('Any identification label')}
            />
          </PlatformInputGroup>
          <ApiKeyWrapper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormHeading>{t('Destination wallet')}</FormHeading>
              <PlatformSelect grabSelectOption={grabSelectOption} />
            </Box>
            {api_key?.[currencyName] !== '' && (
              <Box>
                <ApiKeyHeader>
                  <ApiKeyHeading>{t('API Key')}</ApiKeyHeading>
                </ApiKeyHeader>
                <Box sx={{ mb: '24px' }}>
                  <PlatformsDashboardCopyKey storesToken={storesToken} />
                  <PlatformLink onClick={generateNewApiKey}>{t('Generate new API')}</PlatformLink>
                </Box>
              </Box>
            )}
          </ApiKeyWrapper>
          <PlatformDynamicFields formElements={formElements} />
          <PlatformCallbackField currencyName={currencyName} />
          {isValuesModified ? (
            <Box>
              <Collapse in={isValuesModified}>
                <Alert severity='info' sx={{ mb: 2 }}>
                  <AlertTitle>{t('Confirm saving changes')}</AlertTitle>
                  {t(
                    'To save the changes, send to your email and enter the secret code, and if enabled, the 2-factor authentication code.'
                  )}
                </Alert>
              </Collapse>
              <Box sx={{ mt: 2 }}>
                <SentCode onClick={onSentCode} variant='outlined'>
                  {t('Send code')}
                </SentCode>
                <PlatformInputGroup sx={{ mt: 4 }}>
                  <FormTextInput
                    sx={{ maxWidth: '361px', mb: '5px' }}
                    name='admin_password'
                    control={control}
                    label={`${t('Secret code')}*`}
                    placeholder={t('Enter secret code')}
                  />
                </PlatformInputGroup>
                <PlatformInputGroup>
                  {twoFactorEnabled ? (
                    <FormTextInput
                      sx={{ maxWidth: '361px', mb: '5px', mt: '15px' }}
                      type='number'
                      name='2fa_code'
                      control={control}
                      label={`${t('2-FA code')}*`}
                      inputProps={{ maxLength: 8 }}
                      placeholder={t('Enter 2-FA code')}
                    />
                  ) : null}
                </PlatformInputGroup>
              </Box>
            </Box>
          ) : null}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <SaveChangesButton variant='contained' disableTouchRipple type={'submit'} disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress color='inherit' size={20} /> : t('Save changes')}
            </SaveChangesButton>
            <Box sx={{ textAlign: 'end' }}>
              <ClearButton variant='text' sx={{ m: 0 }} onClick={handleClearButtonClick}>
                {t('Clear')}
              </ClearButton>
            </Box>
          </Box>
        </AddStoreForm>
      </FormProvider>
      <SettingsPlatformsFormModal
        open={open}
        incomingFormValues={incomingFormValues}
        openAccordion={openAccordion}
        handleClose={handleClose}
      />
    </Box>
  )
}
