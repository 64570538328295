// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseParams, BaseReqError, BaseState, IMeta } from '@/app/store/types'
import {
  CloseWithdrawalTicketArgs,
  ReplyWithdrawalTicketArgs,
  WithdrawalAddressResponse,
  WithdrawalGetData,
  WithdrawalTicket
} from '@/shared/types/withdrawal-tickets'
import {
  closeWithdrawal,
  getSingleWithdrawal,
  getWithdrawalsHistory,
  getWithdrawalTickets,
  replyInWithdrawal,
  withdrawalsToAddress
} from '@/app/store/thunks/withdrawal-tickets'
import { TickerDataType, tokenService } from '@/shared'

type WithdrawalTicketsState = BaseState<{
  list: WithdrawalTicket[]
  history: WithdrawalTicket[]
  withdrawal: WithdrawalTicket | null
  meta: IMeta | null
  reqErrors: BaseReqError | null
  params: BaseParams
}>

const initialState: WithdrawalTicketsState = {
  data: {
    list: [],
    history: [],
    meta: null,
    withdrawal: null,
    reqErrors: null,
    params: { page: 1 }
  },
  error: '',
  status: 'idle'
}

export const withdrawalTicketsSlice = createSlice({
  name: 'userPayment',
  initialState,
  reducers: {
    setWithdrawalRequestParams(state: WithdrawalTicketsState, action: PayloadAction<BaseParams>) {
      state.data.params = action.payload
    },
    setRequestErrors(state: WithdrawalTicketsState, action: PayloadAction<BaseReqError>) {
      state.data.reqErrors = action.payload
    },
    clearRequestErrors(state: WithdrawalTicketsState) {
      state.data.reqErrors = null
    }
  },
  extraReducers: builder => {
    builder.addCase(getWithdrawalTickets.pending, (state: WithdrawalTicketsState) => {
      state.status = 'pending'
    })
    builder.addCase(getSingleWithdrawal.pending, (state: WithdrawalTicketsState) => {
      state.status = 'pending'
    })
    builder.addCase(getWithdrawalsHistory.pending, (state: WithdrawalTicketsState) => {
      state.status = 'pending'
    })
    builder.addCase(withdrawalsToAddress.pending, (state: WithdrawalTicketsState) => {
      state.status = 'pending'
    })
    builder.addCase(replyInWithdrawal.pending, (state: WithdrawalTicketsState) => {
      state.status = 'pending'
    })
    builder.addCase(replyInWithdrawal.rejected, (state: WithdrawalTicketsState) => {
      state.status = 'rejected'
    })
    builder.addCase(getSingleWithdrawal.rejected, (state: WithdrawalTicketsState) => {
      state.status = 'rejected'
    })
    builder.addCase(getWithdrawalsHistory.rejected, (state: WithdrawalTicketsState) => {
      state.status = 'rejected'
    })
    builder.addCase(withdrawalsToAddress.rejected, (state: WithdrawalTicketsState) => {
      state.status = 'rejected'
    })
    builder.addCase(closeWithdrawal.rejected, (state: WithdrawalTicketsState) => {
      state.status = 'rejected'
    })
    builder.addCase(
      getWithdrawalTickets.fulfilled,
      (state: WithdrawalTicketsState, action: PayloadAction<WithdrawalGetData>) => {
        state.data.list = action.payload.tickets
        state.data.meta = action.payload.meta
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      getSingleWithdrawal.fulfilled,
      (state: WithdrawalTicketsState, action: PayloadAction<WithdrawalTicket>) => {
        state.data.withdrawal = action.payload
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      getWithdrawalsHistory.fulfilled,
      (state: WithdrawalTicketsState, action: PayloadAction<WithdrawalGetData>) => {
        state.data.history = action.payload.tickets
        state.data.meta = action.payload.meta
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      withdrawalsToAddress.fulfilled,
      (state: WithdrawalTicketsState, action: PayloadAction<WithdrawalAddressResponse>) => {
        state.data.withdrawal = action.payload.withdrawal
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      closeWithdrawal.fulfilled,
      (state: WithdrawalTicketsState, action: PayloadAction<CloseWithdrawalTicketArgs>) => {
        state.data.list = state.data.list.filter(item => item.id !== action.payload?.id)
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      replyInWithdrawal.fulfilled,
      (state: WithdrawalTicketsState, action: PayloadAction<ReplyWithdrawalTicketArgs>) => {
        const data = action.payload?.data as TickerDataType
        const user = tokenService.getUser()
        const newMessage = {
          id: Number(data?.id),
          author: user?.email ?? '',
          user: {
            id: Number(user?.id),
            email: user?.email || '',
            is_admin: false
          },
          is_admin: false,
          message: data?.message ?? '',
          files: data?.files ?? [],
          created_at: new Date().toDateString()
        }
        state.data.list = state.data.list.map(ticket =>
          ticket.id === action.payload.id ? { ...ticket, messages: ticket.messages.concat(newMessage) } : ticket
        )
      }
    )
  }
})

const { reducer, actions } = withdrawalTicketsSlice

export const { setWithdrawalRequestParams, setRequestErrors, clearRequestErrors } = actions
export default reducer
