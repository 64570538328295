/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { SelectProps } from '@mui/material'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'

import { PropsWithChildren } from 'react'
import { FundsWithdrawalPlaceholder, FundsWithdrawalSelect, FundsWithdrawalSelectLabel } from '@/shared'

interface FormSelectProps extends SelectProps {
  control?: Control<any> | undefined
  fullWidth?: boolean
  disableMargin?: boolean
  name: string
}

export const FundsWithdrawalSelectCoin = ({
  control,
  children,
  placeholder,
  label = '',
  name
}: PropsWithChildren<FormSelectProps>) => {
  const labelId = `${name}-label`

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, formState: { errors } }) => (
        <Box>
          <FundsWithdrawalSelectLabel sx={{ color: errors && errors[name] && 'error.main' }}>
            {label}
          </FundsWithdrawalSelectLabel>
          <FundsWithdrawalSelect
            {...field}
            displayEmpty
            error={Boolean(errors[name])}
            autoComplete='off'
            defaultValue={''}
            renderValue={
              field.value !== ''
                ? undefined
                : () => <FundsWithdrawalPlaceholder>{placeholder}</FundsWithdrawalPlaceholder>
            }
            labelId={labelId}
          >
            {children}
          </FundsWithdrawalSelect>
          {errors.hasOwnProperty(name) && errors[name] && (
            <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
              {errors[name]?.message as string}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  )
}
