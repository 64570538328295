import * as styles from '@mui/material/styles'
import * as mui from '@mui/material'

export const NewPasswordWrapper = styles.styled(mui.Box)<mui.BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 1200,
  margin: '0 auto',
  alignItems: 'center',
  marginTop: '80px',
  [theme.breakpoints.down('md')]: {
    marginTop: 0
  }
}))

export const NewPasswordInner = mui.styled(mui.Box)(() => ({
  maxWidth: 584,
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  marginTop: '10.5%',
  marginBottom: '15%'
}))
