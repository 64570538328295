import { Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import {
  Countdown,
  NotVerifiedEmailDialogBody,
  NotVerifiedEmailDialogEmail,
  NotVerifiedEmailDialogTitle,
  NotVerifiedEmailForm,
  NotVerifiedEmailModalToolbar
} from '@/shared'

type Props = {
  readonly open: boolean
  readonly handleClose: () => void
  readonly countdown: Countdown
}

export const NotVerifiedEmailModal = ({ open, handleClose, countdown }: Props) => {
  const { t } = useTranslation()

  return (
    <div>
      <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose}>
        <NotVerifiedEmailModalToolbar>
          <IconButton edge='end' color='inherit' onClick={handleClose} sx={{ mr: 0 }}>
            <CloseIcon />
          </IconButton>
        </NotVerifiedEmailModalToolbar>
        <NotVerifiedEmailDialogBody>
          <NotVerifiedEmailDialogTitle>{t('Enter the email address to send the email to')}</NotVerifiedEmailDialogTitle>
          <NotVerifiedEmailDialogEmail>
            <NotVerifiedEmailForm countdown={countdown} />
          </NotVerifiedEmailDialogEmail>
        </NotVerifiedEmailDialogBody>
      </Dialog>
    </div>
  )
}
