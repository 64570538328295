import { useFormContext } from 'react-hook-form'
import { FormInput } from '../../react-hook-form'
import { PlatformInputGroup } from './styles'
import { useTranslation } from 'react-i18next'
import { CreateStoreData } from '@/shared'

export const PlatformCommonFields = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<CreateStoreData>()

  return (
    <>
      <PlatformInputGroup>
        <FormInput
          name='name'
          fullWidth
          sx={{ maxWidth: 'inherit' }}
          labelSx={{ width: '100%' }}
          isPlatform
          disableMargin
          control={control}
          label={t('Store name')}
          inputProps={{ maxLength: 20 }}
          placeholder={t('Enter store name')}
        />
      </PlatformInputGroup>
      <PlatformInputGroup>
        <FormInput
          name='tag'
          fullWidth
          sx={{ maxWidth: 'inherit' }}
          labelSx={{ width: '100%' }}
          isPlatform
          disableMargin
          control={control}
          inputProps={{ maxLength: 20 }}
          label={t('Tag')}
          placeholder={t('Any identification label')}
        />
      </PlatformInputGroup>
    </>
  )
}
