/**
 * Redux Imports
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HeaderLinkType, LayoutFooterColumnsType, LayoutHeaderType } from '@/shared'
import { BaseState } from '@/app/store/types'
import { getLayout } from '@/app/store/thunks'

type LayoutState = {
  header: LayoutHeaderType
  footer: LayoutFooterColumnsType | null
  logo: string
}

type LayoutSliceState = BaseState<LayoutState>

/**
 * Initial support-tickets store
 */
const initialState: LayoutSliceState = {
  data: {
    header: [],
    footer: null,
    logo: ''
  },
  error: '',
  status: 'idle'
}

/**
 * Tickets slice
 */
export const layoutSlice = createSlice({
  name: 'apps/layout',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLayout.pending, (state: LayoutSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(
      getLayout.fulfilled,
      (
        state: LayoutSliceState,
        action: PayloadAction<{ header: HeaderLinkType[]; footer: LayoutFooterColumnsType; logo: string }>
      ) => {
        state.data.header = action.payload.header
        state.data.footer = action.payload.footer
        state.data.logo = action.payload.logo
        state.status = 'fulfilled'
        state.error = ''
      }
    )
  }
})

const { reducer } = layoutSlice
export default reducer
