import { styled } from '@mui/system'

export const InfoModuleCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 500px',
  maxWidth: '546px',
  padding: '24px',
  background: '#292929',
  borderRadius: '16px',
  [theme.breakpoints.down('lg')]: {
    flex: '1 1 400px'
  },
  [theme.breakpoints.down('md')]: {
    flex: '1 1 100%',
    width: '100%',
    maxWidth: 'auto'
  }
}))

export const InfoModuleCardImage = styled('div')(({ theme }) => ({
  borderRadius: '4px',
  minHeight: '272px',
  width: '100%',
  marginBottom: '12px',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover'
    }
  }
}))

export const InfoModuleCardTitle = styled('div')(() => ({
  fontSize: '24px',
  lineHeight: '32px',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  color: '#FFFFFF',
  '& > *': {
    margin: '12px 0'
  },
  '& > p': {
    margin: '6px 0'
  }
}))

export const InfoModuleCardDescription = styled('p')(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '.15px',
  color: 'rgba(255, 255, 255, 0.75)',
  marginBottom: '12px'
}))

export const SinglePageModuleLoader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  marginLeft: '10px',
  lineHeight: '20px',
  letterSpacing: '.15px',
  fontWeight: theme.palette.fontWeight['500'],
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const InfoModuleCardButton = styled('a')(() => ({
  fontSize: '13px',
  lineHeight: '22px',
  letterSpacing: '.46px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: '#FFFFFF',
  cursor: 'pointer',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  width: 'max-content',
  display: 'flex',
  alignItems: 'center',
  padding: '4px 11px 4px 13px',
  border: 'unset',
  transition: '.25s',
  svg: {
    marginLeft: '8px'
  },
  '&:hover': {
    background: '#1B76F7',
    color: '#FCFCFC',
    boxShadow: '0px 6px 8px rgba(24, 106, 223, 0.35)',
    transition: '.25s'
  }
}))

export const InfoModuleCardText = styled('div')(() => ({}))

export const SinglePageInfoModuleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  rowGap: '24px',
  flexWrap: 'wrap',
  marginBottom: '24px',
  columnGap: '24px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}))
