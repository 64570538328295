import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  addServerErrors,
  CreateStoreData,
  IStore,
  SliceStoreData,
  storesService,
  SuccessfulStoreCreation,
  UpdateStoreArgs,
  UpdateStoreData,
  UpdateStoreTagArgs
} from '@/shared'
import { HookFormError } from '@/shared/types/form'
import toast from 'react-hot-toast'
import i18n from 'i18next'

type StoreCurrencyArgs = { currencyId?: string }
export const getStores = createAsyncThunk<SliceStoreData, StoreCurrencyArgs>(
  'storesData/getStores',
  async ({ currencyId }) => {
    const response = await storesService.getStores(currencyId)

    return response.data
  }
)

export const createStore = createAsyncThunk<
  SuccessfulStoreCreation,
  CreateStoreData & Partial<{ setError?: HookFormError<CreateStoreData> }>
>('storesData/createStore', async ({ setError, ...data }) => {
  try {
    const response = await storesService.createStore(data)

    return response.data
  } catch (e: any) {
    if ('errorMessage' in e) {
      toast.error(i18n.t('Balance is negative. Api access is closed'), { position: 'bottom-right' })
    }
    const errors = 'errors' in e?.response?.data ? e?.response?.data?.errors : e?.response?.data?.error

    addServerErrors<CreateStoreData>(errors, setError!)
  }
})

export const refreshApiKey = createAsyncThunk<IStore, string>('storesData/refreshApiKey', async id => {
  const response = await storesService.refreshApiKey(id)

  return response.data
})

export const updateStoreName = createAsyncThunk(
  'storesData/updateStoreTag',
  async ({ id, name }: UpdateStoreTagArgs) => {
    const response = await storesService.updateStoreName(id, name)

    return response.data
  }
)

export const updateStore = createAsyncThunk(
  'storesData/updateStore',
  async ({ data, id, setError }: UpdateStoreArgs & { setError?: HookFormError<UpdateStoreData> }) => {
    const response = await storesService.updateStore(data, id).catch(e => {
      console.log('e', e)
      const errors = 'errors' in e?.response?.data ? e?.response?.data?.errors : e?.response?.data?.error
      addServerErrors<UpdateStoreData>(errors, setError!)
    })

    return response.data
  }
)

export const deleteStore = createAsyncThunk('storesData/deleteStore', async ({ id }: { id: string }) => {
  const response = await storesService.deleteStore(id)

  return response.data
})

export const getStoreCurrencies = createAsyncThunk('storesData/getStoreCurrencies', async () => {
  const response = await storesService.getStoreCurrencies()

  return response.data.currencies
})
