import { PlatformMenuItem, PlatformOptionMenu, PlatformOptionMenuIconButton, SelectedPlatform } from './styles'
import { Fragment, memo, SyntheticEvent, useEffect, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { storesCurrenciesSelector } from '@/app/store/selectors'
import { StoreCurrencyType, useAppSelector } from '@/shared'

type Props = {
  grabSelectOption?: (name: string) => void
  currencyId?: number
  selectedCurrency?: StoreCurrencyType | undefined
}

export const PlatformSelect = memo(({ grabSelectOption, selectedCurrency }: Props) => {
  const currencies = useAppSelector(storesCurrenciesSelector)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [selectedPlatform, setSelectedPlatform] = useState<string>('BTC')

  useEffect(() => {
    if (selectedCurrency) {
      setSelectedPlatform(selectedCurrency?.name)
    }
  }, [selectedCurrency])

  const handleLangDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePlatformSelect = (p: string) => {
    setSelectedPlatform(p)
    handleLangDropdownClose()
  }

  const handleLangDropdownClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  const DropdownArrow = isOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon

  return (
    <Fragment>
      {currencies.length > 0 ? (
        <Box>
          <PlatformOptionMenuIconButton
            disableRipple
            disableFocusRipple
            open={isOpen}
            disableTouchRipple
            onClick={handleLangDropdownOpen}
          >
            <SelectedPlatform>{selectedPlatform?.toLocaleUpperCase()}</SelectedPlatform>
            <DropdownArrow sx={{ color: '#186ADF' }} />
          </PlatformOptionMenuIconButton>
          <PlatformOptionMenu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleLangDropdownClose}
            defaultValue={currencies?.[0]?.name}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {currencies.map(option => (
              <PlatformMenuItem
                key={option.name}
                disableRipple
                defaultValue={''}
                disableTouchRipple
                selected={selectedPlatform === option.name}
                onClick={() => {
                  handlePlatformSelect(option.name)
                  grabSelectOption && grabSelectOption(option?.name?.toLowerCase())
                }}
              >
                {option.name}
              </PlatformMenuItem>
            ))}
          </PlatformOptionMenu>
        </Box>
      ) : (
        <Chip label={t('none')} variant='outlined' />
      )}
    </Fragment>
  )
})
