import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { Button } from '@mui/material'

export const SubmitTicketWrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  justifyContent: 'center',
  maxWidth: 1200
}))

export const SubmitTicketBoxWrapper = styled(Box)<BoxProps>(() => ({
  height: '56.5%',
  width: '100%',
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignItems: 'flex-start'
}))

export const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

export const SubmitTicketForm = styled('form')(() => ({
  maxWidth: 481,
  width: '100%',
  paddingTop: '14px'
}))

export const SubmitTicketInner = styled(Box)(() => ({
  display: 'flex',
  height: '80%',
  flex: 1,
  flexDirection: 'column'
}))

export const SubmitTickerFormButton = styled(Button)(({ theme }) => ({
  background: '#186ADF',
  fontFamily: `'Ubuntu'`,
  padding: '10px 57px !important',
  borderRadius: '12px',
  maxWidth: 173,
  width: '100%',
  marginRight: '32px',
  '&&&': {
    '&:hover': {
      background: '#1B76F7'
    },
    '&.Mui-disabled': {
      '&:disabled': {
        background: '#186ADF',
        opacity: 0.5,
        color: theme.palette.common.white,
        cursor: 'not-allowed',
        pointerEvents: 'none !important'
      }
    }
  }
}))

export const SubmitTickerCancelButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  background: 'transparent',
  maxWidth: 173,
  width: '100%',
  padding: '10px 57px !important',
  border: '2.5px solid #292929',
  color: '#FCFCFC',
  fontWeight: theme.palette.fontWeight['700'],
  borderRadius: '12px',
  '&&&': {
    '&.Mui-disabled': {
      '&:disabled': {
        background: '#186ADF',
        opacity: 0.5,
        color: theme.palette.common.white,
        cursor: 'not-allowed',
        pointerEvents: 'none !important'
      }
    }
  }
}))

export const UploadFilesButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  background: 'transparent',
  textTransform: 'uppercase',
  minWidth: 202,
  padding: '10px 30.0px !important',
  marginBottom: '36px',
  border: '2.5px solid #292929',
  color: '#FCFCFC',
  fontWeight: theme.palette.fontWeight['700'],
  borderRadius: '12px',
  '&&&': {
    '&:hover': {
      background: 'transparent'
    },
    '&.Mui-disabled': {
      '&:disabled': {
        background: '#186ADF',
        opacity: 0.5,
        color: theme.palette.common.white,
        cursor: 'not-allowed',
        pointerEvents: 'none !important'
      }
    }
  }
}))
