// ** MUI Imports
import Box from '@mui/material/Box'

// ** Type Import
import { Settings } from '@/app/context/settings-context'

// ** Components
import UserDropdown from '@/shared/ui/user-dropdown/user-dropdown'

//** react & hooks
import { AppBarContentContainer } from './styles'
import { DashboardMenuLogo, LanguageDropdown } from '@/shared/layouts'
import { LayoutBurgerMenu } from '@/shared/ui/layout-burger-menu'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings } = props

  return (
    <AppBarContentContainer>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? <DashboardMenuLogo collapsed={false} /> : null}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        <LanguageDropdown isDashboard sx={{ mr: 5 }} />
        {hidden ? <LayoutBurgerMenu isDashboard /> : <UserDropdown settings={settings} />}
      </Box>
    </AppBarContentContainer>
  )
}

export default AppBarContent
