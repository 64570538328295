import { createAsyncThunk } from '@reduxjs/toolkit'
import { addServerErrors, ticketApi, ticketsApi } from '@/shared'
import { WithdrawalMessageValues } from '@/shared/ui/withdrawal-one-ticket'
import { BaseParams } from '@/app/store/types'
import { clearRequestErrors, setRequestErrors } from '@/app/store/apps/withdrawal-tickets'
import {
  CloseWithdrawalTicketArgs,
  FundsWithdrawalArgs,
  ReplyWithdrawalTicketArgs,
  WithdrawalAddressResponse,
  WithdrawalGetData,
  WithdrawalTicket
} from '@/shared/types/withdrawal-tickets'

export const getWithdrawalTickets = createAsyncThunk<WithdrawalGetData, BaseParams>(
  'user/getWithdrawalTickets',
  async params => {
    const { data } = await ticketsApi.getWithdrawalTickets(params)

    return data
  }
)

export const getWithdrawalsHistory = createAsyncThunk<WithdrawalGetData, BaseParams>(
  'ticket/getWithdrawalsHistory',
  async params => {
    const response = await ticketApi.getWithdrawalsHistory(params)

    return response.data
  }
)

export const getSingleWithdrawal = createAsyncThunk<WithdrawalTicket, string>(
  'ticket/getSingleWithdrawal',
  async id => {
    const response = await ticketsApi.getWithdrawal(id)

    return response.data.ticket
  }
)

export const withdrawalsToAddress = createAsyncThunk<WithdrawalAddressResponse, FundsWithdrawalArgs>(
  'user/withdrawalsToAddress',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ticketsApi.withdrawalsToAddress(data)

      return response.data
    } catch (err: any) {
      dispatch(clearRequestErrors())
      if (!err.response) {
        throw err
      }
      const rejectedValue = err?.response?.data
      const errors = rejectedValue?.error

      dispatch(setRequestErrors(errors))

      return rejectWithValue(rejectedValue)
    }
  }
)

export const closeWithdrawal = createAsyncThunk('user/closeWithdrawal', async ({ id }: CloseWithdrawalTicketArgs) => {
  const { data } = await ticketsApi.closeWithdrawal(id)

  return data
})

export const replyInWithdrawal = createAsyncThunk(
  'user/replyInWithdrawal',
  async ({ id, data, setError }: ReplyWithdrawalTicketArgs) => {
    try {
      const response = await ticketsApi.replyInWithdrawal(id, data)

      return response.data
    } catch (e: any) {
      const errors = e?.response?.data
      addServerErrors<WithdrawalMessageValues>(errors, setError)
      throw new Error(e)
    }
  }
)
