import i18n from 'i18next'

function addServerErrors<T>(
  errors: { [P in keyof T]?: string | string[] },
  setError: (fieldName: keyof T, error: { type: string; message: any }) => void
) {
  return (
    errors &&
    Object.keys(errors).forEach(key => {
      setError(key as keyof T, {
        type: key,
        message: Array.isArray(errors[key as keyof T])
          ? i18n.t((errors[key as keyof T] as string[]).join(''))
          : i18n.t(errors[key as keyof T] as string)
      })
    })
  )
}

export { addServerErrors }
