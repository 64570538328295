import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

const createNoopStorage = () => {
  return {
    getItem(_key: string) {
      console.info(_key)

      return Promise.resolve(null)
    },
    setItem(_key: string, value: unknown) {
      return Promise.resolve(value)
    },
    removeItem(_key: string) {
      console.info(_key)

      return Promise.resolve()
    }
  }
}

export const persistStorage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage()
