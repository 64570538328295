import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  EmptyTableTitle,
  ExportHistoryButton,
  PaymentsHistoryTableFooter,
  PaymentsTableAdditional,
  PaymentsTableContainer,
  PaymentsTableLoader,
  PaymentsTableName,
  PaymentsTablePaper,
  PaymentsTablePreset,
  TableFooterSelect,
  TableInfoExport,
  TableRowsPerPage
} from './styles'
import { PaymentsFiltersModal, PaymentsTableHead } from '@/shared/ui'
import { useTranslation } from 'react-i18next'
import { DateTimeFormat, Order, PaymentsTableData, useAppDispatch, useAppSelector } from '@/shared'
import { historyLoadingSelector, historyStateSelector } from '@/app/store/selectors/history.selectors'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { userService } from '@/shared/services/user.service'
import { setDefaultFilters, setRequestParams } from '@/app/store/apps/history'
import { getUserHistory } from '@/app/store/thunks/history'
import { useRouter } from 'next/router'
import * as _ from 'lodash'
import dayjs from 'dayjs'
import { SelectChangeEvent } from '@mui/material/Select'
import { MenuItem } from '@mui/material'
import { SvgChart } from '@/shared/ui/history/PaymentTransactionChartModalHeader'
import { PaymentTransactionChartModal } from '@/shared/ui/history/PaymentTransactionChartModal'

export const PaymentsHistoryTable = () => {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PaymentsTableData>('created_at')
  const router = useRouter()
  const { t } = useTranslation()
  const { history, filters, params } = useAppSelector(historyStateSelector)
  const [exportFormat, setExportFormat] = useState('csv')
  const loading = useAppSelector(historyLoadingSelector)
  const count = Number(history?.meta?.total)
  const dispatch = useAppDispatch()

  const [paymentTransactionChartStoreId, setPaymentTransactionChartModalStoreId] = useState<number | null>(null)

  const exportOptions = ['csv', 'xls', 'xlsx']
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setExportFormat(event.target.value as string)
  }

  const historyRows = (history?.items || [])?.map(item => ({
    ...item,
    name: item?.preset,
    id: item?.id,
    created_at: item?.created_at,
    tag: item?.tag,
    blockchain: item?.currency?.name,
    amount: `${item?.fiat_sum} ${item?.fiat_sign}`,
    sum: item?.sum,
    store_id: item?.store_id
  }))

  const rows = useMemo(() => historyRows, [historyRows])
  const [selected, setSelected] = useState<readonly string[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(history?.meta?.perPage || 10)

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const historyAvailable = (rows ?? []).length > 0

  useEffect(() => {
    dispatch(
      getUserHistory({
        params: {
          page: Number(params?.page) ?? page,
          per_page: rowsPerPage
        },
        filters: filters ?? {},
        sort: order === 'desc' ? `-${orderBy}` : orderBy
      })
    )
  }, [dispatch, filters, order, orderBy, page, params?.page, rowsPerPage])

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIdx = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIdx === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIdx === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIdx === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIdx > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIdx), selected.slice(selectedIdx + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setRequestParams({ page: newPage, per_page: Number(params?.per_page) }))
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const perPageValue = parseInt(event.target.value, 10)
    dispatch(setRequestParams({ page, per_page: perPageValue }))
    setRowsPerPage(perPageValue)
    setPage(0)
  }

  const resetFilters = useCallback(() => {
    dispatch(setRequestParams({ page: 0 }))
    dispatch(setDefaultFilters())
  }, [dispatch])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isPageReload = window.sessionStorage.getItem('isReloaded')
      if (isPageReload) {
        resetFilters()
      } else {
        window.sessionStorage.setItem('isReloaded', 'true')
      }

      router.events.on('routeChangeComplete', resetFilters)

      return () => {
        router.events.off('routeChangeComplete', resetFilters)
        window.sessionStorage.removeItem('isReloaded')
      }
    }
  }, [dispatch, resetFilters, router.asPath, router.events])

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const exportUserHistory = async () => {
    const res = await userService.getCsvUserHistory(filters ?? {}, exportFormat)
    const href = window.URL.createObjectURL(res.data)
    const headerLine = res.headers['last-modified']
    const lastModified = dayjs(headerLine).format(DateTimeFormat.DAY_MONTH_YEAR_FORMAT)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', `payment-transactions-${lastModified}.${exportFormat}`) //or any other extension
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <PaymentsTablePaper>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <PaymentsTableName>{t('Payments history')}</PaymentsTableName>
          <PaymentsFiltersModal />
          {paymentTransactionChartStoreId != null && (
            <PaymentTransactionChartModal
              storeId={paymentTransactionChartStoreId}
              onClose={() => setPaymentTransactionChartModalStoreId(null)}
            />
          )}
        </Box>
        {loading ? (
          <PaymentsTableLoader>
            <CircularProgress color='inherit' size={30} />
          </PaymentsTableLoader>
        ) : (
          <PaymentsTableContainer>
            {historyAvailable && !loading ? (
              <Table>
                <PaymentsTableHead order={order} orderBy={orderBy as string} onRequestSort={handleRequestSort} />
                <TableBody sx={{ height: '100%', overflow: 'scroll' }}>
                  {_.orderBy(rows || [], undefined, [order]).map(row => {
                    const isItemSelected = isSelected(row?.name as string)

                    return (
                      <>
                        <TableRow
                          hover
                          onClick={event => handleClick(event, String(row?.id))}
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.id}
                          selected={isItemSelected}
                        >
                          <PaymentsTablePreset component='th' scope='row' padding='none'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span
                                style={{ marginRight: '8px' }}
                                onClick={() => row?.store_id && setPaymentTransactionChartModalStoreId(row.store_id)}
                              >
                                <SvgChart />
                              </span>

                              {row?.name}
                            </div>

                            <Box>{row?.storeDeleted ? t('deleted') : null}</Box>
                          </PaymentsTablePreset>
                          <PaymentsTableAdditional>{row?.id}</PaymentsTableAdditional>
                          <PaymentsTableAdditional>{row?.created_at}</PaymentsTableAdditional>
                          <PaymentsTableAdditional>
                            <Chip label={row?.tag} />
                          </PaymentsTableAdditional>
                          <PaymentsTableAdditional>{row?.blockchain}</PaymentsTableAdditional>
                          <PaymentsTableAdditional align='right'>{`${row?.sum} ${row.blockchain} ≈ ${row?.amount}`}</PaymentsTableAdditional>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (
              <EmptyTableTitle sx={{ mt: 2 }}>{t('There is not payments yet...')}</EmptyTableTitle>
            )}
          </PaymentsTableContainer>
        )}
        {historyAvailable && (
          <PaymentsHistoryTableFooter>
            <TableInfoExport>
              <ExportHistoryButton
                sx={{ mr: 3 }}
                variant='contained'
                endIcon={<SaveAltIcon />}
                onClick={exportUserHistory}
              >
                {t('Export History')}
              </ExportHistoryButton>
              <TableFooterSelect value={exportFormat} onChange={handleChange}>
                {exportOptions.map(option => (
                  <MenuItem key={option} value={option} sx={{ minWidth: 80 }}>
                    {option}
                  </MenuItem>
                ))}
              </TableFooterSelect>
            </TableInfoExport>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage={<TableRowsPerPage>{t('Rows per page:')}</TableRowsPerPage>}
              component='div'
              page={Number(params?.page) ?? page}
              count={count}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </PaymentsHistoryTableFooter>
        )}
      </PaymentsTablePaper>
    </Box>
  )
}
