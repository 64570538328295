import { styled, Toolbar } from '@mui/material'
import { Box } from '@mui/system'

export const UserAppToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '1200px',
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-end'
  }
}))

export const UserLogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  minWidth: 238,
  minHeight: 58,
  [theme.breakpoints.down('md')]: {
    minWidth: 160,
    minHeight: 36
  }
}))

export const UserHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-end'
  }
}))
