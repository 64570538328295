import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/app/store'
import { MutableRefObject } from 'react'
import { CancelToken, CancelTokenSource } from 'axios'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

//-------------------------------------------------
// CancelToken
//-------------------------------------------------

export type UseCancelToken = {
  source: SourceRef
  newCancelToken: () => CancelToken
  cancelPreviousRequest: (message?: string) => void
  isCancel: (error: any) => boolean
}

export type SourceRef = MutableRefObject<CancelTokenSource | null>

export type { CancelToken } from 'axios'

//-------------------------------------------------
// AbortController
//-------------------------------------------------

export type ControllerRef = MutableRefObject<AbortController | null>

export type UseAbortController = {
  controller: ControllerRef
  newAbortSignal: () => AbortSignal
  cancelPreviousRequest: () => void
  isCancel: (error: any) => boolean
}
