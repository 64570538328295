export const defaultIntOptions = {
  timeZone: 'UTC',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
} as const

export const intlFormat = (isoDate: string, locales: string | string[] = 'uk') =>
  new Intl.DateTimeFormat(locales, defaultIntOptions).format(new Date(isoDate))
