import {
  HistorySupportMessageBody,
  HistorySupportMessageName,
  HistorySupportMessageSender,
  HistorySupportMessageTime,
  HistorySupportMessageWrapper
} from './styles'
import { DateTimeFormat, ITicketMessageType, ticketsApi, useHover, UserSettingsIcon } from '@/shared'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { memo, useCallback } from 'react'
import { ImagePreview, ImagePreviewBody, ImagePreviewText } from '@/shared/ui/one-ticket'
import IconButton from '@mui/material/IconButton'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import dayjs from 'dayjs'

type Props = Readonly<{
  supportMessage: ITicketMessageType
}>

export const HistoryTicketYourMessage = memo(({ supportMessage }: Props) => {
  const { mouseOver, mouseOut, hovered } = useHover()
  const { t, i18n } = useTranslation()
  const locale = i18n.language === 'ukr' ? 'uk' : 'en'
  const time = dayjs(supportMessage?.created_at, { locale }).utcOffset(0).format(DateTimeFormat.TICKET_FORMAT)

  const handleDownload = useCallback(async (url: string, filename: string) => {
    await ticketsApi.downloadChatAttachments(url, filename)
  }, [])

  const renderTicketImagePreview = useCallback(
    (attach: unknown, key: string) => {
      const text = String(attach).split('/').at(-1)
      const iconSx = { color: '#186ADF', height: 24, width: 24 }

      return (
        <ImagePreview>
          <ImagePreviewBody key={key} data-key={key} onMouseOver={mouseOver} onMouseOut={mouseOut}>
            <IconButton sx={{ mr: '8px' }}>
              {hovered[key] ? (
                <FileDownloadIcon
                  sx={iconSx}
                  onClick={() => handleDownload(attach as string, String(attach).split('/').at(-1) as string)}
                />
              ) : (
                <InsertDriveFileIcon sx={iconSx} />
              )}
            </IconButton>
            <ImagePreviewText>{text}</ImagePreviewText>
          </ImagePreviewBody>
        </ImagePreview>
      )
    },
    [handleDownload, hovered, mouseOut, mouseOver]
  )

  const attachmentObj = supportMessage?.files?.map((attach, i) => ({ [`key-${i}`]: attach }))
  const entries = Object.entries(attachmentObj || {})
  const historyAttachedFiles = entries.map(([, value]) =>
    renderTicketImagePreview(Object.values(value)[0], Object.keys(value)[0])
  )

  return (
    <HistorySupportMessageWrapper>
      <HistorySupportMessageSender>
        <UserSettingsIcon
          sx={{ fontSize: '40px', cursor: 'pointer', marginRight: '10px' }}
          fillColor={supportMessage.is_admin ? '#424242' : '#186ADF'}
        />
        <HistorySupportMessageName>{t(supportMessage.is_admin ? 'Support' : 'You')}:</HistorySupportMessageName>
      </HistorySupportMessageSender>
      <HistorySupportMessageBody is_admin={supportMessage.is_admin}>
        {supportMessage.message}
        {historyAttachedFiles.length !== 0 && <Box sx={{ mt: '10px' }}>{historyAttachedFiles}</Box>}
      </HistorySupportMessageBody>
      <HistorySupportMessageTime>{time}</HistorySupportMessageTime>
    </HistorySupportMessageWrapper>
  )
})
