import { NoTicketFound, OneTicketMessagesLoader, StartConversationText, SupportChatWrapper } from './styles'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useAppSelector } from '@/shared/hooks/types'
import { withdrawalTicketsLoadingSelector, withdrawalTicketsStateSelector } from '@/app/store/selectors'
import { useLayoutEffect, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { WithdrawalSendSupportMessage } from './withdrawal-send-support-message'
import { WithdrawalSupportChatYourMessage } from './withdrawal-support-chat-your-message'

export const WithdrawalTicketSupportChat = () => {
  const loading = useAppSelector(withdrawalTicketsLoadingSelector)
  const router = useRouter()
  const { id } = router.query
  const { t } = useTranslation()
  const tickets = useAppSelector(withdrawalTicketsStateSelector)
  const ticket = tickets.list.find(ticket => String(ticket.id) === id)
  const messages = ticket?.messages ?? []

  const scrollRef = useRef<HTMLElement | null>(null)

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }
  }

  const isMessagesAvailable = messages?.length > 0

  useLayoutEffect(scrollToBottom, [scrollToBottom])

  const supportMessages = isMessagesAvailable ? (
    messages?.map(supportMessage => (
      <Box key={supportMessage.id}>
        <WithdrawalSupportChatYourMessage supportMessage={supportMessage} />
      </Box>
    ))
  ) : (
    <NoTicketFound>
      <StartConversationText>
        {t('You can start conversation with support if you have some questions')}
      </StartConversationText>
    </NoTicketFound>
  )

  return (
    <SupportChatWrapper $nomessages={!isMessagesAvailable}>
      <PerfectScrollbar
        containerRef={el => {
          scrollRef.current = el
        }}
      >
        {loading ? (
          <OneTicketMessagesLoader>
            <CircularProgress color='inherit' size={40} />
          </OneTicketMessagesLoader>
        ) : (
          <Box>{supportMessages}</Box>
        )}
      </PerfectScrollbar>
      <WithdrawalSendSupportMessage />
    </SupportChatWrapper>
  )
}
