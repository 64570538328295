import { styled, Toolbar } from '@mui/material'
import { Box } from '@mui/system'

export const AppToolbar = styled(Toolbar)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '1200px',
  width: '100%',
  margin: '0 auto'
}))

export const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  minWidth: 238,
  minHeight: 58,
  [theme.breakpoints.down('md')]: {
    minWidth: 160,
    minHeight: 36
  }
}))

export const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const LogoCryptoWrapper = styled(Box)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 36
  }
}))
