// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseParams, BaseState } from '@/app/store/types'
import { IHistoryResponse, UsersHistoryFilters } from '@/shared'
import { getUserHistory } from '@/app/store/thunks/history'

type HistoryState = BaseState<{
  history: IHistoryResponse
  params: BaseParams
  filters?: Partial<UsersHistoryFilters>
}>

const initialState: HistoryState = {
  data: {
    history: {
      stores: [],
      currencies: [],
      tags: [],
      items: [],
      meta: null,
      filters: null
    },
    params: {
      page: 0,
      per_page: 10
    },
    filters: {
      name: ['none'],
      tag: ['none'],
      blockchain_name: ['none'],
      date_start: '',
      date_end: ''
    }
  },
  error: '',
  status: 'idle'
}

export const historySlice = createSlice({
  name: 'appUsers',
  initialState,
  reducers: {
    setRequestParams(state: HistoryState, action: PayloadAction<BaseParams>) {
      state.data.params = action.payload
    },
    setDefaultFilters(state: HistoryState) {
      state.data.filters = {
        name: ['none'],
        tag: ['none'],
        blockchain_name: ['none'],
        date_start: '',
        date_end: ''
      }
    },
    setDefaultDates(state: HistoryState) {
      state.data.filters = {
        ...state.data.filters,
        date_start: '',
        date_end: ''
      }
    },
    setRequestFilters(state: HistoryState, action: PayloadAction<Partial<UsersHistoryFilters>>) {
      state.data.filters = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserHistory.pending, (state: HistoryState) => {
      state.status = 'pending'
    })
    builder.addCase(getUserHistory.rejected, (state: HistoryState) => {
      state.status = 'rejected'
    })
    builder.addCase(getUserHistory.fulfilled, (state: HistoryState, action: PayloadAction<IHistoryResponse>) => {
      state.data.history = action.payload
      state.status = 'fulfilled'
      state.error = ''
    })
  }
})

export const { setRequestParams, setRequestFilters, setDefaultFilters } = historySlice.actions

const { reducer } = historySlice
export default reducer
