import { Box, FormControl, InputAdornment, OutlinedInput, styled, TextareaAutosize, TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'

type FormErrorProps = {
  error: boolean
}

export const FormLabel = styled('label')(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['700'],
  family: '`Ubuntu`, sans-serif',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: theme.palette.letterSpacing.default,
  color: theme.palette.customColors.delight,
  marginBottom: 4
}))

export const FormTextField = styled(TextField)<FormErrorProps>(({ error, theme }) => ({
  '& .MuiInputBase-input': {
    fontFamily: `'Ubuntu'`,
    fontWeight: theme.palette.fontWeight['400'],
    fontSize: '14px',
    lineHeight: '24px',
    width: '100%',
    minWidth: 268,
    minHeight: 24,
    letterSpacing: theme.palette.letterSpacing.default,
    background: '#FFF',
    borderRadius: '8px',
    padding: '9px 16px',
    color: '#101010',
    '&:focus': {
      padding: '7px 14px',
      border: '2px solid #186ADF'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    fontFamily: `'Ubuntu'`,
    borderRadius: '8px',
    border: error ? '2px solid #EA4335' : 'none'
  }
}))

export const TextArea = styled(TextareaAutosize)<FormErrorProps>(({ theme, error }) => ({
  width: 481,
  minHeight: 171,
  maxHeight: 172,
  fontFamily: `'Ubuntu'`,
  borderRadius: '8px',
  padding: '9px 16px',
  outline: 0,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  color: '#101010',
  border: error ? '2px solid #EA4335' : 'none',
  '&:focus': {
    border: '2px solid #186ADF'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: 268
  }
}))

export const FormOutlinedInput = styled(OutlinedInput)<FormErrorProps>(({ error, theme }) => ({
  '& .MuiOutlinedInput-input': {
    fontWeight: theme.palette.fontWeight['400'],
    fontSize: '14px',
    width: '100%',
    minWidth: 268,
    minHeight: 24,
    letterSpacing: theme.palette.letterSpacing.default,
    background: '#FFF',
    borderRadius: '8px',
    padding: '9px 16px',
    color: '#101010',
    '&:focus': {
      padding: '7px 14px',
      border: '2px solid #186ADF'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
    border: error ? '2px solid #EA4335' : 'none'
  }
}))

export const FormWrapper = styled(Box)<{ rowInput?: boolean }>(({ rowInput }) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: rowInput ? 'row' : 'column'
}))

export const PlatformTextField = styled(TextField)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Iter', sans-serif`,
  width: '100%',
  maxWidth: '521px',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const FieldPlaceholder = styled(Box)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['400'],
  fontFamily: `'Iter', sans-serif`,
  width: '100%',
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const FieldLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: theme.palette.fontWeight['500'],
  fontFamily: `'Ubuntu', sans-serif`,
  width: '100%',
  fontSize: '14px',
  lineHeight: '157%',
  marginBottom: '4px',
  color: theme.palette.customColors.delight
}))

export const FormInputAdornment = styled(InputAdornment)(() => ({
  position: 'absolute',
  right: 15,
  background: '#fff',
  marginLeft: 20
}))

export const OutlinedTextInputControl = styled(FormControl)(() => ({}))

export const OutlinedFormTextInput = styled(TextField)<{ invalid?: boolean }>(({ theme, invalid }) => ({
  font: `'Inter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  gap: 8,
  textTransform: 'capitalize',
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.common.white,
  width: '100%',
  outline: 'none',
  border: invalid ? '1px solid error.main' : 'none',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: invalid ? '1px solid error.main' : '1px solid #484850',
    borderRadius: '8px'
  },
  '& .MuiFormLabel-root': {
    font: `'Inter'`,
    textTransform: 'none',
    fontWeight: theme.palette.fontWeight['400'],
    fontSize: '16px',
    lineHeight: '24px',
    color: !invalid ? 'rgba(255, 255, 255, 0.75)' : invalid ? 'error.main' : '#101010'
  }
}))

export const ApiKeyDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    minWidth: '460px',
    padding: '36px 56px',
    gap: '24px',
    background: '#292929',
    borderRadius: '24px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export const ApiKeyDialogTitle = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter', sans-serif`,
  color: '#FCFCFC',
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '20px',
  lineHeight: '160%',
  marginBottom: '8px'
}))
export const ApiKeyFormButtons = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const ApiKeyCancelButton = styled(Button)(({ theme }) => ({
  font: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  color: '#186ADF',
  padding: '7px 22px',
  border: '1px solid #186ADF',
  borderRadius: '8px',
  gap: '8px',
  marginRight: '24px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '158px',
    marginRight: '12px'
  }
}))

export const ApiKeySaveChanges = styled(Button)(({ theme }) => ({
  font: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '13px',
  lineHeight: '24px',
  color: theme.palette.common.white,
  width: 158,
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  '&:hover': {
    background: '#186ADF'
  }
}))
