/**
 * Redux Imports
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetUserWithdrawalsData,
  IClosedTicket,
  ITicket,
  ITicketMessageType,
  ITicketMeta,
  ITicketNotify,
  TickerDataType,
  tokenService,
  UserWithdrawals
} from '@/shared'
import { BaseParams, BaseState } from '@/app/store/types'
import {
  closeTicket,
  CloseTicketArgs,
  getAllClosedTickets,
  getAllTickets,
  getAllTicketsMessages,
  getTicketsNotifyData,
  getUserWithdrawals,
  reopenTicket,
  replyInTicket,
  ReplyTicketArgs
} from '@/app/store/thunks/tickets'

type TicketsState = {
  tickets: { list: ITicket[]; params: BaseParams; meta: ITicketMeta | null }
  messages: ITicketMessageType[]
  closedTickets: { list: IClosedTicket[]; params: BaseParams; meta: ITicketMeta | null }
  withdrawalTickets: { list: UserWithdrawals[]; params: BaseParams; meta: ITicketMeta | null }
  ticketsNotifyData: ITicketNotify | null
}

type TicketSliceState = BaseState<TicketsState>

const initialState: TicketSliceState = {
  data: {
    tickets: {
      list: [],
      params: { page: 0 },
      meta: null
    },
    messages: [],
    closedTickets: {
      list: [],
      params: { page: 0 },
      meta: null
    },
    withdrawalTickets: {
      list: [],
      params: { page: 0 },
      meta: null
    },
    ticketsNotifyData: null
  },
  error: '',
  status: 'idle'
}

/**
 * Tickets slice
 */
export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTicketsPage: (state: TicketSliceState, action: PayloadAction<BaseParams>) => {
      state.data.tickets.params = action.payload
    },
    setClosedTicketsPage: (state: TicketSliceState, action: PayloadAction<BaseParams>) => {
      state.data.closedTickets.params = action.payload
    },
    setWithdrawalTickets: (state: TicketSliceState, action: PayloadAction<BaseParams>) => {
      state.data.withdrawalTickets.params = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(closeTicket.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(getTicketsNotifyData.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(reopenTicket.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(getAllTickets.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(getAllClosedTickets.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(getAllTicketsMessages.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(getUserWithdrawals.pending, (state: TicketSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(replyInTicket.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(closeTicket.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(getTicketsNotifyData.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(reopenTicket.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(getAllTickets.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(getAllClosedTickets.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(getAllTicketsMessages.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(getUserWithdrawals.rejected, (state: TicketSliceState) => {
      state.status = 'rejected'
    })
    builder.addCase(
      getAllTickets.fulfilled,
      (state: TicketSliceState, action: PayloadAction<{ tickets: ITicket[]; meta: ITicketMeta }>) => {
        const { tickets, meta } = action.payload
        state.data.tickets.list = tickets
        state.data.tickets.meta = meta
        state.status = 'fulfilled'
      }
    )
    builder.addCase(getTicketsNotifyData.fulfilled, (state: TicketSliceState, action: PayloadAction<ITicketNotify>) => {
      state.data.ticketsNotifyData = action.payload
      state.status = 'fulfilled'
    })
    builder.addCase(
      getAllClosedTickets.fulfilled,
      (state: TicketSliceState, action: PayloadAction<{ tickets: IClosedTicket[]; meta: ITicketMeta }>) => {
        const { tickets, meta } = action.payload
        state.data.closedTickets.list = tickets
        state.data.closedTickets.meta = meta
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      getUserWithdrawals.fulfilled,
      (state: TicketSliceState, action: PayloadAction<GetUserWithdrawalsData>) => {
        const { users, meta } = action.payload
        state.data.withdrawalTickets.list = users
        state.data.withdrawalTickets.meta = meta
        state.status = 'fulfilled'
      }
    )
    builder.addCase(
      getAllTicketsMessages.fulfilled,
      (state: TicketSliceState, action: PayloadAction<{ messages: ITicketMessageType[] }>) => {
        state.data.messages = action.payload.messages
        state.status = 'fulfilled'
      }
    )
    builder.addCase(closeTicket.fulfilled, (state: TicketSliceState, action: PayloadAction<CloseTicketArgs>) => {
      const { ticketId } = action.payload
      state.data.tickets.list = state.data.tickets.list.filter(ticket => String(ticket.id) !== ticketId)
    })
    builder.addCase(reopenTicket.fulfilled, (state: TicketSliceState, action: PayloadAction<CloseTicketArgs>) => {
      const { ticketId } = action.payload
      state.data.tickets.list = state.data.closedTickets.list.filter(ticket => String(ticket.id) !== ticketId)
    })
    builder.addCase(replyInTicket.fulfilled, (state: TicketSliceState, action: PayloadAction<ReplyTicketArgs>) => {
      const data = action.payload?.data as TickerDataType
      const user = tokenService.getUser()
      const newMessage = {
        message: data?.message || '',
        files: (data?.files ?? []) as unknown[],
        id: Number(data?.id),
        author: user?.email || '',
        is_admin: false,
        created_at: new Date().toDateString()
      }
      state.data.messages = state.data.messages.concat(newMessage)
    })
  }
})

export const { setTicketsPage, setClosedTicketsPage, setWithdrawalTickets } = ticketsSlice.actions

const { reducer } = ticketsSlice

export default reducer
