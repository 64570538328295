export const authPages = [
  '/login',
  '/login-second-step',
  '/register',
  '/register-confirmation',
  '/register-confirmation-failed',
  '/register-email-sent',
  '/reset-password',
  '/reset-password-success',
  '/your-account-deleted',
  '/forgot-password',
  '/register-confirmation',
  '/confirmation-delete',
  '/contact-support',
  '/email-already-verified',
  '/email-not-verified',
  '/forgot-password',
  '/forgot-password-confirmation'
]

export const dashboardPages = [
  '/withdrawals-tickets',
  '/history-tickets',
  '/support-tickets',
  '/confirm-delete',
  '/merchant-commissions-and-deposits',
  '/merchant-docs',
  '/merchant-platforms',
  '/merchant-referrals',
  '/merchant-test-payments',
  '/settings',
  '/submit-ticket',
  '/watcher-dashboard',
  '/watcher-history',
  '/watcher-wallet',
  '/watcher-withdrawals'
]
