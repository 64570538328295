import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { CurrencyMenuItem, CurrencyOptionMenu, CurrencyPlatform } from './styles'
import { useAppDispatch, useAppSelector } from '@/shared'
import { getWalletAndAllowedCurrencies } from '@/app/store/thunks/wallet'
import { walletCurrenciesSelector } from '@/app/store/selectors'

export const CryptoCurrencySelect = () => {
  const options = useAppSelector(walletCurrenciesSelector)
  const dispatch = useAppDispatch()

  /**
   * states
   */
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<string>(String(options?.[0]?.id || '1'))
  const [selectedPlatform, setSelectedPlatform] = useState<string>(String(options?.[0]?.name || 'BTC'))

  /**
   * handlers
   */
  const handleLangDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePlatformSelect = (p: string) => {
    setSelectedPlatform(p)
    handleLangDropdownClose()
  }

  const handleLangDropdownClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    dispatch(getWalletAndAllowedCurrencies(selectedCurrencyId))
  }, [dispatch, selectedCurrencyId])

  /**
   * variables
   */
  const isOpen = Boolean(anchorEl)

  const DropdownArrow = isOpen ? MdKeyboardArrowUp : MdKeyboardArrowDown

  return (
    <Fragment>
      <IconButton disableRipple disableTouchRipple onClick={handleLangDropdownOpen}>
        <CurrencyPlatform>{selectedPlatform.toLocaleUpperCase()}</CurrencyPlatform>
        <DropdownArrow color={'#186ADF'} />
      </IconButton>
      <CurrencyOptionMenu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleLangDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {(options ?? []).map(option => (
          <CurrencyMenuItem
            key={option.name}
            disableRipple
            disableTouchRipple
            selected={selectedPlatform === option.name}
            onClick={() => {
              handlePlatformSelect(option.name)
              setSelectedCurrencyId(String(option.id))
            }}
          >
            {option.name}
          </CurrencyMenuItem>
        ))}
      </CurrencyOptionMenu>
    </Fragment>
  )
}
