/**
 * MUI imports
 */
import Box from '@mui/material/Box'

/**
 * React widgets imports
 */
import { PropsWithChildren } from 'react'

/**
 * Styled widgets imports
 */
import { UserLayoutFooter, UserLayoutHeader } from '@/shared/ui'

/**
 * Styles imports
 */
import { BlankLayoutWrapper as UserLayoutWrapper } from './styles'

/**
 * @type {Props} - props interface for BlankLayout
 * @property {boolean} visibleHeader - variable that toggle header visibility
 */
type Props = {
  visibleHeader?: boolean
  hiddenFooter?: boolean
}

export const UserLayout = ({ children, visibleHeader = false, hiddenFooter = false }: PropsWithChildren<Props>) => {
  return (
    <UserLayoutWrapper className='layout-wrapper' sx={{ background: '#101010' }}>
      <Box className='app-content' sx={{ minHeight: '100vh' }}>
        {visibleHeader && <UserLayoutHeader />}
        <Box>{children}</Box>
        {!hiddenFooter && <UserLayoutFooter />}
      </Box>
    </UserLayoutWrapper>
  )
}
