import { authConfig } from '@/shared/configs/auth'
import { axiosInstance, tokenService } from '@/shared'

class AuthService {
  async sendEmailVerification(id: string, token: string, lang: string) {
    const response = await axiosInstance.post(
      `${authConfig.emailVerificationEndpoint}/${id}/${token}`,
      {},
      { params: { lang } }
    )

    return response.data
  }
  async secondStepTwoFactor(code: string, lang: string) {
    const response = await axiosInstance.post(authConfig.secondTwoFactorStepEndpoint, { code }, { params: { lang } })

    return response.data
  }

  async verificationNotification(email: string, lang: string) {
    const response = await axiosInstance.post(
      `${authConfig.emailVerificationEndpoint}/verification-notification`,
      {
        email
      },
      { params: { lang } }
    )

    return response.data
  }
  async refreshAccessTokenFx() {
    const refreshToken = tokenService.getLocalRefreshToken()

    try {
      const { data: response } = await axiosInstance.get(authConfig.refreshEndpoint, {
        params: {
          refresh_token: refreshToken
        }
      })
      const tokenData = response?.data
      if (!tokenData?.access_token) {
        tokenService.clear()
        window.location.href = '/login'

        return
      }

      tokenService.updateLocalAccessToken(tokenData?.access_token, tokenData?.expires_in)
      tokenService.setLocalRefreshToken(tokenData?.refresh_token)

      return tokenData
    } catch (_error) {
      tokenService.clear()
    }
  }
}

export const authService = new AuthService()
