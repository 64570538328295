import { FormLabel, SelectProps, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import { ReactNode } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import DialogContent from '@mui/material/DialogContent'
import ButtonGroup from '@mui/material/ButtonGroup'
import TextField from '@mui/material/TextField'

export const PaymentsTableName = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: `'Inter'`,
  lineHeight: '160%',
  fontWeight: theme.palette.fontWeight['500'],
  color: theme.palette.customColors.delight
}))

export const HistoryWrapper = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  width: '100%',
  flex: '1 1 auto'
}))

export const ChipList = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  flexWrap: 'wrap',
  listStyle: 'none',
  p: 0.5,
  m: 0
}))

export const ChipListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
  marginRight: '8px'
}))

export const ChipMenuOption = styled(MenuItem)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const PaymentsTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.common.white,
  '& > span': {
    fontSize: '14px',
    fontFamily: `'Inter'`,
    lineHeight: '24px',
    fontWeight: theme.palette.fontWeight['500'],
    color: theme.palette.common.white
  }
}))

export const PaymentsTablePreset = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '143%',
  textTransform: 'capitalize',
  fontWeight: theme.palette.fontWeight['700'],
  color: `${theme.palette.common.white} !important`
}))

export const PaymentsTableLoader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 560
}))

export const PaymentsTableAdditional = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '143%',
  fontWeight: theme.palette.fontWeight['400'],
  color: `rgba(255, 255, 255, 0.75)`
}))

export const FiltersButtonText = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Inter'`,
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['500'],
  textTransform: 'uppercase',
  color: theme.palette.common.white,
  marginRight: '8px'
}))

export const FiltersButton = styled(Button)(({ theme }) => ({
  minWidth: 125,
  padding: '7px 22px',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  '&:hover': {
    background: '#186ADF'
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 24,
    padding: '5px'
  }
}))

export const FiltersHeading = styled(Box)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: `'Inter'`,
  lineHeight: '160%',
  fontWeight: theme.palette.fontWeight['500'],
  color: theme.palette.customColors.delight,
  marginBottom: '24px'
}))

export const PaymentsHistoryPaper = styled(Paper)(({ theme }) => ({
  maxWidth: '720px !important',
  width: '100%',
  minHeight: '336px',
  background: '#292929',
  borderRadius: '24px',
  padding: '36px 56px',
  [theme.breakpoints.down('sm')]: {
    padding: '1.25rem 0'
  }
}))

export const PaymentsHistoryDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export const ClearButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: theme.palette.fontWeight['500'],
  marginRight: '10px'
}))

export const SentCode = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: theme.palette.fontWeight['500'],
  marginBottom: '10px'
}))

export interface DialogTitleProps {
  children?: ReactNode
  onClose: () => void
}

export const PaymentsHistoryDialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#FFF'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export const ResetAllButton = styled(Button)(({ theme }) => ({
  padding: '16px 32px',
  marginRight: '16px',
  gap: '10px',
  width: '264px',
  height: '48px',
  border: '2.5px solid #424242',
  borderRadius: '12px',
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  background: 'transparent',
  lineHeight: '16px',
  color: '#FCFCFC',
  '&:hover': {
    background: 'transparent'
  }
}))

export const ApplyAllButton = styled(Button)(({ theme }) => ({
  padding: '16px 0',
  gap: '8px',
  width: '264px',
  height: '48px',
  borderRadius: '12px',
  fontFamily: `'Ubuntu'`,
  background: '#186ADF',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '16px',
  color: '#FCFCFC',
  '&:hover': {
    background: '#186ADF'
  }
}))

export const FormSelectLabel = styled(InputLabel)(({ theme }) => ({
  padding: '10px 5px',
  fontSize: '20px',
  fontFamily: `'Inter'`,
  lineHeight: '20px',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.customColors.delight,
  '&:hover, &:focus': {
    color: theme.palette.customColors.delight
  }
}))

export const SelectedRenderValue = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  padding: '5px 0',
  fontFamily: `'Inter'`,
  lineHeight: '20px',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(252, 252, 252, 0.6)',
  '&:hover': {
    color: 'rgba(252, 252, 252, 0.6)'
  }
}))

export const PaymentsMenuItemText = styled(ListItemText)(({ theme }) => ({
  '& > span': {
    fontSize: '16px',
    padding: '5px 0',
    fontFamily: `'Inter'`,
    lineHeight: '24px',
    fontWeight: theme.palette.fontWeight['400'],
    color: theme.palette.customColors.delight
  }
}))

export const PaymentsMenuSelectAll = styled(ListItemText)(({ theme }) => ({
  '& > span': {
    fontSize: '16px',
    padding: '5px 0',
    fontFamily: `'Iter', sans-serif`,
    lineHeight: '24px',
    fontWeight: theme.palette.fontWeight['600'],
    color: theme.palette.customColors.delight
  }
}))

export const PaymentsSelect = styled(Select)(() => ({
  padding: '0 4px',
  width: '264px',
  background: '#101010',
  borderRadius: '8px',
  '&:hover': {
    background: '#101010'
  }
}))

export const PaymentsMenuItem = styled(MenuItem)(() => ({
  background: '#101010',
  paddingRight: 0,
  '& .MuiButtonBase-root .MuiMenuItem-root': {
    padding: 0
  }
}))

export const SelectResetButton = styled(ResetAllButton)({
  width: '100%',
  margin: '12px 8px 0 8px',
  fontSize: '14px',
  lineHeight: '16px'
})

export const SelectApplyButton = styled(ApplyAllButton)({
  width: '119px',
  margin: '12px 8px 0 8px',
  fontSize: '14px',
  lineHeight: '16px'
})

export const SelectFiltersContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
}))

export const SelectFilterItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1 1 264px',
  margin: '8px',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center'
  }
}))

export const ModalActionsButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  mt: '24px',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '20px',
    marginRight: '20px'
  }
}))

export const PaymentsHistoryTableFooter = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '12px'
})

export const TableInfoExport = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const TableFooterSelect = styled(Select)<SelectProps>(() => ({
  height: 38,
  width: '80px',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 }
}))

export const DatePickerWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  width: '232px',
  background: '#FCFCFC',
  border: '2.5px solid #186ADF',
  borderRadius: '12px',
  margin: '0 auto'
})

export const DatePickerDateButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: '#186ADF',
  width: '100%',
  padding: '12px 24px',
  gap: 12,
  maxWidth: 232,
  background: '#FCFCFC',
  border: '2.5px solid #186ADF',
  borderRadius: '12px',
  '&:hover': {
    background: '#FCFCFC'
  }
}))

export const CalendarIcon = styled(CalendarTodayIcon)(() => ({
  fontSize: '24px'
}))

export const DatePickerCalendarWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

export const DatePickerDialog = styled(Dialog)(() => ({
  background: 'transparent',
  maxWidth: '600px',
  width: '100%',
  margin: '0 auto'
}))

export const DatePickerDialogContent = styled(DialogContent)(() => ({
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

export const DialogButton = styled(Button)(({ theme }) => ({
  background: '#101010',
  textTransform: 'none',
  fontSize: '14px',
  fontFamily: `'Iter', sans-serif`,
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['500'],
  color: '#FFF',
  '&:hover': {
    background: '#101010'
  }
}))
export const DialogButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

export const ClientsInputFormControl = styled(Box)(() => ({
  display: 'flex',
  marginTop: '16px',
  flexDirection: 'column',
  minHeight: 80,
  padding: '4px 16px',
  paddingRight: '12px',
  maxWidth: 264,
  width: '100%',
  background: '#101010',
  borderRadius: '8px'
}))

export const ClientsFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: `'Inter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  color: '#FCFCFC',
  marginBottom: '2px'
}))

export const DateTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontWeight: theme.palette.fontWeight['400'],
    gap: '10px',
    padding: '9px 12px',
    border: 0,
    outlined: 0,
    width: 'fit-content',
    fontFamily: `'Ubuntu', sans-serif`,
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '143%',
    color: '#FFF',
    '&:focus': {
      outline: 0,
      border: 0
    }
  }
}))

export const ExportHistoryButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Iter', sans-serif`,
  lineHeight: '24px',
  fontWeight: theme.palette.fontWeight['500'],
  color: theme.palette.common.white,
  background: '#6D788D',
  boxShadow: '0px 4px 8px -4px rgba(20, 21, 33, 0.42)',
  borderRadius: '8px',
  textTransform: 'uppercase',
  '&:hover, &:focus': {
    background: '#6D788D'
  },
  '& .Mui-focused': {
    background: '#6D788D'
  }
}))

export const TableRowsPerPage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
  family: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '143%',
  color: 'rgba(252, 252, 252, 0.6)'
}))

export const PaymentsTableContainer = styled(Box)(() => ({
  overflowX: 'auto',
  boxShadow: 'none',
  width: '100%',
  maxHeight: '560px',
  borderTopColor: 'rgba(76, 78, 100, 0.12)',
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#292929'
  },
  '&::-webkit-scrollbar': {
    height: '10px',
    backgroundColor: '#F5F5F5'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#424242',
    border: '1px solid #555555'
  }
}))

export const EmptyTableTitle = styled(Box)(({ theme }) => ({
  family: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  color: 'rgba(252, 252, 252, 0.6)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px'
  }
}))

export const PaymentsTablePaper = styled(Paper)(() => ({
  width: '100%',
  maxWidth: 1340,
  marginBottom: 2,
  padding: '24px',
  background: '#292929'
}))
