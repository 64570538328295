import { MouseEvent } from 'react'
import { Box, TableCell, TableHead, TableRow } from '@mui/material'
import { PaymentsTableSortLabel } from './styles'
import { visuallyHidden } from '@mui/utils'
import { Order, PaymentsTableData } from '@/shared'
import { useTranslation } from 'react-i18next'

interface PaymentsTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: any) => void
  order: Order
  orderBy: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof PaymentsTableData
  label: string
  numeric: boolean
  sortable: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Store',
    sortable: true
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Transaction ID',
    sortable: true
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Time',
    sortable: true
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: 'Tag',
    sortable: true
  },
  {
    id: 'blockchain',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
    sortable: true
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
    sortable: false
  }
]

export const PaymentsTableHead = ({ order, orderBy, onRequestSort }: PaymentsTableProps) => {
  const { t } = useTranslation()
  const createSortHandler = (property: keyof PaymentsTableData) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sx={{ minWidth: 142 }}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <PaymentsTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                width: 'max-content',
                pointerEvents: headCell.sortable ? 'inherit' : 'none',
                '& > svg': {
                  display: headCell.sortable ? 'inherit' : 'none'
                }
              }}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </PaymentsTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
