import { useTranslation } from 'react-i18next'
import {
  FundsWithdrawalForm,
  FundsWithdrawalHeading,
  FundsWithdrawalLink,
  FundsWithdrawalTwoFactor,
  FundsWithdrawalWrapper
} from '@/shared/ui'
import { WithdrawalRequestModal } from './withdrawal-request-modal'
import { tokenService } from '@/shared'
import { useState } from 'react'

export const FundsWithdrawalContent = () => {
  const { t } = useTranslation()
  const [requestModal, setRequestModal] = useState<boolean>(false)

  const handleRequestModal = () => {
    setRequestModal(prev => !prev)
  }

  const user = tokenService.getUser()
  const isTwoFactorEnabled = Boolean(user?.is_two_auth_enabled)

  return (
    <FundsWithdrawalWrapper>
      <FundsWithdrawalHeading>{t('Withdraw Crypto')}</FundsWithdrawalHeading>
      {isTwoFactorEnabled ? (
        <FundsWithdrawalForm handleRequestModal={handleRequestModal} />
      ) : (
        <FundsWithdrawalTwoFactor>
          {t('To enable withdrawal, please go to')}
          <FundsWithdrawalLink href='/settings'>{t('Settings')}</FundsWithdrawalLink> <br />
          {t('and turn on two-factor authentication')}
        </FundsWithdrawalTwoFactor>
      )}
      <WithdrawalRequestModal open={requestModal} handleRequestModal={handleRequestModal} />
    </FundsWithdrawalWrapper>
  )
}
