import { SvgIconProps } from '@mui/material/SvgIcon'
import { SvgIcon } from '@mui/material'

export const EmailIcon = (props: SvgIconProps) => (
  <SvgIcon width='128' height='128' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.6665 37.3333C10.6665 28.4967 17.8299 21.3333 26.6665 21.3333H101.333C110.17 21.3333 117.333 28.4967 117.333 37.3333V90.6666C117.333 99.5031 110.17 106.667 101.333 106.667H26.6665C17.8299 106.667 10.6665 99.5031 10.6665 90.6666V37.3333Z'
      fill='#FCFCFC'
    />
    <path
      d='M58.1866 65.5547L10.6665 34.6666C10.6665 27.3028 16.636 21.3333 23.9998 21.3333H104C111.364 21.3333 117.333 27.3028 117.333 34.6666L69.813 65.5547C66.2781 67.8524 61.7215 67.8524 58.1866 65.5547Z'
      fill='#186ADF'
    />
  </SvgIcon>
)
