import { Dialog, DialogActions, IconButton, Toolbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DialogBody, DialogEmail, DialogEmailText, DialogInnerTitle, DialogLinkedEmail, DialogOkButton } from './styles'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/shared/hooks/useAuth'
import { EmailIcon as DarkThemeEmail } from '@/shared/ui'
import { useRef } from 'react'

type Props = {
  readonly open: boolean
  readonly handleClose: () => void
}

export const CheckEmailModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation()
  const dialogRef = useRef<HTMLDivElement | null>(null)
  const { user } = useAuth()

  return (
    <div ref={dialogRef}>
      <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton edge='end' color='inherit' onClick={handleClose} sx={{ mr: 0 }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogBody>
          <DialogInnerTitle>{t('Check your email')}</DialogInnerTitle>
          <DialogEmail>
            <DarkThemeEmail style={{ fontSize: '128px' }} />
            <DialogEmailText>
              {t('We have sent the link to')}{' '}
              <DialogLinkedEmail>{user?.email || 'emailexmaple@mail.com'}</DialogLinkedEmail> <br />{' '}
              {t('Follow the link to delete your account.')}
            </DialogEmailText>
          </DialogEmail>
        </DialogBody>
        <DialogActions>
          <DialogOkButton onClick={handleClose} variant={'contained'}>
            Ok
          </DialogOkButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
