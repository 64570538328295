export const useDateString = (date: string) => {
  const lang = (typeof window !== 'undefined' && localStorage.getItem('i18nextLng')) || 'en'
  const dateOptions = {
    timeZone: 'UTC',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: lang === 'en'
  } as const
  const dateString = new Date(date)

  return dateString.toLocaleDateString(lang, dateOptions)
}
