import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { EnableFormButtons, EnableFormWrapper, EnableTwoFactorButton } from '@/shared'
import toast from 'react-hot-toast'
import { FormInput } from '@/shared/ui/react-hook-form'
import { userService } from '@/shared/services/user.service'
import CircularProgress from '@mui/material/CircularProgress'
import { disableFormSchema } from '../disable-form.schema'
import Button from '@mui/material/Button'

type DisableFormData = {
  code: string
}

const defaultValues = {
  code: ''
}

export const DisableForm = () => {
  /**
   * hook form
   */
  const router = useRouter()
  const { i18n } = useTranslation()
  const schema = useMemo(() => disableFormSchema(), [i18n.language])

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<DisableFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  /**
   * translation hooks
   */
  const { t } = useTranslation()

  /**
   * submit handler
   */
  const onSubmit = async (data: DisableFormData) => {
    const response = await userService.disableTwoFactor(data.code).catch(e => {
      const message = e?.response?.data?.error?.message || ''

      if (message) {
        setError('code', { message })
      }
    })

    if (response !== undefined) {
      toast.success(t(`2-Step Verification disabled.`), {
        position: 'bottom-right'
      })
      await router.push('/settings')
    }
  }

  const goBackHandler = () => router.push('/settings')

  return (
    <EnableFormWrapper noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        name='code'
        control={control}
        label={t('Verification code')}
        placeholder={t('Enter your code')}
        inputProps={{ minLength: 0, maxLength: 6 }}
      />
      <EnableFormButtons>
        <EnableTwoFactorButton
          fullWidth
          size='large'
          disabled={!isDirty || !isValid || isSubmitting}
          type='submit'
          variant='contained'
        >
          {isSubmitting ? <CircularProgress color='inherit' size={30} /> : t('Disable 2FA')}
        </EnableTwoFactorButton>
        <Button variant='text' sx={{ height: '48px' }} onClick={goBackHandler}>
          {t('Back')}
        </Button>
      </EnableFormButtons>
    </EnableFormWrapper>
  )
}
