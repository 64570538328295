import { styled } from '@mui/system'
import { Box, Table, TableCell } from '@mui/material'
import TableRow from '@mui/material/TableRow'

export const ReferralHeading = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  color: '#FCFCFC',
  marginBottom: '16px'
}))

export const ReferralCopy = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Inter'`,
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(255, 255, 255, 0.75)',
  padding: '4px 12px',
  maxWidth: '843px',
  width: '100%',
  border: '1px solid rgba(234, 242, 255, 0.22)',
  borderRadius: 8,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'auto',
    alignItems: 'flex-start',
    fontSize: '14px',
    padding: '15px 12px'
  }
}))

export const ReferralText = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '12px',
  lineHeight: '20px',
  color: 'rgba(255, 255, 255, 0.75)',
  marginTop: '3px',
  marginLeft: '12px'
}))

export const ReferralsWrap = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
}))

export const NoReferralsFound = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0',
  width: 'max-content',
  family: `'Iter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  color: 'rgba(252, 252, 252, 0.6)',
  lineHeight: '133%'
}))

export const ReferralsCol = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '48px'
}))

export const ReferralsTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.common.white,
  textTransform: 'none'
}))

export const ReferralTableHeading = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '20px',
  lineHeight: '160%',
  color: '#FCFCFC',
  textTransform: 'capitalize'
}))

export const ReferralTable = styled(Table)(() => ({}))

export const ReferralCreatedAtCell = styled(TableCell)(({ theme }) => ({
  paddingRight: '12.5rem',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    paddingRight: '0'
  }
}))

export const TablePaginationRow = styled(TableRow)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& > td': {
      padding: '0 !important'
    }
  }
}))

export const TableRowsPerPage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
  family: `'Ubuntu', sans-serif`,
  textTransform: 'none',
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '143%',
  color: 'rgba(252, 252, 252, 0.6)'
}))
