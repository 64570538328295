import { useFormContext } from 'react-hook-form'
import { CreateStoreData, PlatformInputGroup, PlatformLink } from '@/shared'
import { FormInput } from '@/shared/ui/react-hook-form'
import { Box } from '@mui/material'

export type TDynamicFormField = {
  id: string
  label: string
  fields: TCommonField[]
}

export type TCommonField = {
  id: string
  label: string
  name: string
  placeholder: string
  additionalField?: TAdditionalField
}

export type TAdditionalField = {
  name: string
  label: string
  placeholder: string
  linkLabel: string
}

export type DynamicFormFieldProps = {
  formElements: TCommonField[] | undefined
}

export const PlatformDynamicFields = ({ formElements }: DynamicFormFieldProps) => {
  const {
    watch,
    control,
    formState: { dirtyFields }
  } = useFormContext<CreateStoreData>()
  const { xpub } = watch()
  const xpubTouched = 'xpub' in dirtyFields && !!xpub

  return (
    <>
      {formElements &&
        formElements.map(({ id, label, name, placeholder, additionalField }) => (
          <PlatformInputGroup key={id}>
            <FormInput
              name={name}
              fullWidth
              sx={{ maxWidth: 'inherit' }}
              labelSx={{ width: '100%' }}
              isPlatform
              disableMargin
              control={control}
              label={label}
              placeholder={placeholder}
            />
            {additionalField && xpubTouched ? (
              <Box sx={{ mt: 3 }}>
                <FormInput
                  name={additionalField.name}
                  fullWidth
                  sx={{ maxWidth: 'inherit' }}
                  labelSx={{ width: '100%' }}
                  isPlatform
                  disableMargin
                  control={control}
                  label={additionalField.label}
                  placeholder={additionalField.placeholder}
                />
                <PlatformLink>{additionalField.linkLabel}</PlatformLink>
              </Box>
            ) : null}
          </PlatformInputGroup>
        ))}
    </>
  )
}
