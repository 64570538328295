import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import {
  ApiKeyCancelButton,
  ApiKeyDialog,
  ApiKeyDialogTitle,
  ApiKeyFormButtons,
  ApiKeySaveChanges,
  FormTextInput
} from '@/shared/ui/react-hook-form'
import { addServerErrors, IStore, settingsService, useAppDispatch, useAppSelector, UserType } from '@/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo } from 'react'
import { userStateSelector } from '@/app/store/selectors'
import { apiValuesFormSchema, passwordFormSchema } from '@/widgets/pages/settings/ui/settings-api-key.schema'
import { getStores, refreshApiKey } from '@/app/store/thunks'
import toast from 'react-hot-toast'

export interface ApiKeyFormValues {
  '2fa_code': string
  admin_password: string
}

type Props = {
  open: boolean
  incomingFormValues: IStore
  openAccordion: () => void
  handleClose: () => void
}

export const SettingsPlatformsFormModal = ({ open, handleClose, openAccordion, incomingFormValues }: Props) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const user = useAppSelector(userStateSelector) as UserType
  const twoFactorEnabled = Boolean(user?.is_two_auth_enabled)
  const apiKeySchema = twoFactorEnabled ? apiValuesFormSchema() : passwordFormSchema()
  const schema = useMemo(() => apiKeySchema, [apiKeySchema, i18n.language])
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<ApiKeyFormValues>({
    mode: 'onChange',
    defaultValues: {
      '2fa_code': '',
      admin_password: ''
    },
    resolver: yupResolver(schema)
  })
  const disabled = !isValid || !isDirty

  const onFormCancel = () => {
    reset()
    handleClose()
  }
  const onSubmit: SubmitHandler<ApiKeyFormValues> = async data => {
    try {
      const dataObj = Object.fromEntries(Object.entries(data ?? {}).filter(([, v]) => v !== '')) as ApiKeyFormValues
      const response = await settingsService.updateSettingsApiKey(dataObj)
      const hasErrors = response.data === undefined

      if (!hasErrors) {
        reset()
        toast.success(t(`API key was changed. Please update your API key in the callback url`), {
          position: 'bottom-right'
        })
        dispatch(refreshApiKey(String(incomingFormValues?.id))).then(() => {
          dispatch(getStores({}))
          openAccordion()
        })
        handleClose()
      }
    } catch (e: any) {
      const errors = 'error' in e?.response?.data ? e?.response?.data?.error : e?.response?.data?.errors
      await addServerErrors<ApiKeyFormValues>(errors ?? {}, setError)
    }
  }

  return (
    <ApiKeyDialog onClose={handleClose} open={open}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#FFF'
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ApiKeyDialogTitle sx={{ mb: 5 }}>{t('Generate new API key?')}</ApiKeyDialogTitle>
          <FormTextInput
            sx={{ maxWidth: '361px', mb: '5px' }}
            name='admin_password'
            control={control}
            label={`${t('Secret code')}*`}
            placeholder={t('Enter secret code')}
          />
          {twoFactorEnabled ? (
            <FormTextInput
              sx={{ maxWidth: '361px', mb: '5px', mt: '15px' }}
              type='number'
              name='2fa_code'
              control={control}
              label={`${t('2-FA code')}*`}
              inputProps={{ maxLength: 8 }}
              placeholder={t('Enter 2-FA code')}
            />
          ) : null}
          <ApiKeyFormButtons sx={{ mt: '20px' }}>
            <ApiKeyCancelButton onClick={onFormCancel}>{t('Cancel')}</ApiKeyCancelButton>
            <ApiKeySaveChanges variant='contained' type='submit' disabled={disabled}>
              {isSubmitting ? <CircularProgress size={20} /> : t('generate')}
            </ApiKeySaveChanges>
          </ApiKeyFormButtons>
        </form>
      </DialogContent>
    </ApiKeyDialog>
  )
}
