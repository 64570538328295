import { useCallback, useState } from 'react'

export const useEditMode = () => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const enableEditMode = useCallback(() => {
    setEditMode(true)
  }, [])

  const disableEditMode = useCallback(() => {
    setEditMode(false)
  }, [])

  return {
    editMode,
    enableEditMode,
    disableEditMode
  }
}
