import { Box } from '@mui/material'

export const CheckCircled = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <svg width='128' height='128' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M64.0003 117.333C93.4555 117.333 117.334 93.4552 117.334 64C117.334 53.9404 114.549 44.5314 109.708 36.5018L66.2095 79.9998C62.0439 84.1654 55.2901 84.1654 51.1245 79.9998L36.2291 65.1044C34.1463 63.0216 34.1463 59.6447 36.2291 57.5619C38.3119 55.4791 41.6888 55.4791 43.7716 57.5619L58.667 72.4574L102.896 28.2286C103.012 28.1119 103.133 28.0017 103.258 27.8981C93.5105 17.3046 79.5306 10.6666 64.0003 10.6666C34.5451 10.6666 10.667 34.5448 10.667 64C10.667 93.4552 34.5451 117.333 64.0003 117.333Z'
          fill='#186ADF'
        />
      </svg>
    </Box>
  )
}
