import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { RootState } from '@/app/store'

export const userRootState = (state: RootState) => state.user

export const userStateSelector = createDraftSafeSelector(userRootState, state => state.data)
export const userUserQrSelector = createDraftSafeSelector(userRootState, state => ({
  qrcode_src: state.qrcode_src,
  qrcode_text: state.qrcode_text
}))
export const userLoadingSelector = createDraftSafeSelector(userRootState, state => state.status === 'pending')
export const userLoadingSelectorReferrerAccrual = createDraftSafeSelector(userRootState, state => state.referralsAccrualState === 'pending')
export const userErrorSelector = createDraftSafeSelector(userRootState, state => state.error)
export const userStatusErrorSelector = createDraftSafeSelector(userRootState, state => state.responseStatus)
