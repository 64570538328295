import { authConfig } from '@/shared/configs/auth'
import { TestPaymentTraction } from '@/shared/types/payment'
import { axiosInstance } from '@/shared'

class PlatformService {
  testPaymentTransaction = async (data: TestPaymentTraction) => {
    const { currency_id, ...rest } = data
    const response = await axiosInstance.post(
      `${authConfig.testTransactionEndpoint}/payment`,
      {
        ...rest,
        store_id: Number(data.store_id)
      },
      {
        params: {
          currency_id
        }
      }
    )

    return response.data
  }

  getTestPaymentTransactionLogs = async (signal?: AbortSignal) => {
    const response = await axiosInstance.get(`${authConfig.testTransactionEndpoint}/logs`, { signal })

    return response.data
  }
}

export const platformService = new PlatformService()
