import { authConfig, UserType } from '@/shared'
import Cookies, { CookieAttributes } from 'js-cookie'
import { persistor } from '@/app/store'

class TokenService {
  readonly cookieOptions = { domain: String(process.env.APP_COOKIE_DOMAIN) }
  readonly cookieNames = [
    'user-watcher-dashboard',
    'user-email',
    'accessToken',
    'refreshToken',
    'referral_token',
    'is-user-verified',
    '2factor',
    '2fa_confirm'
  ]
  getLocalRefreshToken() {
    return typeof window !== 'undefined' && (Cookies.get(authConfig.storageRefreshTokenKeyName) as string)
  }

  setLocalRefreshToken(token: string) {
    Cookies.set(authConfig.storageRefreshTokenKeyName, token, { ...this.cookieOptions, expires: 30, secure: true })
  }

  setCookieValue(name: string, value: string, options?: CookieAttributes) {
    Cookies.set(name, value, { ...this.cookieOptions, ...options, secure: true })
  }

  removeCookieValue(name: string, options?: CookieAttributes) {
    Cookies.remove(name, { ...this.cookieOptions, ...options })
  }

  getLocalAccessToken() {
    return typeof window !== 'undefined' ? (Cookies.get(authConfig.storageTokenKeyName) as string) : null
  }

  setReferral(_value: string) {
    Cookies.set(authConfig.storageReferralTokenKeyName, _value)
  }
  setLocalAccessToken(token: string, expireTimeInSeconds: number) {
    const expires = expireTimeInSeconds / 60 / 60 / 24
    Cookies.set(authConfig.storageTokenKeyName, token, { ...this.cookieOptions, expires, secure: true })
  }

  updateLocalAccessToken(token: string, seconds: number) {
    this.setLocalAccessToken(token, seconds)
  }

  getUser(): UserType | undefined {
    const value = localStorage.getItem(authConfig.storageUserKeyName) as string

    return !!value ? JSON.parse(value as string) : null
  }

  setUser(user: UserType | null) {
    localStorage.setItem(authConfig.storageUserKeyName, JSON.stringify(user))
  }

  clear() {
    persistor.pause()
    this.cookieNames.forEach(cookieName => {
      Cookies.remove(cookieName, this.cookieOptions)
    })
    window.localStorage.removeItem(authConfig.storageTwoFactorKey)
    window.localStorage.removeItem(authConfig.storageSettingsName)
    window.localStorage.removeItem(authConfig.storageUserKeyName)
    persistor.flush().then(() => persistor.purge())
  }
}

export const tokenService = new TokenService()
