import { Box, Button, IconButton, InputAdornment, MenuItem, Paper, TextField } from '@mui/material'
import { styled } from '@mui/system'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const OneTicketWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}))

export const TicketNavigationContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
}))

export const InfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export const OptionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: '12px'
  }
}))

export const InfoTicketName = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  lineHeight: '123%',
  wordBreak: 'break-all',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white,
  margin: '0 12px',
  [theme.breakpoints.down('md')]: {
    margin: 0,
    order: 2
  }
}))

export const InfoTicketHash = styled(Box)(({ theme }) => ({
  fontSize: '34px',
  fontFamily: `'Inter'`,
  lineHeight: '123%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)',
  [theme.breakpoints.down('sm')]: {
    order: 1
  }
}))

export const SupportChatWrapper = styled(Box)(({ theme }) => ({
  marginTop: '24px',
  display: 'flex',
  padding: '48px 36px 0px',
  flexDirection: 'column',
  gap: '36px',
  width: '100%',
  maxHeight: '692px',
  height: '100%',
  background: '#292929',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    padding: '36px 16px'
  }
}))

export const NoTicketFound = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const NoTicketFoundText = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Inter'`,
  fontSize: '24px',
  marginTop: '15px',
  lineHeight: '123.5%',
  fontWeight: theme.palette.fontWeight['400']
}))

export const SupportMessageWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '36px'
}))

export const SupportMessageSender = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const SupportMessageName = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '150%',
  fontWeight: theme.palette.fontWeight['400'],
  color: theme.palette.common.white
}))

export const SupportMessageBody = styled(Box)<{ is_admin: boolean }>(({ is_admin }) => ({
  display: 'flex',
  padding: '16px 12px',
  flexDirection: 'column',
  gap: '10px',
  width: '100%',
  minHeight: '56px',
  wordBreak: 'break-all',
  background: '#424242',
  color: '#fff',
  border: is_admin ? '1px solid #424242' : 'none',
  borderRadius: '8px',
  alignSelf: 'stretch',
  marginTop: '12px',
  marginBottom: '8px'
}))

export const SupportMessageTime = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: `'Ubuntu'`,
  lineHeight: '143%',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(255, 255, 255, 0.4)'
}))

export const SentMessageWrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  paddingBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '0 8px 0 8px'
  }
}))

export const SentMessageTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  background: '#424242',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

export const SentMessageButton = styled(Button)(({ theme }) => ({
  family: `'Iter'`,
  fontSize: '13px',
  fontWeight: theme.palette.fontWeight['500'],
  lineHeight: '24px',
  width: '100%',
  maxWidth: 156,
  padding: '16px 22px',
  background: '#186ADF',
  boxShadow: '0px 4px 8px -4px rgba(76, 78, 100, 0.42)',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '16px',
    maxWidth: '500px'
  }
}))

export const SentMessageInputPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginRight: '12px',
  [theme.breakpoints.down('sm')]: {
    marginRight: 0
  }
}))

export const BackIcon = styled(ArrowBackIcon)({ fontSize: '24px', cursor: 'pointer', mb: '4px' })

export const TicketInputAdornment = styled(InputAdornment)(({ theme }) => ({
  paddingRight: '5px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: 0
  }
}))

export const TicketOptionsNavigationItem = styled(MenuItem)(({ theme }) => ({
  family: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  height: '50px',
  lineHeight: '143%',
  borderRadius: '10px',
  letterSpacing: '0.15px',
  color: '#FFF'
}))

export const TicketOptionsIconButton = styled(IconButton)<{ open: boolean }>(({ open }) =>
  open
    ? {
        borderRadius: 0,
        width: '42px',
        height: '42px',
        background: '#424242',
        color: '#FFF'
      }
    : { borderRadius: 0 }
)

export const OneTicketMessagesLoader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
}))

export const ImagePreviewBody = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px'
}))

export const ImagePreview = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '10px',
  cursor: 'pointer'
}))

export const ImagePreviewText = styled(Box)(({ theme }) => ({
  family: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '150%',
  color: 'rgba(255, 255, 255, 0.75)'
}))
