/**
 * Function that checks if 2 array are equal
 * @param newInputs
 * @param lastInputs
 */
export const areInputsEqual = (newInputs: unknown[], lastInputs: unknown[]): boolean => {
  // no checks needed if the inputs length has changed
  if (newInputs.length !== lastInputs.length) {
    return false
  }

  for (let i = 0; i < newInputs.length; i++) {
    // using shallow equality check
    if (newInputs[i] !== lastInputs[i]) {
      return false
    }
  }

  return true
}
