import { styled } from '@mui/material/styles'
import { Box, Button, Toolbar } from '@mui/material'

export const EmailNotVerifiedButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

export const EmailNotVerifiedContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  marginTop: '56px',
  height: '80vh',
  maxWidth: '800px',
  margin: '0 auto',
  justifyContent: 'center'
}))

export const EmailNotVerifiedText = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  lineHeight: '133%',
  color: '#FCFCFC',
  textAlign: 'center',
  marginBottom: '15px',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  }
}))

export const EmailNotVerifiedLoginButton = styled(Button)(({ theme }) => ({
  minWidth: 150,
  marginRight: '15px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '10px',
    marginRight: 0
  }
}))

export const NotVerifiedEmailModalToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'flex-end'
})

export const NotVerifiedEmailDialogBody = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}))

export const NotVerifiedEmailDialogEmail = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px'
}))

export const NotVerifiedEmailDialogTitle = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  color: theme.palette.common.white,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '24px',
  textAlign: 'center',
  lineHeight: '133.3%',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  }
}))

export const NotVerifiedForm = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '30px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))
