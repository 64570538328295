import { ImageWithState } from '@/shared/ui/image-with-state'

type Props = {
  height?: number
  width?: number
  color?: string
}

export const LogoCryptoBlack = ({ height = 58, width = 238, color }: Props) => {
  return (
    <ImageWithState
      src='/images/logos/logo-crypto-black.svg'
      height={height}
      width={width}
      alt='logo-crypto-black'
      color={color}
    />
  )
}
