import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import {
  CryptocurrenciesTableTitle,
  FiatBlockchainIso,
  FiatBlockchainSum,
  FiatBlockchainValue,
  TableCurrenciesCell,
  TableRowCurrencyCell,
  useAppDispatch,
  useAppSelector
} from '@/shared'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { getAuthUserDashboardSpentData } from '@/app/store/thunks/wallet'
import { walletLoadingSelector, walletSliceSelector } from '@/app/store/selectors'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { useEffect } from 'react'

export const WalletBalanceTable = () => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector(walletSliceSelector)
  const loading = useAppSelector(walletLoadingSelector)
  const wallets = wallet?.dashboardSpent?.wallets ?? []
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getAuthUserDashboardSpentData())
  }, [dispatch])

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Skeleton variant='rectangular' width='100%' height={231} />
      ) : (
        <Box>
          <CryptocurrenciesTableTitle>{t('Wallet Balance')}</CryptocurrenciesTableTitle>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '& > .MuiTableCell-head:first-of-type': {
                    paddingLeft: 0
                  }
                }}
              >
                <TableCurrenciesCell>{t('Currency')}</TableCurrenciesCell>
                <TableCurrenciesCell>{t('Amount')}</TableCurrenciesCell>
                <TableCurrenciesCell align='right'>{t('Value for Today')}</TableCurrenciesCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map((row, key) => {
                const currency = row?.iso_name === 'btc' ? '/images/icons/bitcoin.svg' : '/images/icons/bnb.svg'

                return (
                  <TableRow
                    key={key}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& > .MuiTableCell-root:first-of-type': {
                        padding: 0
                      }
                    }}
                  >
                    <TableRowCurrencyCell>
                      <FiatBlockchainValue>
                        <img width={24} height={24} loading='lazy' alt={currency} src={currency} />
                        <Box sx={{ ml: '6px' }}>{row?.exchange_name}</Box>
                        <FiatBlockchainIso>{row?.iso_name?.toLocaleUpperCase()}</FiatBlockchainIso>
                      </FiatBlockchainValue>
                    </TableRowCurrencyCell>
                    <TableRowCurrencyCell>
                      <FiatBlockchainSum>{row?.sum}</FiatBlockchainSum>
                    </TableRowCurrencyCell>
                    <TableRowCurrencyCell align='right'>
                      <FiatBlockchainSum>{`${row?.sum_today} ${row?.user_fiat}`}</FiatBlockchainSum>
                    </TableRowCurrencyCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      )}
    </TableContainer>
  )
}
