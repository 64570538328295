// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
// hook returns tuple(array) with type [any, boolean]
import { MouseEvent, useCallback, useMemo, useState } from 'react'

export const useHover = () => {
  const [hovered, setHovered] = useState<Record<string, any>>({})
  const mouseOver = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const key = target.getAttribute('data-key') as string
    setHovered(curState => ({ ...curState, [key]: true }))
  }, [])

  const mouseOut = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const key = target.getAttribute('data-key') as string
    setHovered(curState => ({ ...curState, [key]: false }))
  }, [])

  return useMemo(() => ({ mouseOver, mouseOut, hovered }), [hovered, mouseOut, mouseOver])
}
