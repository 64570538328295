import TwitterIcon from '@mui/icons-material/Twitter'
import MailIcon from '@mui/icons-material/Mail'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'
import {
  UserFooterPrivacyPolicy,
  UserFooterPrivacyPolicyText,
  UserFooterPrivacyPolicyTitle,
  UserFooterSocial,
  UserFooterSocialsInfo,
  UserFooterSocialsWrapper
} from './styles'
import { useTranslation } from 'react-i18next'
import { LogoCryptoWhite } from '@/shared/ui'
import { LogoCryptoWrapper, LogoWrapper } from '@/shared/ui/blank-layout-header/styles'
import { useRouter } from 'next/router'
import { useAppSelector } from '@/shared/hooks/types'
import { layoutFooterSelector } from '@/app/store/selectors/layout.selectors'
import { HeaderLinkType, WP_URL } from '@/shared'

export const UserFooterSocials = () => {
  /**
   * Variables
   */
  const { t } = useTranslation()
  const router = useRouter()
  const lang = (typeof window !== 'undefined' && localStorage.getItem('i18nextLng')) || 'en'
  const footer = useAppSelector(layoutFooterSelector)
  const socials = footer?.socials
  const privacy = footer?.privacy as HeaderLinkType

  const footerIcon = {
    color: 'rgba(252, 252, 252, 0.6)',
    cursor: 'pointer',
    transition: '.2s ease-in-out',
    '&:hover': {
      color: '#186ADF'
    }
  }

  const socialsMap: Record<string, JSX.Element> = {
    twitter: <TwitterIcon key='twitter' sx={footerIcon} />,
    email: <MailIcon key='mail' sx={footerIcon} />,
    facebook: <FacebookIcon key='facebook' sx={footerIcon} />,
    linkedin: <LinkedInIcon key='linked' sx={footerIcon} />,
    instagram: <InstagramIcon key='instagram' sx={footerIcon} />
  }

  const socialsHandler = (url: string) => router.push(url)

  const footerSocials = Object.entries((socials ?? {}) as HeaderLinkType).map(([name, value]) => (
    <UserFooterSocial key={name} onClick={() => socialsHandler(value)}>
      {socialsMap[name]}
    </UserFooterSocial>
  ))

  return (
    <UserFooterSocialsWrapper>
      <UserFooterSocialsInfo lang={lang}>
        <LogoWrapper onClick={() => router.push('/login')}>
          <LogoCryptoWrapper onClick={() => router.push(WP_URL)}>
            <LogoCryptoWhite height={58} width={238} />
          </LogoCryptoWrapper>
          <UserFooterPrivacyPolicy>
            <UserFooterPrivacyPolicyTitle onClick={() => socialsHandler(privacy?.url)}>
              {t(privacy?.title)}
            </UserFooterPrivacyPolicyTitle>
            <UserFooterPrivacyPolicyText>{t('© 2022 – Crypto Processing')}</UserFooterPrivacyPolicyText>
          </UserFooterPrivacyPolicy>
        </LogoWrapper>
      </UserFooterSocialsInfo>
      <UserFooterSocialsInfo>{footerSocials}</UserFooterSocialsInfo>
    </UserFooterSocialsWrapper>
  )
}
