import { SingleHistoryTicket, useAppSelector } from '@/shared'
import { Box } from '@mui/material'
import { useCallback, useDeferredValue } from 'react'
import { useRouter } from 'next/router'
import { TicketsPagination } from '@/shared/ui/tickets'
import { useTranslation } from 'react-i18next'
import { closedTicketsSelector } from '@/app/store/selectors'

type Props = {
  handleChange(e: any, p: number): void
}

export const TicketsHistoryList = ({ handleChange }: Props) => {
  const { t } = useTranslation()
  const { list: closedTickets, meta } = useAppSelector(closedTicketsSelector)
  const count = Math.ceil(Number(meta?.total) / Number(meta?.perPage))
  const router = useRouter()

  const onTicketClick = useCallback(
    (id: string) => {
      router.push(`/history-tickets/${id}`)
    },
    [router]
  )

  const ticketsList = useDeferredValue(
    closedTickets.map(closedTicket => (
      <Box key={closedTicket.id}>
        <SingleHistoryTicket closedTicket={closedTicket} handleClick={() => onTicketClick(String(closedTicket.id))} />
      </Box>
    ))
  )

  return (
    <Box>
      {ticketsList.length ? (
        <>
          <Box>{ticketsList}</Box>
          <TicketsPagination
            count={count}
            size='large'
            page={Number(meta?.currentPage)}
            shape='circular'
            onChange={handleChange}
          />
        </>
      ) : (
        <Box sx={{ textAlign: 'center', fontSize: '20px' }}>{t('No tickets yet...')}</Box>
      )}
    </Box>
  )
}
