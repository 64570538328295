import { Countdown, RegisterFailedResentEmailButton } from '@/shared'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mui/material/Button/Button'

interface CountDownProps extends ButtonProps {
  countdown: Countdown
  onClick?: () => void
}

export const EmailNotVerifiedButton = ({ onClick, disabled, countdown }: CountDownProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    onClick && onClick()
    countdown.reset({ minutes: 1, seconds: 0 })
  }

  return (
    <div>
      <RegisterFailedResentEmailButton
        variant='outlined'
        disabled={countdown.isActive || disabled}
        onClick={handleClick}
      >
        {countdown.isInactive ? t('Resend email') : countdown.formatted}
      </RegisterFailedResentEmailButton>
    </div>
  )
}
