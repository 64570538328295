import * as styles from '@mui/material/styles'
import * as mui from '@mui/material'

export const ConfirmationWrapper = styles.styled(mui.Box)<mui.BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1200,
  position: 'relative',
  margin: '0 auto',
  marginTop: 56,
  alignItems: 'center'
}))

export const ConfirmationText = mui.styled(mui.Typography)(({ theme }) => ({
  color: theme.palette.textShadow.default,
  textAlign: 'center',
  letterSpacing: theme.palette.letterSpacing.default,
  fontSize: '16px',
  fontWeight: theme.palette.fontWeight['400'],
  [theme.breakpoints.down('sm')]: {
    maxWidth: 545,
    padding: '10px'
  }
}))

export const ConfirmationInner = mui.styled(mui.Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 584
}))

export const EmailBox = mui.styled(mui.Box)(() => ({
  textAlign: 'center',
  margin: '50px 0'
}))

export const ConfirmationArrowBack = mui.styled(mui.Box)(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  left: 10,
  [theme.breakpoints.down('md')]: {
    left: 24
  }
}))

export const VerifyingBox = mui.styled(mui.Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60vh'
})
