/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { FormControl, FormHelperText, TextFieldProps } from '@mui/material'

/**
 * styled items from styling
 */
import { DashboardTextInput, DashboardTextInputControl, DashboardTextInputLabel } from './styles'

/**
 * DashboardFormInput type for props
 */
type FormInputProps = {
  control?: Control<any> | undefined
  name: string
  noMargin?: boolean
  className?: string
  label: string
  sx?: any
  wrapperSx?: any
} & TextFieldProps

/**
 * Custom form input component
 * @params {FormInputProps} - props for input component
 * @default type - text
 */
export const DashboardFormInput = ({
  control,
  label = '',
  name,
  variant = 'outlined',
  wrapperSx,
  placeholder,
  ...otherProps
}: FormInputProps) => {
  return (
    <FormControl sx={{ mb: '24px', ...wrapperSx }}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => (
          <DashboardTextInputControl>
            <DashboardTextInputLabel sx={{ color: errors && errors[name] && 'error.main' }}>
              {label}
            </DashboardTextInputLabel>
            <DashboardTextInput
              {...field}
              {...otherProps}
              variant={variant}
              error={Boolean(errors[name])}
              placeholder={placeholder}
              InputLabelProps={{ shrink: false }}
            />
            <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
              {errors[name]?.message as string}
            </FormHelperText>
          </DashboardTextInputControl>
        )}
      />
    </FormControl>
  )
}
