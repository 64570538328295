import { DashboardOutlined } from '@/shared/ui/dashboard-button/styles'
import { useTranslation } from 'react-i18next'
import { PersonIcon } from '@/shared/ui/person-icon/person-icon'
import { ButtonProps } from '@mui/material/Button/Button'

interface Props extends ButtonProps {
  onClick?: () => void
  title?: string
  sx?: any
}

export const DashboardButton = ({ onClick, title = '', sx }: Props) => {
  const { t } = useTranslation()

  const handleRedirect = () => {
    onClick && onClick()
  }

  return (
    <DashboardOutlined sx={sx} disableRipple variant='contained' onClick={handleRedirect} endIcon={<PersonIcon />}>
      {t(String(title))}
    </DashboardOutlined>
  )
}
