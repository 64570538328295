import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CryptoCellText, CryptoName, CryptoValue, TablePaper, TableTitle, WalletTableHeading } from './styles'
import { useTranslation } from 'react-i18next'
import { TableNoTransactions } from '@/shared'

type WalletTableRow = { blockchainName: string; blockchainValue: string; balanceInTokens: string; equivalent: string }

type Props = Readonly<{ title: string; rows: WalletTableRow[] }>

export const ShopWalletTable = ({ title, rows }: Props) => {
  const { t } = useTranslation()

  return (
    <TableContainer component={TablePaper} sx={{ minHeight: 'fit-content' }}>
      <Box sx={{ padding: '24px' }}>
        <TableTitle>{title}</TableTitle>
        <Table title={title}>
          <TableHead>
            <TableRow>
              <WalletTableHeading>{t('Currency')}</WalletTableHeading>
              <WalletTableHeading>{t('Balance in tokens')}</WalletTableHeading>
              <WalletTableHeading align='right'>{t('Equivalent')}</WalletTableHeading>
            </TableRow>
          </TableHead>
          {rows.length > 0 ? (
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.blockchainName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    <CryptoName>{t(row.blockchainName)}</CryptoName>
                    <CryptoValue>{row.blockchainValue}</CryptoValue>
                  </TableCell>
                  <TableCell>
                    <CryptoCellText>{row.balanceInTokens}</CryptoCellText>
                  </TableCell>
                  <TableCell align='right'>
                    <CryptoCellText>{row.equivalent}</CryptoCellText>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableNoTransactions>{t('There is no transactions for this store yet')}</TableNoTransactions>
          )}
        </Table>
      </Box>
    </TableContainer>
  )
}
