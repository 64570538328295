import {
  SentMessageButton,
  SentMessageInputPaper,
  SentMessageTextField,
  SentMessageWrapper,
  TicketInputAdornment
} from '@/shared/ui/one-ticket'
import { useRouter } from 'next/router'
import { useAppDispatch } from '@/shared/hooks/types'
import { getAllTicketsMessages, replyInTicket } from '@/app/store/thunks/tickets'
import { useCallback, useMemo, useState } from 'react'
import { FileProp } from '@/pages/submit-ticket'
import { MessageFileUploader } from '@/widgets/forms/form-elements/file-uploader'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CircularProgress, FormHelperText } from '@mui/material'
import { flushSync } from 'react-dom'
import { sentSupportMessageFormSchema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

export type SendSupportMessageValues = {
  message: ''
}

export const SendSupportMessage = () => {
  /**
   * State hooks
   */
  const [attachedFiles, setAttachedFiles] = useState<File[]>([])
  const { t, i18n } = useTranslation()
  const schema = useMemo(() => sentSupportMessageFormSchema(), [i18n.language])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const MAX_LENGTH_EACH_FILE = 10e6
      const someFileGreaterThanLimit = acceptedFiles.some(
        file => file.size > MAX_LENGTH_EACH_FILE || file.type === 'image/svg+xml'
      )
      if (someFileGreaterThanLimit) {
        toast.error(
          t(
            `You can only upload 3 files, maximum size of 10 MB. The File must be a file of type: jpg, jpeg, png, gif, heic, ico, webp, mp4, mov, wav, mp3, m4a, pdf, doc, docx, txt, rtf, xls, xlsx, ppt, pptx, csv, bmp.`
          ),
          {
            duration: 2000,
            position: 'bottom-right'
          }
        )
        setAttachedFiles([])

        return
      }

      setAttachedFiles(acceptedFiles.map(file => Object.assign(file)))
    },
    [t]
  )

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isValid, isDirty, isSubmitting }
  } = useForm<SendSupportMessageValues>({
    defaultValues: { message: '' },
    mode: 'onChange',
    resolver: yupResolver(schema)
  })
  const handleRemoveFile = (file: FileProp) => {
    const filtered = attachedFiles.filter((i: FileProp) => i.name !== file.name)
    setAttachedFiles([...filtered])
  }

  const { query } = useRouter()
  const { id } = query
  const dispatch = useAppDispatch()
  const { message } = watch()

  const onSubmit: SubmitHandler<SendSupportMessageValues> = async data => {
    await flushSync(async () => {
      /**
       * Add files to FormData
       */
      const formData = new FormData()

      /**
       * append formData for each file
       */
      for (let i = 0; i < attachedFiles.length; i++) {
        formData.append(`files[${i}]`, attachedFiles[i])
      }

      formData.append('id', String(id))
      formData.append('message', data.message)

      const ticketData = { ticketId: String(id), data: formData }
      const response = await dispatch(replyInTicket({ ...ticketData, setError }))

      if (response?.payload !== undefined) {
        dispatch(getAllTicketsMessages({ ticketId: String(id) }))
        setValue('message', '')
        setAttachedFiles([])
      }
    })
  }

  return (
    <SentMessageWrapper onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='message'
        control={control}
        render={({ field }) => (
          <SentMessageInputPaper>
            <SentMessageTextField
              fullWidth
              {...field}
              placeholder={t('Type here to send message additional message to ticket')}
              error={Boolean(errors['message'])}
              autoComplete='off'
              inputProps={{ maxLength: 1000 }}
              InputProps={{
                endAdornment: (
                  <TicketInputAdornment position='end'>
                    <MessageFileUploader onDrop={onDrop} handleRemoveFile={handleRemoveFile} files={attachedFiles} />
                  </TicketInputAdornment>
                )
              }}
            />
            {errors && errors['message'] && (
              <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                {errors['message'].message}
              </FormHelperText>
            )}
          </SentMessageInputPaper>
        )}
      />
      <SentMessageButton
        type='submit'
        variant='contained'
        disabled={!isDirty || !isValid || isSubmitting || !message.trim().length}
      >
        {isSubmitting ? <CircularProgress color='inherit' size={20} /> : t('Send message')}
      </SentMessageButton>
    </SentMessageWrapper>
  )
}
