import { Box } from '@mui/material'
import { styled } from '@mui/system'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'

export const UserDropdownMenuItem = styled(Box)(({ theme }) => ({
  padding: '10px 15px',
  width: '100%',
  display: 'flex',
  whiteSpace: 'break-spaces',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '143%',
  alignItems: 'center',
  color: theme.palette.common.white,
  '&:last-child': {
    color: 'rgba(255, 255, 255, 0.75)'
  },
  '& svg': {
    fontSize: '1.375rem',
    color: 'text.secondary'
  }
}))

export const LogoutButton = styled(LogoutVariant)(() => ({
  '&:last-child': {
    color: 'rgba(255, 255, 255, 0.75)'
  }
}))
