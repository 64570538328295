import { Box, Menu } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { MouseEvent, useState } from 'react'
import { paperProps } from './utils'
import EditIcon from '@mui/icons-material/Edit'
import { PlatformOptionsIconButton, PlatformOptionsNavigationItem } from '@/shared/ui/platforms'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

type Props = {
  enableEditMode?: () => void
  onDeleteStore: () => void
}

export const PlatformOptionsMenu = ({ onDeleteStore, enableEditMode }: Props) => {
  /**
   * States
   */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useTranslation()

  /**
   * Variables
   */
  const open = Boolean(anchorEl)

  /**
   * Click handlers
   *
   */
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ position: 'absolute', right: 40, top: '15px' }} onClick={e => e.stopPropagation()}>
      <PlatformOptionsIconButton
        open={open}
        aria-controls={open ? 'long-menu' : undefined}
        disableRipple
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </PlatformOptionsIconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
      >
        {enableEditMode ? (
          <PlatformOptionsNavigationItem
            key={'Rename store'}
            onClick={() => {
              enableEditMode && enableEditMode()
              handleClose()
            }}
          >
            <EditIcon sx={{ fontSize: '20px', mr: '10px' }} /> {t('Rename store')}
          </PlatformOptionsNavigationItem>
        ) : null}
        <PlatformOptionsNavigationItem
          key={'Delete store'}
          onClick={() => {
            onDeleteStore()
            handleClose()
          }}
        >
          <DeleteIcon sx={{ fontSize: '20px', mr: '10px' }} /> {t('Delete store')}
        </PlatformOptionsNavigationItem>
      </Menu>
    </Box>
  )
}
