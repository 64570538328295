import {
  AccordionEditForm,
  AccordionEditFormButtons,
  IconCheckButton,
  IStore,
  PlatformAccordionEditInput,
  PlatformCheck,
  useAppDispatch
} from '@/shared'
import CloseIcon from '@mui/icons-material/Close'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { platformAccordionEditFromSchema } from '@/shared/ui/platforms/ui/platform-accordion-edit-from.schema'
import { FormHelperText, IconButton } from '@mui/material'
import { getStores, updateStoreName } from '@/app/store/thunks'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type PlatformAccordionEditFormData = { name: string }

type Props = {
  store: IStore & { panel: string }
  disableEditMode: () => void
}

export const PlatformAccordionEditForm = ({ store, disableEditMode }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<PlatformAccordionEditFormData>({
    mode: 'onChange',
    defaultValues: { name: store?.name },
    resolver: yupResolver(platformAccordionEditFromSchema)
  })

  const { name } = watch()
  const isEdited = name !== store?.name

  const onSubmit: SubmitHandler<PlatformAccordionEditFormData> = async ({ name }) => {
    await dispatch(updateStoreName({ id: String(store?.id), name }))
      .unwrap()
      .then(() => {
        disableEditMode()
        toast.success(t('Store updated!'), { position: 'bottom-right' })
        dispatch(getStores({}))
      })
  }

  return (
    <AccordionEditForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={'name'}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <PlatformAccordionEditInput
            {...field}
            variant={'standard'}
            fullWidth
            autoComplete='off'
            onChange={e => {
              const value = e.target.value.replace(/^[\u0400-\u04FF]+$/, '')
              field.onChange(value)
            }}
            error={Boolean(errors['name'])}
            inputProps={{ maxLength: 20 }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <AccordionEditFormButtons>
                  <IconButton disableTouchRipple disableRipple onClick={disableEditMode}>
                    <CloseIcon sx={{ color: 'rgba(255, 255, 255, 0.54)', cursor: 'pointer' }} />
                  </IconButton>
                  <IconCheckButton disableTouchRipple disableRipple type='submit' disabled={!isEdited || !name.length}>
                    <PlatformCheck />
                  </IconCheckButton>
                </AccordionEditFormButtons>
              )
            }}
            InputLabelProps={{ shrink: false }}
          />
        )}
      />
      {errors && errors['name'] && (
        <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>{errors['name'].message}</FormHelperText>
      )}
    </AccordionEditForm>
  )
}
