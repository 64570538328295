import { AlertsValuesType } from '@/app/context/types'
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { InfoModule, slugify, useAppDispatch, useAppSelector, UserAlert } from '@/shared'
import { userRootState } from '@/app/store/selectors'
import Stack from '@mui/material/Stack'
import { AlertColor } from '@mui/material'
import { getAlert, updateAlertShownStatus } from '@/app/store/thunks'
import { useRouter } from 'next/router'
import { pageModuleStateState } from '@/app/store/selectors/pages-module.selectors'
import { SinglePageInfoModuleWrapper } from '@/shared/ui/info-module/styles'
import { AlertDescription, SnackAlert, SnackAlertTitle } from '@/shared/ui/alert/styles'

export const defaultAlertProvider: AlertsValuesType = {
  renderAlertsFx: () => <></>,
  renderInfoModulesFx: () => <></>,
  showAlerts: () => null
}

const AlertsContext = createContext(defaultAlertProvider)

const AlertsProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch()
  const controller = new AbortController()
  const signal = controller.signal
  const router = useRouter()
  const [infoVisible, setInfoVisible] = useState(true)
  const userData = useAppSelector(userRootState)
  const [alerts, setAlerts] = useState<UserAlert[]>([])
  const page = useAppSelector(pageModuleStateState)
  const modulesAvailable = page?.modules.length > 0
  const slug = slugify(router?.asPath)
  const updateAlertStatusFx = useCallback(
    async (id: number) => {
      const response = await dispatch(updateAlertShownStatus({ id }))
      const alertsAfterAction = alerts.filter(alert => alert.id !== id)
      const hasErrors = response.payload === undefined || response.payload.errors
      if (!hasErrors) {
        setAlerts(alertsAfterAction)
        dispatch(getAlert({ slug, signal }))
      }
    },
    [alerts, dispatch, signal, slug]
  )

  const showAlerts = useCallback(() => {
    setInfoVisible(true)
  }, [])

  const hideAlerts = useCallback(() => {
    setInfoVisible(false)
  }, [])

  useEffect(() => {
    if (userData?.alerts) {
      setAlerts(userData?.alerts || [])
    }
  }, [userData?.alerts])

  const renderAlertsFx = () => {
    return (
      <Stack sx={{ zIndex: 999, position: 'fixed', bottom: 10, right: 24 }} spacing={2}>
        {infoVisible
          ? alerts.map(alert => {
              const pattern = /(?<=>)([^<>]+?)(?=<\/)/gm
              const alertTitle = alert?.title ?? ''
              const isTitleEmpty = alertTitle?.match(pattern) === null

              return (
                <SnackAlert
                  key={alert?.title}
                  variant='filled'
                  onClose={async () => {
                    await updateAlertStatusFx(alert.id)
                  }}
                  color={alert.type as AlertColor}
                  severity={alert.type as AlertColor}
                >
                  {!isTitleEmpty ? <SnackAlertTitle dangerouslySetInnerHTML={{ __html: alertTitle }} /> : null}
                  <AlertDescription>{alert?.description || ''}</AlertDescription>
                </SnackAlert>
              )
            })
          : null}
      </Stack>
    )
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', hideAlerts)

    return () => {
      router.events.off('routeChangeComplete', hideAlerts)
    }
  }, [hideAlerts, router.events, showAlerts])

  const renderInfoModulesFx = () =>
    infoVisible && modulesAvailable ? (
      <SinglePageInfoModuleWrapper>
        {page?.modules?.map((module, idx) => (
          <InfoModule key={idx} {...module} />
        ))}
      </SinglePageInfoModuleWrapper>
    ) : null

  const values = {
    renderAlertsFx,
    showAlerts,
    renderInfoModulesFx
  }

  return <AlertsContext.Provider value={values}>{children}</AlertsContext.Provider>
}

export { AlertsProvider, AlertsContext }
