import { memo } from 'react'
import {
  SingleTicketContainer,
  SingleTicketContent,
  SingleTicketInfo,
  SingleTicketInfoBody,
  SingleTicketOptions,
  SupportImage,
  TicketCreatedAt,
  TicketLastMessage,
  TicketMessageContent,
  TicketName,
  TicketStatus,
  TicketUniqueIdentifier
} from './styles'
import { Box } from '@mui/material'
import { ITicket, tokenService } from '@/shared'
import { TicketOptionsMenu } from '@/shared/ui/one-ticket'
import { useTranslation } from 'react-i18next'
import { useDateString } from '@/shared/hooks/useDateString'
import { truncateString } from '@/shared/utils/truncate'
import Image from 'next/image'

type Props = {
  handleClick: () => void
  ticket: ITicket
}

export const SingleTicket = memo(({ ticket, handleClick }: Props) => {
  /**
   * variables
   */
  const { t } = useTranslation()
  const options = [t('Close ticket')]
  const reportTime = useDateString(ticket?.report_time)
  const user = tokenService.getUser()
  const userName = user?.name
  const isSupportManager = ticket.last_message.author === 'Support Manager'
  const isUserSender = ticket.last_message.author === userName
  const lastMessage = ticket.last_message.message
  const sender = `${isSupportManager ? t('Support') : isUserSender ? t('You') : ticket.last_message.author}:`

  const messageIcon = (
    <TicketMessageContent>
      {isSupportManager && (
        <SupportImage>
          <Image width={24} height={24} alt={'support-icon'} src={'/images/icons/support-icon.svg'} color={'#424242'} />
        </SupportImage>
      )}
      {ticket.last_message.author ? (
        <TicketLastMessage>{truncateString(`${sender} ${lastMessage}`, 50)}</TicketLastMessage>
      ) : null}
    </TicketMessageContent>
  )

  return (
    <SingleTicketContainer onClick={handleClick}>
      <SingleTicketContent>
        <SingleTicketInfo>
          <SingleTicketInfoBody>
            <TicketCreatedAt>{`${t('Created')} ${t('on')} ${reportTime}`}</TicketCreatedAt>
            <SingleTicketOptions>
              <TicketStatus variant={'contained'} disableTouchRipple>
                {t(ticket?.status)}
              </TicketStatus>
              <Box sx={{ ml: '12px' }} onClick={e => e.stopPropagation()}>
                <TicketOptionsMenu options={options} ticket={ticket as ITicket} />
              </Box>
            </SingleTicketOptions>
          </SingleTicketInfoBody>
          {ticket?.id && <TicketUniqueIdentifier>#{ticket?.id}</TicketUniqueIdentifier>}
          {ticket?.subject && <TicketName>{truncateString(ticket?.subject, 20)}</TicketName>}
          <TicketLastMessage>{messageIcon}</TicketLastMessage>
        </SingleTicketInfo>
      </SingleTicketContent>
    </SingleTicketContainer>
  )
})
