import Chip from '@mui/material/Chip'
import { historyStateSelector } from '@/app/store/selectors/history.selectors'
import { useAppDispatch, useAppSelector } from '@/shared'
import { PaymentsFiltersMenu } from './payments-filters-menu'
import { ChipList, ChipListItem } from './styles'
import Zoom from '@mui/material/Zoom'
import { memo, useCallback } from 'react'
import { setRequestFilters, setRequestParams } from '@/app/store/apps/history'
import useMediaQuery from '@mui/material/useMediaQuery'

export const PaymentsFiltersToolbar = memo(() => {
  const {
    history: { filters },
    filters: selectedFilters
  } = useAppSelector(historyStateSelector)

  const selectedValues = Object.values(filters ?? {}).flat()
  const chipsData = Object.values(filters ?? {}).flat()
  const keys = Object.keys(filters ?? {})
  const hasFilters = keys.length > 0
  const isMobile = useMediaQuery('(max-width: 767px)')
  const showLimitExceeded = selectedValues.length > 5 || (isMobile && selectedValues.length > 1)
  const dispatch = useAppDispatch()
  const handleDelete = useCallback(
    (chipToDelete: string) => {
      const filtersMap = Object.entries(selectedFilters ?? {}).map(([key, value]) => {
        const filterField = Array.isArray(value)
          ? value.filter(el => el !== chipToDelete)
          : value.split(' ').filter(el => el !== chipToDelete)

        return [key, filterField]
      })
      const filtersList = Object.fromEntries(filtersMap)
      Object.keys(filtersList || {}).forEach(key => !filtersList[key].length && delete filtersList[key])

      dispatch(setRequestParams({ page: 0 }))
      dispatch(setRequestFilters(filtersList))
    },
    [dispatch, selectedFilters]
  )

  return (
    <ChipList component='ul' sx={{ p: 0 }}>
      {!showLimitExceeded ? (
        chipsData.map((chip, idx) => (
          <Zoom in={hasFilters} key={idx}>
            <ChipListItem>
              <Chip label={chip.substring(0, 10)} variant='outlined' onDelete={() => handleDelete(chip)} />
            </ChipListItem>
          </Zoom>
        ))
      ) : (
        <PaymentsFiltersMenu chipsData={chipsData} handleDelete={handleDelete} />
      )}
    </ChipList>
  )
})
