import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseState } from '@/app/store/types'
import { GetPageModuleArgs, GetPageTooltipArgs, PageModule, PagesTooltip } from '@/shared'
import { getPageModules, getPagesTooltip } from '@/app/store/thunks/pages-module'

type PagesModuleState = {
  pages: PagesTooltip | null
  modules: PageModule[]
}

type PagesModuleSliceState = BaseState<PagesModuleState>

const initialState: PagesModuleSliceState = {
  data: {
    pages: null,
    modules: []
  },
  error: '',
  status: 'idle'
}

export const pagesModuleSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPagesTooltip.pending, (state: PagesModuleSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(getPageModules.pending, (state: PagesModuleSliceState) => {
      state.status = 'pending'
    })
    builder.addCase(
      getPagesTooltip.fulfilled,
      (state: PagesModuleSliceState, action: PayloadAction<GetPageTooltipArgs>) => {
        state.status = 'fulfilled'
        state.data.pages = action.payload.pages
      }
    )
    builder.addCase(
      getPageModules.fulfilled,
      (state: PagesModuleSliceState, action: PayloadAction<GetPageModuleArgs>) => {
        state.status = 'fulfilled'
        state.data.modules = action.payload.modules
      }
    )
  }
})

const { reducer } = pagesModuleSlice
export default reducer
