// ** React Imports
import { ReactNode, useEffect, useRef, useState } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import MuiDialog from '@mui/material/Dialog'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'

// ** Icons Imports
import Tab from 'mdi-material-ui/Tab'
import Magnify from 'mdi-material-ui/Magnify'
import Lastpass from 'mdi-material-ui/Lastpass'
import ChartDonut from 'mdi-material-ui/ChartDonut'
import CartOutline from 'mdi-material-ui/CartOutline'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import AccountGroup from 'mdi-material-ui/AccountGroup'
import CalendarBlank from 'mdi-material-ui/CalendarBlank'
import CalendarRange from 'mdi-material-ui/CalendarRange'
import ViewGridOutline from 'mdi-material-ui/ViewGridOutline'
import GestureTapButton from 'mdi-material-ui/GestureTapButton'
import AccountCogOutline from 'mdi-material-ui/AccountCogOutline'
import FormatListCheckbox from 'mdi-material-ui/FormatListCheckbox'
import FormatListNumbered from 'mdi-material-ui/FormatListNumbered'
import ChartTimelineVariant from 'mdi-material-ui/ChartTimelineVariant'
import FormatTextVariantOutline from 'mdi-material-ui/FormatTextVariantOutline'
import CardBulletedSettingsOutline from 'mdi-material-ui/CardBulletedSettingsOutline'

// ** Third Party Imports
// ** Types Imports
import { Settings } from '@/app/context/settings-context'

// ** Configs Imports
// ** Custom Components Imports
// ** API Icon Import with object

interface Props {
  hidden: boolean
  settings: Settings
}

interface DefaultSuggestionsProps {
  setOpenDialog: (val: boolean) => void
}

interface DefaultSuggestionsType {
  category: string
  suggestions: {
    link: string
    icon: ReactNode
    suggestion: string
  }[]
}

const defaultSuggestionsData: DefaultSuggestionsType[] = [
  {
    category: 'Popular Searches',
    suggestions: [
      {
        suggestion: 'CRM',
        link: '/watcher-dashboard',
        icon: <ChartDonut fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Analytics',
        link: '/watcher-dashboard/analytics/',
        icon: <ChartTimelineVariant fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'eCommerce',
        link: '/watcher-dashboard/merchant-platforms/',
        icon: <CartOutline fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'User List',
        link: '/apps/user-settings-icon/list/',
        icon: <AccountGroup fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      }
    ]
  },
  {
    category: 'Apps & Pages',
    suggestions: [
      {
        suggestion: 'Calendar',
        link: '/apps/layout/',
        icon: <CalendarBlank fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Invoice List',
        link: '/apps/invoice/list/',
        icon: <FormatListNumbered fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Pricing',
        link: '/pages/pricing/',
        icon: <CurrencyUsd fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Account Settings',
        link: '/pages/settings/',
        icon: <AccountCogOutline fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      }
    ]
  },
  {
    category: 'User Interface',
    suggestions: [
      {
        suggestion: 'Typography',
        link: '/ui/typography/',
        icon: <FormatTextVariantOutline fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Tabs',
        link: '/widgets/tabs/',
        icon: <Tab fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Buttons',
        link: '/widgets/buttons/',
        icon: <GestureTapButton fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Advanced Cards',
        link: '/ui/cards/advanced/',
        icon: <CardBulletedSettingsOutline fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      }
    ]
  },
  {
    category: 'Forms & Tables',
    suggestions: [
      {
        suggestion: 'Select',
        link: '/forms/form-elements/select/',
        icon: <FormatListCheckbox fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Autocomplete',
        link: '/forms/form-elements/autocomplete/',
        icon: <Lastpass fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Table',
        link: '/tables/mui/',
        icon: <ViewGridOutline fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      },
      {
        suggestion: 'Date Pickers',
        link: '/forms/form-elements/pickers/',
        icon: <CalendarRange fontSize='small' sx={{ mr: 2.5, color: 'text.primary' }} />
      }
    ]
  }
]

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(4px)'
  },
  '& .MuiDialog-paper': {
    overflow: 'hidden',
    '&:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: 550
    }
  }
})
const DefaultSuggestions = ({ setOpenDialog }: DefaultSuggestionsProps) => {
  return (
    <Grid container spacing={6} sx={{ ml: 0 }}>
      {defaultSuggestionsData.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Typography component='p' variant='overline' sx={{ lineHeight: 1.25, color: 'text.disabled' }}>
            {item.category}
          </Typography>
          <List sx={{ py: 2.5 }}>
            {item.suggestions.map((suggestionItem, index2) => (
              <ListItem key={index2} sx={{ py: 2 }} disablePadding>
                <Link passHref href={suggestionItem.link}>
                  <Box
                    component='a'
                    onClick={() => setOpenDialog(false)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      '&:hover > *': { color: 'primary.main' }
                    }}
                  >
                    {suggestionItem.icon}
                    <Typography variant='body2' sx={{ color: 'text.primary' }}>
                      {suggestionItem.suggestion}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  )
}

const AutocompleteComponent = ({ hidden, settings }: Props) => {
  // ** States
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  // ** Hooks & Vars
  const theme = useTheme()
  const { layout } = settings
  const wrapper = useRef<HTMLDivElement>(null)
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setIsMounted(true)

    return () => setIsMounted(false)
  }, [])

  if (!isMounted) {
    return null
  } else {
    return (
      <Box
        ref={wrapper}
        onClick={() => !openDialog && setOpenDialog(true)}
        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
      >
        <IconButton color='inherit' sx={!hidden && layout === 'vertical' ? { mr: 1, ml: -2.75 } : {}}>
          <Magnify />
        </IconButton>
        {!hidden && layout === 'vertical' ? (
          <Typography sx={{ color: 'text.disabled' }}>Search (Ctrl+/)</Typography>
        ) : null}
        <Dialog fullWidth open={openDialog} fullScreen={fullScreenDialog} onClose={() => setOpenDialog(false)}>
          <Box sx={{ top: 0, width: '100%', position: 'sticky' }} />
          <Box
            sx={{
              p: 10,
              display: 'grid',
              overflow: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              borderTop: `1px solid ${theme.palette.divider}`,
              height: fullScreenDialog ? 'calc(100vh - 69px)' : '100%'
            }}
          >
            <DefaultSuggestions setOpenDialog={setOpenDialog} />
          </Box>
        </Dialog>
      </Box>
    )
  }
}

export default AutocompleteComponent
