/**
 * React hook form, yup imports
 */
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { setNewEmailFormSchema } from '@/widgets/pages/settings'

// ** UI elements
import { CircularProgress } from '@mui/material'

/**
 * Styled imports
 */
import {
  EmailFormButtons,
  FormCancelButton,
  FormEmailWrapper,
  SetNewEmailBody,
  SetNewEmailInner,
  SetNewEmailSubmitButton
} from './styles'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { addServerErrors } from '@/shared'
import { useAppDispatch } from '@/shared/hooks/types'
import { getCurrentUser } from '@/app/store/thunks'
import { DashboardFormInput, DashboardFormPassword } from '@/shared/ui/react-hook-form'
import { userService } from '@/shared/services/user.service'
import toast from 'react-hot-toast'

/**
 * defaultValues for form
 */
const defaultValues = {
  email: '',
  password: ''
}

/**
 * NewPasswordFormData interface for register form
 */
export interface NewEmailFormData {
  email: string
  password: string
}

export const SetNewEmailForm = () => {
  /**
   * Hooks
   */
  const router = useRouter()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // ** react hook form
  const schema = useMemo(() => setNewEmailFormSchema(), [i18n.language])

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<NewEmailFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** submit form handler
  const onSubmit: SubmitHandler<NewEmailFormData> = async data => {
    const res = await userService.updateUserEmail(data, i18n.language).catch(err => {
      const errors = err?.response?.data?.errors
      addServerErrors(errors, setError)
    })

    if (res) {
      toast.success(t('Email successfully updated!'), { position: 'bottom-right' })
      await router.push('/settings/set-email-success')
    }
  }

  /**
   * request a user
   */
  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  /**
   * handlers
   */
  const onFormCancel = useCallback(async () => {
    reset()
    await router.push('/settings')
  }, [reset, router])

  return (
    <FormEmailWrapper>
      <SetNewEmailInner noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <SetNewEmailBody>
          <DashboardFormInput
            name='email'
            wrapperSx={{ mb: '8px' }}
            control={control}
            label={`${t('New email')}*`}
            inputProps={{ minLength: 8, maxLength: 100 }}
          />
          <DashboardFormPassword
            name='password'
            wrapperSx={{ mb: '8px' }}
            control={control}
            label={`${t('Password')}*`}
            inputProps={{ minLength: 8, maxLength: 16 }}
          />
        </SetNewEmailBody>
        <EmailFormButtons>
          <FormCancelButton variant='outlined' onClick={onFormCancel}>
            {t('Cancel')}
          </FormCancelButton>
          <SetNewEmailSubmitButton
            size='large'
            disabled={!isDirty || !isValid || isSubmitting}
            type='submit'
            variant='contained'
          >
            {isSubmitting ? <CircularProgress size={20} /> : t('Save changes')}
          </SetNewEmailSubmitButton>
        </EmailFormButtons>
      </SetNewEmailInner>
    </FormEmailWrapper>
  )
}
