import { createContext, ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  AuthValuesType,
  ErrCallbackType,
  ForgotPasswordArgs,
  LoginArgs,
  RegisterArgs,
  ResetPasswordArgs
} from './types'
import { AxiosError } from 'axios'
import { authConfig } from '@/shared/configs/auth'
import { axiosInstance, tokenService, useAppDispatch, usePreviousRoute, UserType, useTicketsWatchCount } from '@/shared'
import { UseFormSetError } from 'react-hook-form'
import { LoginFormData } from '@/pages/login'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { getCurrentUser } from '@/app/store/thunks'
import { BroadcastChannel } from 'broadcast-channel'

type Props = {
  children: ReactNode
}

// ** Defaults
export const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  onAccountDelete: () => Promise.resolve(),
  logoutAllTabs: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
  forgot: () => Promise.resolve(),
  reset: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }: Props) => {
  const logoutChannel = new BroadcastChannel('logout')
  const [user, setUser] = useState<UserType | null>(defaultProvider.user)
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState<boolean>(defaultProvider.isInitialized)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const defaultParams = {
    lang: i18n.language
  }

  const router = useRouter()
  const isNotRegisterConfirmed = router.pathname !== '/register-confirmation'
  const previousRoute = usePreviousRoute()
  const redirectTo = previousRoute === '/confirm-delete' ? '/you-account-deleted' : '/login'

  useTicketsWatchCount()

  useEffect(() => {
    const storedToken = Cookies.get(authConfig.storageTokenKeyName)

    const initAuth = async (): Promise<void> => {
      setIsInitialized(true)
      if (storedToken && isNotRegisterConfirmed) {
        await dispatch(getCurrentUser())
          .then(async ({ payload }) => {
            setLoading(false)
            if (payload !== undefined) {
              tokenService.setUser(payload as UserType)
              setUser(payload as UserType)
            }
          })
          .catch(() => {
            setLoading(false)
            setUser(null)
          })
      } else {
        setLoading(false)
      }
    }
    initAuth()
  }, [dispatch, isNotRegisterConfirmed])

  const handleLogin = async (
    data: LoginArgs,
    errorCallback?: ErrCallbackType,
    setError?: UseFormSetError<LoginFormData>
  ) => {
    return await axiosInstance
      .post(authConfig.loginEndpoint, data, { params: { ...defaultParams, timezone } })
      .then(async ({ data }) => {
        const loginData = data?.data
        const isEnabledTwoFactor = loginData?.two_factor_enabled
        const isDisabledTwoFactor = !isEnabledTwoFactor
        const isVerifiedUser = loginData?.is_verified
        tokenService.removeCookieValue(authConfig.storageReferralTokenKeyName)
        tokenService.setCookieValue(authConfig.storageTwoFactorPassedKey, '0')
        tokenService.setCookieValue(authConfig.userVerifiedStatus, isVerifiedUser)
        tokenService.setCookieValue(authConfig.storageTwoFactorKey, isEnabledTwoFactor)
        if (loginData?.access_token && loginData?.refresh_token) {
          tokenService.setLocalAccessToken(loginData?.access_token, loginData?.expires_in)
          tokenService.setLocalRefreshToken(loginData?.refresh_token)
        }
        if (isVerifiedUser && isEnabledTwoFactor) {
          await router.replace('/login-second-step')
        }
        if (!isVerifiedUser && setError) {
          setError('email', { message: '' })
          setError('password', { message: '' })
          toast.error(t("Your email isn't verified."), { position: 'bottom-right' })
          await router.replace('/email-not-verified')
        }
        if (loginData?.message && setError) {
          setError('email', { message: '' })
          setError('password', { message: '' })
        }

        if (isVerifiedUser && isDisabledTwoFactor) {
          await dispatch(getCurrentUser()).then(async ({ payload }) => {
            const userData = payload as UserType
            const isEnabledTwoFactor = userData?.is_two_auth_enabled
            const isVerifiedUser = userData?.is_verified
            setUser(userData)
            if (isVerifiedUser && !isEnabledTwoFactor) {
              const returnUrl = router.query.returnUrl
              const fromTicketUrl = previousRoute === '/submit-ticket/' ? '/submit-ticket' : returnUrl
              const redirectURL = returnUrl && returnUrl !== '/submit-ticket' ? fromTicketUrl : '/watcher-dashboard'
              await router.replace(redirectURL as string)
            }
            if (!isVerifiedUser) {
              await router.replace('/email-not-verified')
            }
          })
        }
      })
      .catch(err => {
        const message = err?.response?.data?.message ?? ''
        if (errorCallback) errorCallback(err)
        message !== '' && toast.error(i18n.t(message), { position: 'bottom-right' })
        if (setError) {
          setError('email', { message: '' })
          setError('password', { message: '' })
        }
        tokenService.clear()
        setUser(null)
      })
  }

  const channelLogout = async () => {
    await axiosInstance
      .post(authConfig.logoutEndpoint, {}, { params: { ...defaultParams } })
      .then(() => {
        tokenService.clear()
        logoutChannel.postMessage('Logout')
        setIsInitialized(false)
        router.replace(redirectTo)
      })
      .catch((err: AxiosError) => {
        console.error(err)
      })
  }

  const onAccountDelete = async () => {
    setUser(null)
    tokenService.clear()
    logoutChannel.postMessage('Logout')
    setIsInitialized(false)
  }

  const handleLogout = () => {
    router.replace(redirectTo)
  }

  logoutChannel.addEventListener('message', handleLogout)

  const handleRegister = async (registerData: RegisterArgs, errorCallback?: ErrCallbackType) => {
    try {
      const { data } = await (
        await axiosInstance.post(authConfig.registerEndpoint, registerData, { params: { ...defaultParams } })
      ).data
      tokenService.setReferral(data?.user?.referral_token)
      const user = data?.user as UserType
      tokenService.setCookieValue(authConfig.storageUserEmailName, user?.email)

      if (data) {
        await router.push('/register-email-sent')
      }

      return data
    } catch (err) {
      errorCallback && errorCallback(err as AxiosError)
    }
  }

  const forgotPassword = async (data: ForgotPasswordArgs, errorCallback?: ErrCallbackType) => {
    return await axiosInstance
      .post(authConfig.forgotPasswordEndpoint, data, { params: { ...defaultParams } })
      .then(() => {
        router.push('/forgot-password-confirmation')
      })
      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const resetPassword = async (data: ResetPasswordArgs, errorCallback?: ErrCallbackType) => {
    return await axiosInstance
      .post(authConfig.resetPasswordEndpoint, data, { params: { ...defaultParams } })
      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    onAccountDelete,
    setIsInitialized,
    login: handleLogin,
    logout: channelLogout,
    logoutAllTabs: handleLogout,
    register: handleRegister,
    forgot: forgotPassword,
    reset: resetPassword
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
