import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const BreadcrumbsTitle = styled(Box)(({ theme }) => ({
  family: `'Ubuntu', sans-serif`,
  display: 'none',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '24px',
  marginBottom: '4px',
  color: 'rgba(252, 252, 252, 0.6)',
  [theme.breakpoints.down('md')]: {
    display: 'flex'
  }
}))

export const BreadcrumbsHeading = styled(Box)(({ theme }) => ({
  family: `'Iter', sans-serif`,
  display: 'none',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '34px',
  lineHeight: '123%',
  color: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    display: 'flex'
  }
}))
