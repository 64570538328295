// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseState } from '@/app/store/types'
import {
  AllowedWalletsResponseType,
  GetAuthUserDashboardDataArgs,
  GetDashboardSpentData,
  GetWalletsByUsersStoreArgs
} from '@/shared'
import {
  getAuthUserDashboardData,
  getAuthUserDashboardSpentData,
  getWalletAndAllowedCurrencies,
  getWalletsListByUsersStores
} from '@/app/store/thunks/wallet'

type WalletState = BaseState<
  AllowedWalletsResponseType &
    GetWalletsByUsersStoreArgs & {
      dashboard: GetAuthUserDashboardDataArgs
    } & {
      dashboardSpent: GetDashboardSpentData
    }
>

const initialState: WalletState = {
  data: {
    total_wallet_balance: '',
    total_wallet_balance_currency: '',
    currencies: [],
    dashboard: {
      currencies_to_fiats: [],
      transactions: {
        monthly: null,
        today: null
      }
    },
    dashboardSpent: {
      wallets: [],
      currencies_to_fiats: [],
      transactions: {
        monthly: null,
        today: null
      }
    },
    wallet: null,
    meta: null,
    stores: []
  },
  error: '',
  status: 'idle'
}

export const walletSlice = createSlice({
  name: 'appWallet',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWalletAndAllowedCurrencies.pending, (state: WalletState) => {
      state.status = 'pending'
    })
    builder.addCase(getWalletAndAllowedCurrencies.rejected, (state: WalletState) => {
      state.status = 'rejected'
    })
    builder.addCase(getWalletsListByUsersStores.pending, (state: WalletState) => {
      state.status = 'pending'
    })
    builder.addCase(getWalletsListByUsersStores.rejected, (state: WalletState) => {
      state.status = 'rejected'
    })
    builder.addCase(getAuthUserDashboardData.pending, (state: WalletState) => {
      state.status = 'pending'
    })
    builder.addCase(getAuthUserDashboardData.rejected, (state: WalletState) => {
      state.status = 'rejected'
    })
    builder.addCase(getAuthUserDashboardSpentData.pending, (state: WalletState) => {
      state.status = 'pending'
    })
    builder.addCase(getAuthUserDashboardSpentData.rejected, (state: WalletState) => {
      state.status = 'rejected'
    })
    builder.addCase(
      getWalletsListByUsersStores.fulfilled,
      (state: WalletState, action: PayloadAction<GetWalletsByUsersStoreArgs>) => {
        state.status = 'fulfilled'
        state.data.total_wallet_balance_currency = action.payload.total_wallet_balance_currency
        state.data.total_wallet_balance = action.payload.total_wallet_balance
        state.data.stores = action.payload.stores
      }
    )
    builder.addCase(
      getAuthUserDashboardData.fulfilled,
      (state: WalletState, action: PayloadAction<GetAuthUserDashboardDataArgs>) => {
        state.status = 'fulfilled'
        state.data.dashboard = action.payload
      }
    )
    builder.addCase(
      getAuthUserDashboardSpentData.fulfilled,
      (state: WalletState, action: PayloadAction<GetDashboardSpentData>) => {
        state.status = 'fulfilled'
        state.data.dashboardSpent = action.payload
      }
    )
    builder.addCase(
      getWalletAndAllowedCurrencies.fulfilled,
      (state: WalletState, action: PayloadAction<AllowedWalletsResponseType>) => {
        state.data.currencies = action.payload.currencies
        state.data.meta = action.payload.meta
        state.data.wallet = action.payload.wallet
        state.status = 'fulfilled'
      }
    )
  }
})

const { reducer } = walletSlice
export default reducer
