import { AppBar, Box } from '@mui/material'
import { ElevationScroll, LayoutNavigation, LogoCryptoWhite } from '@/shared/ui'
import { UserAppToolbar, UserHeaderWrapper, UserLogoWrapper } from './styles'
import { LanguageDropdown } from '@/shared/layouts'
import { useRouter } from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { LayoutBurgerMenu } from '@/shared/ui/layout-burger-menu'
import { WP_URL } from '@/shared'

export const UserLayoutHeader = () => {
  const router = useRouter()
  const isTablet = useMediaQuery('(max-width: 1023px)')
  const hiddenMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ElevationScroll>
        <AppBar
          component='nav'
          sx={{
            background: '#101010',
            borderBottom: '1px solid #292929',
            padding: hiddenMd ? '1.5px 16px' : '10.5px 16px'
          }}
        >
          <UserAppToolbar disableGutters>
            <UserLogoWrapper onClick={() => router.push(WP_URL)}>
              <LogoCryptoWhite height={isTablet ? 36 : 58} width={isTablet ? 160 : 238} />
            </UserLogoWrapper>
            {isTablet ? (
              <UserHeaderWrapper>
                <LanguageDropdown />
                <LayoutBurgerMenu />
              </UserHeaderWrapper>
            ) : (
              <UserHeaderWrapper>
                <LayoutNavigation />
                <LanguageDropdown sx={{ mr: 0 }} />
              </UserHeaderWrapper>
            )}
          </UserAppToolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  )
}
