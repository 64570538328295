import { SyntheticEvent, useState } from 'react'
import { TabContextContainer } from '@/shared/ui/dashboard'
import { AvailableInWallet } from './available-in-wallet'
import { AllTransactions } from './all-transactions'
import { useTranslation } from 'react-i18next'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import TabList from '@mui/lab/TabList'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'

export const DashboardTabs = () => {
  const [currentTab, setCurrentTab] = useState('all-currencies')
  const { t } = useTranslation()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }

  return (
    <TabContextContainer>
      <TabContext value={currentTab}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab label={t('All currencies')} value='all-currencies' />
            <Tab label={t('Only available in wallet')} value='available-in-wallet' />
          </TabList>
        </Box>
        <TabPanel value='all-currencies'>
          <AllTransactions />
        </TabPanel>
        <TabPanel value='available-in-wallet'>
          <AvailableInWallet />
        </TabPanel>
      </TabContext>
    </TabContextContainer>
  )
}
