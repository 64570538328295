import { useCallback, useEffect, useRef } from 'react'
import { ControllerRef, UseAbortController } from './types'
import axios from 'axios'

export const useAbortController = (): UseAbortController => {
  const controller: ControllerRef = useRef(null)
  const { isCancel } = axios

  const newAbortSignal = useCallback((): AbortSignal => {
    controller.current = new AbortController()

    return controller.current.signal
  }, [])

  const cancelPreviousRequest = useCallback((): void => {
    if (controller.current) controller.current.abort()
  }, [])

  // Cancel pending request on unmount
  useEffect(() => cancelPreviousRequest, [cancelPreviousRequest])

  return { controller, newAbortSignal, cancelPreviousRequest, isCancel }
}
