import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import { Settings } from '@/app/context/settings-context'
import { useTranslation } from 'react-i18next'
import { LogoutButton, UserSettingsIcon } from '@/shared/ui'
import { UserDropdownMenuItem } from './styles'
import { truncateString, useAppDispatch, useAppSelector, useAuth, WP_URL } from '@/shared'
import { userStateSelector } from '@/app/store/selectors'
import { getCurrentUser } from '@/app/store/thunks'

interface Props {
  settings: Settings
}

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(userStateSelector)
  const { t } = useTranslation()

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const { logout } = useAuth()

  // ** Hooks
  const router = useRouter()

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    await logout()
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box onClick={handleDropdownOpen} sx={{ height: '40px' }}>
        <UserSettingsIcon sx={{ fontSize: '40px', cursor: 'pointer' }} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <MenuItem sx={{ p: 0, pointerEvents: 'none' }}>
          <UserDropdownMenuItem>
            {truncateString(String(currentUser?.email) ?? 'example@g.com', 25)}
          </UserDropdownMenuItem>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose(`${WP_URL}/faq`)}>
          <UserDropdownMenuItem>
            <HelpCircleOutline sx={{ mr: 2 }} />
            FAQ
          </UserDropdownMenuItem>
        </MenuItem>
        <Divider />
        <UserDropdownMenuItem onClick={handleLogout}>
          <LogoutButton
            sx={{
              mr: 2,
              fontSize: '1.375rem'
            }}
          />
          {t('Logout')}
        </UserDropdownMenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
