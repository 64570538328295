// ** React Imports
import { ReactElement, ReactNode, useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from '@/shared/hooks/useAuth'
import { authConfig } from '@/shared'
import { useStorage } from '@/shared/hooks/useStorage'

interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const { getItem } = useStorage()
  const auth = useAuth()
  const router = useRouter()
  const isUserExists = getItem(authConfig.storageUserKeyName)

  useEffect(() => {
    const authIgnoreRoutes = ['/your-account-deleted', '/login-second-step']

    if (!router.isReady) {
      return
    }

    if (authIgnoreRoutes.includes(router.pathname)) {
      return
    }
  }, [auth.user, isUserExists, router])

  if (auth.loading) {
    return fallback
  }

  return <>{children}</>
}

export default AuthGuard
