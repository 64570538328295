import { Box } from '@mui/material'
import { styled } from '@mui/system'
import Link from 'next/link'

export const DocsBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const DocsItem = styled(Link)(() => ({
  display: 'flex',
  marginBottom: '16px',
  textDecoration: 'none',
  cursor: 'pointer'
}))

export const DocsTitle = styled('a')(({ theme }) => ({
  fontSize: '16px',
  cursor: 'pointer',
  fontFamily: `'Ubuntu'`,
  textDecoration: 'none',
  lineHeight: '28px',
  fontWeight: theme.palette.fontWeight['400'],
  color: 'rgba(252, 252, 252, 0.6)',
  transition: '.2s ease-in-out',
  '&:hover': {
    color: '#186ADF'
  }
}))
