import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import { FormWrapper } from '@/shared'
import ErrorIcon from '@mui/icons-material/Error'
import { useRouter } from 'next/router'

export const ErrorBoundaryFallback = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const handleReloadFx = () => {
    router.reload()
  }

  return (
    <Box className='content-center' sx={{ pt: 0 }}>
      <FormWrapper>
        <Alert
          severity='error'
          icon={<ErrorIcon fontSize='large' />}
          action={
            <Box onClick={handleReloadFx}>
              <Button color='inherit' size='small'>
                {t('Reload')}
              </Button>
            </Box>
          }
        >
          <AlertTitle sx={{ fontSize: '25px' }}>{t('Internal server error')}</AlertTitle>
          {t('We are currently trying to fix the problem.')}
        </Alert>
      </FormWrapper>
    </Box>
  )
}
