import {
  addServerErrors,
  GetUserWithdrawalsArgs,
  GetUserWithdrawalsData,
  IClosedTicket,
  ITicket,
  ITicketMessageType,
  ITicketMeta,
  ITicketNotify,
  TickerDataType,
  ticketApi
} from '@/shared'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SendSupportMessageValues } from '@/shared/ui/one-ticket/ui/send-support-message'
import { UseFormSetError } from 'react-hook-form'
import { userService } from '@/shared/services/user.service'
import { BaseParams } from '@/app/store/types'

export type ReplyTicketArgs = { ticketId: string; data: TickerDataType | FormData; setError: UseFormSetError<any> }

export type CloseTicketArgs = Pick<ReplyTicketArgs, 'ticketId'>

export const replyInTicket = createAsyncThunk(
  'ticket/replyInTicket',
  async ({ ticketId, data, setError }: ReplyTicketArgs) => {
    try {
      const response = await ticketApi.replyInTicket(ticketId, data)

      return response.data
    } catch (e: any) {
      const errors = e?.response?.data
      addServerErrors<SendSupportMessageValues>(errors, setError)
      throw new Error(e)
    }
  }
)

export const closeTicket = createAsyncThunk('ticket/closeTicket', async ({ ticketId }: CloseTicketArgs) => {
  const response = await ticketApi.closeTicket(ticketId)

  return response.data
})

export const reopenTicket = createAsyncThunk('ticket/reopenTicket', async ({ ticketId }: CloseTicketArgs) => {
  const response = await ticketApi.reopenTicket(ticketId)

  return response.data
})
export const getTicketsNotifyData = createAsyncThunk<ITicketNotify, void>('ticket/getTicketsNotifyData', async () => {
  const response = await ticketApi.getTicketsNotifyData()

  return response.data
})

export const getAllTickets = createAsyncThunk<
  { tickets: ITicket[]; meta: ITicketMeta },
  BaseParams & { status?: string }
>('ticket/getTicketsList', async ({ status, ...params }) => {
  const response = await ticketApi.getAllTickets(params, status ?? 'all')

  return response.data
})

export const getAllClosedTickets = createAsyncThunk<{ tickets: IClosedTicket[]; meta: ITicketMeta }, BaseParams>(
  'ticket/getClosedTicketsList',
  async params => {
    const response = await ticketApi.getAllClosedTickets(params)

    return response.data
  }
)

export const getAllTicketsMessages = createAsyncThunk<{ messages: ITicketMessageType[] }, { ticketId: string }>(
  'ticket/getAllTicketsMessages',
  async ({ ticketId }) => {
    const response = await ticketApi.getAllTicketsMessages(ticketId)

    return response.data
  }
)

export const getUserWithdrawals = createAsyncThunk<GetUserWithdrawalsData, GetUserWithdrawalsArgs>(
  'ticket/getUserWithdrawals',
  async ({ id, page, per_page }) => {
    const response = await userService.getUserWithdrawals(id, { page, per_page })

    return response.data
  }
)
