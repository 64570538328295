import HomeIcon from '@mui/icons-material/Home'
import Badge from '@mui/material/Badge'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HistoryIcon from '@mui/icons-material/History'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PaymentIcon from '@mui/icons-material/Payment'
import VerifiedIcon from '@mui/icons-material/Verified'
import CogOutline from 'mdi-material-ui/CogOutline'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import themeConfig from '@/shared/configs/themeConfig'
import { NavListItemHeader } from '@/shared/layouts/core/vertical/navigation/styles'
import { getPagesTooltip } from '@/app/store/thunks/pages-module'
import { pageModuleStateState, ticketsRootState } from '@/app/store/selectors'
import { useAppDispatch, useAppSelector } from '@/shared'
import { useEffect } from 'react'

export const useVerticalLinks = () => {
  const theme = useTheme()
  const {
    data: { ticketsNotifyData }
  } = useAppSelector(ticketsRootState)
  const { pages } = useAppSelector(pageModuleStateState)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const color = '#FCFCFC'
  const iconPalette = {
    color,
    fontSize: theme.palette.fontSize['md']
  }
  const supportTicketsCount = ticketsNotifyData?.answered_tickets_count ?? 0
  const moderationWithdrawalCount = ticketsNotifyData?.moderation_withdrawal_count ?? 0
  useEffect(() => {
    dispatch(getPagesTooltip())
  }, [dispatch])

  const navigationLinks = () => [
    {
      subheader: (
        <NavListItemHeader
          {...((themeConfig.menuTextTruncate || !themeConfig.menuTextTruncate) && {
            noWrap: false
          })}
        >
          {t('Wallet Watcher')}
        </NavListItemHeader>
      ),
      children: [
        {
          icon: <HomeIcon sx={iconPalette} />,
          text: t('Dashboard'),
          path: '/watcher-dashboard',
          openInNewTab: false
        },
        {
          icon: <AccountBalanceWalletIcon sx={iconPalette} />,
          text: t('My wallet'),
          path: '/watcher-wallet',
          openInNewTab: false
        },
        {
          icon: <HistoryIcon sx={iconPalette} />,
          text: t('History'),
          path: '/watcher-history',
          openInNewTab: false
        },
        {
          icon: <MonetizationOnIcon sx={iconPalette} />,
          text: t('Funds withdrawal'),
          path: '/watcher-withdrawals',
          openInNewTab: false
        },
        {
          icon: <CogOutline sx={iconPalette} />,
          text: t('Settings'),
          path: '/settings',
          openInNewTab: false
        }
      ]
    },
    {
      subheader: (
        <NavListItemHeader
          {...((themeConfig.menuTextTruncate || !themeConfig.menuTextTruncate) && {
            noWrap: false
          })}
        >
          {t('Merchant')}
        </NavListItemHeader>
      ),
      children: [
        {
          icon: <HomeIcon sx={iconPalette} />,
          text: t('Dashboard'),
          path: '/merchant-dashboard',
          openInNewTab: false
        },
        {
          icon: <ShoppingCartIcon sx={iconPalette} />,
          text: t('Platforms'),
          path: '/merchant-platforms',
          openInNewTab: false
        },
        {
          icon: <VerifiedIcon sx={iconPalette} />,
          text: t('Test payments'),
          path: '/merchant-test-payments',
          openInNewTab: false
        },
        {
          icon: <PaymentIcon sx={iconPalette} />,
          text: t('Commissions and Deposits'),
          path: '/merchant-commissions-and-deposits',
          openInNewTab: false
        },
        {
          icon: <SupervisorAccountIcon sx={iconPalette} />,
          text: t('Referrals'),
          path: '/merchant-referrals',
          openInNewTab: false
        },
        {
          icon: <InsertDriveFileIcon sx={iconPalette} />,
          text: t('DOCS'),
          path: '/merchant-docs',
          openInNewTab: false
        }
      ]
    },
    {
      subheader: (
        <NavListItemHeader
          {...((themeConfig.menuTextTruncate || !themeConfig.menuTextTruncate) && {
            noWrap: false
          })}
        >
          {t('Tickets')}
        </NavListItemHeader>
      ),
      children: [
        {
          icon: (
            <Badge color='secondary' badgeContent={supportTicketsCount}>
              <InsertCommentIcon sx={iconPalette} />
            </Badge>
          ),
          text: t('Support tickets'),
          path: '/support-tickets',
          openInNewTab: false
        },
        {
          icon: (
            <Badge color='secondary' badgeContent={moderationWithdrawalCount}>
              <AttachMoneyIcon sx={iconPalette} />
            </Badge>
          ),
          text: t('Withdrawal tickets'),
          path: '/withdrawals-tickets',
          openInNewTab: false
        },
        {
          icon: <HistoryIcon sx={iconPalette} />,
          text: t('Tickets history'),
          path: '/history-tickets',
          openInNewTab: false
        },
        {
          icon: <HistoryIcon sx={iconPalette} />,
          text: t('Withdrawal tickets history'),
          path: '/withdrawal-tickets-history',
          openInNewTab: false
        }
      ]
    }
  ]

  const verticalLinks = navigationLinks().map(link => {
    return {
      ...link,
      children: link.children.map(child => ({ ...child, tooltip: (pages ?? {})[child.path.slice(1)] }))
    }
  })

  return { verticalLinks }
}
