// ** MUI Imports
import { Theme } from '@mui/material/styles'

const box = (theme: Theme) => {
  return {
    MuiBox: {
      styleOverrides: {
        default: {
          font: `'Ubuntu', sans-serif`,
          color: theme.palette.primary || '#000'
        }
      }
    }
  }
}

export default box
