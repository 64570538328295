import { Box } from '@mui/material'
import { styled } from '@mui/system'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

export const AlertDescription = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  color: '#FFF'
}))

export const SnackAlertTitle = styled(AlertTitle)(({ theme }) => ({
  display: 'flex',
  fontFamily: `'Inter', sans-serif`,
  fontWeight: theme.palette.fontWeight['500'],
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  overflow: 'hidden',
  color: '#FFF'
}))

export const SnackAlert = styled(Alert)(() => ({
  display: 'flex',
  flex: 1,
  padding: '6px 16px',
  gap: '12px',
  color: '#FFF',
  minWidth: '365px',
  maxWidth: '370px',
  height: '135px',
  width: '100%',
  borderRadius: '8px',
  '& > .MuiAlert-icon': {
    marginRight: 0
  }
}))
