/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { Box, FormControl, FormHelperText, TextFieldProps } from '@mui/material'
import { FundsWithdrawalChars, FundsWithdrawalFormLabel, FundsWithdrawalTextField } from '@/shared'

/**
 * styled items from styling
 */

type FormInputProps = TextFieldProps & {
  control?: Control<any> | undefined
  defaultValue?: string
  name: string
}

export const FundsWithdrawalAdminMessage = ({
  control,
  label = '',
  name,
  variant = 'outlined',
  placeholder,
  ...otherProps
}: FormInputProps) => {
  const MAX_CHARS_TO_ENTER = 150

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => (
          <Box>
            {label && (
              <FundsWithdrawalFormLabel sx={{ color: errors && errors[name] && 'error.main' }}>
                {label}
              </FundsWithdrawalFormLabel>
            )}
            <FundsWithdrawalTextField
              {...field}
              {...otherProps}
              fullWidth
              autoComplete='off'
              variant={variant}
              inputProps={{ minLength: 1, maxLength: MAX_CHARS_TO_ENTER }}
              error={Boolean(errors[name])}
              placeholder={placeholder}
              InputLabelProps={{ shrink: false }}
            />
            <FundsWithdrawalChars>{`${
              field.value.toString().trim().length
            } / ${MAX_CHARS_TO_ENTER}`}</FundsWithdrawalChars>
            {errors.hasOwnProperty(name) && errors[name] && (
              <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                {errors[name]?.message as string}
              </FormHelperText>
            )}
          </Box>
        )}
      />
    </FormControl>
  )
}
