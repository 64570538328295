import { useRouter } from 'next/router'

export const useAuthPage = () => {
  const router = useRouter()
  const authPages = [
    '/login',
    '/register',
    '/register-confirmation',
    '/forgot-password',
    '/forgot-password-confirmation',
    '/reset-password',
    '/reset-password-success',
    '/login-second-step',
    '/email-already-verified',
    '/email-not-verified'
  ]

  const isAuthPage = authPages.includes(router.pathname)

  return { isAuthPage }
}
