import { ICurrencyTableRow } from './types'
import { memo } from 'react'
import {
  CurrencyTableDate,
  CurrencyTableHeading,
  CurrencyTableName,
  HistoryBody,
  HistoryCell,
  HistoryContent
} from './styles'
import { useTranslation } from 'react-i18next'

type Props = {
  row: ICurrencyTableRow
  currency?: string
}

export const HistoryRow = memo(({ row, currency }: Props) => {
  const { t } = useTranslation()

  return (
    <HistoryContent key={row.date}>
      <HistoryBody>
        <HistoryCell>
          <CurrencyTableHeading>{`${t('Date')}:`}</CurrencyTableHeading>
          <CurrencyTableDate>{row.date}</CurrencyTableDate>
        </HistoryCell>
        <HistoryCell>
          <CurrencyTableHeading>{`${t('Tx Detail')}:`}</CurrencyTableHeading>
          <CurrencyTableName>{row.txDetail}</CurrencyTableName>
        </HistoryCell>
        <HistoryCell>
          <CurrencyTableHeading>{t(`Amount`) + ` (${currency})`}</CurrencyTableHeading>
          <CurrencyTableName>{row.amount}</CurrencyTableName>
        </HistoryCell>
        <HistoryCell>
          <CurrencyTableHeading>{t(`Balance`) + ` (${currency})`}</CurrencyTableHeading>
          <CurrencyTableName> {row.balance}</CurrencyTableName>
        </HistoryCell>
      </HistoryBody>
    </HistoryContent>
  )
})
