import { memo } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { css, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { FiltersButton, FiltersButtonText } from './styles'
import { styled } from '@mui/system'

type Props = {
  readonly onOpen: () => void
}

export const PaymentTransactionChartModalHeader = memo(({ onOpen }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
        onClick={onOpen}
      >
        <FiltersButton variant='contained' style={{ backgroundColor: 'transparent', border: '2px solid gray' }}>
          {!isMobile ? <FiltersButtonText style={{ color: 'gray' }}>{t('Total Volume')}</FiltersButtonText> : null}
          <SvgChart $isHoverAnimationEnabled={false} />
        </FiltersButton>
      </Toolbar>
    </Box>
  )
})

interface SvgChartProps {
  $isHoverAnimationEnabled?: boolean
}

export const SvgChart = memo<SvgChartProps>(({ $isHoverAnimationEnabled = true }) => (
  <Svg $isHoverAnimationEnabled={$isHoverAnimationEnabled} width='20' height='20' viewBox='0 0 20 20' fill='gray'>
    <path d='M9.27711 1.4458C4.15349 1.4458 0 5.59929 0 10.7229C0 15.8465 4.15349 20 9.27711 20C14.4007 20 18.5542 15.8465 18.5542 10.7229H9.27711V1.4458Z' />
    <path d='M10.7229 0V9.27711H20C20 4.15349 15.8465 0 10.7229 0Z' />
  </Svg>
))

const Svg = styled('svg')<SvgChartProps>`
  ${props => {
    if (props.$isHoverAnimationEnabled) {
      return css`
        :hover {
          fill: #186adf;
          cursor: pointer;
        }
      `
    }
  }}
`
