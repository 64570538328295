import { HistoryTicketNavigationContainer } from './styles'
import { HistoryTicketNavigationInfo, HistoryTicketNavigationOptions, IClosedTicket } from '@/shared'
import { useRouter } from 'next/router'
import { useAppSelector } from '@/shared/hooks/types'
import { closedTicketsSelector } from '@/app/store/selectors/tickets.selectors'

export const HistoryTicketNavigation = () => {
  const router = useRouter()
  const closedStateTickets = useAppSelector(closedTicketsSelector)

  const { id } = router.query
  const closedTicket = closedStateTickets.list.find(ticket => String(ticket.id) === id) as IClosedTicket

  return (
    <HistoryTicketNavigationContainer>
      <HistoryTicketNavigationInfo id={closedTicket?.id} subject={closedTicket?.subject} />
      <HistoryTicketNavigationOptions closedTicket={closedTicket} />
    </HistoryTicketNavigationContainer>
  )
}
