import { getPageModules } from '@/app/store/thunks/pages-module'
import { slugify, useAppDispatch } from '@/shared'
import { useAbortController } from './useAbortController'
import { useCallback, useContext, useLayoutEffect } from 'react'
import { AlertsContext } from '@/app/context'
import { getAlert } from '@/app/store/thunks'
import { useRouter } from 'next/router'

export const useModulesLoad = () => {
  // Hooks
  const router = useRouter()
  const dispatch = useAppDispatch()
  const slug = slugify(router?.asPath)
  const { showAlerts } = useContext(AlertsContext)

  // Declare AbortController instance for requests
  const { newAbortSignal, cancelPreviousRequest } = useAbortController()

  const onPageLoadComplete = useCallback(() => {
    // Define signal for request cancelling
    const signal = newAbortSignal()

    // Array of promises to fetch
    const promises = [dispatch(getPageModules({ slug, signal })), dispatch(getAlert({ slug, signal }))]

    // Take an iterable of promises
    Promise.all(promises).then(() => {
      showAlerts()
    })
  }, [dispatch, newAbortSignal, showAlerts, slug])

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      /**
       * @description get navigation type from window object
       * See {@link https://developer.mozilla.org/ru/docs/Web/API/performance_property}
       */

      const isPageReload = window.sessionStorage.getItem('isReloaded')
      if (isPageReload) {
        onPageLoadComplete()
      } else {
        window.sessionStorage.setItem('isReloaded', 'true')
      }

      /**
       * @event routeChangeComplete(url, { shallow }) - Fires when a route changed completely
       * See  {@link https://nextjs.org/docs/api-reference/next/router Next.js}.
       */
      router.events.on('routeChangeComplete', onPageLoadComplete)

      /**
       * @event routeChangeError(err, url, { shallow }) - Fires when there's an error when changing routes, or a route load is cancelled
       * See  {@link https://nextjs.org/docs/api-reference/next/router Next.js}.
       */
      router.events.on('routeChangeError', cancelPreviousRequest)

      /**
       * unsubscribe events
       */
      return () => {
        router.events.off('routeChangeError', cancelPreviousRequest)
        router.events.off('routeChangeComplete', onPageLoadComplete)
        window.sessionStorage.removeItem('isReloaded')
      }
    }
  }, [cancelPreviousRequest, onPageLoadComplete, router.events])
}
