import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetUserHistoryParams, IHistoryResponse } from '@/shared'
import { userService } from '@/shared/services/user.service'

export const getUserHistory = createAsyncThunk<IHistoryResponse, GetUserHistoryParams>(
  'user/getUserHistory',
  async ({ params, filters, sort }) => {
    const { data } = await userService.getUserHistory(params, filters ?? {}, sort)

    return data
  }
)
