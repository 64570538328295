import { TicketNavigationContainer } from './styles'
import { TicketNavigationInfo, TicketNavigationOptions } from '@/shared/ui/one-ticket'
import { useRouter } from 'next/router'
import { useAppSelector } from '@/shared/hooks/types'
import { ticketsStateSelector } from '@/app/store/selectors/tickets.selectors'
import { ITicket } from '@/shared'

export const OneTicketNavigation = () => {
  const router = useRouter()
  const tickets = useAppSelector(ticketsStateSelector)

  const { id } = router.query
  const ticket = tickets.list.find(ticket => String(ticket.id) === id) as ITicket

  return (
    <TicketNavigationContainer>
      <TicketNavigationInfo id={ticket?.id} subject={ticket?.subject} />
      <TicketNavigationOptions ticket={ticket} />
    </TicketNavigationContainer>
  )
}
