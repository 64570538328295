import { useState } from 'react'
import { Box } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import HistoryIcon from '@mui/icons-material/History'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HomeIcon from '@mui/icons-material/Home'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import PaymentIcon from '@mui/icons-material/Payment'
import VerifiedIcon from '@mui/icons-material/Verified'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { IconedMenuItem } from './app-bar-burger/iconed-menu-item'
import { HeadMenuItem } from './app-bar-burger/head-menu-item'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { IconedMenuText, MobileMenuItem } from '@/shared/layouts'
import { UserSettingsIcon } from '@/shared/ui/user-settings-icon'
import { formatLink } from '@/shared/utils/format-links'
import { useAppSelector, useAuth } from '@/shared'
import { WP_LINK } from '@/shared/utils/links'
import Badge from '@mui/material/Badge'
import { ticketsRootState } from '@/app/store/selectors'

type Props = {
  handleDrawerClose: () => void
}

type MenuSections = 'profile' | 'wallet' | 'merchant' | 'tickets' | 'none'

export const AppBarMenu = ({ handleDrawerClose, ...props }: Props) => {
  const [menuSection, setMenuSection] = useState<MenuSections>('none')
  const router = useRouter()
  const {
    data: { ticketsNotifyData }
  } = useAppSelector(ticketsRootState)
  const supportTicketsCount = ticketsNotifyData?.answered_tickets_count ?? 0
  const moderationWithdrawalCount = ticketsNotifyData?.moderation_withdrawal_count ?? 0
  const { t } = useTranslation()
  const { logout } = useAuth()
  const iconThemeColor = '#101010'
  const isProfile = menuSection === 'profile'
  const isNone = menuSection === 'none'
  const isWallet = menuSection === 'wallet'
  const isMerchant = menuSection === 'merchant'
  const isTickets = menuSection === 'tickets'

  const iconStyles = { fontSize: '24px', mr: '8px', color: '#101010' }
  const handleLinkClick = (url: string) => {
    router.push(url)
    handleDrawerClose()
  }

  const handleLogout = () => {
    logout()
    handleDrawerClose()
  }

  return (
    <Box {...props} sx={{ padding: '16px', background: '#F7F7F7', overflow: 'auto', paddingBottom: '10px' }}>
      {isProfile ? (
        <>
          <IconedMenuItem
            text={t('Profile')}
            onClick={() => setMenuSection('none')}
            icon={<ArrowBackIosIcon sx={iconStyles} />}
          />
          <HeadMenuItem onClick={() => setMenuSection('wallet')} title={t('Wallet watcher')} />
          <HeadMenuItem onClick={() => setMenuSection('merchant')} title={t('Merchant')} />
          <HeadMenuItem onClick={() => setMenuSection('tickets')} title={t('Tickets')} />

          <IconedMenuItem
            text={t('Settings')}
            icon={<SettingsOutlinedIcon sx={iconStyles} />}
            onClick={() => handleLinkClick('/settings')}
          />
          <MobileMenuItem sx={{ color: 'rgba(16, 16, 16, 0.5)' }} onClick={handleLogout}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: iconThemeColor
                }}
              >
                <LogoutOutlinedIcon
                  sx={{
                    fontSize: '24px',
                    marginRight: '8px',
                    color: iconThemeColor
                  }}
                />
                {t('Logout')}
              </Box>
            </Box>
          </MobileMenuItem>
        </>
      ) : (
        <>
          {isWallet ? (
            <>
              <IconedMenuItem
                text={t('Wallet')}
                onClick={() => setMenuSection('profile')}
                icon={<ArrowBackIosIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Dashboard')}
                onClick={() => handleLinkClick('/watcher-dashboard')}
                icon={<HomeIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('My wallet')}
                onClick={() => handleLinkClick('/watcher-wallet')}
                icon={<AccountBalanceWalletIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('History')}
                onClick={() => handleLinkClick('/watcher-history')}
                icon={<HistoryIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                onClick={() => handleLinkClick('/watcher-withdrawals')}
                text={t('Funds withdrawal')}
                icon={<MonetizationOnIcon sx={iconStyles} />}
              />
            </>
          ) : null}
          {isMerchant ? (
            <>
              <IconedMenuItem
                text={t('Merchant')}
                onClick={() => setMenuSection('profile')}
                icon={<ArrowBackIosIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Dashboard')}
                onClick={() => handleLinkClick('/watcher-dashboard')}
                icon={<HomeIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Platforms')}
                onClick={() => handleLinkClick('/merchant-platforms')}
                icon={<ShoppingCartIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Test payments')}
                onClick={() => handleLinkClick('/merchant-test-payments')}
                icon={<VerifiedIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Commissions and Deposits')}
                onClick={() => handleLinkClick('/merchant-commissions-and-deposits')}
                icon={<PaymentIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Referrals')}
                onClick={() => handleLinkClick('/merchant-referrals')}
                icon={<SupervisorAccountIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                onClick={() => handleLinkClick('/merchant-docs')}
                text={t('DOCS')}
                icon={<InsertDriveFileIcon sx={iconStyles} />}
              />
            </>
          ) : null}
          {isTickets ? (
            <>
              <IconedMenuItem
                text={t('Tickets')}
                onClick={() => setMenuSection('profile')}
                icon={<ArrowBackIosIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Support tickets')}
                onClick={() => handleLinkClick('/support-tickets')}
                icon={
                  <Badge color='secondary' badgeContent={supportTicketsCount}>
                    <InsertCommentIcon sx={iconStyles} />
                  </Badge>
                }
              />
              <IconedMenuItem
                onClick={() => handleLinkClick('/withdrawals-tickets')}
                text={t('Withdrawal tickets')}
                icon={
                  <Badge color='secondary' badgeContent={moderationWithdrawalCount}>
                    <AttachMoneyIcon sx={iconStyles} />
                  </Badge>
                }
              />
              <IconedMenuItem
                onClick={() => handleLinkClick('/withdrawal-tickets-history')}
                text={t('Withdrawal tickets history')}
                icon={<AttachMoneyIcon sx={iconStyles} />}
              />
              <IconedMenuItem
                text={t('Tickets history')}
                onClick={() => handleLinkClick('/history-tickets')}
                icon={<HistoryIcon sx={iconStyles} />}
              />
            </>
          ) : null}
          {isNone && (
            <div>
              <HeadMenuItem
                title={t('Profile')}
                onClick={() => setMenuSection('profile')}
                icon={<UserSettingsIcon sx={{ fontSize: '36px', cursor: 'pointer', mr: '8px' }} />}
              />
              {['About us', 'Prices', 'Affiliate', 'Wallet Watcher', 'Integrations', 'API', 'Merchant', 'FAQ'].map(
                link => (
                  <MobileMenuItem key={link} onClick={() => handleLinkClick(WP_LINK + formatLink(link))}>
                    <IconedMenuText> {t(link)}</IconedMenuText>
                  </MobileMenuItem>
                )
              )}
            </div>
          )}
        </>
      )}
    </Box>
  )
}
