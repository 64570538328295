/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { Box, FormControl, FormHelperText } from '@mui/material'

/**
 * styled items from styling
 */
import { FormLabel, FormWrapper, TextArea } from './styles'
import { TextareaAutosizeProps } from '@mui/base/TextareaAutosize/TextareaAutosize'

/**
 * DashboardFormInput type for props
 */
type FormInputProps = {
  control?: Control<any> | undefined
  name: string
  className?: string
  label: string
  sx?: any
} & TextareaAutosizeProps

/**
 * Custom form input component
 * @params {FormInputProps} - props for input component
 * @default type - text
 */
export const FormTextarea = ({ control, label = '', name, placeholder, ...otherProps }: FormInputProps) => {
  return (
    <FormControl sx={{ mb: '24px' }}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => (
          <FormWrapper>
            <FormLabel sx={{ color: errors && errors[name] && 'error.main' }}>{label}</FormLabel>
            <Box sx={{ position: 'relative' }}>
              <TextArea {...field} {...otherProps} error={Boolean(errors[name])} placeholder={placeholder} />
            </Box>
            {errors.hasOwnProperty(name) && errors[name] && (
              <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
                {errors[name]?.message as string}
              </FormHelperText>
            )}
          </FormWrapper>
        )}
      />
    </FormControl>
  )
}
