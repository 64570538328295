import { Fragment, useState } from 'react'

// ** MUI Imports
import { Badge, Box, Dialog, DialogContent, IconButton, List, Typography } from '@mui/material'

// ** Icons Imports
import Close from 'mdi-material-ui/Close'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import {
  FileDetails,
  FileName,
  FilePreview,
  FileSize,
  MessageDialogTitle,
  UploadListItem
} from '@/widgets/forms/form-elements/file-uploader/styles'
import { FileProp } from '@/pages/submit-ticket'
import { acceptMimeOptions, bytesToKb, bytesToMb } from '@/shared'
import { ImageWithState } from '@/shared/ui/image-with-state'
import { toast } from 'react-hot-toast'

type Props = Partial<{
  files: File[]
  onDrop(acceptedFiles: File[]): void
  handleRemoveFile(file: FileProp): void
}>

export const MessageFileUploader = ({ files, onDrop, handleRemoveFile }: Props) => {
  // ** State
  const MAX_LENGTH = 3
  const MAX_LENGTH_EACH_FILE = 10e6
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_LENGTH,
    maxSize: MAX_LENGTH * MAX_LENGTH_EACH_FILE,
    onDrop,
    onDropRejected: () => {
      toast.error(
        t(
          `You can only upload 3 files, maximum size of 10 MB. The File must be a file of type: jpg, jpeg, png, gif, heic, ico, webp, mp4, mov, wav, mp3, m4a, pdf, doc, docx, txt, rtf, xls, xlsx, ppt, pptx, csv, bmp.`
        ),
        {
          duration: 2000,
          position: 'bottom-right'
        }
      )
    },
    accept: acceptMimeOptions
  })

  /**
   * handlers
   */
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fileList = (files || []).map((file: FileProp) => (
    <UploadListItem key={file.name}>
      <FileDetails>
        <FilePreview>
          <ImageWithState width={38} height={38} alt={file.name} src={'/images/icons/file.svg'} />
        </FilePreview>
        <div>
          <FileName>{file.name}</FileName>
          <FileSize>{Math.round(file.size / 100) / 10 > 1000 ? bytesToMb(file.size) : bytesToKb(file.size)}</FileSize>
        </div>
      </FileDetails>
      <IconButton
        sx={{ p: 0 }}
        onClick={() => handleRemoveFile && handleRemoveFile(file)}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Close fontSize='medium' sx={{ color: '#FCFCFC' }} />
      </IconButton>
    </UploadListItem>
  ))

  return (
    <Fragment>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth='sm'>
        <MessageDialogTitle>
          {t('Attachments')}
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MessageDialogTitle>
        <DialogContent>
          {(files ?? []).length ? (
            <Fragment>
              <List>{fileList}</List>
            </Fragment>
          ) : (
            <Typography className='file-name'>{t('No attachments yet')}</Typography>
          )}
        </DialogContent>
      </Dialog>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
              <IconButton>
                <AttachFileIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Badge badgeContent={files?.length} color='primary' onClick={handleClickOpen} sx={{ cursor: 'pointer' }} />
      </Box>
    </Fragment>
  )
}
