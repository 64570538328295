import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material'

import { styled } from '@mui/system'

export const DashboardTextInput = styled(TextField)(({ theme }) => ({
  font: `'Inter', sans-serif`,
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '16px',
  lineHeight: '14px',
  color: theme.palette.common.white,
  maxWidth: '424px',
  width: '100%',
  outline: 'none'
}))

export const DashboardTextInputLabel = styled(FormLabel)(({ theme }) => ({
  font: `'Inter', sans-serif`,
  position: 'absolute',
  top: '-5px',
  zIndex: 10,
  background: theme.palette.mode !== 'dark' ? '#F7F7F9 !important' : '#101010',
  paddingLeft: '5px',
  paddingRight: '5px',
  left: '16px',
  fontWeight: theme.palette.fontWeight['400'],
  fontSize: '12px',
  lineHeight: '12px',
  color: 'rgba(255, 255, 255, 0.75)'
}))

export const DashboardTextInputControl = styled(FormControl)(({ theme }) => ({
  m: 1,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    marginBottom: '16px'
  }
}))

export const FormInputError = styled(FormHelperText)(() => ({ color: 'error.main', ml: 0, mt: '7px' }))
