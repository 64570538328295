// ** React Imports
import { ReactNode, useEffect } from 'react'

// ** Next Imports
import Head from 'next/head'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// ** Store Imports
import { persistor, store } from 'src/app/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import '../shared/configs/i18n'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from '@/shared/layouts/core/additional/user-layout'
import GuestGuard from '@/shared/ui/auth/guest-guard'
import WindowWrapper from 'src/shared/ui/window-wrapper'

// ** Contexts
import { AuthProvider } from '@/app/context/auth-context'
import { SettingsConsumer, SettingsProvider } from '@/app/context/settings-context'

// ** Styled Components
import ReactHotToast from 'src/app/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from '@/shared/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Global css styles
import '../app/styles/globals.css'
import themeConfig from '../shared/configs/themeConfig'
import AuthGuard from '../shared/ui/auth/auth-guard'
import Spinner from '@/shared/ui/spinner'

import dayjs from 'dayjs'

// Locales
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'dayjs/locale/uk'
import 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import { AlertsProvider } from '@/app/context/alerts-context'
import { useAuth } from '@/shared'
import ThemeComponent from '@/app/theme/ThemeComponent'

// Load plugins
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(calendar)

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const Guard = ({ children, authGuard, guestGuard }: GuardProps) =>
  guestGuard ? (
    <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  ) : !guestGuard && !authGuard ? (
    <>{children}</>
  ) : (
    <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  )

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const { logoutAllTabs } = useAuth()

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? true
  const guestGuard = Component.guestGuard ?? false

  useEffect(() => {
    logoutAllTabs()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            <title>{`${themeConfig.templateName} - Dashboard`}</title>
            <meta property='og:title' content='Crypto processing admin' />
            <meta property='og:description' content='Crypto processing admin' />
            <link rel='shortcut icon' href='/images/icons/file-icons/favicon-crypto.svg' type='image/x-icon' />
            <meta property='og:url' content={`${process.env.APP_SITE_HOST}`} />
            <meta property='og:type' content='website' />
            <meta name='description' content='Crypto processing admin' />
            <meta name='keywords' content='Material Design, MUI, Crypto processing dashboard, React Admin Dashboard' />
            <meta name='viewport' content='initial-scale=1, width=device-width' />
          </Head>

          <AuthProvider>
            <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
              <SettingsConsumer>
                {({ settings }) => (
                  <ThemeComponent settings={settings}>
                    <WindowWrapper fallback={<Spinner />}>
                      <Guard authGuard={authGuard} guestGuard={guestGuard}>
                        <AlertsProvider>{getLayout(<Component {...pageProps} />)}</AlertsProvider>
                      </Guard>
                    </WindowWrapper>
                    <ReactHotToast>
                      <Toaster
                        position={settings.toastPosition}
                        toastOptions={{ className: 'react-hot-toast', duration: 3000, success: { duration: 3000 } }}
                      />
                    </ReactHotToast>
                  </ThemeComponent>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </CacheProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
