import {
  TicketChatInfoHeader,
  WithdrawalChatInfo,
  WithdrawalChatName,
  WithdrawalChatValue
} from '@/shared/ui/withdrawal-one-ticket'
import { TicketWithdrawalContent, useAppSelector } from '@/shared'
import { withdrawalTicketsStateSelector } from '@/app/store/selectors'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

export const WithdrawalTicketChatInfo = () => {
  const router = useRouter()
  const { id } = router.query
  const { t } = useTranslation()
  const tickets = useAppSelector(withdrawalTicketsStateSelector)
  const ticket = tickets.list.find(ticket => String(ticket.id) === id)
  const bitcoinSelected = ticket?.currency?.name === 'BTC'

  return (
    <TicketChatInfoHeader>
      <TicketWithdrawalContent sx={{ maxWidth: 600, flexWrap: 'wrap', p: 0 }}>
        <WithdrawalChatInfo>
          <WithdrawalChatName>{t('Coin')}:</WithdrawalChatName>
          <WithdrawalChatValue>{ticket?.currency?.name}</WithdrawalChatValue>
        </WithdrawalChatInfo>
        {!bitcoinSelected ? (
          <WithdrawalChatInfo>
            <WithdrawalChatName>{t('Network')}:</WithdrawalChatName>
            <WithdrawalChatValue>BEP-20</WithdrawalChatValue>
          </WithdrawalChatInfo>
        ) : null}
        <WithdrawalChatInfo>
          <WithdrawalChatName>{t('Amount')}:</WithdrawalChatName>
          <WithdrawalChatValue>
            {Number(ticket?.sum)} {ticket?.currency?.name}
          </WithdrawalChatValue>
        </WithdrawalChatInfo>
        <WithdrawalChatInfo>
          <WithdrawalChatName>{t('Address')}:</WithdrawalChatName>
          <WithdrawalChatValue>{ticket?.address}</WithdrawalChatValue>
        </WithdrawalChatInfo>
      </TicketWithdrawalContent>
    </TicketChatInfoHeader>
  )
}
