import { BreadcrumbsHeading, BreadcrumbsTitle } from './styles'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type Props = {
  breadcrumbs: string
  breadcrumbsTitle?: string
}

export const PageBreadcrumbs = ({ breadcrumbs, breadcrumbsTitle = '' }: Props) => {
  const theme = useTheme()
  const hidden = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: hidden ? 0 : '36px' }}>
      <BreadcrumbsTitle>{t(breadcrumbs)}</BreadcrumbsTitle>
      <BreadcrumbsHeading>{t(breadcrumbsTitle)}</BreadcrumbsHeading>
    </Box>
  )
}
