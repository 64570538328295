import { SyntheticEvent, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { DropdownMenuItem, LanguageDropdownMenu, SelectedLang } from './styles'
import { localeStorageService, tokenService, useAuthPage } from '@/shared'
import { useSettings } from '@/shared/hooks/useSettings'
import { Box, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useAppDispatch } from '@/shared/hooks/types'
import { getLayout } from '@/app/store/thunks'
import { userService } from '@/shared/services/user.service'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type LanguageType = 'en' | 'ukr'

type Props = {
  isAuthPages?: boolean
  isDashboard?: boolean
  sx?: SxProps<Theme> | undefined
}

export const LanguageDropdown = ({ isAuthPages = false, sx }: Props) => {
  /**
   * states
   */
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(
    (localeStorageService.getCurrentLang() as LanguageType) || 'en'
  )

  // ** Hook
  const { i18n } = useTranslation()
  const { isAuthPage } = useAuthPage()
  const selectedLang = String(i18n.language).toLocaleUpperCase()
  const token = tokenService.getLocalAccessToken()
  const { settings, saveSettings } = useSettings()
  const dispatch = useAppDispatch()

  /**
   * Vars
   */

  const isOpen = Boolean(anchorEl)
  const DropdownIcon = isOpen ? ExpandLessIcon : ExpandMoreIcon

  useEffect(() => {
    if (selectedLanguage) {
      dispatch(getLayout({ locale: selectedLanguage }))
    }
    if (!isAuthPage && token) {
      userService.setLocaleForUser(selectedLanguage)
    }
  }, [dispatch, isAuthPage, selectedLanguage, token])

  /**
   * handlers
   */
  const handleLangDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const getThemeColor = () => {
    if (isAuthPages) {
      return '#101010'
    }

    return '#FCFCFC'
  }

  const handleLangDropdownClose = () => {
    setAnchorEl(null)
  }

  const handleLangItemClick = (lang: LanguageType) => {
    setSelectedLanguage(lang)
    i18n.changeLanguage(lang)
    localeStorageService.setCurrentLang(lang)
    handleLangDropdownClose()
  }

  /**
   * vars
   */
  const color = getThemeColor()

  return (
    <Box>
      <IconButton
        disableRipple
        disableTouchRipple
        onClick={handleLangDropdownOpen}
        sx={{ mr: '30px', padding: 0, ...sx }}
      >
        <SelectedLang sx={{ color }}>{selectedLang}</SelectedLang>
        <DropdownIcon sx={{ color }} />
      </IconButton>
      <LanguageDropdownMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLangDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <DropdownMenuItem
          disableRipple
          disableTouchRipple
          selected={i18n.language === 'en'}
          onClick={() => {
            handleLangItemClick('en')
            saveSettings({ ...settings, direction: 'ltr' })
          }}
        >
          English
        </DropdownMenuItem>
        {/*<DropdownMenuItem*/}
        {/*  disableRipple*/}
        {/*  disableTouchRipple*/}
        {/*  selected={i18n.language === 'ukr'}*/}
        {/*  onClick={() => {*/}
        {/*    handleLangItemClick('ukr')*/}
        {/*    saveSettings({ ...settings, direction: 'ltr' })*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Українська*/}
        {/*</DropdownMenuItem>*/}
      </LanguageDropdownMenu>
    </Box>
  )
}
