import { NewPasswordFormData } from '@/widgets/pages/settings/set-new-password'
import { NewEmailFormData } from '@/widgets/pages/settings'
import { authConfig, axiosInstance, UsersHistoryFilters } from '@/shared'
import { BaseParams } from '@/app/store/types'
import Cookies from 'js-cookie'

class UserService {
  private timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  async getUser() {
    const token = Cookies.get(authConfig.storageTokenKeyName)

    const response = await axiosInstance.get(authConfig.userEndpoint, {
      headers: { Authorization: `Bearer ${token}` },
      params: { timezone: this.timezone }
    })

    return response.data
  }

  setLocaleForUser = async (lang: string) => {
    const response = await axiosInstance.post(`${authConfig.userEndpoint}/set-locale`, {}, { params: { lang } })

    return response.data
  }

  getUserReferrals = async (params: BaseParams) => {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/referrals`, { params })

    return response.data
  }

  getUserReferralsAccruals = async (params: BaseParams) => {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/referrals-accruals`, { params })

    return response.data
  }

  deleteUserAccount = async (token: string, lang: string) => {
    const response = await axiosInstance.delete(authConfig.deleteUserAccountEndpoint, {
      params: { lang, token }
    })

    return response.data
  }

  sendAccountDeleteConfirmationEmail = async (lang: string) => {
    return await axiosInstance.post(authConfig.sendAccountDeleteConfirmationEmailEndpoint, {}, { params: { lang } })
  }

  updateUserEmail = async (data: NewEmailFormData, lang: string) => {
    return await axiosInstance.patch(authConfig.updateUserEmailEndpoint, data, {
      params: { lang }
    })
  }

  updateUserPassword = async (data: NewPasswordFormData, lang: string) => {
    return await axiosInstance.patch(authConfig.updateUserPasswordEndpoint, data, {
      params: { lang }
    })
  }

  applyCoupon = async (coupon: string) => {
    return await axiosInstance.post(`${authConfig.userEndpoint}/apply-coupon`, { coupon })
  }

  getWalletAndAllowedCurrencies = async (currencyId: string) => {
    const res = await axiosInstance.get(`${authConfig.userEndpoint}/wallet/${currencyId}`)

    return res.data
  }

  replenishToWallet = async (walletId: string) => {
    return await axiosInstance.post(`${authConfig.userEndpoint}/replenish/${walletId}`)
  }

  getUserHistory = async (params: BaseParams, filters: Partial<UsersHistoryFilters>, sort?: string) => {
    const query = Object.keys(filters ?? {})
      .filter(k => {
        const filterKey = (filters ?? {})[k as keyof Partial<UsersHistoryFilters>]

        return filterKey?.at(-1) !== 'none' && filterKey !== ''
      })
      .reduce((prev, key, i) => {
        const paramsKey = (filters ?? {})[key as keyof Partial<UsersHistoryFilters>]
        const sign = `${prev}${i !== 0 ? '&' : '?'}`
        const filterOptions = Array.isArray(paramsKey)
          ? `filter[${key}]=${paramsKey.map(k => encodeURIComponent(k)).join(',')}`
          : `filter[${key}]=${paramsKey}`

        return `${sign}${filterOptions}`
      }, '')

    const res = await axiosInstance.get(`${authConfig.userEndpoint}/history${query}`, {
      params: {
        ...params,
        sort
      }
    })

    return res.data
  }
  getCsvUserHistory = async (filters: Partial<UsersHistoryFilters>, type: string) => {
    const csvString = Object.keys(filters ?? {})
      .filter(k => {
        const filterKey = (filters ?? {})[k as keyof Partial<UsersHistoryFilters>]

        return filterKey?.at(-1) !== 'none' && filterKey !== ''
      })
      .reduce((prev, key, i) => {
        const filtersKey = (filters ?? {})[key as keyof Partial<UsersHistoryFilters>]
        const filtersSign = `${prev}${i !== 0 ? '&' : '?'}`
        const filterOptions = Array.isArray(filtersKey)
          ? filtersKey.map(value => `filter[${key}]=${value}`).join('&')
          : `filter[${key}]=${filtersKey}`

        return `${filtersSign}${filterOptions}`
      }, '')

    return await axiosInstance.get(`${authConfig.userEndpoint}/history/export${csvString}`, {
      responseType: 'blob',
      params: { type }
    })
  }
  getTwoFactorQr = async () => {
    const res = await axiosInstance.get(`${authConfig.userEndpoint}/2fa`)

    return res.data
  }
  enableTwoFactor = async (code: string) => {
    const res = await axiosInstance.post(`${authConfig.userEndpoint}/2fa/enable`, { code })

    return res.data
  }
  disableTwoFactor = async (code: string) => {
    const res = await axiosInstance.post(`${authConfig.userEndpoint}/2fa/disable`, { code })

    return res.data
  }
  getUserTwoFactorQrCode = async () => {
    const res = await axiosInstance.get(`${authConfig.userEndpoint}/2fa`)

    return res.data
  }
  enablePaymentNotifications = async () => {
    const res = await axiosInstance.post(`${authConfig.userEndpoint}/notifications/payment/enable`)

    return res.data
  }
  disablePaymentNotifications = async () => {
    const res = await axiosInstance.post(`${authConfig.userEndpoint}/notifications/payment/disable`)

    return res.data
  }

  getUserWithdrawals = async (id: number, params: BaseParams) => {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/${id}/withdrawals`, { params })

    return response.data
  }

  async getWalletsListByUsersStores() {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/storeWallets`)

    return response.data
  }

  async getAlert(slug: string, signal: AbortSignal) {
    const response = await axiosInstance.post(authConfig.userAlertsEndpoint, { slug }, { signal })

    return response.data
  }

  async updateAlertShownStatus(id: number) {
    const response = await axiosInstance.put(`${authConfig.userAlertsEndpoint}/${id}/shown`)

    return response.data
  }

  async getAuthUserDashboardData() {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/dashboard`)

    return response.data
  }
  async getAuthUserDashboardSpentData() {
    const response = await axiosInstance.get(`${authConfig.userEndpoint}/dashboard-spent`)

    return response.data
  }
}

export const userService = new UserService()
