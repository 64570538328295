import Card from '@mui/material/Card'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import { ApexOptions } from 'apexcharts'
import ReactApexcharts from '@/shared/ui/react-apexcharts'
import { useTranslation } from 'react-i18next'
import { CardContentAnalytics, CardContentTitle, useAppSelector } from '@/shared'
import { walletLoadingSelector, walletSliceSelector } from '@/app/store/selectors'
import Skeleton from '@mui/material/Skeleton'
import CardContent from '@mui/material/CardContent'

export const WalletBalanceAnalytics = () => {
  const theme = useTheme()
  const wallet = useAppSelector(walletSliceSelector)
  const loading = useAppSelector(walletLoadingSelector)
  const chartsData = (wallet?.dashboardSpent?.currencies_to_fiats || [])?.map(currency => currency?.weekly_tx_sum)
  const series = [
    {
      data: chartsData
    }
  ]
  const { t } = useTranslation()
  const options: ApexOptions = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        barHeight: '50px',
        horizontal: true,
        distributed: true,
        dataLabels: {},
        startingShape: 'flat'
      }
    },
    tooltip: {
      enabled: false
    },
    grid: {
      strokeDashArray: 8,
      xaxis: {
        lines: { show: true }
      },
      yaxis: {
        lines: { show: false }
      }
    },
    colors: ['#F7931A', '#F0B90B'],
    legend: { show: false },
    dataLabels: {
      enabled: true,
      formatter(val: string | number | number[], opts?: any): string | number {
        return `${val} ${wallet?.dashboard?.currencies_to_fiats?.[opts?.dataPointIndex]?.user_fiat}`
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Ubuntu',
        fontWeight: 700
      }
    },
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    xaxis: {
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: ['BTC', 'BNB'],
      labels: {
        formatter: val =>
          Boolean(Number(val) % 1)
            ? `${Number(val).toFixed(2)}`
            : Number(val) > 10e2
            ? `${Math.floor(Number(val) / 1000)}k`
            : `${Math.floor(Number(val))}`
      }
    },
    yaxis: {
      labels: { align: theme.direction === 'rtl' ? 'right' : 'left' }
    }
  }

  return (
    <Card sx={{ height: '260px' }}>
      <CardContent sx={{ p: '.8rem' }}>
        {loading ? (
          <Skeleton variant='rounded' width='100%' height={239} />
        ) : (
          <>
            <CardHeader
              sx={{ p: 0 }}
              title={
                <CardContentTitle>
                  {t('Amount of transactions')}
                  <br />
                  {t('for last 7 days')}
                </CardContentTitle>
              }
            />
            <CardContentAnalytics>
              <ReactApexcharts type='bar' height={165} series={series} options={options} />
            </CardContentAnalytics>
          </>
        )}
      </CardContent>
    </Card>
  )
}
