export const acceptMimeOptions = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/heic': ['.heic'],
  'image/vnd.microsoft.icon': ['.ico'],
  'image/webp': ['.webp'],
  'image/bmp': ['.bmp'],
  'video/quicktime': ['.mov', '.qt'],
  'video/mp4': ['.mp4'],
  'audio/m4a': ['.m4a'],
  'audio/wav': ['.wav'],
  'audio/mpeg': ['.mp3'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/rtf': ['.rtf'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'text/plain': ['.txt'],
  'text/csv': ['.csv']
}
