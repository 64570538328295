import {
  CrmTransactionsTableWrapper,
  CryptocurrenciesTableNavigation,
  CryptocurrenciesTableTitle,
  DashboardSpentTransaction,
  NavigationButton,
  TableBalanceRow,
  TableCurrenciesCell,
  TableNoTransactions,
  TableRowCell,
  TableRowsPerPage,
  useAppSelector
} from '@/shared'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { walletLoadingSelector, walletSliceSelector } from '@/app/store/selectors'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useCallback, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { Table } from '@mui/material'

type NavigationButtonType = { name: string; filter: string }
export const WalletBalanceLastMonthTransactions = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [transactionFilter, setTransactionFilter] = useState<string>('all')
  const wallet = useAppSelector(walletSliceSelector)
  const loading = useAppSelector(walletLoadingSelector)
  const monthlyTransactions: Record<string, DashboardSpentTransaction[]> =
    wallet?.dashboardSpent?.transactions?.monthly || {}
  const tableRows = monthlyTransactions[transactionFilter] as DashboardSpentTransaction[]
  const buttons: NavigationButtonType[] = [
    { name: 'All', filter: 'all' },
    { name: 'BTC', filter: '1' },
    { name: 'BNB', filter: '3' }
  ]
  const rowsAvailable = tableRows?.length > 0

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleTransactionChange = useCallback((name: string) => {
    setTransactionFilter(name)
  }, [])
  const renderTableFilters = () =>
    buttons.map(button => (
      <NavigationButton
        key={button.name}
        variant={transactionFilter === button.filter ? 'contained' : 'outlined'}
        onClick={() => handleTransactionChange(button.filter)}
      >
        {t(button.name)}
      </NavigationButton>
    ))

  return (
    <CrmTransactionsTableWrapper>
      <TableContainer component={Paper}>
        {loading ? (
          <Skeleton variant='rectangular' width='100%' height={426} />
        ) : (
          <Box>
            <CryptocurrenciesTableTitle>{t('Transactions for last 30 days')}</CryptocurrenciesTableTitle>
            <CryptocurrenciesTableNavigation>{renderTableFilters()}</CryptocurrenciesTableNavigation>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    '& > .MuiTableCell-head:first-of-type': {
                      paddingLeft: 0
                    }
                  }}
                >
                  <TableCurrenciesCell>{t('Currency')}</TableCurrenciesCell>
                  <TableCurrenciesCell sx={{ minWidth: '100px' }}>{t('Amount')}</TableCurrenciesCell>
                  <TableCurrenciesCell sx={{ minWidth: '150px' }}>{t('Value on admission')}</TableCurrenciesCell>
                  <TableCurrenciesCell align='right'>{t('Balance')}</TableCurrenciesCell>
                </TableRow>
              </TableHead>
              {rowsAvailable ? (
                <TableBody>
                  {tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                    const sign = row.is_positive ? '+' : '-'
                    const amount = row.amount_on_admission
                    const amountSum = `${sign}${row?.sum} ${row?.currency_name?.toLocaleUpperCase()}`
                    const amountOnAdmission = `${amount} ${row?.fiat_name?.toLocaleUpperCase()}`
                    const balance = parseFloat(row?.balance?.toString() ?? '')

                    return (
                      <TableRow
                        key={key}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& > .MuiTableCell-root:first-of-type': {
                            padding: 0
                          }
                        }}
                      >
                        <TableRowCell>{row?.currency_name?.toLocaleUpperCase()}</TableRowCell>
                        <TableRowCell sx={{ color: row?.is_positive ? '#64C623' : '#E04440' }}>
                          {amountSum}
                        </TableRowCell>
                        <TableBalanceRow>{amountOnAdmission}</TableBalanceRow>
                        <TableBalanceRow align='right'>{balance}</TableBalanceRow>
                      </TableRow>
                    )
                  })}
                </TableBody>
              ) : (
                <TableNoTransactions>{t('There is no transactions yet')}</TableNoTransactions>
              )}
              {rowsAvailable ? (
                <TableFooter
                  sx={{
                    '& > td': {
                      border: 0
                    }
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={<TableRowsPerPage>{t('Rows per page')}:</TableRowsPerPage>}
                    count={tableRows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableFooter>
              ) : null}
            </Table>
          </Box>
        )}
      </TableContainer>
    </CrmTransactionsTableWrapper>
  )
}
