import { styled } from '@mui/system'
import { Box, Button } from '@mui/material'

export const HeaderNavigationWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

export const HeaderNavigationItem = styled(Box)(({ theme }) => ({
  fontFamily: `'Ubuntu'`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '24px',
  color: '#FCFCFC',
  transition: '.2s ease-in-out',
  marginRight: '17px',
  cursor: 'pointer',
  '&:last-of-type': {
    marginRight: 0
  },
  '&:hover': {
    color: '#186ADF'
  }
}))

export const HeaderNavigation = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center'
}))

export const HeaderNavigationButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '16px',
  padding: '16px 0px',
  gap: '8px',
  width: 'auto',
  height: '48px',
  background: '#186ADF',
  borderRadius: '12px',
  marginRight: '24px',
  color: '#FCFCFC',
  '&:hover': {
    background: '#1B76F7'
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: '136px'
  }
}))

export const LayoutMenuButton = styled(Button)(({ theme }) => ({
  fontFamily: `'Ubuntu', sans-serif`,
  fontWeight: theme.palette.fontWeight['700'],
  fontSize: '14px',
  lineHeight: '16px',
  padding: '16px',
  gap: '8px',
  minWidth: '136px',
  height: '48px',
  background: '#186ADF',
  borderRadius: '12px',
  marginRight: '24px',
  color: '#FCFCFC',
  '&:hover': {
    background: '#1B76F7'
  }
}))
