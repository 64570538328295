import * as yup from 'yup'
import i18n from 'i18next'

export const formSchema = () =>
  yup.object().shape({
    xpub: yup.string().optional().nullable(),
    sample_receiving_address: yup.string().optional(),
    tag: yup.string().required(i18n.t('Tag is required.')).max(30, i18n.t('Tag length should be less than 30')),
    binance_address: yup.string().optional().nullable(),
    callback_url: yup.object().shape({}).required(i18n.t('HTTP Callback URL is required.')).nullable(),
    '2fa_code': yup.string().optional(),
    admin_password: yup
      .string()
      .optional()
      .matches(/[0-9a-zA-Z]{8,}/gm, i18n.t('The password must consist of latin letters and numbers!'))
  })
