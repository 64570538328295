import { Box, FormControl } from '@mui/material'
import { useState } from 'react'
import { FormSelectLabel, PaymentsSelect, SelectedRenderValue } from './styles'
import { useTranslation } from 'react-i18next'
import { DatePickerButton } from '@/shared/ui'
import { paperProps } from './utils'
import { DateTimeFormat } from '@/shared'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'

export const PaymentsFiltersTimeDropdown = () => {
  const { t, i18n } = useTranslation()
  const { watch } = useFormContext()
  const { date_start, date_end } = watch()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const locale = i18n.language === 'ukr' ? 'uk' : 'en'
  const formatPeriodDate = (date: Date | string) =>
    dayjs(date, { locale }).utcOffset(0).format(DateTimeFormat.PAYMENTS_FORMAT)

  const fromDate = formatPeriodDate(date_start)
  const toDate = formatPeriodDate(date_end)

  return (
    <FormControl variant='filled'>
      <FormSelectLabel shrink>{t('Time period')}</FormSelectLabel>
      <PaymentsSelect
        autoFocus={false}
        open={open}
        displayEmpty
        onOpen={handleOpen}
        onClose={handleClose}
        disableUnderline
        MenuProps={{
          PaperProps: paperProps
        }}
        renderValue={() => (
          <SelectedRenderValue>
            {t('Selected')}:{' '}
            {![date_start, date_end].every(Boolean) ? t(`none`) : `${t('From')} ${fromDate} ${t('to')} ${toDate}`}
          </SelectedRenderValue>
        )}
      >
        <Box>
          <DatePickerButton />
        </Box>
      </PaymentsSelect>
    </FormControl>
  )
}
