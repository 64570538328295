// ** Third Party Import
import { useTranslation } from 'react-i18next'
import { TranslationsLink } from '@/shared/layouts'

interface Props {
  text: string
}

const Translation = ({ text }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <TranslationsLink>{`${t(text)}`}</TranslationsLink>
    </>
  )
}

export default Translation
