import { useFormContext } from 'react-hook-form'
import { FormLabel, FormWrapper, PlatformTextField } from '../../react-hook-form'
import { PlatformInputGroup } from './styles'
import { useTranslation } from 'react-i18next'
import { CreateStoreData } from '@/shared'
import { FormHelperText } from '@mui/material'

type PlatformCallbackProps = { currencyName: string }
export const PlatformCallbackField = ({ currencyName }: PlatformCallbackProps) => {
  const { t } = useTranslation()
  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext<CreateStoreData>()
  const { callback_url } = watch()

  return (
    <PlatformInputGroup>
      <FormWrapper>
        <FormLabel sx={{ color: errors && errors['callback_url'] && 'error.main' }}>{t('HTTP Callback URL')}</FormLabel>
        <PlatformTextField
          {...register(`callback_url.${currencyName}`)}
          value={callback_url?.[currencyName] ?? ''}
          onChange={e => {
            const value = e.target.value
            setValue(`callback_url.${currencyName}`, value)
          }}
          error={Boolean(errors && errors['callback_url'])}
          fullWidth
          sx={{ maxWidth: 'inherit' }}
          placeholder={t('Enter your websites callback URL')}
        />
        {errors.hasOwnProperty('callback_url') && errors['callback_url'] && (
          <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
            {errors['callback_url']?.message as any}
          </FormHelperText>
        )}
      </FormWrapper>
    </PlatformInputGroup>
  )
}
