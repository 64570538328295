import toast from 'react-hot-toast'
import { Box, FormControl, IconButton, InputAdornment } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from 'react-i18next'
import { EcommerceDashboardCopyInput } from '@/shared/ui'

type Props = {
  storesToken: string
}

export const PlatformsDashboardCopyKey = ({ storesToken }: Props) => {
  const { t } = useTranslation()

  const copyTextToClipboard = async (text: string) => {
    return 'clipboard' in navigator && (await navigator.clipboard.writeText(text))
  }

  const handleCopy = () => {
    copyTextToClipboard(storesToken)
      .then(() => {
        toast.success(t('Referral link copied to clipboard'), { position: 'bottom-right' })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <FormControl fullWidth variant='outlined'>
      <Box>
        <EcommerceDashboardCopyInput
          value={storesToken}
          readOnly
          endAdornment={
            <InputAdornment position='end'>
              <IconButton type='button' sx={{ p: '10px' }} onClick={handleCopy}>
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby='outlined-weight-helper-text'
          inputProps={{
            'aria-label': 'weight'
          }}
        />
      </Box>
    </FormControl>
  )
}
