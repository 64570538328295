import { CrmCryptocurrenciesCard } from '@/shared/ui/dashboard'
import { WalletBalanceTable } from './wallet-balance-table'
import CardContent from '@mui/material/CardContent'

export const CrmWalletBalance = () => {
  return (
    <CrmCryptocurrenciesCard>
      <CardContent>
        <WalletBalanceTable />
      </CardContent>
    </CrmCryptocurrenciesCard>
  )
}
