import { SyntheticEvent, useState } from 'react'
import Image, { ImageProps } from 'next/image'
import { ImageCircularProgress, ImageWithStateContainer } from './styles'
import Skeleton from '@mui/material/Skeleton'

type ImageWithStateProps = ImageProps &
  Partial<{
    fallback: string
    debug: string
  }>

const ImageWithState = ({ src, ...props }: ImageWithStateProps): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [onErrorSrc, setOnErrorSrc] = useState<string | undefined>(undefined)
  const handleOnError = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
    if (e?.currentTarget?.src !== props.fallback) {
      setOnErrorSrc(props.fallback)
    }
  }

  return (
    <ImageWithStateContainer height={props.height} width={props.width}>
      {loading && (
        <ImageCircularProgress>
          <Skeleton variant='rectangular' height={props.height} width={props.width} animation='wave' />
        </ImageCircularProgress>
      )}
      <Image
        {...props}
        loading='lazy'
        objectFit='contain'
        alt={props.alt}
        src={onErrorSrc || src}
        onLoadingComplete={() => setLoading(false)}
        onError={e => handleOnError(e)}
      />
    </ImageWithStateContainer>
  )
}

export { ImageWithState }
