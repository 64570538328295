import * as yup from 'yup'
import i18n from 'i18next'

export const disableFormSchema = () =>
  yup.object().shape({
    code: yup
      .string()
      .required(i18n.t('Code is required field.'))
      .matches(/^\d+$/, i18n.t('Must be only digits'))
      .max(6, i18n.t('Code should be 6 characters.'))
  })
