import * as yup from 'yup'
import i18n from 'i18next'

export const setNewEmailFormSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('Email is required field.'))
      .email(i18n.t('Invalid e-mail format.'))
      .min(8, i18n.t('Email should be minimum 8 characters.'))
      .max(100, i18n.t('Email should be 100 characters.')),
    password: yup
      .string()
      .required(i18n.t('Password is required field.'))
      .min(8, i18n.t('Password should be minimum 8 characters.'))
      .max(16, i18n.t('Password should be up to 16 characters.'))
      .matches(
        /^(?=[a-zA-Z\d]*[A-Z])(?=[a-zA-Z\d]*[a-z])(?=[a-zA-Z\d]*\d)[a-zA-Z\d]{8,16}$/g,
        i18n.t('At least 8 character long, must include at least one-ticket uppercase character and digit')
      )
  })
