import * as yup from 'yup'
import i18n from 'i18next'

export const loginFormSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('Email is required field.'))
      .email(i18n.t('Invalid e-mail format.'))
      .min(8, i18n.t('Email should be minimum 8 characters.'))
      .max(100, i18n.t('Email should be 100 characters.')),
    password: yup
      .string()
      .required(i18n.t('Password is required field.'))
      .min(8, '')
      .max(16, i18n.t('Password should be up to 16 characters.'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,16}$/gm, ' ')
  })
