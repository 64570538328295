import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import { useTranslation } from 'react-i18next'
import { FiltersHeading, PaymentsHistoryDialogTitle, PaymentsHistoryPaper } from './styles'
import { Chart, registerables } from 'chart.js'
import { styled } from '@mui/system'
import Dialog from '@mui/material/Dialog'
import { axiosInstance, useAppSelector } from '@/shared'
import { historyStateSelector } from '@/app/store/selectors/history.selectors'

interface GetPaymentTransactionStatsOutput {
  data: {
    bnb: {
      count: number
      percent: number
    }
    btc: {
      count: number
      percent: number
    }
  }
}

interface PaymentTransactionChartModalProps {
  storeId: number
  onClose: () => void
}

export const PaymentTransactionChartModal = memo<PaymentTransactionChartModalProps>(({ storeId, onClose }) => {
  const canvasRef = useRef<HTMLCanvasElement>()
  const chartRef = useRef<Chart>()
  const isLoadingRef = useRef(false)

  const [data, setData] = useState<GetPaymentTransactionStatsOutput['data'] | null>(null)

  const {
    history: { filters }
  } = useAppSelector(historyStateSelector)
  const { t } = useTranslation()

  useEffect(() => {
    if (!canvasRef.current || !data) return

    const chart = new Chart(canvasRef.current, {
      type: 'doughnut',
      data: {
        labels: [t('BTC'), t('BNB')],
        datasets: [
          {
            data: [data.btc.count, data.bnb.count],
            backgroundColor: ['#186adf', '#5d72d0'],
            borderColor: '#292929'
          },
          {
            data: []
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }
    })

    return () => {
      chart.destroy()
    }
  }, [data, t])

  const getData = useCallback(async () => {
    if (isLoadingRef.current) return

    isLoadingRef.current = true

    Chart.register(...registerables)

    let url = `/api/v1/stores/${storeId}/stats`

    if (filters && filters['date_start'] && filters['date_end']) {
      url += `?period_start=${filters['date_start']}&period_end=${filters['date_end']}`
    } else if (filters && filters['date_start']) {
      url += `?period_start=${filters['date_start']}`
    } else if (filters && filters['date_end']) {
      url += `?period_end=${filters['date_end']}`
    }

    const {
      data: { data }
    } = await axiosInstance.get<GetPaymentTransactionStatsOutput>(url)

    if (chartRef.current) {
      chartRef.current?.destroy()
    }

    setData(data)
  }, [filters, storeId])

  return (
    <Container onClose={onClose} open={true} PaperComponent={PaymentsHistoryPaper}>
      <PaymentsHistoryDialogTitle onClose={onClose} />
      <DialogContent>
        <FiltersHeading>{t('Transaction Volumes')}</FiltersHeading>

        <CanvasContainer>
          <CanvasWrapper>
            {data && (
              <CanvasValueContainer>
                <CanvasValueTitle>{t('Total')}</CanvasValueTitle>
                <CanvasValueAmount>{data.btc.count + data.bnb.count}</CanvasValueAmount>
              </CanvasValueContainer>
            )}
            <canvas
              ref={ref => {
                if (ref) {
                  canvasRef.current = ref
                  void getData()
                }
              }}
            />
          </CanvasWrapper>
        </CanvasContainer>

        {data && (
          <Footer>
            <FooterRow>
              <FooterRowColor style={{ backgroundColor: '#186adf' }} />
              <FooterRowTitle>{t('BTC')}</FooterRowTitle>
              <FooterRowAmount>{data.btc.count}</FooterRowAmount>
              <FooterRowPercent>{data.btc.percent.toFixed(2)}%</FooterRowPercent>
            </FooterRow>
            <FooterRow style={{ marginTop: 16 }}>
              <FooterRowColor style={{ backgroundColor: '#5d72d0' }} />
              <FooterRowTitle>{t('BNB')}</FooterRowTitle>
              <FooterRowAmount>{data.bnb.count}</FooterRowAmount>
              <FooterRowPercent>{data.bnb.percent.toFixed(2)}%</FooterRowPercent>
            </FooterRow>
          </Footer>
        )}
      </DialogContent>
    </Container>
  )
})

export const Container = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    padding: '36px 56px',
    width: 'auto'
  },
  '& .MuiDialogContent-root': {
    width: '100%',
    overflow: 'hidden',
    padding: '0 3px'
  }
}))

const CanvasContainer = styled('div')`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
`

const CanvasWrapper = styled('div')`
  width: 60vw;
  max-width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CanvasValueContainer = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CanvasValueTitle = styled('div')`
  color: rgba(255, 255, 255, 0.32);
`

const CanvasValueAmount = styled('div')`
  font-size: 28px;
  font-weight: 700;
`

const Footer = styled('div')`
  margin-top: 24px;
`

const FooterRow = styled('div')`
  display: flex;
  align-items: center;
`

const FooterRowColor = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 5px;
  margin-right: 8px;
`

const FooterRowTitle = styled('div')`
  font-size: 16px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  flex-grow: 1;
  flex-basis: 0;
`

const FooterRowAmount = styled('div')`
  font-size: 24px;
  font-weight: 700;
  font-family: Inter, sans-serif;
  margin-left: 20px;
`

const FooterRowPercent = styled('div')`
  width: 70px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  margin-left: 20px;
`
