import {
  CalendarIcon,
  ClientsFormLabel,
  ClientsInputFormControl,
  DatePickerCalendarWrapper,
  DatePickerDateButton,
  DatePickerDialog,
  DatePickerDialogContent,
  DateTextField,
  DialogButton,
  DialogButtonGroup
} from '@/shared/ui/history/styles'
import { useCallback, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Controller, useFormContext } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimeFormat } from '@/shared'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export const DatePickerButton = () => {
  const { control, setValue, reset } = useFormContext()
  const { t, i18n } = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)
  const adapterLocale = i18n.language === 'en' ? 'en' : 'uk'
  const today = useMemo(() => new Date(), [])
  const libDate = dayjs(today)
  const yesterday = libDate.add(-1, 'day').toDate()
  const lastThreeDays = libDate.add(-3, 'day').toDate()
  const lastSevenDays = libDate.add(-7, 'day').toDate()
  const lastMonth = libDate.add(-1, 'month').toDate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const onAppliedClicked = () => {
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onRangeSelect = useCallback(
    (start: Date, end: Date) => {
      setValue('date_start', start.toISOString())
      setValue('date_end', end.toISOString())
    },
    [setValue]
  )

  const dateRanges = [
    {
      label: 'Last 24h',
      startDate: yesterday,
      endDate: today
    },
    {
      label: 'Last 3 days',
      startDate: lastThreeDays,
      endDate: today
    },
    {
      label: 'Last 7 days',
      startDate: lastSevenDays,
      endDate: today
    },
    {
      label: 'Last month',
      startDate: lastMonth,
      endDate: today
    }
  ]

  const renderButtonGroup = () =>
    dateRanges.map(({ label, startDate, endDate }) => (
      <DialogButton key={label} onClick={() => onRangeSelect(startDate, endDate)}>
        {t(label)}
      </DialogButton>
    ))

  const renderCalendarDropdown = () =>
    dateRanges.map(({ label, startDate, endDate }) => (
      <MenuItem key={label} onClick={() => onRangeSelect(startDate, endDate)} value={label}>
        {t(label)}
      </MenuItem>
    ))

  const onDateReset = () => {
    reset({
      date_start: '',
      date_end: ''
    })
  }

  return (
    <DatePickerCalendarWrapper>
      <DatePickerDateButton
        variant={'contained'}
        onClick={e => {
          e.stopPropagation()
          handleClickOpen()
        }}
        endIcon={<CalendarIcon />}
      >
        {t('calendar')}
      </DatePickerDateButton>
      <DatePickerDialog fullWidth maxWidth='md' onClose={handleClose} open={open} onClick={e => e.stopPropagation()}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
          <DatePickerDialogContent onClick={e => e.stopPropagation()}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                  m: 1
                }
              }}
            >
              {isMobile ? (
                <Select
                  defaultValue=''
                  sx={{
                    height: '40px'
                  }}
                  displayEmpty
                  renderValue={value => (value?.length ? (Array.isArray(value) ? value.join(', ') : value) : t('none'))}
                >
                  <MenuItem value={'none'} onClick={onDateReset}>
                    {t('none')}
                  </MenuItem>
                  {renderCalendarDropdown()}
                </Select>
              ) : (
                <DialogButtonGroup variant='contained' orientation={'horizontal'}>
                  {renderButtonGroup()}
                </DialogButtonGroup>
              )}
            </Box>
            <Controller
              control={control}
              name='date_start'
              render={({ field }) => (
                <ClientsInputFormControl sx={{ m: '0 auto', mt: 5 }}>
                  <ClientsFormLabel>{t('Start date')}</ClientsFormLabel>
                  <DatePicker
                    {...field}
                    inputFormat={DateTimeFormat.FULL_DATE_FORMAT}
                    onChange={date => {
                      const localDate = dayjs(date).utc(true).toDate()
                      setValue('date_start', localDate.toISOString())
                    }}
                    renderInput={params => (
                      <DateTextField
                        {...params}
                        disabled
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t('Select start date'),
                          readOnly: true
                        }}
                      />
                    )}
                  />
                </ClientsInputFormControl>
              )}
            />
            <Controller
              control={control}
              name='date_end'
              render={({ field }) => (
                <ClientsInputFormControl sx={{ m: '0 auto', mt: 5 }}>
                  <ClientsFormLabel>{t('End date')}</ClientsFormLabel>
                  <DatePicker
                    {...field}
                    inputFormat={DateTimeFormat.FULL_DATE_FORMAT}
                    onChange={date => {
                      const localDate = dayjs(date).utc(true).toDate()
                      setValue('date_end', localDate.toISOString())
                    }}
                    renderInput={params => (
                      <DateTextField
                        {...params}
                        disabled
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t('Select end date'),
                          readOnly: true
                        }}
                      />
                    )}
                  />
                </ClientsInputFormControl>
              )}
            />
          </DatePickerDialogContent>
        </LocalizationProvider>
        <DialogActions>
          <Button onClick={onAppliedClicked} autoFocus>
            {t('Apply')}
          </Button>
        </DialogActions>
      </DatePickerDialog>
    </DatePickerCalendarWrapper>
  )
}
