// Styled component for Blank Layout component
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

export const BlankLayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  background: '#101010',

  // For V1 Blank layout pages
  '& .content-center': {
    display: 'flex',
    minHeight: '87vh',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '56px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '81vh'
    }
  },

  // For V2 Blank layout pages
  '& .content-right': {
    display: 'flex',
    minHeight: '87vh',
    overflowX: 'hidden',
    position: 'relative'
  }
}))
