import Box from '@mui/material/Box'
import { TicketsPagination } from '@/shared/ui/tickets'
import { withdrawalTicketsStateSelector } from '@/app/store/selectors'
import { useCallback, useDeferredValue } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/shared'
import { WithdrawalSingleTicket } from '@/shared/ui/withdrawal-tickets/ui/withdrawal-single-ticket'
import { WithdrawalTicket } from '@/shared/types/withdrawal-tickets'

type Props = {
  handleChange(e: any, p: number): void
  list: WithdrawalTicket[]
}

export const WithdrawalTicketsList = ({ handleChange, list }: Props) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { meta } = useAppSelector(withdrawalTicketsStateSelector)
  const count = Math.ceil(Number(meta?.total) / Number(meta?.perPage))
  const onTicketClick = useCallback(
    (id: string) => {
      router.push(`/withdrawals-tickets/${id}`)
    },
    [router]
  )

  const ticketsList = useDeferredValue(
    (list ?? []).map(ticket => (
      <Box key={ticket.id}>
        <WithdrawalSingleTicket ticket={ticket} handleClick={() => onTicketClick(String(ticket.id))} />
      </Box>
    ))
  )

  return (
    <Box>
      {ticketsList.length ? (
        <>
          <Box>{ticketsList}</Box>
          <TicketsPagination
            count={count}
            size='large'
            page={Number(meta?.currentPage)}
            shape='circular'
            onChange={handleChange}
          />
        </>
      ) : (
        <Box sx={{ textAlign: 'center', fontSize: '20px' }}>{t('No tickets yet...')}</Box>
      )}
    </Box>
  )
}
