import {
  AccordionChip,
  AccordionChipLabel,
  AccordionChipText,
  AccordionContent,
  AccordionSummaryContent,
  AccordionTypography,
  EcommerceAccordion,
  IStore,
  PlatformEditMode,
  PlatformOptionsMenu,
  truncateString,
  useAppDispatch,
  useEditMode
} from '@/shared'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionDetails } from '@mui/material'
import { PlatformsEditForm } from '@/shared/ui/platforms/ui/platforms-edit-form'
import { SyntheticEvent, useCallback, useState } from 'react'
import { PlatformAccordionEditForm } from '@/shared/ui/platforms/ui/platform-accordion-edit-form'
import { deleteStore, getStores } from '@/app/store/thunks'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

type Props = {
  store: IStore & { panel: string }
}

export const PlatformPreparedStore = ({ store }: Props) => {
  const [expanded, setExpanded] = useState<string | boolean>(false)
  const { editMode, disableEditMode, enableEditMode } = useEditMode()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    event.preventDefault()
    if (event.target !== event.currentTarget) {
      setExpanded(isExpanded ? panel : false)
    }
  }

  const onExpandHandler = () => setExpanded(true)

  const onExpandCloseHandler = () => setExpanded(false)

  const onDeleteStore = useCallback(
    async (id: string) => {
      await dispatch(deleteStore({ id }))
        .unwrap()
        .then(() => {
          toast.success(t('Store deleted!'), { position: 'bottom-right' })
          dispatch(getStores({}))
          setExpanded(false)
        })
    },
    [dispatch, t]
  )

  return (
    <EcommerceAccordion
      key={store?.id}
      expanded={expanded === store.panel}
      onChange={handleChange(store.panel)}
      disableGutters
    >
      <PlatformEditMode>
        {editMode ? (
          <PlatformAccordionEditForm store={store} disableEditMode={disableEditMode} />
        ) : (
          <AccordionSummaryContent expandIcon={<ExpandMoreIcon fontSize={'large'} />}>
            <AccordionContent sx={{ mr: 10 }}>
              <AccordionTypography>{truncateString(String(store?.name), 10)}</AccordionTypography>
              <AccordionChip>
                <AccordionChipLabel />
                <AccordionChipText>{store.tag}</AccordionChipText>
              </AccordionChip>
            </AccordionContent>
            <PlatformOptionsMenu
              enableEditMode={enableEditMode}
              onDeleteStore={() => onDeleteStore(String(store?.id))}
            />
          </AccordionSummaryContent>
        )}
      </PlatformEditMode>
      <AccordionDetails sx={{ padding: 0 }}>
        <PlatformsEditForm
          incomingFormValues={store}
          openAccordion={onExpandHandler}
          closeAccordion={onExpandCloseHandler}
        />
      </AccordionDetails>
    </EcommerceAccordion>
  )
}
