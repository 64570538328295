// ** MUI Imports
import Box from '@mui/material/Box'

// ** Type Import
import { Settings } from '@/app/context/settings-context'

// ** Components
import Autocomplete from '@/shared/layouts/core/additional/autocomplete'
import UserDropdown from '@/shared/ui/user-dropdown/user-dropdown'
import NotificationDropdown from '@/shared/ui/notification-dropdown/notification-dropdown'
import LanguageDrop from '@/shared/ui/language-drop/language-drop'

interface Props {
  hidden: boolean
  settings: Settings
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete hidden={hidden} settings={settings} />
      <LanguageDrop settings={settings} saveSettings={saveSettings} />
      <NotificationDropdown settings={settings} />
      <UserDropdown settings={settings} />
    </Box>
  )
}

export default AppBarContent
