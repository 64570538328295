import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const HeadingText = styled(Box)(({ theme }) => ({
  fontFamily: `'Inter'`,
  fontWeight: theme.palette.fontWeight['900'],
  fontSize: '42px',
  lineHeight: '56px',
  letterSpacing: '0.25px',
  color: '#FCFCFC',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
    lineHeight: '40px'
  }
}))
