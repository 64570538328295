/**
 * Hook form imports
 */
import { Control, Controller } from 'react-hook-form'

/**
 * mui imports
 */
import { FormControl, FormHelperText, TextFieldProps } from '@mui/material'

/**
 * styled items from styling
 */
import { FieldLabel, FormTextField, FormWrapper } from './styles'

/**
 * DashboardSubmitInput type for props
 */
type SubmitInputProps = {
  control?: Control<any> | undefined
  fullWidth?: boolean
  disableMargin?: boolean
  name: string
  className?: string
  label: string
  sx?: any
} & TextFieldProps

/**
 * Custom form input component
 * @params {SubmitInputProps} - props for input component
 * @default type - text
 */
export const SubmitInput = ({
  control,
  fullWidth = false,
  disableMargin = false,
  sx = {},
  label = '',
  name,
  variant = 'outlined',
  placeholder,
  ...otherProps
}: SubmitInputProps) => {
  return (
    <FormControl sx={{ mb: !disableMargin ? '24px' : 0, width: '100%' }}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field, formState: { errors } }) => (
          <FormWrapper>
            <FieldLabel sx={{ color: errors && errors[name] && 'error.main' }}>{label}</FieldLabel>
            <FormTextField
              {...field}
              {...otherProps}
              variant={variant}
              autoComplete='off'
              sx={{ maxWidth: fullWidth ? '100%' : 300, width: '100%', ...sx }}
              value={field.value || ''}
              error={Boolean(errors[name])}
              placeholder={placeholder}
              InputLabelProps={{ shrink: false }}
            />
            <FormHelperText sx={{ color: 'error.main', ml: 0, mt: '7px' }}>
              {errors[name]?.message as string}
            </FormHelperText>
          </FormWrapper>
        )}
      />
    </FormControl>
  )
}
