import axios from 'axios'
import { authService, tokenService } from '@/shared'

type Subscriber = Parameters<typeof subscribeTokenRefresh>[0]

const headerParams = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

export const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  ...headerParams
})

export const axiosDownloadInstance = axios.create()

export const wpAxiosInstance = axios.create({
  baseURL: process.env.WP_API_URL,
  ...headerParams
})

axiosInstance.interceptors.request.use(
  config => {
    const token = tokenService.getLocalAccessToken() ?? ''

    if (config.headers && token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => Promise.reject(error)
)

let isRefreshing = false
let subscribers: Subscriber[] = []

const subscribeTokenRefresh = (cb: (token: string) => void): void => {
  subscribers.push(cb)
}

const onRefreshed = (token: string) => {
  subscribers.map(cb => cb(token))
}

axiosInstance.interceptors.response.use(undefined, err => {
  const status = err?.response?.status
  const originalRequest = err?.config

  if (status === 401) {
    if (!isRefreshing) {
      isRefreshing = true
      authService.refreshAccessTokenFx().then(response => {
        const token = response?.access_token ?? ''
        if (!token) return

        isRefreshing = false
        onRefreshed(token)
        subscribers = []
      })
    }

    return new Promise((resolve, reject) => {
      subscribeTokenRefresh(token => {
        if (!token) reject('Token was not provided!')
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
        resolve(axiosInstance(originalRequest))
      })
    })
  }

  return Promise.reject(err)
})
