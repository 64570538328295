import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { ClearButton, FiltersButton, FiltersButtonText } from './styles'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/shared'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { historyStateSelector } from '@/app/store/selectors/history.selectors'
import Zoom from '@mui/material/Zoom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

type Props = {
  readonly onClick: () => void
  readonly tableFiltersReset: () => void
}

export const PaymentsHistoryTableToolbar = ({ onClick, tableFiltersReset }: Props) => {
  const { t } = useTranslation()
  const { filters } = useAppSelector(historyStateSelector)
  const theme = useTheme()
  const hidden = useMediaQuery(theme.breakpoints.down('sm'))
  const hasFilters = Object.values(filters ?? {}).some(value =>
    Array.isArray(value) ? value[0] !== 'none' : value !== ''
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {hasFilters ? (
        <Zoom in={hasFilters}>
          <ClearButton variant='text' onClick={tableFiltersReset}>
            {t('Clear')}
          </ClearButton>
        </Zoom>
      ) : null}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
        onClick={onClick}
      >
        <FiltersButton variant='contained'>
          {hidden ? null : <FiltersButtonText>{t('Filters')}</FiltersButtonText>}
          <FilterAltIcon sx={{ color: '#FFFFFF' }} />
        </FiltersButton>
      </Toolbar>
    </Box>
  )
}
